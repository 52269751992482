import React, { useMemo } from 'react'
import { Box, Group, Space, Text } from '@mantine/core'

import { ErrorBeacon, InlineLoader, useFormContext } from '@/components'
import { useSelectedCoin } from '@/contexts/wallet'
import { formatDenom, formatCurrency } from '@/wallet-utils'
import { useTokenValueInUsdQuery } from '@/queries'
import { useHostZoneQuery } from '../queries'
import { useStake } from '../StakeProvider'
import { StakeFormType } from './useStakeForm'
import { getLsmEstimatedStTokenValue } from '../utils'
const StakeLsmEstimate: React.FC = () => {
  const denom = useSelectedCoin()

  const { lsm } = useStake()

  const { values } = useFormContext<StakeFormType>()

  const { data: stTokenValueInUsd, error: stTokenValueInUsdError } = useTokenValueInUsdQuery(`st${denom}`)

  const { data: hostZone, isLoading: isHostZoneLoading, error: hostZoneError } = useHostZoneQuery()

  const estimate = useMemo(() => {
    return getLsmEstimatedStTokenValue({
      amount: Number(values.lsmAmount),
      hostZone,
      validatorAddress: lsm.validatorAddress
    })
  }, [lsm, hostZone, values, denom])

  if (hostZoneError) {
    return (
      <StakeLsmEstimateWrapper>
        <ErrorBeacon label="Unable to fetch redemption rate at this time. Please try again." />
      </StakeLsmEstimateWrapper>
    )
  }

  if (isHostZoneLoading) {
    return (
      <StakeLsmEstimateWrapper>
        <InlineLoader />
      </StakeLsmEstimateWrapper>
    )
  }

  return (
    <StakeLsmEstimateWrapper>
      <Text weight={700} size="xl" align="right" sx={(t) => ({ color: t.colors.gray[8] })} inline>
        {format(estimate)}
      </Text>

      <Space h={4} />

      <Group spacing="xs" position="right">
        <Text weight={500} align="right" size="sm" inline sx={(t) => ({ color: t.colors.gray[7] })}>
          <span>≈</span>
        </Text>

        {stTokenValueInUsdError ? (
          <ErrorBeacon label={`Unable to get the dollar value of st${denom}. Please try again.`} />
        ) : stTokenValueInUsd == null ? (
          <InlineLoader />
        ) : (
          <Text weight={500} align="right" size="sm" inline sx={(t) => ({ color: t.colors.gray[7] })}>
            {formatCurrency(estimate * stTokenValueInUsd)}
          </Text>
        )}
      </Group>
    </StakeLsmEstimateWrapper>
  )
}

const format = (estimate: number) => {
  if (estimate >= 1) return formatDenom(estimate, 2)
  if (estimate >= 0.1) return formatDenom(estimate, 3)
  if (estimate >= 0.01) return formatDenom(estimate, 4)
  return formatDenom(estimate, 5)
}

const StakeLsmEstimateWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Box sx={(t) => ({ paddingRight: t.other.stakeForm.amountPaddingRight })}>{children}</Box>
}

export { StakeLsmEstimate }
