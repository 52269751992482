import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { stride } from 'stridejs'
import { STRIDE_CHAIN_INFO, AIRDROP_IDENTIFIERS } from '@/config'
import { fatal, disregard } from '@/utils'
import { queryKeys } from '@/query-keys'
import { notify } from '@/contexts/notifications'
import { useTopBanner } from '@/contexts/top-banner'
import { assertIsDeliverTxSuccess, broadcastTx, formatMicroDenom } from '@/wallet-utils'
import { MutationParameters } from './types'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { dismissedTransactionsAtom } from '../../atoms'
import { useRefreshBalances, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

export interface BroadcastLiquidStakeMutationReturnType {
  transaction: DeliverTxResponse
  claimableAmount: bigint
}

const useBroadcastLiquidStakeMutation = ({
  ibcTransferTransaction,
  liquidStakeRaw,
  setLiquidStakeTransaction
}: MutationParameters) => {
  const strideAccount = useStrideWallet()
  const selectedAccount = useSelectedWallet()
  const updateAccountBalances = useRefreshBalances()

  const setDismissedTxs = useSetAtom(dismissedTransactionsAtom)

  const { setBanner } = useTopBanner()

  const client = useQueryClient()

  // We'll fetch if user has any claimable amount and prepare the data needed
  // so we can properly display a top-banner notification after liquid staking succeeds.
  const getClaimableAmountFromLiquidStakeTask = async (): Promise<bigint> => {
    if (!strideAccount) {
      // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
      // But we'll return anyway instead of crashing just to be safe (given the unknowns).
      // https://github.com/Stride-Labs/interface/issues/262
      return BigInt(0)
    }

    const rpc = await stride.ClientFactory.createRPCQueryClient({
      rpcEndpoint: STRIDE_CHAIN_INFO.rpc
    })

    const data = await Promise.all(
      AIRDROP_IDENTIFIERS.map((identifier) => {
        return rpc.stride.claim.claimableForAction({
          airdropIdentifier: identifier,
          address: strideAccount.address,
          action: 1 // 1 Correponds to ACTION_LIQUID_STAKE
        })
      })
    )

    const total = data.reduce((total, claimable) => {
      const sum = claimable.coins.reduce((t, coin) => {
        return t + BigInt(coin.amount)
      }, BigInt(0))

      return total + sum
    }, BigInt(0))

    return total
  }

  // @TODO: It may make more sense to move this entirely to the mutation.
  // If the user completed the airdrop task, we'll display a top-banner notification
  const handleClaimableAmountFromLiquidStakeTask = (amount: bigint) => {
    if (amount <= 0) {
      return
    }

    if (!strideAccount) {
      // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
      // But we'll return anyway instead of crashing just to be safe (given the unknowns).
      // https://github.com/Stride-Labs/interface/issues/262
      return
    }

    const formattedAmount = `${formatMicroDenom(String(amount), strideAccount.currency.coinMinimalDenom, 2)} ${
      strideAccount.currency.coinDenom
    }`

    setBanner({
      icon: 'arrowHorizontal',
      text: (
        <>
          You completed a task and claimed <strong>{formattedAmount}</strong>
        </>
      ),
      url: '/airdrop/claim'
    })
  }

  const handleMutation = async (): Promise<BroadcastLiquidStakeMutationReturnType> => {
    if (!strideAccount || !selectedAccount || !strideAccount.client) {
      throw fatal('Unable to stake without connecting your wallet.')
    }

    if (!liquidStakeRaw) {
      throw fatal('Unable to broadcast transaction without a signed transaction raw.')
    }

    const claimableAmount = await getClaimableAmountFromLiquidStakeTask().catch(() => {
      return BigInt(0)
    })

    const bytes = TxRaw.encode(liquidStakeRaw).finish()

    const transaction = await broadcastTx(strideAccount.client, bytes)

    assertIsDeliverTxSuccess(transaction)

    return { transaction, claimableAmount }
  }

  const handleSettled = async () => {
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })
  }

  const handleSuccess = async ({ transaction, claimableAmount }: BroadcastLiquidStakeMutationReturnType) => {
    setLiquidStakeTransaction(transaction)

    if (ibcTransferTransaction) {
      // We'll check for the existence of the transaction ref since it's not guaranteed to exist
      // like for instance, user skipped the IBC transfer step.
      // We'll dismiss the preceding IBC transfer transaction since they're likely connected.
      // This would minimize the number of transactions in the transaction history.
      setDismissedTxs((dismissedTxs) => [...dismissedTxs, ibcTransferTransaction.transactionHash])
    }

    handleClaimableAmountFromLiquidStakeTask(claimableAmount)

    if (!strideAccount || !selectedAccount) {
      // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
      // But we'll return anyway instead of crashing just to be safe (given the unknowns).
      // https://github.com/Stride-Labs/interface/issues/262
      return
    }

    try {
      await updateAccountBalances()
    } catch (e) {
      disregard(e)
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
    }
  }

  return useMutation({
    mutationFn: handleMutation,
    onSettled: handleSettled,
    onSuccess: handleSuccess
  })
}

export { useBroadcastLiquidStakeMutation }
