import React, { useContext, useState } from 'react'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal } from '@/utils'
import {
  useSignRedeemStakeMutation,
  useBroadcastRedeemStakeMutation,
  useClaimTokensMutation,
  MutationParameters
} from './mutations'

interface UnstakeModalContextType {
  resetUnstakeModalState: () => void

  signRedeemStake: () => Promise<TxRaw>
  isSigningRedeemStake: boolean
  signRedeemStakeError: unknown

  broadcastRedeemStake: () => Promise<DeliverTxResponse>
  isBroadcastingRedeemStake: boolean
  broadcastRedeemStakeError: unknown

  claimTokens: () => Promise<DeliverTxResponse>
  isClaimingTokens: boolean
  claimTokensError: unknown
}

const UnstakeModalContext = React.createContext<UnstakeModalContextType | null>(null)

const UnstakeModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [redeemStakeRaw, setRedeemStakeRaw] = useState<TxRaw | null>(null)

  const parameters: MutationParameters = {
    redeemStakeRaw,
    setRedeemStakeRaw
  }

  const {
    mutateAsync: signRedeemStake,
    isPending: isSigningRedeemStake,
    error: signRedeemStakeError,
    reset: resetSignRedeemStake
  } = useSignRedeemStakeMutation(parameters)

  const {
    mutateAsync: broadcastRedeemStake,
    isPending: isBroadcastingRedeemStake,
    error: broadcastRedeemStakeError,
    reset: resetBroadcastRedeemStake
  } = useBroadcastRedeemStakeMutation(parameters)

  const {
    mutateAsync: claimTokens,
    isPending: isClaimingTokens,
    error: claimTokensError,
    reset: resetClaimTokens
  } = useClaimTokensMutation()

  const resetUnstakeModalState = () => {
    setRedeemStakeRaw(null)
    resetSignRedeemStake()
    resetBroadcastRedeemStake()
    resetClaimTokens()
  }

  return (
    <UnstakeModalContext.Provider
      value={{
        resetUnstakeModalState,

        signRedeemStake,
        isSigningRedeemStake,
        signRedeemStakeError,

        broadcastRedeemStake,
        isBroadcastingRedeemStake,
        broadcastRedeemStakeError,

        claimTokens,
        isClaimingTokens,
        claimTokensError
      }}>
      {children}
    </UnstakeModalContext.Provider>
  )
}

const useUnstakeModal = () => {
  const context = useContext(UnstakeModalContext)

  if (context == null) {
    throw fatal('You forgot to mount UnstakeModalProvider.')
  }

  return context
}

export { UnstakeModalProvider, useUnstakeModal }
