import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { notify } from '@/contexts/notifications'
import { fatal, disregard } from '@/utils'
import { queryKeys } from '@/query-keys'
import {
  assertIsDeliverTxSuccess,
  broadcastIbcTransaction,
  traceIbcStatus,
  IBCTransferStatus,
  isSafeModeAccount
} from '@/wallet-utils'
import { MutationParameters } from './types'
import { useRefreshBalances, useSelectedWallet } from '@/contexts/wallet'
import { flushSync } from 'react-dom'

export interface BroadcastIbcTransferMutationReturnType {
  tx: DeliverTxResponse
  status: IBCTransferStatus
}

const useBroadcastIbcTransferMutation = ({ ibcTransferRaw, setIbcTransferTransaction }: MutationParameters) => {
  const selectedAccount = useSelectedWallet()

  const updateAccountBalances = useRefreshBalances()

  const client = useQueryClient()

  const handleMutation = async (): Promise<BroadcastIbcTransferMutationReturnType> => {
    if (!selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!ibcTransferRaw) {
      throw fatal('Unable to broadcast transaction without a signed transaction raw.')
    }

    if (isSafeModeAccount(selectedAccount)) {
      throw fatal('Safe mode is enabled.')
    }

    const tx = await broadcastIbcTransaction(selectedAccount, ibcTransferRaw)

    assertIsDeliverTxSuccess(tx)

    const status = await traceIbcStatus({ account: selectedAccount, tx })

    if (status === 'return-later') {
      return {
        tx: tx,
        status: 'return-later'
      }
    }

    // We'll handle this after balance is updated because gas fee is still consumed
    // @TODO: It's possible that we may have to wait for funds to be fully refunded
    // @TODO: Handle error - we probably need a flow for this
    if (status === 'timeout') {
      return {
        tx: tx,
        status: 'timeout'
      }
    }

    return {
      tx: tx,
      status: 'complete'
    }
  }

  const handleSuccess = async ({ tx }: BroadcastIbcTransferMutationReturnType) => {
    // This is the only way to allow React v18 to update the state first before
    // the next mutation (which requires this state to have a value) is executed.
    flushSync(() => {
      setIbcTransferTransaction(tx)
    })

    try {
      await updateAccountBalances()
    } catch (e) {
      notify.error(`We could not refresh your transaction history. Please refresh the page upon closing this modal.`)
      disregard(e)
    }

    // We only want to invalidate the transaction history ibc balances after the account balances have been updated,
    // given that implementation for LSM IBC relies on stride's account balances. For the same reason, unlike most
    // IBC transactions, we won't be invalidating the ibc status query.
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryLsmIbcBalancesBase })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

export { useBroadcastIbcTransferMutation }
