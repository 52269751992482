import React from 'react'
import BigNumber from 'bignumber.js'
import { Button } from '@mantine/core'
import { CHAIN_INFO_LIST } from '@/config'
import { StepperModalContent, useStepperContext } from '@/components'
import { SimulateGasInsufficientAmountError } from './mutations'
import { useStakeIbcx } from '../StakeIbcxProvider'
import { useStakeIbcxModal } from './StakeIbcxModalProvider'
import { fatal } from '@/utils'

import { IBCX_CURRENCY } from '@/config/ibcx'
import { useChainWallet } from '@/contexts/wallet'

const StakeIbcxStepOne: React.FC = () => {
  const { start, nextStep, forceClose, close } = useStepperContext()

  const { signStakeIbcx, isSigningStakeIbcx, signStakeIbcxError } = useStakeIbcxModal()

  const { stakeModal } = useStakeIbcx()

  const { data: osmosisAccount } = useChainWallet('OSMO')

  const denom = IBCX_CURRENCY.coinDenom

  if (osmosisAccount == null) {
    throw fatal('Unable to stake ibcx while disconnected.')
  }

  const formattedAmount = `${new BigNumber(stakeModal.amount).decimalPlaces(3).toString()} ${denom}`

  const handleStart = async () => {
    start()
    await signStakeIbcx()
    nextStep()
  }

  const handleStartStaking = () => {
    handleStart()
  }

  const handleRetryStaking = () => {
    handleStart()
  }

  if (signStakeIbcxError instanceof SimulateGasInsufficientAmountError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            This transfer could not be completed because the calculation of the estimated underlying assets' amount has
            failed. Please try increasing the amount you're staking.
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetryStaking}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (signStakeIbcxError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`This transfer could not be completed. Your tokens are on ${
          CHAIN_INFO_LIST[osmosisAccount.currency.coinDenom].chainName
        }, but have not been staked. Please try again.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
            <Button color="dark" variant="outline" onClick={handleRetryStaking}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningStakeIbcx) {
    return (
      <StepperModalContent
        title="Approve the transaction in your wallet to continue"
        description={`This will initiate staking and the transfer of ${denom} to your wallet`}
      />
    )
  }

  return (
    <StepperModalContent
      title={`Nice! You’re about to liquid stake ${formattedAmount} on Osmosis`}
      description={`This will initiate staking of ${denom} to your wallet.`}
      actions={
        <>
          <Button variant="outline" color="dark" onClick={close}>
            Cancel
          </Button>

          <Button onClick={handleStartStaking}>Start staking</Button>
        </>
      }
    />
  )
}

export { StakeIbcxStepOne }
