import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { useMutation } from '@tanstack/react-query'

import { delay, fatal } from '@/utils'
import { convertDenomToMicroDenom } from '@/wallet-utils'

import { MutationParameters } from './types'
import { IBCX_CONTRACTS, STAKED_IBCX_CURRENCY } from '@/config/ibcx'

import BigNumber from 'bignumber.js'
import { useStakeIbcx } from '../../StakeIbcxProvider'
import { simulateStIbcxRedeem } from '../../queries/simulate'
import { useOsmosisPoolsQuery } from '../../queries/useOsmosisPoolsQuery'
import { ExecuteMsg } from '@many-things/ibcx-contracts-sdk/types/contracts/Periphery.types'
import { toUtf8 } from '@cosmjs/encoding'
import { useChainWallet } from '@/contexts/wallet'

const useSignRedeemStIbcxMutation = ({ setRedeemStakeRaw }: MutationParameters) => {
  const { unstakeModal } = useStakeIbcx()

  const { data: osmosisPools } = useOsmosisPoolsQuery()

  const { data: osmosisAccount } = useChainWallet('OSMO')

  const handleMutation = async (): Promise<TxRaw> => {
    await delay(2000)

    if (!osmosisAccount || !osmosisAccount.cosmwasm) {
      throw fatal('You must be connected to a wallet and approved Osmosis chain permission for to stake IBCX.')
    }

    const amountInMicroDenom = convertDenomToMicroDenom(unstakeModal.amount, STAKED_IBCX_CURRENCY.coinMinimalDenom)

    if (!osmosisPools) {
      throw fatal('Missing osmosis pools data')
    }

    const { burnSwapInfo, burnExactAmountOutResult, mintSwapInfo, mintExactAmountInResult } =
      await simulateStIbcxRedeem({
        inputAmount: BigNumber(unstakeModal.amount).toString(),
        pools: osmosisPools.pools
      })

    const msgStIbcxBurnPayload: ExecuteMsg = {
      burn_exact_amount_out: {
        core_addr: IBCX_CONTRACTS.stIbcx,
        swap_info: burnSwapInfo,
        output_asset: burnExactAmountOutResult.swap_result_amount
      }
    }

    const msgStIbcxBurn = {
      typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
      value: {
        sender: osmosisAccount.address,
        contract: IBCX_CONTRACTS.peripheryV2,
        msg: toUtf8(JSON.stringify(msgStIbcxBurnPayload)),
        funds: [
          {
            denom: STAKED_IBCX_CURRENCY.coinMinimalDenom,
            amount: amountInMicroDenom.toString()
          }
        ]
      }
    }

    const msgIbcxMintPayload: ExecuteMsg = {
      mint_exact_amount_in: {
        core_addr: IBCX_CONTRACTS.ibcx,
        input_asset: 'uosmo',
        min_output_amount: BigNumber(mintExactAmountInResult.mint_amount).decimalPlaces(0).toString(),
        swap_info: mintSwapInfo
      }
    }

    const msgIbcxMint = {
      typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
      value: {
        sender: osmosisAccount.address,
        contract: IBCX_CONTRACTS.peripheryV2,
        msg: toUtf8(JSON.stringify(msgIbcxMintPayload)),
        funds: [burnExactAmountOutResult.swap_result_amount]
      }
    }

    const simulateGas = await osmosisAccount.cosmwasm
      .simulate(osmosisAccount.address, [msgStIbcxBurn, msgIbcxMint], '')
      .catch((e: Error) => {
        throw new SimulateGasInsufficientAmountError(e.message)
      })

    const fee = {
      amount: [{ amount: '625', denom: osmosisAccount.currency.coinMinimalDenom }],
      gas: BigNumber(simulateGas).multipliedBy(1.25).toString()
    }

    return await osmosisAccount.cosmwasm.sign(osmosisAccount.address, [msgStIbcxBurn, msgIbcxMint], fee, '')
  }

  const handleSuccess = (raw: TxRaw) => {
    setRedeemStakeRaw(raw)
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  });
}

class SimulateGasInsufficientAmountError extends Error {}

export { useSignRedeemStIbcxMutation, SimulateGasInsufficientAmountError }
