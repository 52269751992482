import BigNumber from 'bignumber.js'
import React, { useMemo } from 'react'
import { Text, Tooltip } from '@mantine/core'
import { Icon, TextLoader } from '@/components'
import { getDexInfoFromDenom } from '@/config'
import { useIbcxTokensMarketPriceQuery } from '../queries'
import { useSelectedCoin } from '@/contexts/wallet'

const StakeIbcxStatMarketRate: React.FC = () => {
  const denom = useSelectedCoin()

  const dexInfo = useMemo(() => getDexInfoFromDenom(denom), [denom])

  const {
    data: marketPrice,
    isLoading: isMarketPriceLoading,
    error: marketPriceError
  } = useIbcxTokensMarketPriceQuery()

  const dexPriceValue = useMemo(() => {
    if (!marketPrice?.dexAvailability) return '0'
    return new BigNumber(marketPrice.dexStTokenValue).decimalPlaces(3).toString()
  }, [marketPrice])

  if (dexInfo == null) {
    return <Text sx={(t) => ({ color: t.colors.gray[7] })}>None</Text>
  }

  if (marketPriceError) {
    return (
      <Tooltip label={`Unable to load ${dexInfo.name} market price at this time.`} withArrow>
        <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
      </Tooltip>
    )
  }

  return (
    <TextLoader loading={isMarketPriceLoading} sx={(t) => ({ color: t.colors.gray[7] })}>
      1 st{denom} = {dexPriceValue} {denom}
    </TextLoader>
  )
}

export { StakeIbcxStatMarketRate }
