import dynamic from 'next/dynamic'
import { TextLoader, useFormContext } from '@/components'
import { Alert, Box, Divider, Group, Space, Text, TextInput, UnstyledButton } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import BigNumber from 'bignumber.js'
import { useMemo, useState } from 'react'
import { CHAIN_CONFIG } from '@/config'

import { StakeFormType } from './useStakeIbcxForm'
import { IBCX_CURRENCY, IBCX_LS_MIN_THRESHOLD } from '@/config/ibcx'
import { StakeIbcxEstimate } from '../StakeIbcxEstimate'
import { useStakeIbcx } from '../StakeIbcxProvider'
import { useChainWallet, useSelectedCoin, useWallet } from '@/contexts/wallet'

const StakeIbcxButton = dynamic<{}>(() => import('./StakeIbcxButton').then((module) => module.StakeIbcxButton), {
  ssr: false
})

interface Props {
  amountInputRef: React.RefObject<HTMLInputElement>
}

const StakeIbcxForm: React.FC<Props> = ({ amountInputRef }) => {
  const { data: osmosisAccount } = useChainWallet('OSMO')

  const denom = IBCX_CURRENCY.coinDenom

  const selectedCoinDenom = useSelectedCoin()
  const { isFetchingSelectedAccount } = useWallet()

  const [isFocused, setIsFocused] = useState(false)

  const { values, setFieldValue, getInputProps, onSubmit, validate, errors } = useFormContext<StakeFormType>()

  const { setStakeModal } = useStakeIbcx()

  const ibcxBalance = useMemo(() => {
    const amount = osmosisAccount?.coins.find((coin) => {
      return coin.denom === IBCX_CURRENCY.coinMinimalDenom
    })?.amount

    return Number(amount ?? 0)
  }, [osmosisAccount])

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const recommendedMaxAmount = useMemo(() => {
    const maxRoundedDown = new BigNumber(ibcxBalance).minus(CHAIN_CONFIG[selectedCoinDenom].minimumGas).decimalPlaces(5)

    return BigNumber.max(maxRoundedDown, 0)
  }, [ibcxBalance, selectedCoinDenom])

  const handleClickMax = () => {
    setFieldValue('amount', String(recommendedMaxAmount))
  }

  const handleClickHalf = () => {
    const halfRoundedDown = new BigNumber(ibcxBalance).dividedBy(2).decimalPlaces(5)

    const half = BigNumber.max(halfRoundedDown, 0)

    setFieldValue('amount', half.toString())
  }

  const handleSubmit = () => {
    const amount = Number(values.amount)

    setStakeModal({ isOpen: true, amount })
  }

  useDidUpdate(() => {
    if (!isFocused) return
    // We only want to validate if the user changed something,
    // but not if reset by a successful stake
    // We're strictly not validating when focus changes as it
    // causes the form to validate when we autofocus on mount
    // or when stake modal closes and reset the form
    validate()
  }, [values.amount])

  const isTransferringBelowRecommended = useMemo(() => {
    if (!values.amount || errors.amount) {
      // We only want to show this note if the field value has some value and is valid.
      return false
    }

    return Number(values.amount) < IBCX_LS_MIN_THRESHOLD
  }, [errors.amount, values.amount])

  const isTransferringAboveRecommendedBalance = useMemo(() => {
    // If user is staking more than their total balance, we'll let the validation take care of it.
    if (Number(values.amount) > ibcxBalance) {
      return false
    }

    return Number(values.amount) > recommendedMaxAmount.toNumber()
  }, [values, ibcxBalance, recommendedMaxAmount])

  const stakedDenom = `st${denom}`

  return (
    <>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Text align="center" sx={(t) => ({ color: t.colors.gray[9] })}>
          Stake your {denom} tokens in exchange for {stakedDenom} which you can deploy around the ecosystem. You can
          liquid stake half of your balance, if you're going to LP.
        </Text>

        <Space h="lg" />

        <Box>
          <Text inline sx={(t) => ({ color: t.colors.gray[9] })}>
            Amount to stake:
          </Text>

          <Space h="xs" />

          <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
            {denom}
          </Text>
        </Box>

        <Space h="sm" />

        <TextInput
          ref={amountInputRef}
          required
          placeholder="0"
          size="xl"
          sx={(t) => ({
            input: {
              fontWeight: 'bold',
              textAlign: 'right',
              color: t.colors.gray[9],
              '&::placeholder': { color: t.colors.gray[9] },
              '&:focus:not(.mantine-TextInput-invalid)': {
                border: `1px solid ${t.colors.gray[6]}`
              }
            }
          })}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...getInputProps('amount')}
        />

        {!isTransferringAboveRecommendedBalance && isTransferringBelowRecommended && (
          <>
            <Space h="xs" />

            <Alert color="yellow">
              <Text sx={(t) => ({ color: t.colors.gray[9] })}>
                Transaction amount is small, and calculation amount of the the estimated underlying assets' amount may
                fail. We recommend you stake at least {IBCX_LS_MIN_THRESHOLD} {denom} to guarantee that your transaction
                works.
              </Text>
            </Alert>
          </>
        )}

        {isTransferringAboveRecommendedBalance && (
          <>
            <Space h="xs" />

            <Alert color="yellow">
              <Text sx={(t) => ({ color: t.colors.gray[9] })}>
                It looks like you're transferring close to your full balance. We recommend you leave at least{' '}
                {CHAIN_CONFIG[selectedCoinDenom].minimumGas} {denom} in your wallet to cover the gas fees associated
                with liquid staking. The transaction may fail if you run out of gas.
              </Text>
            </Alert>
          </>
        )}

        {Boolean(osmosisAccount) && (
          <>
            <Space h="xs" />

            <Group position="apart" align="center">
              <Group spacing={0}>
                <Text sx={(t) => ({ color: t.colors.gray[7] })}>Available in wallet:</Text>
                &nbsp;
                <TextLoader loading={isFetchingSelectedAccount} sx={(t) => ({ color: t.colors.gray[7] })}>
                  <>
                    {new BigNumber(ibcxBalance).decimalPlaces(5).toString()} {denom}
                  </>
                </TextLoader>
              </Group>

              <Group spacing="sm">
                <UnstyledButton onClick={handleClickHalf}>
                  <Text sx={(t) => ({ color: t.colors.pink[7] })}>Add half</Text>
                </UnstyledButton>

                <UnstyledButton onClick={handleClickMax}>
                  <Text sx={(t) => ({ color: t.colors.pink[7] })}>Add max</Text>
                </UnstyledButton>
              </Group>
            </Group>
          </>
        )}

        <Space h="lg" />

        <Divider />

        <Space h="lg" />

        <Group position="apart" align="center">
          <Box>
            <Text inline sx={(t) => ({ color: t.colors.gray[9] })}>
              What you'll get
            </Text>

            <Space h="xs" />

            <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
              {stakedDenom}
            </Text>
          </Box>

          <Text
            weight={700}
            size="xl"
            sx={(t) => ({ paddingRight: t.other.stakeForm.amountPaddingRight, color: t.colors.gray[7] })}
            inline>
            <StakeIbcxEstimate amount={Number(values.amount)} mode="stake" />
          </Text>
        </Group>

        <Space h="lg" />

        <StakeIbcxButton />
      </form>
    </>
  )
}

export { StakeIbcxForm }
