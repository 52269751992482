import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { Anchor, Box, Card, Space, Stack, Text, Title, Tooltip } from '@mantine/core'
import { CollapsibleStatsItem, CollapsibleStatsList, DataListItem, Icon, TextLoader } from '@/components'
import { config, getDexInfoFromDenom, DEX_INFO_LIST, IBCX_PORTFOLIO_ASSETS } from '@/config'
import { and } from '@/utils'
import { useSmallScreen } from '@/hooks'

import { StakeIbcxStatProtocolRedemptionRate } from './StakeIbcxStatProtocolRedemptionRate'
import { StakeIbcxStatMarketRate } from './StakeIbcxStatMarketRate'
import { useIbcxTokensMarketPriceQuery, useStIbcxApyQuery } from '../queries'
import { useSelectedCoin } from '@/contexts/wallet'

const IBCX_PORTFOLIO_ASSETS_IN_STR = and(IBCX_PORTFOLIO_ASSETS)

const StakeIbcxStats: React.FC = () => {
  const denom = useSelectedCoin()

  // For display purposes, we'll default to Osmosis if the denom has no DEX information.
  const dexInfo = useMemo(() => getDexInfoFromDenom(denom) ?? DEX_INFO_LIST.osmosis, [denom])

  const { isSmallScreen } = useSmallScreen()

  const {
    data: marketPrice,
    isLoading: isMarketPriceLoading,
    error: marketPriceError
  } = useIbcxTokensMarketPriceQuery()

  const { data: apy, isError: apyError, isLoading: apyLoading } = useStIbcxApyQuery()

  const apyRate = `${BigNumber(apy ?? 0)
    .decimalPlaces(2)
    .toString()}%`

  const stakedTokenValue = useMemo(() => {
    if (marketPrice == null) return '0'

    return new BigNumber(marketPrice.strideStTokenValue).decimalPlaces(3).toString()
  }, [marketPrice])

  return (
    <>
      <Card p="md">
        <Title order={5} sx={(t) => ({ color: t.colors.gray[9], fontWeight: 600 })}>
          About st{denom}
        </Title>

        <Space h="xs" />

        <CollapsibleStatsList>
          <CollapsibleStatsItem
            icon="infoCircleFilled"
            label="How does stIBCX work?"
            description={
              <Box
                sx={{
                  margin: '0',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <Text component="p" sx={{ margin: '0' }}>
                  Similar to IBCX, stIBCX tracks twelve Cosmos assets, based on these weights. However, stIBCX contains
                  the liquid staked versions of six of the IBCX portfolio regular tokens of IBCX portfolio assets (
                  {IBCX_PORTFOLIO_ASSETS_IN_STR}). As Stride supports more LSTs, they will be included in stIBCX.
                </Text>
                <Space h="sm" />
                <Text component="p" sx={{ margin: '0' }}>
                  Here’s how stIBCX works, under the hood:
                </Text>
                <Space h="sm" />
                <Box component="ol" pl="sm" m={0}>
                  <li>The user liquid stakes IBCX on app.stride.zone.</li>
                  <Space h="xs" />
                  <li>
                    The IBCX smart contract burns the submitted IBCX in exchange for OSMO, then exchanges that OSMO for
                    stIBCX’s underlying assets using Osmosis, then exchanges that OSMO for stIBCX’s underlying assets
                    using Osmosis.
                  </li>
                  <Space h="xs" />
                  <li>The user receives newly minted stIBCX from the smart contract.</li>
                </Box>
                <Space h="sm" />
                <Text component="p" sx={{ margin: '0' }}>
                  All IBCX swap transactions execute on the Osmosis chain, not on Stride. stIBCX lives on the Osmosis
                  blockchain.
                </Text>
              </Box>
            }
          />

          <CollapsibleStatsItem
            icon="arrowHorizontal"
            label="Unbonding"
            description={
              <>
                stIBCX can be redeemed for IBCX immediately. Redemptions are processed by swapping stIBCX’s underlying
                assets for IBCX's underlying assets, so users don’t need to wait for unbonding.
              </>
            }
          />

          <CollapsibleStatsItem
            icon="trophy"
            label="Rewards"
            value={
              Boolean(apyError) ? (
                <Tooltip label="Unable to load stride yield at this time." withArrow>
                  <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                </Tooltip>
              ) : (
                <TextLoader loading={apyLoading}>{apyRate}</TextLoader>
              )
            }
            description={
              <>
                Rewards on Stride are similar to native staking rewards. However, you can use your st{denom} throughout
                DeFi.
              </>
            }
            data={[
              {
                label: 'stIBCX yield',
                value: Boolean(apyError) ? (
                  <Tooltip label="Unable to load current yield at this time." withArrow>
                    <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                  </Tooltip>
                ) : (
                  <TextLoader loading={apyLoading}>{apyRate}</TextLoader>
                )
              },
              {
                label: 'Calculation formula',
                value: 'Σ(APY_i * weight_i)'
              }
            ]}
          />

          <CollapsibleStatsItem
            icon="atom"
            label="Fees"
            description={
              <Box
                sx={{
                  margin: '0',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <Text component="p" sx={{ margin: '0' }}>
                  There are two types of fees from IBCX and one from Stride.
                </Text>

                <Space h="sm" />

                <Box component="ol" pl="sm" m={0}>
                  <li>
                    <Box>Management Fee: 0.55%</Box>
                    <Space h={4} />
                    <Box>
                      Both IBCX and stIBCX charge a 0.55% of Annual Management Fee. For example, holding a $100 worth of
                      IBCX for one year will incur a $0.55 Management Fee. Management Fee.
                    </Box>
                  </li>

                  <Space h="sm" />

                  <li>
                    <Box>Burn Fee: 0.15%</Box>
                    <Space h={4} />
                    <Box>
                      Users minting stIBCX pay a 0.15% Burn Fee. In the future, IBCX may choose to reimburse this fee,
                      core contributors are exploring this possibility.
                    </Box>
                  </li>

                  <Space h="sm" />

                  <li>
                    Stride Fee: Stride charges a 10% fee on the staking rewards you earn via stTokens. Stride also
                    autocompounds rewards, which can offset the 10% fee entirely in some cases.
                  </li>
                </Box>
              </Box>
            }
          />

          <CollapsibleStatsItem
            icon="check"
            label="Rebalancing"
            description={
              <>
                stIBCX is periodically rebalanced to maintain asset ratios in line with the stIBCX portfolio composition
                weights. The stIBCX index is rebalanced frequently to account for appreciation of the LSTs underlying
                the index.
              </>
            }
          />

          <CollapsibleStatsItem
            icon="upwardArrow"
            label={`Value of 1 st${denom}`}
            value={
              Boolean(marketPriceError) ? (
                <Tooltip label="Unable to load redemption rate at this time." withArrow>
                  <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                </Tooltip>
              ) : (
                <TextLoader loading={isMarketPriceLoading}>
                  <Box sx={{ display: isSmallScreen ? 'inline' : 'none' }}>1 st{denom} = </Box>
                  {stakedTokenValue} {denom}
                </TextLoader>
              )
            }
            description={
              <>
                The value of 1 st{denom} if redeemed through the Stride protocol redemption rate grows predictably as
                staking rewards accrue.
                <Space h="sm" />
                The market price of 1 st{denom} on exchanges fluctuates based on supply and demand.
              </>
            }>
            {!isSmallScreen && (
              <Stack>
                <Box>
                  <Text sx={(t) => ({ color: t.colors.gray[9] })}>Protocol redemption rate</Text>

                  <Box>
                    <StakeIbcxStatProtocolRedemptionRate />
                  </Box>
                </Box>

                <Box>
                  <Text sx={(t) => ({ color: t.colors.gray[9] })}>{dexInfo.name} market rate</Text>

                  <Box>
                    <StakeIbcxStatMarketRate />
                  </Box>
                </Box>
              </Stack>
            )}

            {isSmallScreen && (
              <>
                <DataListItem
                  label="Protocol redemption rate"
                  bold={false}
                  value={<StakeIbcxStatProtocolRedemptionRate />}
                />
                <DataListItem label={`${dexInfo.name} market rate`} bold={false} value={<StakeIbcxStatMarketRate />} />
              </>
            )}
          </CollapsibleStatsItem>
        </CollapsibleStatsList>

        <Space h="xs" />

        <Card.Section
          p="md"
          pt="sm"
          sx={(t) => ({ background: t.colors.gray[0], paddingBottom: t.other.stakeStatsCard.paddingBottom })}>
          <Text sx={(t) => ({ color: t.colors.gray[7] })}>
            Want to learn more about rewards, fees and unbonding on Stride? Check out the&nbsp;
            <Anchor href={config.links.docs} target="_blank" underline sx={{ color: 'currentColor' }}>
              Stride Docs
            </Anchor>
            . You can also learn about IBCX via the&nbsp;
            <Anchor href={config.links.ibcxMedium} target="_blank" underline sx={{ color: 'currentColor' }}>
              IBCX Medium
            </Anchor>
            .
          </Text>
        </Card.Section>
      </Card>
    </>
  )
}

export { StakeIbcxStats }
