import { getStIbcxDenomByStTokenIbcDenom, SELECTED_COIN_DENOM_BY_ST_TOKEN_DENOM } from '@/config/ibcx'
import { queryKeys } from '@/query-keys'

import { useQuery } from '@tanstack/react-query'

import BigNumber from 'bignumber.js'
import { fatal } from '@/utils'
import { useStakeStatsQuery } from '@/queries'
import { useIbcxTokensMarketPriceQuery } from './useIbcxTokensMarketPriceQuery'
import { useIbcxTokensSwapRoutesQuery } from './useIbcxTokensSwapRoutesQuery'

export const useStIbcxApyQuery = () => {
  const { data: swapRoutes, isLoading: isSwapRoutesLoading, error: swapRoutesError } = useIbcxTokensSwapRoutesQuery()

  const { data: stakeStats, isLoading: isStakeStatsQueryLoading, error: stakeStatsError } = useStakeStatsQuery()

  const {
    data: ibcxTokensMarketPrice,
    isLoading: isibcxTokensMarketPriceLoading,
    error: ibcxTokensMarketPriceError
  } = useIbcxTokensMarketPriceQuery()

  const handleRequest = async () => {
    if (swapRoutes === undefined || stakeStats === undefined || ibcxTokensMarketPrice === undefined)
      throw fatal('Unable to get stIbcx apy')

    const { stIbcxRoutes } = swapRoutes

    const totalOsmoAmount = stIbcxRoutes.reduce(
      (acc, { calculatedOsmoOutput }) => acc.plus(BigNumber(calculatedOsmoOutput)),
      BigNumber(0)
    )

    const filteredStTokenOsmoAmount = stIbcxRoutes
      .map(({ minimalDenom, ...rest }) => {
        const denom = getStIbcxDenomByStTokenIbcDenom(minimalDenom)
        if (denom == null) return null
        return { denom, ...rest }
      })
      .filter(function <T>(value: T): value is NonNullable<T> {
        return Boolean(value)
      })

    const stAssetsInfo = filteredStTokenOsmoAmount.map(({ denom, calculatedOsmoOutput }) => ({
      apy: stakeStats[SELECTED_COIN_DENOM_BY_ST_TOKEN_DENOM[denom]].strideYield,
      ratio: BigNumber(calculatedOsmoOutput).dividedBy(totalOsmoAmount).multipliedBy(100).decimalPlaces(2).toNumber()
    }))

    const price = ibcxTokensMarketPrice.strideStTokenValueInUsd

    const yieldPrice = stAssetsInfo.reduce((total, { apy, ratio }) => {
      const tokenPrice = new BigNumber(price).multipliedBy(ratio).multipliedBy(apy)
      return tokenPrice.plus(total).toNumber()
    }, 0)

    return new BigNumber(yieldPrice).plus(price).dividedBy(price).minus(1).toNumber()
  }

  const { isLoading, error, ...rest } = useQuery({
    queryKey: queryKeys.stakedIbcxApyQuery,
    queryFn: handleRequest,
    enabled: swapRoutes !== undefined && stakeStats !== undefined && ibcxTokensMarketPrice !== undefined
  })

  return {
    ...rest,
    error: error || swapRoutesError || stakeStatsError || ibcxTokensMarketPriceError,
    isLoading: isLoading || isSwapRoutesLoading || isStakeStatsQueryLoading || isibcxTokensMarketPriceLoading
  }
}
