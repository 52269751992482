import { flushSync } from 'react-dom'
import { useMutation } from '@tanstack/react-query'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import axios from 'axios'
import { z } from 'zod'

import { TX_MSG, convertDenomToMicroDenom } from '@/wallet-utils'
import { STRIDE_CHAIN_INFO } from '@/config'
import { fatal } from '@/utils'
import { useEligibleAirdropsQuery } from '@/queries'
import { useStake } from '@/page-components/Stake/StakeProvider'
import { convertAttributesToObject } from '@/page-components/Stake/utils'
import { useSelectedWallet, useStrideWallet } from '@/contexts/wallet'
import { useReferral } from '@/page-components/Referral'
import { MutationParameters } from './types'

const useSignLiquidStakeMutation = ({
  tokenizeSharesTokenValue,
  ibcTransferTransaction,
  setLiquidStakeRaw
}: MutationParameters) => {
  const { memo } = useReferral()

  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const { data: eligibleAirdrops } = useEligibleAirdropsQuery()

  const { lsm } = useStake()

  const handleMutation = async (): Promise<TxRaw> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to stake without connecting your wallet.')
    }

    // `tokenizeSharesTokenValue` is the accurate token value that was tokenized set by `useBroadcastTokenizeShares`.
    // (aka this means they went through the flow normally, from tokenized -> ibc -> lsm liquid stake)
    // Otherwise, we'll use `lsm.amount` which means that the user continued lsm from the transaction history.
    const amountInMicroDenom = tokenizeSharesTokenValue
      ? tokenizeSharesTokenValue
      : convertDenomToMicroDenom(lsm.amount, selectedAccount.currency.coinMinimalDenom)

    if (!eligibleAirdrops) {
      // Eligible airdrops data should have been loaded by the staking form.
      // If the user gets here while eligible airdrop is missing, there are a few likely things:
      // We forgot to run the eligible airdrop query in the staking form or forgot to show a loader.
      // We did not disable the action that leads to this code (either it is loading or resulted to an error).
      throw fatal('Unable to calculate gas multiplier as eligible airdrops have not been fetched yet.')
    }

    const gasMultiplier = Math.max(eligibleAirdrops.length, 0)

    // We used to set 0.6 ATOM per airdrop, but that doesn't seem enough anymore
    const gasAmountFromAirdrops = 1 * gasMultiplier

    // We'll set 1.5 ATOM as minimum regardless of airdrop because we run out of gas otherwise.
    const gasAmount = 1.5 + gasAmountFromAirdrops

    const fee = {
      amount: [{ amount: '0', denom: strideAccount.currency.coinMinimalDenom }],
      gas: convertDenomToMicroDenom(gasAmount, strideAccount.currency.coinMinimalDenom).toString()
    }

    const getLsmTokenIbcDenom = () => {
      const txHistoryDenom = lsm.txHistoryLsIbcDenom || lsm.txHistoryIbcDenom

      if (txHistoryDenom) {
        // If we're continuing from the transaction history (either tokenize or ibc), we'll use the provided values
        return Promise.resolve(txHistoryDenom)
      }

      if (ibcTransferTransaction == null) {
        throw fatal('Unable to liquid stake without the ibc transfer transaction.')
      }

      // Otherwise, we'll use the ibc denom from the ibc transfer's transaction events.
      return getLsmTokenizedDenomFromIbc(ibcTransferTransaction.events)
    }

    const msgLiquidStake = {
      typeUrl: TX_MSG.MsgLSMLiquidStake,
      value: {
        creator: strideAccount.address,
        amount: amountInMicroDenom.toString(),
        lsmTokenIbcDenom: await getLsmTokenIbcDenom()
      }
    }

    if (!strideAccount.client) {
      throw fatal('Stride client is not available.')
    }

    return await strideAccount.client.sign(strideAccount.address, [msgLiquidStake], fee, memo)
  }

  const handleSuccess = (raw: TxRaw) => {
    // This is the only way to allow React v18 to update the state first before
    // the next mutation (which requires this state to have a value) is executed.
    flushSync(() => {
      setLiquidStakeRaw(raw)
    })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

// @TODO: Reusable
const packetDataSchema = z.object({
  amount: z.string(),
  denom: z.string(),
  receiver: z.string(),
  sender: z.string()
})

const sendPacketSchema = z.object({
  packet_data: z.string(),
  packet_data_hex: z.string(),
  packet_timeout_height: z.string(),
  packet_timeout_timestamp: z.string(),
  packet_sequence: z.string(),
  packet_src_port: z.string(),
  packet_src_channel: z.string(),
  packet_dst_port: z.string(),
  packet_dst_channel: z.string(),
  packet_channel_ordering: z.string(),
  packet_connection: z.string()
})

const denomHashResponseSchema = z.object({
  hash: z.string()
})

// Get the tokenized denom from send_packet.attributes.packet_data.denom
// Afterwards, get its ibc denom on Stride side by making a request to the Stride chain.
// @TODO: In the near future, if this fails often, it may make sense to
// implement a particular error state for this.
const getLsmTokenizedDenomFromIbc = async (events: DeliverTxResponse['events']) => {
  const event = events.find((event) => {
    return event.type === 'send_packet'
  })

  if (event == null) {
    throw fatal('Unable to find `send_packet` from the committed ibc transaction for lsm.')
  }

  const { packet_data, packet_dst_port, packet_dst_channel } = sendPacketSchema.parse(
    convertAttributesToObject(event.attributes)
  )

  const tokenizedDenom = packetDataSchema.parse(JSON.parse(packet_data)).denom

  const instance = axios.create({
    baseURL: STRIDE_CHAIN_INFO.rest
  })

  // e.g., ibc/apps/transfer/v1/denom_hashes/transfer/channel-0/cosmosvaloper17kht2x2ped6qytr2kklevtvmxpw7wq9rarvcqz/3
  const response = await instance.get(
    `ibc/apps/transfer/v1/denom_hashes/${packet_dst_port}/${packet_dst_channel}/${tokenizedDenom}`
  )

  // Hash doesn't contain the `ibc/` prefix, so we'll do it ourself
  return `ibc/${denomHashResponseSchema.parse(response.data).hash}`
}

export { useSignLiquidStakeMutation }
