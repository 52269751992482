import React, { useContext, useState } from 'react'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal } from '@/utils'
import { IBCTransferStatus, IbcReturnType } from '@/wallet-utils'
import {
  useSignTokenizeSharesMutation,
  useBroadcastTokenizeSharesMutation,
  useSignIbcTransferMutation,
  useBroadcastIbcTransferMutation,
  useTraceIbcTransferMutation,
  useSignLiquidStakeMutation,
  useBroadcastLiquidStakeMutation,
  MutationParameters,
  BroadcastTokenizeSharesMutationReturnType,
  BroadcastIbcTransferMutationReturnType,
  BroadcastLiquidStakeMutationReturnType
} from './mutations'

interface ContextType {
  resetStakeLsmModalState: () => void
  resetForLsmLiquidStake: () => void

  signTokenizeShares: () => Promise<TxRaw>
  isSigningTokenizeShares: boolean
  signTokenizeSharesError: unknown

  broadcastTokenizeShares: () => Promise<BroadcastTokenizeSharesMutationReturnType>
  isBroadcastingTokenizeShares: boolean
  broadcastTokenizeSharesError: unknown

  signIbcTransfer: () => Promise<IbcReturnType>
  isSigningIbcTransfer: boolean
  signIbcTransferError: unknown

  broadcastIbcTransfer: () => Promise<BroadcastIbcTransferMutationReturnType>
  isBroadcastingIbcTransfer: boolean
  broadcastIbcTransferError: unknown

  ibcStatus: { status: IBCTransferStatus } | undefined
  traceIbcStatus: () => Promise<{ status: IBCTransferStatus }>
  isIbcStatusLoading: boolean
  ibcStatusError: unknown

  signLiquidStake: () => Promise<TxRaw>
  isSigningLiquidStake: boolean
  signLiquidStakeError: unknown

  broadcastLiquidStakeData: BroadcastLiquidStakeMutationReturnType | undefined
  broadcastLiquidStake: () => Promise<BroadcastLiquidStakeMutationReturnType>
  isBroadcastingLiquidStake: boolean
  broadcastLiquidStakeError: unknown
}

const Context = React.createContext<ContextType | null>(null)

const StakeLsmModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tokenizeSharesRaw, setTokenizeSharesRaw] = useState<TxRaw | null>(null)

  // The resulting denom of the tokenized shares, so we can properly IBC to Stride
  const [tokenizeSharesDenom, setTokenizeSharesDenom] = useState<string | null>(null)

  // The accurate token value that was tokenized, so we can properly IBC to Stride
  // It may not seem obvious, when a user delegates to a validator, they actually get shares
  // This means that when this is tokenized, the resulting will slightly be different from
  // shares they have. (tokenValue = nativelyStakedBalance * validator.shares_to_tokens_rate)
  const [tokenizeSharesTokenValue, setTokenizeSharesTokenValue] = useState<string | null>(null)

  const [ibcTransferRaw, setIbcTransferRaw] = useState<IbcReturnType | null>(null)

  const [ibcTransferTransaction, setIbcTransferTransaction] = useState<DeliverTxResponse | null>(null)

  const [liquidStakeRaw, setLiquidStakeRaw] = useState<TxRaw | null>(null)

  const parameters: MutationParameters = {
    tokenizeSharesRaw,
    setTokenizeSharesRaw,
    tokenizeSharesDenom,
    setTokenizeSharesDenom,
    tokenizeSharesTokenValue,
    setTokenizeSharesTokenValue,
    ibcTransferRaw,
    setIbcTransferRaw,
    ibcTransferTransaction,
    setIbcTransferTransaction,
    liquidStakeRaw,
    setLiquidStakeRaw
  }

  const {
    mutateAsync: signTokenizeShares,
    isPending: isSigningTokenizeShares,
    error: signTokenizeSharesError,
    reset: resetSignTokenizeShares
  } = useSignTokenizeSharesMutation(parameters)

  const {
    mutateAsync: broadcastTokenizeShares,
    isPending: isBroadcastingTokenizeShares,
    error: broadcastTokenizeSharesError,
    reset: resetBroadcastTokenizeShares
  } = useBroadcastTokenizeSharesMutation(parameters)

  const {
    mutateAsync: signIbcTransfer,
    isPending: isSigningIbcTransfer,
    error: signIbcTransferError,
    reset: resetSignIbcTransfer
  } = useSignIbcTransferMutation(parameters)

  const {
    mutateAsync: broadcastIbcTransfer,
    isPending: isBroadcastingIbcTransfer,
    error: broadcastIbcTransferError,
    reset: resetBroadcastIbcTransfer
  } = useBroadcastIbcTransferMutation(parameters)

  const {
    data: ibcStatus,
    mutateAsync: traceIbcStatus,
    isPending: isIbcStatusLoading,
    error: ibcStatusError,
    reset: resetIbcStatus
  } = useTraceIbcTransferMutation(parameters)

  const {
    mutateAsync: signLiquidStake,
    isPending: isSigningLiquidStake,
    error: signLiquidStakeError,
    reset: resetSignLiquidStake
  } = useSignLiquidStakeMutation(parameters)

  const {
    data: broadcastLiquidStakeData,
    mutateAsync: broadcastLiquidStake,
    isPending: isBroadcastingLiquidStake,
    error: broadcastLiquidStakeError,
    reset: resetBroadcastLiquidStake
  } = useBroadcastLiquidStakeMutation(parameters)

  const resetStakeLsmModalState = () => {
    setTokenizeSharesRaw(null)
    setTokenizeSharesDenom(null)
    setTokenizeSharesTokenValue(null)
    setIbcTransferRaw(null)
    setIbcTransferTransaction(null)
    setLiquidStakeRaw(null)
    resetSignTokenizeShares()
    resetBroadcastTokenizeShares()
    resetSignIbcTransfer()
    resetBroadcastIbcTransfer()
    resetIbcStatus()
    resetSignLiquidStake()
    resetBroadcastLiquidStake()
  }

  const resetForLsmLiquidStake = () => {
    setLiquidStakeRaw(null)
    resetSignLiquidStake()
    resetBroadcastLiquidStake()
  }

  return (
    <Context.Provider
      value={{
        resetStakeLsmModalState,
        resetForLsmLiquidStake,

        signTokenizeShares,
        isSigningTokenizeShares,
        signTokenizeSharesError,

        broadcastTokenizeShares,
        isBroadcastingTokenizeShares,
        broadcastTokenizeSharesError,

        signIbcTransfer,
        isSigningIbcTransfer,
        signIbcTransferError,

        broadcastIbcTransfer,
        isBroadcastingIbcTransfer,
        broadcastIbcTransferError,

        ibcStatus,
        traceIbcStatus,
        isIbcStatusLoading,
        ibcStatusError,

        signLiquidStake,
        isSigningLiquidStake,
        signLiquidStakeError,

        broadcastLiquidStakeData,
        broadcastLiquidStake,
        isBroadcastingLiquidStake,
        broadcastLiquidStakeError
      }}>
      {children}
    </Context.Provider>
  )
}

const useStakeLsmModal = () => {
  const context = useContext(Context)

  if (context == null) {
    throw fatal('You forgot to mount useStakeLsmModal.')
  }

  return context
}

export { StakeLsmModalProvider, useStakeLsmModal }
