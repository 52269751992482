import React from 'react'
import { ErrorBeacon, TextLoader } from '@/components'
import { useSelectedCoin } from '@/contexts/wallet'
import { useTvlQuery } from '@/queries'
import { convertMicroDenomToDenom, formatCurrency } from '@/wallet-utils'
import { useTokenValueInUsdQuery } from '@/queries'
import { CHAIN_INFO_LIST } from '@/config'

const StakeStatTvl: React.FC = () => {
  const denom = useSelectedCoin()

  const { data: tvlPayload, isLoading: isTvlPayloadLoading, error: tvlPayloadError } = useTvlQuery(denom)

  const {
    data: tokenValueInUsd,
    isLoading: isTokenValueInUsdLoading,
    error: tokenValueInUsdError
    // } = useTokenValueInUsdQuery(`st${denom}`)
  } = useTokenValueInUsdQuery(`st${denom}`)

  if (tvlPayloadError) {
    return <ErrorBeacon label={`Unable to load TVL for ${denom} at this time.`} />
  }

  if (tokenValueInUsdError) {
    return <ErrorBeacon label={`Unable to load token value of at this time.`} />
  }

  const chainInfo = CHAIN_INFO_LIST[denom]

  const tvlInDenom = tvlPayload
    ? Number(convertMicroDenomToDenom(tvlPayload.tvl, chainInfo.stakeCurrency.coinMinimalDenom))
    : 0

  const stTokenTvlInUsd = tvlInDenom && tokenValueInUsd ? tvlInDenom * tokenValueInUsd : 0

  return (
    <TextLoader loading={isTvlPayloadLoading || isTokenValueInUsdLoading}>{formatCurrency(stTokenTvlInUsd)}</TextLoader>
  )
}

export { StakeStatTvl }
