import dynamic from 'next/dynamic'
import { Container, Paper, Tabs, Stack, useMantineTheme } from '@mantine/core'
import { StakeIbcxStats } from './StakeIbcxStats'
import { StakeIbcxModal, StakeIbcxModalProvider } from './StakeIbcxModal'
import { useAutoFocusRef } from '@/hooks'
import { FormProvider } from '@/components/Form'
import { useMediaQuery } from '@mantine/hooks'
import { StakeIbcxForm, useStakeIbcxForm } from './StakeIbcxForm'
import { UnstakeIbcxForm, useUnstakeIbcxForm } from './UnstakeIbcxForm'
import { StakeIbcxCoinSelector } from './StakeIbcxCoinSelector'
import { UnstakeIbcxModal, UnstakeIbcxModalProvider } from './UnstakeIbcxModal'

const StakeIbcxWallet = dynamic<{}>(() => import('./StakeIbcxWallet').then((module) => module.StakeIbcxWallet), {
  ssr: false
})

const StakeIbcx: React.FC = () => {
  const stakeForm = useStakeIbcxForm()

  const unstakeForm = useUnstakeIbcxForm()

  const stakeAmountInputRef = useAutoFocusRef()

  const theme = useMantineTheme()

  const isMediumScreen = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`, true)

  const handleStakeSuccess = () => {
    stakeForm.reset()
  }

  const handleClaimSuccess = () => {
    unstakeForm.reset()
  }

  return (
    <>
      <Container size="sm" px={isMediumScreen ? 'lg' : 'sm'} pt={isMediumScreen ? 'xl' : 'md'}>
        <StakeIbcxCoinSelector />

        <Stack spacing="md">
          <Paper p={isMediumScreen ? 48 : 'md'}>
            <Tabs
              tabPadding="md"
              styles={(t) => ({
                tabControl: {
                  color: t.colors.gray[9]
                },
                tabActive: {
                  color: t.colors.gray[9]
                }
              })}>
              <Tabs.Tab label="Stake">
                <FormProvider {...stakeForm}>
                  <StakeIbcxForm amountInputRef={stakeAmountInputRef} />
                </FormProvider>
              </Tabs.Tab>

              <Tabs.Tab label="Unstake">
                <FormProvider {...unstakeForm}>
                  <UnstakeIbcxForm />
                </FormProvider>
              </Tabs.Tab>
            </Tabs>
          </Paper>

          <StakeIbcxWallet />

          <StakeIbcxStats />
        </Stack>
      </Container>

      <StakeIbcxModalProvider>
        <StakeIbcxModal onSuccess={handleStakeSuccess} />
      </StakeIbcxModalProvider>

      <UnstakeIbcxModalProvider>
        <UnstakeIbcxModal onSuccess={handleClaimSuccess} />
      </UnstakeIbcxModalProvider>
    </>
  )
}

export { StakeIbcx }
