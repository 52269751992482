import { useMemo } from 'react'
import { useForm, zodResolver } from '@mantine/form'
import z from 'zod'
import { useSelectedCoin, useStrideBalancesV2 } from '@/contexts/wallet'
import { CHAIN_CONFIG } from '@/config'
import { convertMicroDenomToDenom } from '@/wallet-utils'

export interface UnstakeFormType {
  amount: string
}

const useUnstakeForm = () => {
  const { data: strideBalances } = useStrideBalancesV2()

  const denom = useSelectedCoin()

  const stTokenBalance = strideBalances?.strideSelectedAccountStakedBalance ?? '0'

  const schema = useMemo(() => {
    const stTokenBalanceInDenom = Number(convertMicroDenomToDenom(stTokenBalance, denom))

    const minimum = CHAIN_CONFIG[denom].unstakeMinimum

    // @TODO: Maybe we should add a validation for the decimal precision so
    // they don't manually enter something that would fail the ibc like so: 0.02310230985
    // Not 100% sure though since it's likely that the conversion fixes would fix this
    // since any decimals past the micro denom would naturally be removed.
    return z.object({
      amount: z.preprocess(
        (v) => Number(z.string().parse(v)),
        z
          .number({
            required_error: 'Amount is required.',
            invalid_type_error: 'Amount must be a number.'
          })
          .min(minimum, `Amount must have a value more than ${minimum}.`)
          .max(stTokenBalanceInDenom, 'Amount exceeds your balance.')
      )
    })
  }, [stTokenBalance, denom])

  return useForm<UnstakeFormType>({
    schema: zodResolver(schema),
    initialValues: { amount: '' }
  })
}

export { useUnstakeForm }
