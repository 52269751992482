import React from 'react'
import { Button } from '@mantine/core'

import { StepperModalContent, useStepperContext } from '@/components'
import { CHAIN_INFO_LIST } from '@/config'
import { useLatestValue } from '@/hooks'
import { notify } from '@/contexts/notifications'
import { useSelectedCoin } from '@/contexts/wallet'
import { StandardTransactionError, formatMicroDenom } from '@/wallet-utils'
import { AnalyticsTracker } from '@/page-components/shared'
import { useStake } from '../StakeProvider'
import { useWithdrawLsmModal } from './WithdrawLsmModalProvider'

const WithdrawLsmModalStepOne: React.FC = () => {
  const {
    signWithdrawTokenizedShares,
    isSigningWithdrawTokenizedShares,
    signWithdrawTokenizedSharesError,
    broadcastWithdrawTokenizedShares,
    isBroadcastingWithdrawTokenizedShares,
    broadcastWithdrawTokenizedSharesError,
    traceIbcStatus,
    isIbcStatusLoading
  } = useWithdrawLsmModal()

  const { start, nextStep, handleClose, forceClose } = useStepperContext()

  const denom = useSelectedCoin()

  const { withdrawLsm } = useStake()

  const isOpenRef = useLatestValue(withdrawLsm.isOpen)

  const chainInfo = CHAIN_INFO_LIST[denom]

  const handleBroadcast = async () => {
    const { status } = await broadcastWithdrawTokenizedShares()

    if (!isOpenRef.current) {
      // This function doesn't get cancelled when the modal gets closed.
      // If we get here when pool is already closed, it's likely that the stuff that
      // `traceIbcStatus` (transactionRef) needs has already been reset by the
      // modal close handler.
      return
    }

    if (status === 'return-later') {
      // If we're here, it means that our ibc transfer took too long and we're tracking it
      // the second time without the 30-second timeout. This way, the user can continue the
      // flow if it they haven't closed.
      //
      // We can check for the return-later status by checking using `isIbcStatusLoading`
      // to check if the ibc transfer hasn't completed and ibc tracking is still on-going.
      //
      // @TODO: Handle timeout state if this doesn't succeed. The original implementation
      // from the ibc/staking modal doesn't have this yet neither. Add display states for
      // `status === 'timeout'` for both broadcast and tracing and `ibcStatusError`
      await traceIbcStatus()
    }

    nextStep()
  }

  const handleSign = async () => {
    await signWithdrawTokenizedShares()
    await handleBroadcast()
  }

  const handleStart = async () => {
    start()
    await handleSign()
  }

  const handleCloseCallback = () => {
    // If it was before and after the broadcast, we don't need to prepend.
    if (!isBroadcastingWithdrawTokenizedShares && !isIbcStatusLoading) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the transfer completes.")
  }

  handleClose(handleCloseCallback)

  const formattedAmount = formatMicroDenom(withdrawLsm.amount, chainInfo.stakeCurrency.coinMinimalDenom, 3)

  if (isIbcStatusLoading) {
    return (
      <StepperModalContent
        title="This transaction is taking longer than usual"
        description={`If you don’t want to wait here, you can close this dialog. Your transaction will continue and you’ll be able to see status on the Stride app page.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
      />
    )
  }

  if (broadcastWithdrawTokenizedSharesError instanceof StandardTransactionError) {
    // Generally, INSUFFICIENT_FUNDS (code 5) and OUT_OF_GAS (code 11) can mean the same thing.
    // We're also not sure if tokenized shares can be used as gas if there's not enough unstaked
    // or staked ATOM.
    //
    // https://github.com/Stride-Labs/interface/issues/529
    // @TODO: When we try fixing how retry broadcast works, it's possible that when their tokenized
    // shares are used as gas, they'll have less, and so we might want to cover for that.
    const isOutOfGas =
      broadcastWithdrawTokenizedSharesError.description === 'INSUFFICIENT_FUNDS' ||
      broadcastWithdrawTokenizedSharesError.description === 'OUT_OF_GAS'

    // @TODO: Update out of gas message to be more friendly and relevant
    const description = isOutOfGas
      ? `Transaction failed due to out of gas. Please try again.`
      : broadcastWithdrawTokenizedSharesError.message

    return (
      <StepperModalContent
        title={`Transaction error`}
        description={description}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
        error={broadcastWithdrawTokenizedSharesError}
      />
    )
  }

  if (broadcastWithdrawTokenizedSharesError) {
    return (
      <StepperModalContent
        title={`Transaction error`}
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isBroadcastingWithdrawTokenizedShares) {
    return (
      <StepperModalContent
        title={`Transferring tokenized ${denom} to ${chainInfo.chainName}...`}
        description={`This could take 30 seconds or longer if the network is congested.`}
      />
    )
  }

  if (signWithdrawTokenizedSharesError) {
    return (
      <StepperModalContent
        title={`Transaction error`}
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleSign}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningWithdrawTokenizedShares) {
    return (
      <StepperModalContent
        title={`Approve the transaction in your wallet to continue`}
        description={`This will start the transfer of your ${denom} tokens to your ${chainInfo.chainName} wallet.`}
      />
    )
  }

  return (
    <>
      <StepperModalContent
        title={`You’re about to revert ${formattedAmount} ${denom} back to its original state.`}
        description={`This should take about a minute and will require 2 wallet approvals.`}
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Cancel
            </Button>

            <Button onClick={handleStart}>Revert</Button>
          </>
        }
      />

      <AnalyticsTracker eventName="withdraw_lsm_start" />
    </>
  )
}

export { WithdrawLsmModalStepOne }
