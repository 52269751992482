import React, { useContext, useEffect, useState } from 'react'

import { delay, fatal } from '@/utils'
import { useSelectedCoin, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'
import { CHAIN_SUPPORTS_LSM } from '@/config'
import { useHostZoneQuery, useLsmValidatorsQuery } from './queries'

type Mode = 'default' | 'lsm'

interface StakeFormContextType {
  mode: Mode

  setMode: (mode: Mode) => void

  lsm: {
    // Stride Frontend tries to have isolated state from the modal's state as much as possible.
    // For instance, any state inside the modal is either: 1 - write-only-once outside the modal,
    // often to open the modal and set amount OR to prefill data, used to resume a flow
    // (e.g.,txHistoryTokenizedDenom in this case); and 2 - not-to-be read outside the modal.
    // **This practices makes it easier to reason about**.
    // However, this is an exception: this is read and written both inside and outside StakeLsmModal.
    // useStakeForm need a way to access the selected validator. However, running the selected validator right
    // where the schema is generated (useStakeForm) is not possible without making performance worse.
    // Reusing this same value here solves this problem. We also have the option to make a duplicate of
    // this value inside useStakeForm, but that doesn't seem necessary.
    validatorAddress: string
    // Unlike validatorId, this behaves like most of Stride Frontend. This is exclusively write-only outside the modal,
    // and read-only inside the modal.
    amount: number
    isOpen: boolean
    // When the user minimizes a "MsgTokenizeShares" transaction (step 1 of LSM flow), we display a transaction item.
    // This allows the user to resume the flow by pressing the "Continue" button. This state lets us display relevant custom
    // copywriting for step 2.
    txHistoryTokenizedDenom: string
    // When the user minimizes an IBC transfer (step 2 of LSM flow), we display a transaction item.
    // This allows the user to resume the flow by pressing the "Continue" button. This state lets us display relevant custom
    // copywriting for step 3.
    txHistoryIbcDenom: string
    // This is literally the same as `txHistoryIbcDenom` but strictly used for liquid staking; avoid mixing up payload for
    // different purposes.
    txHistoryLsIbcDenom: string
    // This is needed to properly dismiss the retried transaction
    txHistoryLsSourceHash: string
  }

  // Let's consider moving this to its own context if there's not a lot of shared state between this and `lsm`.
  withdrawLsm: {
    amount: string
    isOpen: boolean
    // A payload and an indicator that the lsm revert flow was started from the LSM TokenizeShares transaction item
    txHistoryTokenizedDenom: string
    // A payload and an indicator that the lsm revert flow was started from the LSM IBC transaction item
    txHistoryIbcDenom: string
    // Payload set from LSM IBC transaction item used to identify the tokenized denom to redeem and validator's address
    txHistoryIbcTokenizedDenom: string
  }

  lsmTutorialTooltip: {
    isOpen: boolean
  }

  openLsm: (value: Pick<StakeFormContextType['lsm'], 'amount'>) => void

  resumeLsmTokenizeShare: (value: { amount: number; tokenizedDenom: string; validatorAddress: string }) => void

  resumeLsmIbc: (value: { amount: number; ibcDenom: string; validatorAddress: string }) => void

  retryLsmLs: (value: { amount: number; ibcDenom: string; sourceHash: string; validatorAddress: string }) => void

  closeLsm: () => void

  closeUnbegunLsm: () => void

  setLsmValidatorAddress: (validatorAddress: string) => void

  closeWithdrawLsm: () => void

  withdrawLsmTokenizeShare: (value: { amount: string; tokenizedDenom: string }) => void

  withdrawLsmIbc: (value: { amount: string; ibcDenom: string; tokenizedDenom: string }) => void

  openLsmTutorialTooltip: () => void
}

const DEFAULT_LSM_VALUE = {
  validatorAddress: '',
  amount: 0,
  isOpen: false,
  txHistoryTokenizedDenom: '',
  txHistoryIbcDenom: '',
  txHistoryLsIbcDenom: '',
  txHistoryLsSourceHash: ''
}

const DEFAULT_WITHDRAW_LSM_VALUE = {
  amount: '0',
  isOpen: false,
  txHistoryTokenizedDenom: '',
  txHistoryIbcTokenizedDenom: '',
  txHistoryIbcDenom: ''
}

const DEFAULT_LSM_TUTORIAL_TOOLTIP_VALUE = {
  isOpen: false
}

const StakeContext = React.createContext<StakeFormContextType | null>(null)

const StakeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [internalMode, setInternalMode] = useState<Mode>('default')

  const [lsm, setLsm] = useState<StakeFormContextType['lsm']>(DEFAULT_LSM_VALUE)

  const [withdrawLsm, setWithdrawLsm] = useState<StakeFormContextType['withdrawLsm']>(DEFAULT_WITHDRAW_LSM_VALUE)

  const [lsmTutorialTooltip, setLsmTutorialTooltip] = useState<StakeFormContextType['lsmTutorialTooltip']>(
    DEFAULT_LSM_TUTORIAL_TOOLTIP_VALUE
  )

  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const denom = useSelectedCoin()

  const { data: lsmValidators } = useLsmValidatorsQuery()

  const { data: hostZone } = useHostZoneQuery()

  // Internal function only.
  // Set a delay after closing the lsm tutorial tooltip to allow the tooltip to disappear
  // first before a layout shift occurs. Otherwise, the tooltip would animate in a weird
  // fashion before disappearing despite not having any exit animation ms.
  const closeLsmTutorialTooltip = async () => {
    // Hide behind aan explicit flag to avoid the delay if the tooltip is not open.
    if (!lsmTutorialTooltip.isOpen) return
    setLsmTutorialTooltip({ isOpen: false })
    await delay(200)
  }

  const openLsmTutorialTooltip = () => {
    // We want to skip showing the tooltip if the user has only one validator.
    // Given the function below (`setModeInteractively`), if an account only has
    // a single validator, the user will skip the validator selection screeen and
    // automatically select the validator.
    if (lsmValidators?.validators.length === 1) return
    setLsmTutorialTooltip({ isOpen: true })
  }

  // External function only, but keep this in sync with `setModeProgramatically`.
  // Use this function to set the mode through user events (like clicking the switch toggle).
  // This function makes sure to update related state when necessary.
  // This function is async because we want to set a delay after closing the lsm tutorial tooltip
  const setModeInteractively = async (mode: Mode) => {
    if (mode === 'default') {
      // If the user came from lsm tutorial and switches back anyway, we want to close the tooltip.
      await closeLsmTutorialTooltip()
      // We'll reset the lsm state; cleanup
      setLsm(DEFAULT_LSM_VALUE)
    }

    if (mode === 'lsm' && lsmValidators?.validators.length === 1) {
      // Autoselect the only validator if the user has only one validator
      setLsm((lsm) => ({
        ...lsm,
        validatorAddress: lsmValidators.validators[0].address
      }))
    }

    setInternalMode(mode)
  }

  // Internal function only, but keep this in sync with `setModeInteractively`.
  // Use this function to set the mode through useEffect.
  // This function makes sure to update related state when necessary.
  const setModeProgramatically = (mode: Mode) => {
    if (mode === 'default') {
      // If the user came from lsm tutorial and switches back anyway, we want to close the tooltip.
      setLsmTutorialTooltip({ isOpen: false })
      // We'll reset the lsm state; cleanup
      setLsm(DEFAULT_LSM_VALUE)
    }

    if (mode === 'lsm' && lsmValidators?.validators.length === 1) {
      // Autoselect the only validator if the user has only one validator
      setLsm((lsm) => ({
        ...lsm,
        validatorAddress: lsmValidators.validators[0].address
      }))
    }

    setInternalMode(mode)
  }

  useEffect(() => {
    // If the user disconnects or switches to another wallet account, we want to reset back to default so that the
    // LSM screen does not crash; said screen assumes that the validators are existent - which is technically correct.
    // As much as we want to run `setMode` directly on the wallet disconnection function, it's too far up the tree.
    setModeProgramatically('default')
  }, [strideAccount?.address, selectedAccount?.address])

  useEffect(() => {
    // When a TokenizeShares completes that causes the user to be depleted of any delegations, StakeToggle
    // becomes disabled. We want to reset back to default to avoid the lsm screen from being awkwardly
    // stuck on the LSM mode.
    if (internalMode === 'lsm' && !lsmValidators?.validators.length) {
      setModeProgramatically('default')
    }
  }, [lsmValidators, internalMode])

  useEffect(() => {
    // hostZone is used to check for redelegation eligibility, slash status, and other chain-specific things.
    // However, since it's not being "paused" or "disabled", its values become undefined as soon as we switch chains.
    // This causes the page to crash because a lot of nested LSM components assume that the hostZone is existent.
    // @FUTURE: When start having more LSM-enabled chains, we may want to check if we want this behavior of resetting
    // to default when between two LSM-enabled chains.
    if (internalMode === 'lsm' && !hostZone) {
      setModeProgramatically('default')
    }
  }, [hostZone, internalMode])

  useEffect(() => {
    // Some queries depend on `selectedAccount` and are disabled after switching chains until `selectedAccount`
    // is fully loaded. We want to reset back to default to avoid the lsm screen from being awkwardly stuck on
    // the LSM mode. This is just an extra check, a duplicate of the effects above for lsmValidators & hostZone
    if (internalMode === 'lsm' && !CHAIN_SUPPORTS_LSM[denom]) {
      setModeProgramatically('default')
    }
  }, [denom, internalMode])

  // useEffect happens after render, so this makes sure we're on the intended state
  // before the useEffect functions are complete. In a way, the useEffect in this case
  // becomes clean up functions.
  //
  // We're forcing mode to be set to default if the lsmValidators or hostZone becomes empty.
  // This makes sure that LSM screen does not crash; said screen assumes that the validators are existent.
  // Crash may happen if user has enabled lsm and disconnects/switches their wallet account.
  //
  // In addition to lsmValidators, when a TokenizeShares completes and causes the user to be depleted of
  // any delegations,StakeToggle becomes disabled. We want to reset back to default to avoid the lsm screen
  // from being awkwardly stuck on the LSM mode.
  //
  // hostZone is used to check for redelegation eligibility, slash status, and other chain-specific things.
  // However, since it's not being "paused" or "disabled", its values become undefined as soon as we switch chains.
  // This causes the page to crash because a lot of nested LSM components assume that the hostZone is existent.
  // @FUTURE: When start having more LSM-enabled chains, we may want to check if we want this behavior of resetting
  // to default when between two LSM-enabled chains.
  //
  // For context, we only recently added hostZone because its behavior on production is different. On production
  // (at this time), we still use the old wallet connection which almost already requests and stores the account
  // data for other chains. Meaning, the query never becomes "paused" or "disables".
  //
  // CHAIN_SUPPORTS_LSM is just an extra check, a duplicate for lsmValidators & hostZone for safety, for whatever reasons.
  const mode = !CHAIN_SUPPORTS_LSM[denom] || !hostZone || !lsmValidators?.validators.length ? 'default' : internalMode

  const setLsmValidatorAddress = async (validatorAddress: string) => {
    // If the user came from lsm tutorial and selects a validator, we want to close the tooltip.
    await closeLsmTutorialTooltip()

    setLsm((lsm) => ({
      ...lsm,
      validatorAddress
    }))
  }

  const openLsm: StakeFormContextType['openLsm'] = ({ amount }) => {
    setLsm((lsm) => ({
      ...lsm,
      amount,
      isOpen: true
    }))
  }

  const resumeLsmTokenizeShare: StakeFormContextType['resumeLsmTokenizeShare'] = ({
    amount,
    tokenizedDenom,
    validatorAddress
  }) => {
    setLsm((lsm) => ({
      ...lsm,
      amount,
      isOpen: true,
      validatorAddress,
      txHistoryTokenizedDenom: tokenizedDenom
    }))
  }

  const resumeLsmIbc: StakeFormContextType['resumeLsmIbc'] = ({
    amount,
    ibcDenom,
    validatorAddress: validatorAddress
  }) => {
    setLsm((lsm) => ({
      ...lsm,
      amount,
      isOpen: true,
      validatorAddress,
      txHistoryIbcDenom: ibcDenom
    }))
  }

  const retryLsmLs: StakeFormContextType['retryLsmLs'] = ({ amount, ibcDenom, sourceHash, validatorAddress }) => {
    setLsm((lsm) => ({
      ...lsm,
      amount,
      isOpen: true,
      validatorAddress,
      txHistoryLsIbcDenom: ibcDenom,
      txHistoryLsSourceHash: sourceHash
    }))
  }

  // Closes the modal and try to reset the selected validator
  const closeLsm = () => {
    // If the user only has a single validator when lsm succeeds, we want to auto-select the only validator.
    if (lsmValidators?.validators.length === 1) {
      setLsm({
        ...DEFAULT_LSM_VALUE,
        validatorAddress: lsmValidators.validators[0].address
      })
    } else {
      setLsm(DEFAULT_LSM_VALUE)
    }
  }

  // Closes the modal but keeps the selected validator
  const closeUnbegunLsm = () => {
    setLsm({
      ...DEFAULT_LSM_VALUE,
      validatorAddress: lsm.validatorAddress
    })
  }

  const closeWithdrawLsm = () => {
    setWithdrawLsm(DEFAULT_WITHDRAW_LSM_VALUE)
  }

  const withdrawLsmTokenizeShare: StakeFormContextType['withdrawLsmTokenizeShare'] = ({ amount, tokenizedDenom }) => {
    setWithdrawLsm((withdrawLsm) => ({
      ...withdrawLsm,
      amount,
      isOpen: true,
      txHistoryTokenizedDenom: tokenizedDenom
    }))
  }

  const withdrawLsmIbc: StakeFormContextType['withdrawLsmIbc'] = ({ amount, ibcDenom, tokenizedDenom }) => {
    setWithdrawLsm((withdrawLsm) => ({
      ...withdrawLsm,
      amount,
      isOpen: true,
      txHistoryIbcTokenizedDenom: tokenizedDenom,
      txHistoryIbcDenom: ibcDenom
    }))
  }

  return (
    <StakeContext.Provider
      value={{
        mode,
        setMode: setModeInteractively,

        lsm,
        openLsm,
        resumeLsmTokenizeShare,
        resumeLsmIbc,
        retryLsmLs,
        closeLsm,
        closeUnbegunLsm,
        setLsmValidatorAddress,

        withdrawLsm,
        closeWithdrawLsm,
        withdrawLsmTokenizeShare,
        withdrawLsmIbc,

        lsmTutorialTooltip,
        openLsmTutorialTooltip
      }}>
      {children}
    </StakeContext.Provider>
  )
}

const useStake = () => {
  const context = useContext(StakeContext)

  if (context == undefined) {
    throw fatal('useStakeForm must be used within a StakeProvider')
  }

  return context
}

export { StakeProvider, useStake }
