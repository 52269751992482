import BigNumber from 'bignumber.js'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { DEX_CONFIG } from '@/config'
import { fatal } from '@/utils'
import { queryKeys } from '@/query-keys'

export interface OsmosisPoolAprItemResponse {
  pool_id: number
  apr_list: {
    start_date: string
    denom: string
    symbol: string
    apr_1d: number
    apr_7d: number
    apr_14d: number
    apr_superfluid: null | number
  }[]
}

export interface OsmosisPoolFeeResponse {
  last_update_at: number
  data: {
    pool_id: number
    volume_24h: number
    volume_7d: number
    fees_spent_24h: number
    fees_spent_7d: number
    fees_percentage: string
  }[]
}

export interface OsmosisPoolByIdItemResponse {
  symbol: string
  amount: number
  denom: string
  coingecko_id: string
  liquidity: number
  liquidity_24h_change: number
  volume_24h: number
  volume_24h_change: number
  price: number
  price_24h_change: number
  fees: string
}

const useStakeIbcxOsmosisPoolTotalApr = () => {
  const handleRequest = async () => {
    const dexConfig = DEX_CONFIG.STIBCX

    if (dexConfig == null) {
      throw fatal('Unable to find dex config for stIBCX')
    }

    const { poolId } = dexConfig

    const getOsmosisPoolApr = async () => {
      return (await axios.get<OsmosisPoolAprItemResponse[]>(`https://api-osmosis.imperator.co/apr/v2/${poolId}`)).data
    }

    const getOsmosisPoolFee = async () => {
      return (await axios.get<OsmosisPoolFeeResponse>(`https://api-osmosis.imperator.co/fees/v1/${poolId}`)).data
    }

    const getOsmosisPoolById = async () => {
      return (await axios.get<OsmosisPoolByIdItemResponse[]>(`https://api-osmosis.imperator.co/pools/v2/${poolId}`))
        .data
    }

    const [poolInfo, feeData, aprData] = await Promise.all([
      getOsmosisPoolById(),
      getOsmosisPoolFee(),
      getOsmosisPoolApr()
    ])

    const getApr = () => {
      const list = aprData[0].apr_list
      if (list == null) return 0
      const osmoIncentive = aprData[0].apr_list.find((item) => item.symbol === 'OSMO') || aprData[0].apr_list[0]
      const { apr_14d, apr_superfluid } = osmoIncentive
      return apr_superfluid || apr_14d
    }

    const apr = getApr()

    const { liquidity: poolLiquidity } = poolInfo[0]
    const fee7d = feeData.data[0].fees_spent_7d
    const fee1y = new BigNumber(fee7d).dividedBy(7).multipliedBy(365).toNumber()
    const feeApr = new BigNumber(fee1y).dividedBy(poolLiquidity).multipliedBy(100).toNumber()

    return {
      apr: new BigNumber(feeApr).plus(apr).toNumber()
    }
  }

  return useQuery({
    queryKey: queryKeys.stakeIbcxModalOsmosisPoolApr,
    queryFn: handleRequest
  })
}

export { useStakeIbcxOsmosisPoolTotalApr }
