import { StepperModalContent, useStepperContext } from '@/components'
import { useMount } from '@/hooks'
import { Button } from '@mantine/core'
import React, { useMemo } from 'react'
import { useStakeClassicModal } from './StakeClassicModalProvider'
import { useSelectedCoin, useStrideWallet } from '@/contexts/wallet'
import { convertDenomToMicroDenom, formatMicroDenom, isSafeModeAccount } from '@/wallet-utils'
import { useAtomValue } from 'jotai'
import { getLiquidStakeSafetyDeduction } from './utils'
import { stakeAmountAtom } from '../atoms'
import { CHAIN_INFO_LIST } from '@/config'

const StakeClassicModalStepThree: React.FC = () => {
  const { signLiquidStake, signLiquidStakeError, isIbcTransferSkipped } = useStakeClassicModal()

  const denom = useSelectedCoin()

  const amount = useAtomValue(stakeAmountAtom)

  const strideAccount = useStrideWallet()

  const { nextStep, forceClose } = useStepperContext()

  const deductible = useMemo(() => {
    if (!strideAccount) {
      return BigInt(0)
    }

    if (isSafeModeAccount(strideAccount)) {
      return BigInt(0)
    }

    return getLiquidStakeSafetyDeduction({
      amount: convertDenomToMicroDenom(amount, denom),
      denom,
      strideAccount
    })
  }, [amount, denom, strideAccount])

  useMount(() => {
    handleStep()
  })

  const handleStep = async () => {
    await signLiquidStake()
    nextStep()
  }

  const handleRetrySignLiquidStake = async () => {
    handleStep()
  }

  const stepperModalContentTitle = isIbcTransferSkipped
    ? `Great! Approve in your wallet to start staking your ${denom}`
    : `Transfer complete! Approve in your wallet again to start staking your ${denom}`

  if (signLiquidStakeError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            {' '}
            This transfer could not be completed. Your tokens are on Stride, but have not been staked. Please try again.
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
            <Button color="dark" variant="outline" onClick={handleRetrySignLiquidStake}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  const chainInfo = CHAIN_INFO_LIST[denom]

  return (
    <StepperModalContent
      title={stepperModalContentTitle}
      description={
        <>
          This will initiate staking and the transfer of {denom} to your wallet.
          {deductible > 0 && (
            <>
              {' '}
              You have no tokens for gas on Stride.{' '}
              {formatMicroDenom(deductible, chainInfo.stakeCurrency.coinMinimalDenom)} {denom} will be deducted from the
              amount you're liquid staking, to cover gas on Stride
            </>
          )}
        </>
      }
    />
  )
}

export { StakeClassicModalStepThree }
