import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Center, Group, Modal, UnstyledButton } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import Lottie from 'react-lottie'
import Image from "next/legacy/image"
import { useMediumScreen, useSimpleCountdown } from '@/hooks'
import lsmAnimation from './animation.json'
import lsmTutorialConnectWallet from '@/images/lsm-tutorial-connect-wallet.svg'
import lsmTutorialUnqualified from '@/images/lsm-tutorial-unqualified.svg'
import { getTotalNativelyStakedBalance } from '../utils'
import { useLsmValidatorsQuery } from '../queries'
import { useStake } from '../StakeProvider'
import { LsmTutorialGetStartedStep } from './LsmTutorialGetStartedStep'
import { LsmTutorialWalletConnectStep } from './LsmTutorialWalletConnectStep'
import { LsmTutorialUnqualifiedStep } from './LsmTutorialUnqualifiedStep'
import { Icon } from '@/components'
import { delay, env } from '@/utils'
import { CHAIN_SUPPORTS_LSM } from '@/config'
import { useSelectedCoin, useSelectedWallet } from '@/contexts/wallet'

type LsmTutorialStep = 'get-started' | 'connect-wallet' | 'unqualified'

const IS_LSM_TUTORIAL_ENABLED = env('LSM_TUTORIAL_ENABLED', 'false') === 'true'

const LsmTutorialModal: React.FC = () => {
  const { setMode, openLsmTutorialTooltip } = useStake()

  const denom = useSelectedCoin()

  // @TODO: In the future, we might want to avoid flipped names like this.
  // It's very confusing. Let's just use `isTutorialOpen` instead.
  const [internalIsTutorialSeen, setIsTutorialSeen] = useLocalStorage({
    key: 'stride.lsm.tutorial',
    defaultValue: false
  })

  // The denom relies on query parameters which aren't available until Next hydrates it.
  // Omega hack to make sure the atom is fully hydrated. Otherwise, the modal flashes for
  // a split second until it closes.
  const { countdown } = useSimpleCountdown(1)

  // Allow modal to be disabled in a few weeks through env variable
  // Never show modal for non-lsm chains.
  const isTutorialSeen =
    IS_LSM_TUTORIAL_ENABLED && countdown === 0 && CHAIN_SUPPORTS_LSM[denom] ? internalIsTutorialSeen : true

  const [step, setStep] = useState<LsmTutorialStep>('get-started')

  const { isMediumScreen } = useMediumScreen()

  const { data: lsmValidators } = useLsmValidatorsQuery()

  const selectedAccount = useSelectedWallet()

  const totalNativelyStakedBalance = useMemo(() => {
    return getTotalNativelyStakedBalance({ lsmValidators })
  }, [lsmValidators])

  // We want to make sure that the modal is fully closed until
  // the closing animation completes so that the tooltip is properly
  // attached to the stake form. The modal interferes with its
  // positioning.
  const openLsmTutorialTooltipDelayed = async () => {
    await delay(500)
    openLsmTutorialTooltip()
  }

  useEffect(() => {
    // We don't want the effect to be ran in anyway once it's dismissed.
    if (isTutorialSeen) {
      return
    }

    // We don't want to do anything until the user has connected their
    // wallet and we have fetched their natively staked balance per validator.
    // This is for wallets that are disconnected by default.
    if (!selectedAccount || !lsmValidators || step !== 'connect-wallet') {
      return
    }

    // Either move move to the unqualified step or enable lsm mode
    if (totalNativelyStakedBalance <= 0) {
      setStep('unqualified')
    } else {
      setMode('lsm')
      setIsTutorialSeen(true)
      openLsmTutorialTooltipDelayed()
    }
  }, [selectedAccount, lsmValidators, totalNativelyStakedBalance, isTutorialSeen, step])

  const handleClose = useCallback(() => {
    setIsTutorialSeen(true)
  }, [])

  const handleStart = () => {
    // Here, we plan to skip running the effect by going straight to unqualified.
    // This is for wallets that were auto-connected before they had
    // the chance to click "Connect wallet" through this tutorial.
    if (selectedAccount && lsmValidators && totalNativelyStakedBalance <= 0) {
      setStep('unqualified')
    } else {
      // Otherwise, we'll proceed as usual, let the effect run the flow.
      setStep('connect-wallet')
    }
  }

  const content: Record<LsmTutorialStep, () => React.ReactElement> = {
    'get-started': () => <LsmTutorialGetStartedStep onStart={handleStart} onClose={handleClose} />,
    'connect-wallet': () => <LsmTutorialWalletConnectStep />,
    unqualified: () => <LsmTutorialUnqualifiedStep onClose={handleClose} />
  }

  const icon: Record<LsmTutorialStep, () => React.ReactElement> = {
    'get-started': () => {
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: lsmAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }

      return (
        <Box
          sx={(t) => ({
            borderTopLeftRadius: t.radius.md,
            borderBottomLeftRadius: t.radius.md,
            overflow: 'hidden',
            height: '100%',
            width: 365
          })}>
          <Lottie options={defaultOptions} height={365} width={300} isPaused={false} />
        </Box>
      )
    },
    'connect-wallet': () => (
      <Image src={lsmTutorialConnectWallet} alt="Simplified icon of connecting your wallet on Stride" />
    ),
    unqualified: () => <Image src={lsmTutorialUnqualified} alt="Simplified icon of unqualification for lsm" />
  }

  return (
    <Modal opened={!isTutorialSeen} onClose={handleClose} size={712} padding={0} withCloseButton={false}>
      <Group spacing={isMediumScreen ? 0 : 40}>
        <Center
          sx={(t) => ({
            width: 300,
            height: 365,
            background: t.colors.gray[9],
            borderTopLeftRadius: t.radius.md,
            borderBottomLeftRadius: t.radius.md
          })}>
          {icon[step]()}
        </Center>

        <Box
          sx={(t) => ({
            position: 'relative',
            padding: isMediumScreen ? t.other.stepperModal.contentPaddingDesktop : t.spacing.md,
            paddingRight: isMediumScreen ? t.other.stepperModal.contentPaddingRightDesktop : t.spacing.md,
            width: t.other.stepperModal.contentWidth
          })}>
          {content[step]()}
        </Box>
      </Group>

      <UnstyledButton
        sx={(t) => ({ position: 'absolute', top: t.spacing.xl, right: t.spacing.xl })}
        onClick={handleClose}>
        <Icon name="cross" sx={(t) => ({ color: t.colors.gray[8] })} />
      </UnstyledButton>
    </Modal>
  )
}

export { LsmTutorialModal }
