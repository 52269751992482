import React, { useMemo } from 'react'
import { Button } from '@mantine/core'

import { StepperModalContent, useStepperContext } from '@/components'
import { SimulateGasInsufficientAmountError } from './mutations'
import { useUnstakeIbcxModal } from './UnstakeIbcxModalProvider'
import { useSelectedCoin } from '@/contexts/wallet'

const UnstakeIbcxModalStepOne: React.FC = () => {
  const { start, nextStep, close, forceClose } = useStepperContext()

  const { signRedeemStake, isSigningRedeemStake, signRedeemStakeError } = useUnstakeIbcxModal()

  const denom = useSelectedCoin()

  const stakedDenom = useMemo(() => `st${denom}`, [denom])

  const handleStart = async () => {
    start()
    await signRedeemStake()
    nextStep()
  }

  const handleRetrySignStakeTokens = () => {
    handleStart()
  }

  if (signRedeemStakeError instanceof SimulateGasInsufficientAmountError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            This transfer could not be completed because the calculation of the estimated underlying assets' amount has
            failed. Please try increasing the amount you're unstaking.
          </>
        }
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" onClick={handleRetrySignStakeTokens}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (signRedeemStakeError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="Your tokens couldn’t be unstaked at this time. Try again in a few minutes."
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" onClick={handleRetrySignStakeTokens}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningRedeemStake) {
    return (
      <StepperModalContent
        title="Confirm the transaction in your wallet to continue"
        description={`This will start the transfer of your ${stakedDenom} tokens out of your wallet and start the unstaking process`}
      />
    )
  }

  return (
    <StepperModalContent
      title="Confirm the transaction in your wallet to continue"
      description={<>This will burn your desired amount of stIBCX tokens to unstake and mint the IBCX.</>}
      actions={
        <>
          <Button variant="outline" color="dark" onClick={close}>
            Cancel
          </Button>
          <Button onClick={handleStart}>Continue unstake</Button>
        </>
      }
    />
  )
}

export { UnstakeIbcxModalStepOne }
