import { useMutation } from '@tanstack/react-query'
import { assert } from '@/utils'
import { CHAIN_CONFIG } from '@/config'
import { calculateFee, isSafeModeAccount } from '@/wallet-utils'
import {
  generateIbcTimeoutTimestamp,
  signIbcTransaction,
  IbcReturnType,
  simulateIbcTransaction
} from '@/wallet-utils/ibc'
import { MutationParameters } from './types'
import { useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

export interface SignIbcTransferMutationParameters {
  amount: string
}

const useSignIbcTransferMutation = ({ setIbcTransferRaw }: MutationParameters) => {
  const strideAccount = useStrideWallet()
  const selectedAccount = useSelectedWallet()

  const handleMutation = async ({ amount }: SignIbcTransferMutationParameters): Promise<IbcReturnType> => {
    assert(strideAccount, 'You are unable to send token without connecting your wallet.')
    assert(selectedAccount, 'You are unable to send token without connecting your wallet.')
    assert(!isSafeModeAccount(selectedAccount), 'Cannot ibc transfer in safe mode.')

    const msgTransfer = {
      typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
      value: {
        sourcePort: 'transfer',
        sourceChannel: CHAIN_CONFIG[selectedAccount.currency.coinDenom].depositChannel,
        token: {
          amount,
          denom: selectedAccount.currency.coinMinimalDenom
        },
        sender: selectedAccount.address,
        receiver: strideAccount.address,
        memo: '',
        timeoutTimestamp: generateIbcTimeoutTimestamp()
      }
    }

    const gas = await simulateIbcTransaction(selectedAccount, selectedAccount.address, [msgTransfer], '')
    const fee = calculateFee(selectedAccount, gas)

    return await signIbcTransaction(selectedAccount, selectedAccount.address, [msgTransfer], fee, '')
  }

  const handleSuccess = (raw: IbcReturnType) => {
    setIbcTransferRaw(raw)
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  });
}

export { useSignIbcTransferMutation }
