import React from 'react'
import { useLsmValidatorPersonaQuery } from '../../queries'
import { Avatar, Skeleton } from '@mantine/core'

interface LsmValidatorAvatarProps {
  size?: 'sm' | 'md'
  // We'll make this optional which means that validator's
  // address is still being fetched (like for redelegation)
  address?: string | undefined
}

// @TODO: Handle errors
const LsmValidatorAvatar: React.FC<LsmValidatorAvatarProps> = ({ address, size = 'md' }) => {
  const { data: lsmValidatorPersona, isLoading: isLsmValidatorPersonaLoading } = useLsmValidatorPersonaQuery({
    address
  })

  const sizes = {
    sm: 24,
    md: 40
  }

  if (address == null || isLsmValidatorPersonaLoading) {
    return <Skeleton height={sizes[size]} width={sizes[size]} radius="xl" />
  }

  return <Avatar src={lsmValidatorPersona?.logo} size={sizes[size]} radius="xl" />
}

export { LsmValidatorAvatar }
