import { TransactionCard } from '@/components'
import { useHostZoneQuery } from './queries'
import { useSelectedCoin } from '@/contexts/wallet'
import { CHAIN_INFO_LIST } from '@/config'

const StakeChainHaltedCard = () => {
  const { data } = useHostZoneQuery()

  const denom = useSelectedCoin()

  if (!data?.halted) {
    return null
  }

  return (
    <TransactionCard
      status="warning"
      title={`st${denom} is turned off at the moment`}
      description={`Liquid staking and unstaking for ${CHAIN_INFO_LIST[denom].chainName} has been disabled temporarily due to chain halting.`}
    />
  )
}

export { StakeChainHaltedCard }
