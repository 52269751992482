import React from 'react'
import { Anchor } from '@mantine/core'
import { CHAIN_INFO_LIST, CHAIN_CONFIG } from '@/config'
import { fatal } from '@/utils'
import { useSelectedWallet } from '@/contexts/wallet'

const ChainAssetUrl: React.FC = () => {
  const selectedAccount = useSelectedWallet()

  if (!selectedAccount) {
    throw fatal('Unable to render ibc transaction while disconnected.')
  }

  const chainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

  const chainConfig = CHAIN_CONFIG[selectedAccount.currency.coinDenom]

  if (!chainConfig.assetsUrl) {
    return <span>{chainInfo.chainName}</span>
  }

  return (
    <Anchor href={chainConfig.assetsUrl} target="_blank" underline sx={{ color: 'inherit' }}>
      {chainInfo.chainName}
    </Anchor>
  )
}

export { ChainAssetUrl }
