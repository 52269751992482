import React from 'react'
import { Box, Button, Group, Space, Text, Title } from '@mantine/core'

interface LsmTutorialUnqualifiedStepProps {
  onClose: () => void
}

const LsmTutorialUnqualifiedStep: React.FC<LsmTutorialUnqualifiedStepProps> = ({ onClose }) => {
  return (
    <Box sx={{ maxWidth: 320 }}>
      <Title order={4} sx={(t) => ({ colors: t.colors.gray[9], lineHeight: 1 })}>
        No natively staked balance
      </Title>

      <Space h="xs" />

      <Text sx={(t) => ({ color: t.colors.gray[7] })}>
        Looks like you don’t have any natively staked balance in this wallet, or your validator(s) don’t support this
        feature yet.
      </Text>

      <Space h="xs" />

      <Text sx={(t) => ({ color: t.colors.gray[7] })}>Change the wallet or contact your validator.</Text>

      <Space h="md" />

      <Group spacing="xs">
        <Button onClick={onClose}>Close</Button>
      </Group>
    </Box>
  )
}

export { LsmTutorialUnqualifiedStep }
