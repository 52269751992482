import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { fatal } from '@/utils'
import { STRIDE_CHAIN_INFO } from '@/config'
import { queryKeys } from '@/query-keys'
import { LsmLiquidStakeMetaData, TxQueryResponse } from '@/wallet-utils'
import { getLsmLiquidStakeAttributes, lsmStatusSchema } from '@/page-components/Stake/utils'
import { z } from 'zod'
import { useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

interface LiquidStakeQueryCallbackQueryPayload {
  status: z.infer<typeof lsmStatusSchema>
}

interface LsmLiquidStakeQueryCallbackQueryParameters {
  meta: LsmLiquidStakeMetaData | undefined
}

// Fetch LSMLiquidStake's query callback which will contain updated status about the transaction
// This works by getting the `lsm_liquid_stake_tx_id` attribute from `LSMLiquidStake`'s transaction events
// and polling its events until the transaction is no longer pending.
const useLsmLiquidStakeQueryCallbackQuery = ({ meta }: LsmLiquidStakeQueryCallbackQueryParameters) => {
  const strideAccount = useStrideWallet()
  const selectedAccount = useSelectedWallet()

  const handleRequest = async (): Promise<LiquidStakeQueryCallbackQueryPayload> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('Unable to fetch query callback without connecting your wallet.')
    }

    if (meta == null) {
      throw fatal('Unable to fetch query callback without a base transaction')
    }

    const txUrlParameters = new URLSearchParams()
    txUrlParameters.append('events', `lsm_liquid_stake.lsm_liquid_stake_tx_id='${meta.values.queryCallbackId}'`)

    const instance = axios.create({
      baseURL: STRIDE_CHAIN_INFO.rest
    })

    const response = await instance.get<TxQueryResponse>(`cosmos/tx/v1beta1/txs?${txUrlParameters}`)

    // We expect the query above to return two transactions:
    // the original transaction and the query callback which we need in this case.
    const queryCallback = response.data.tx_responses.find((response) => {
      return response.txhash !== meta.values.hash
    })

    if (queryCallback == null) {
      // This may happen if the query callback hasn't been made, due to relayer going down or being overloaded.
      // To "simulate" this on Dockernet, liquid stake twice to the same validator. Afterwards, pause the gaia relayer,
      // then liquid stake again. Just log inside the if condition to confirm you're getting here. For reference, our
      // guides / Sam should know more about this.
      return { status: 'pending' }
    }

    const attributes = getLsmLiquidStakeAttributes(queryCallback.events)

    return { status: attributes.transaction_status }
  }

  return useQuery({
    queryKey: queryKeys.transactionHistoryLsmLiquidStakeQueryCallbackByHash({
      hash: meta?.values.queryCallbackId ?? ''
    }),
    queryFn: handleRequest,
    enabled: Boolean(strideAccount && meta?.values.queryCallbackId),
    // We want to keep refetching the status of the transaction until it is no longer pending.
    refetchInterval: (query) => {
      return query.state.data?.status === 'pending' ? 30_000 : false
    }
  })
}

export { useLsmLiquidStakeQueryCallbackQuery }
