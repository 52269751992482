import React, { useContext, useState } from 'react'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal } from '@/utils'
import { IBCTransferStatus } from '@/wallet-utils'
import {
  useSignWithdrawStTokenMutation,
  useBroadcastWithdrawStTokenMutation,
  useTraceIbcTransfer,
  SignWithdrawStTokenMutationReturnType,
  BroadcastWithdrawStTokenMutationReturnType,
  MutationParameters
} from './mutations'

interface WithdrawStTokenContextType {
  resetWithdrawStTokenState: () => void

  signWithdrawStToken: (amount: string) => Promise<SignWithdrawStTokenMutationReturnType>
  signWithdrawStTokenData: SignWithdrawStTokenMutationReturnType | undefined
  isSigningWithdrawStToken: boolean
  signWithdrawStTokenError: unknown

  broadcastWithdrawStToken: () => Promise<BroadcastWithdrawStTokenMutationReturnType>
  isBroadcastingWithdrawStToken: boolean
  isBroadcastWithdrawStTokenSuccess: boolean
  broadcastWithdrawStTokenError: unknown

  ibcStatus: { status: IBCTransferStatus } | undefined
  traceIbcStatus: () => Promise<{ status: IBCTransferStatus }>
  isIbcStatusLoading: boolean
  ibcStatusError: unknown
}

const WithdrawStTokenContext = React.createContext<WithdrawStTokenContextType | null>(null)

const WithdrawStTokenProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [withdrawStTokenRaw, setWithdrawStTokenRaw] = useState<TxRaw | null>(null)

  const [withdrawStTokenTransaction, setWithdrawStTokenTransaction] = useState<DeliverTxResponse | null>(null)

  const parameters: MutationParameters = {
    withdrawStTokenRaw,
    setWithdrawStTokenRaw,
    withdrawStTokenTransaction,
    setWithdrawStTokenTransaction
  }

  const {
    mutateAsync: signWithdrawStToken,
    data: signWithdrawStTokenData,
    isPending: isSigningWithdrawStToken,
    error: signWithdrawStTokenError,
    reset: resetSignWithdrawStToken
  } = useSignWithdrawStTokenMutation(parameters)

  const {
    mutateAsync: broadcastWithdrawStToken,
    isPending: isBroadcastingWithdrawStToken,
    isSuccess: isBroadcastWithdrawStTokenSuccess,
    error: broadcastWithdrawStTokenError,
    reset: resetBroadcastWithdrawStToken
  } = useBroadcastWithdrawStTokenMutation(parameters)

  const {
    data: ibcStatus,
    mutateAsync: traceIbcStatus,
    isPending: isIbcStatusLoading,
    error: ibcStatusError,
    reset: resetIbcStatus
  } = useTraceIbcTransfer(parameters)

  const resetWithdrawStTokenState = () => {
    setWithdrawStTokenRaw(null)
    setWithdrawStTokenTransaction(null)
    resetSignWithdrawStToken()
    resetBroadcastWithdrawStToken()
    resetIbcStatus()
  }

  return (
    <WithdrawStTokenContext.Provider
      value={{
        resetWithdrawStTokenState,

        signWithdrawStToken,
        signWithdrawStTokenData,
        isSigningWithdrawStToken,
        signWithdrawStTokenError,

        broadcastWithdrawStToken,
        isBroadcastingWithdrawStToken,
        isBroadcastWithdrawStTokenSuccess,
        broadcastWithdrawStTokenError,

        ibcStatus,
        traceIbcStatus,
        isIbcStatusLoading,
        ibcStatusError
      }}>
      {children}
    </WithdrawStTokenContext.Provider>
  )
}

const useWithdrawStToken = () => {
  const context = useContext(WithdrawStTokenContext)

  if (context == null) {
    throw fatal('You forgot to mount WithdrawStTokenProvider.')
  }

  return context
}

export { WithdrawStTokenProvider, useWithdrawStToken }
