import React, { useMemo } from 'react'
import { Space } from '@mantine/core'
import { TransactionCard, TransactionCardStatus } from '@/components'
import { CHAIN_INFO_LIST } from '@/config'
import { IBCWithdrawStTokenMetaData, formatMicroDenom } from '@/wallet-utils'
import { ChainAssetUrl, ViewMintscanButton } from './shared'
import { useIbcStatusQuery } from './queries'

interface IBCWithdrawStTokenMetaDataCardProps {
  meta: IBCWithdrawStTokenMetaData
  onDismiss: (id: string) => void
}

// We don't need to support the other states
type SimpleTransactionCardStatus = Exclude<TransactionCardStatus, 'checking-expanded' | 'warning'>

const IBCWithdrawStTokenMetaDataCard: React.FC<IBCWithdrawStTokenMetaDataCardProps> = ({ meta, onDismiss }) => {
  const { data, error } = useIbcStatusQuery({ transaction: meta })

  const status: SimpleTransactionCardStatus = useMemo(() => {
    if (error) return 'checking-failed'
    if (data == null) return 'checking'
    if (data.status === 'return-later') return 'pending'
    if (data.status === 'timeout') return 'error'
    return data.status
  }, [data, error])

  const chainInfo = CHAIN_INFO_LIST[meta.values.hostDenom]

  const formattedAmount = `${formatMicroDenom(meta.values.amount, meta.values.hostMinimalDenom, 5)} ${
    meta.values.denom
  }`

  const titles: Record<SimpleTransactionCardStatus, () => string> = {
    checking: () => `Transfer of ${formattedAmount} to ${chainInfo.chainName}`,
    'checking-failed': () => `Transfer of ${formattedAmount} to ${chainInfo.chainName}`,
    pending: () => `Transferring ${formattedAmount} to ${chainInfo.chainName}`,
    error: () => `Error transferring ${formattedAmount}`,
    complete: () => `${formattedAmount} transferred to ${chainInfo.chainName}`
  }

  const description: Record<SimpleTransactionCardStatus, () => React.ReactNode> = {
    checking: () => 'Checking the status of your IBC transfer.',
    'checking-failed': () => 'We were unable to check the status of this IBC transaction. Please refresh the page.',
    pending: () =>
      'This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the transfer will continue. Once the transfer is complete, you will need to return to initiate the staking process.',
    complete: () => (
      <>
        {formattedAmount} has been successfully transferred from Stride to your wallet on {chainInfo.chainName}. The
        tokens are visible at your <ChainAssetUrl /> address.
      </>
    ),
    error: () =>
      `This transfer could not be completed. Your tokens have not been moved to ${chainInfo.chainName}. You can try again if you haven’t already.`
  }

  return (
    <TransactionCard
      status={status}
      title={titles[status]()}
      onDismiss={() => onDismiss(meta.values.hash)}
      description={description[status]()}>
      {(status === 'complete' || status === 'error') && (
        <>
          <Space h="md" />

          <ViewMintscanButton denom="STRD" hash={meta.values.hash} />
        </>
      )}
    </TransactionCard>
  )
}

export { IBCWithdrawStTokenMetaDataCard }
