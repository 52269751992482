import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { addHours } from 'date-fns'
import { z } from 'zod'

import { queryKeys } from '@/query-keys'
import { CHAIN_INFO_LIST, STRIDE_CHAIN_INFO } from '@/config'
import { useSelectedCoin } from '@/contexts/wallet'

export interface RateLimitPayload {
  remaining: string
  resetAt: number
}

// @TIP: The simplest way to test this query and all the other parts of rate limiting is to
// find `getRateLimitMetaData` and change it up to enable the rate limit screens to be shown.
const useRateLimitQuery = () => {
  const denom = useSelectedCoin()

  const handleRequest = async (): Promise<RateLimitPayload> => {
    const [rateLimits] = await Promise.all([getRateLimits()])

    const chainInfo = CHAIN_INFO_LIST[denom]

    const rateLimit = rateLimits.rate_limits.find((ratelimit) => {
      return ratelimit.path.denom === `st${chainInfo.stakeCurrency.coinMinimalDenom}`
    })

    if (rateLimit == null) {
      // If the rate limit does not exist, it means these chains will never hit them.
      // resetAt does not matter and we just need to set a high amount that will never
      // be hit.
      return {
        remaining: BigInt(1e30).toString(),
        resetAt: 0
      }
    }

    return {
      remaining: getRateLimitRemainingCapacity(rateLimit).toString(),
      resetAt: getRateLimitResetAt()
    }
  }

  return useQuery({
    queryKey: queryKeys.stakingRateLimitByDenom({ denom }),
    queryFn: handleRequest,
    // We want to ratelimit to be always updated (every minute) so we have an accurate data to present to the user
    refetchInterval: 60_000
  })
}

// -----------------------------------------------------
// Utilities
// -----------------------------------------------------

// Rate limits now reset at UTC 0 at 00:00:00
// @TODO - make sure this is working correctly. We're not
// anticipating any rate limits for the mean time.
const getRateLimitResetAt = () => {
  return addHours(new Date(), 8).getTime()
}

// capacity = (max_percent_send * channel_value / 100)
// remaining = capacity + inflow - outflow
const getRateLimitRemainingCapacity = (rateLimit: z.infer<typeof rateLimitSchema>): bigint => {
  const totalCapacity = (BigInt(rateLimit.quota.max_percent_send) * BigInt(rateLimit.flow.channel_value)) / BigInt(100)
  return totalCapacity + BigInt(rateLimit.flow.inflow) - BigInt(rateLimit.flow.outflow)
}

// -----------------------------------------------------
// API schema and functions
// -----------------------------------------------------

const instance = axios.create({
  baseURL: STRIDE_CHAIN_INFO.rest
})

const rateLimitSchema = z.object({
  path: z.object({
    denom: z.string(),
    channel_id: z.string()
  }),

  quota: z.object({
    max_percent_send: z.string(),
    max_percent_recv: z.string(),
    duration_hours: z.string()
  }),

  flow: z.object({
    inflow: z.string(),
    outflow: z.string(),
    channel_value: z.string()
  })
})

const rateLimitResponseSchema = z.object({
  rate_limits: z.array(rateLimitSchema)
})

const getRateLimits = async () => {
  const response = await instance.get(`Stride-Labs/ibc-rate-limiting/ratelimit/ratelimits`)
  return rateLimitResponseSchema.parse(response.data)
}

export { useRateLimitQuery }
