import React from 'react'
import BigNumber from 'bignumber.js'
import { Box, Button, Space } from '@mantine/core'
import { StepperModalContent, useStepperContext, InlineLoader } from '@/components'
import { useMount } from '@/hooks'

import { fatal } from '@/utils'
import { notify } from '@/contexts/notifications'
import { OSMO_CHAIN_INFO, DEX_INFO_LIST, DEX_CONFIG, DEX_POOL_MINIMUM_APY } from '@/config'

import { useStakeIbcx } from '../StakeIbcxProvider'
import { useStakeIbcxModal } from './StakeIbcxModalProvider'
import { useIbcxTokensMarketPriceQuery } from '../queries'
import { useStakeIbcxOsmosisPoolTotalApr } from './queries'
import { useSelectedCoin } from '@/contexts/wallet'

const StakeIbcxStepTwo: React.FC = () => {
  const { complete, forceClose, close, handleClose } = useStepperContext()

  const { broadcastStakeIbcx, isBroadcastingStakeIbcx, isBroadcastStakeIbcxSuccessful, broadcastStakeIbcxError } =
    useStakeIbcxModal()

  const { stakeModal } = useStakeIbcx()

  const { data: osmosisPoolTotalApr, error: osmosisPoolTotalAprError } = useStakeIbcxOsmosisPoolTotalApr()

  const denom = useSelectedCoin()

  const { data: marketPrice } = useIbcxTokensMarketPriceQuery()

  const redemptionRate = Number(marketPrice?.strideStTokenValue ?? 1)

  // May be a good idea to not format this and instead add a validation for max number of precisions
  const formattedAmount = `${new BigNumber(stakeModal.amount).decimalPlaces(3).toString()} ${denom}`

  const stakedAmount = new BigNumber(stakeModal.amount).dividedBy(redemptionRate).decimalPlaces(3).toNumber()

  const formattedStakedAmount = `${stakedAmount} st${denom}`

  const handleStep = async () => {
    await broadcastStakeIbcx()
    complete()
  }

  const handleCloseCallback = () => {
    // If it was closed after the broadcast, it doesn't matter
    if (!isBroadcastingStakeIbcx) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the staking completes.")
  }

  useMount(() => {
    handleStep()
  })

  handleClose(handleCloseCallback)

  const handleRetry = async () => {
    handleStep()
  }

  if (broadcastStakeIbcxError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="This transaction could not be completed. Your tokens have not been staked."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetry}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  const dexConfig = DEX_CONFIG.STIBCX

  const dexInfo = dexConfig && DEX_INFO_LIST[dexConfig.type]

  if (dexConfig == null || dexInfo == null) {
    throw fatal('Unable to find dex info for stIBCX')
  }

  // osmosisPoolTotalApr operates from 0 to 100, meanwhile DEX_POOL_MINIMUM_APY is decimal-based.
  const HUMAN_READABLE_POOL_MINIMUM_APY = DEX_POOL_MINIMUM_APY * 100

  const isAprDataInsufficient =
    Boolean(osmosisPoolTotalAprError) &&
    osmosisPoolTotalApr &&
    osmosisPoolTotalApr.apr <= HUMAN_READABLE_POOL_MINIMUM_APY

  if (isBroadcastStakeIbcxSuccessful) {
    return (
      <StepperModalContent
        title="Success!"
        description={
          <>
            <Box>
              You staked {formattedAmount} on {OSMO_CHAIN_INFO.chainName} and {formattedStakedAmount} has been added to
              your wallet on {OSMO_CHAIN_INFO.chainName}.
            </Box>

            <Space h="xs" />

            {isAprDataInsufficient ? (
              <Box>Do you want to add it to the liquidity pool on {dexInfo.name} to earn an extra APR?</Box>
            ) : (
              <Box>
                Do you want to add it to the liquidity pool on {dexInfo.name} to earn an{' '}
                <strong>
                  extra{' '}
                  {osmosisPoolTotalApr == null ? (
                    <InlineLoader />
                  ) : (
                    new BigNumber(osmosisPoolTotalApr.apr).decimalPlaces(2).toString()
                  )}
                  % APR
                </strong>
                ?
              </Box>
            )}
          </>
        }
        actions={
          <>
            <Button variant="outline" color="dark" onClick={close}>
              Exit
            </Button>

            <Button component="a" href={dexInfo.poolUrl(dexConfig.poolId)} target="_blank">
              Open Pool
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title={`Staking your ${denom}`}
      description="Just a few seconds, unless the network is congested"
    />
  )
}

export { StakeIbcxStepTwo }
