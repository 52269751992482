import { useInterval } from '@/hooks'
import { intervalToDuration } from 'date-fns'
import { useState } from 'react'
import { useRateLimitQuery } from '../../queries'
import { ErrorBeacon, InlineLoader } from '@/components'

// @TODO: Suffix with `StepperContent`
const RateLimitTimeUntilReset: React.FC = () => {
  const { data: rateLimit, error: rateLimitError } = useRateLimitQuery()

  const [now, setNow] = useState(() => new Date())

  useInterval(
    () => {
      setNow(new Date())
    },
    rateLimit == null ? null : 30_000
  )

  // For a moment 2-weeks after I worked on this, I thought this wasn't needed.
  // This is important because we also use this component when a transaction fails due to rate limiting.
  if (rateLimitError) {
    return (
      <>
        Rate Limits will reset in <ErrorBeacon label="An error occurred while trying to fetch rate limiting status" />
      </>
    )
  }

  // For a moment 2-weeks after I worked on this, I thought this wasn't needed.
  // This is important because we also use this component when a transaction fails due to rate limiting.
  if (rateLimit == null) {
    return (
      <>
        Rate Limits will reset in <InlineLoader />
      </>
    )
  }

  const interval = intervalToDuration({
    start: now,
    end: new Date(rateLimit.resetAt)
  })

  const getFormattedInterval = () => {
    if (interval.hours) {
      return (
        <>
          in{' '}
          <strong>
            {interval.hours}h {interval.minutes}m
          </strong>
        </>
      )
    }

    if (interval.minutes) {
      return (
        <>
          in <strong>{interval.minutes} minutes</strong>
        </>
      )
    }

    return <>any time now</>
  }

  return <>Rate Limits will reset {getFormattedInterval()}</>
}

export { RateLimitTimeUntilReset }
