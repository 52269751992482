import { Box, Group, Space, Text, Stack, Button, Tooltip, Alert, Anchor } from '@mantine/core'
import { isHostZone } from '@/queries'
import { LsmValidator, useHostZoneQuery, useLsmValidatorsQuery } from '../queries'
import { assert } from '@/utils'
import { useMemo } from 'react'
import { useStake } from '../StakeProvider'
import { LsmValidatorAvatar } from '../shared/LsmValidatorAvatar/LsmValidatorAvatar'
import { LsmValidatorName } from '../shared'
import { CHAIN_INFO_LIST, LSM_VALIDATOR_ADDRESSES_AUTO_REDELEGATE } from '@/config'
import { useSelectedCoin } from '@/contexts/wallet'
import { formatMicroDenom } from '@/wallet-utils'

const StakeLsmSelection: React.FC = () => {
  const denom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const { data: lsmValidators } = useLsmValidatorsQuery()

  const { data: hostZone } = useHostZoneQuery()

  assert(lsmValidators, `Unable to render LSM selection while validators have not loaded yet.`)

  assert(hostZone, `Unable to render LSM selection while hostZone has not loaded yet.`)

  assert(isHostZone(hostZone), `Incorrect host zone type for ${chainInfo.chainId}`)

  // Given this is a significantly bigger list, convert the host zone validators into a map for faster lookup
  // This is used to either check if the validator is part of stride's validator list (130-ish) or if it's currently slashed
  const hostZoneValidatorsMap = useMemo(() => {
    // { 'cosmosvaloper1...': true, ... }
    return Object.fromEntries(
      hostZone.validators.map((validator) => {
        return [validator.address, validator]
      })
    )
  }, [hostZone])

  const lsmValidatorPayloads = lsmValidators.validators.map((validator) => {
    // Validate conditions ahead of time, and treat error handlers inside the LSM flows
    // as a fallback if for some unknown reason they're able to proceed. So to speak, if
    // we need to test error handling, we just need to comment out some conditions here.
    //
    // For validators not in the active set (not included in the hostZone.vaidators list),
    // I found that LSM Liquid Stake would end up with code -32603 error.
    //
    // For validators that were recently redelegated to, they'll run into simulation errors first
    // (which we handle) on the first step.
    //
    // For validators that have a remaining bond shares of 0, they'll run into validation errors first.
    // For testing, it will need to be disabled. BBut they'll immediately run into simulation and tx
    // errors on the first step (which we handle).
    const tooltip = hostZoneValidatorsMap[validator.address]?.slash_query_in_progress
      ? 'This validator was recently slashed. Please try again in a few minutes.'
      : !hostZoneValidatorsMap[validator.address]
      ? `This validator hasn't been active long enough to qualify.`
      : validator.redelegating
      ? 'You have recently redelegated to this validator. Your funds are locked until the redelegation completes.'
      : BigInt(validator.remainingBondShares) <= 0
      ? 'This validator must increase their self-bond.'
      : LSM_VALIDATOR_ADDRESSES_AUTO_REDELEGATE.includes(validator.address) // We don't want these to be slashed if they are; instead, auto-initiate redelegation instead
      ? 'This validator currently does not meet Stride’s requirements. Consider relegating to another validator.'
      : ''

    return { validator, tooltip }
  })

  // Sort by amount from highest to lowest
  const toSortByAmount = (payloads: typeof lsmValidatorPayloads) => {
    return payloads.sort((a, b) => {
      const amounts = { a: BigInt(a.validator.amount), b: BigInt(b.validator.amount) }
      return amounts.a > amounts.b ? -1 : amounts.a < amounts.b ? 1 : 0
    })
  }

  // Sort validators without errors to the top
  const lsmValidatorsWithoutErrors = toSortByAmount(
    lsmValidatorPayloads.filter(({ tooltip }) => {
      return tooltip === ''
    })
  )

  // Sort validators with errors to the bottom
  const lsmValidatorsWithErrors = toSortByAmount(
    lsmValidatorPayloads.filter(({ tooltip }) => {
      return tooltip !== ''
    })
  )

  return (
    <>
      <Text inline>Choose the validator to pull from:</Text>

      <Space h="md" />

      <Stack spacing="lg">
        {lsmValidatorsWithoutErrors.map(({ validator, tooltip }) => {
          return <StakeLsmSelectionItem key={validator.address} validator={validator} tooltip={tooltip} />
        })}
        {lsmValidatorsWithErrors.map(({ validator, tooltip }) => {
          return <StakeLsmSelectionItem key={validator.address} validator={validator} tooltip={tooltip} />
        })}

        {lsmValidatorsWithErrors.length ? (
          <Alert color="yellow">
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>
              If you can't select a validator of your choice, it likely doesn't meet Stride's requirements. Consider
              redelegating to another validator. Learn more{' '}
              <Anchor href="https://docs.stride.zone/docs/lsm-troubleshooting" target="_blank">
                here
              </Anchor>
              .
            </Text>
          </Alert>
        ) : null}
      </Stack>
    </>
  )
}

interface StakeLsmSelectionItemProps {
  validator: LsmValidator
  tooltip: string
}

const StakeLsmSelectionItem = ({ validator, tooltip }: StakeLsmSelectionItemProps) => {
  const denom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const { setLsmValidatorAddress } = useStake()

  const handleSelect = () => {
    setLsmValidatorAddress(validator.address)
  }

  return (
    <Group position="apart" key={validator.address}>
      <Group spacing="sm" sx={{ opacity: tooltip ? 0.6 : 1 }}>
        <LsmValidatorAvatar address={validator.address} />

        <Box>
          <Text inline weight={600} size="md">
            <LsmValidatorName address={validator.address} />
          </Text>

          <Space h="xs" />

          <Text inline>
            Available: {formatMicroDenom(validator.amount, chainInfo.stakeCurrency.coinMinimalDenom, 3)} {denom}
          </Text>
        </Box>
      </Group>

      <Tooltip label={tooltip} disabled={!tooltip} width={260} wrapLines>
        <Button onClick={handleSelect} disabled={Boolean(tooltip)}>
          Select
        </Button>
      </Tooltip>
    </Group>
  )
}

export { StakeLsmSelection }
