import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { fatal, disregard } from '@/utils'
import { stride } from 'stridejs'
import { queryKeys } from '@/query-keys'
import { STRIDE_CHAIN_INFO, AIRDROP_IDENTIFIERS } from '@/config'
import {
  assertIsDeliverTxSuccess,
  broadcastIbcTransaction,
  traceIbcStatus,
  IBCTransferStatus,
  isSafeModeAccount,
  formatMicroDenom
} from '@/wallet-utils'
import { useTopBanner } from '@/contexts/top-banner'
import { notify } from '@/contexts/notifications'
import { MutationParameters } from './types'
import { useRefreshBalances, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

export type BroadcastSendTokenMutationMode = 'deposit' | 'withdraw'

export interface BroadcastSendTokenMutationReturnType {
  tx: DeliverTxResponse
  status: IBCTransferStatus
  claimableAmount: bigint
}

// Top-level things that this mutation does:
// - Fetches airdrop claimable amount
// - Broadcasts an ibc transfer
// - Display banner for claimed amount
const useBroadcastStakeAutopilotMutation = ({
  stakeAutopilotRaw,
  setStakeAutopilotTransaction
}: MutationParameters) => {
  const client = useQueryClient()

  const strideAccount = useStrideWallet()
  const selectedAccount = useSelectedWallet()
  const updateAccountBalances = useRefreshBalances()

  const { setBanner } = useTopBanner()

  // We'll fetch if user has any claimable amount and prepare the data needed
  // so we can properly display a top-banner notification after liquid staking succeeds.
  const getClaimableAmountFromLiquidStakeTask = async (): Promise<bigint> => {
    if (!strideAccount) {
      // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
      // But we'll return anyway instead of crashing just to be safe (given the unknowns).
      // https://github.com/Stride-Labs/interface/issues/262
      return BigInt(0)
    }

    const rpc = await stride.ClientFactory.createRPCQueryClient({
      rpcEndpoint: STRIDE_CHAIN_INFO.rpc
    })

    const data = await Promise.all(
      AIRDROP_IDENTIFIERS.map((identifier) => {
        return rpc.stride.claim.claimableForAction({
          airdropIdentifier: identifier,
          address: strideAccount.address,
          action: 1 // 1 Correponds to ACTION_LIQUID_STAKE
        })
      })
    )

    return data.reduce((total, claimable) => {
      const sum = claimable.coins.reduce((t, coin) => {
        return BigInt(t) + BigInt(coin.amount)
      }, BigInt(0))

      return BigInt(total) + BigInt(sum)
    }, BigInt(0))
  }

  const handleClaimableAmountFromLiquidStakeTask = (amount: bigint) => {
    if (amount <= 0) {
      return
    }

    if (!strideAccount) {
      // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
      // But we'll return anyway instead of crashing just to be safe (given the unknowns).
      // https://github.com/Stride-Labs/interface/issues/262
      return
    }

    const formattedAmount = `${formatMicroDenom(amount, 'ustrd', 2)} ${strideAccount.currency.coinDenom}`

    setBanner({
      icon: 'arrowHorizontal',
      text: (
        <>
          You completed a task and claimed <strong>{formattedAmount}</strong>
        </>
      ),
      url: '/airdrop/claim'
    })
  }

  const handleMutation = async (): Promise<BroadcastSendTokenMutationReturnType> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!stakeAutopilotRaw) {
      throw fatal('Unable to broadcast transaction without a signed transaction raw.')
    }

    const claimableAmount = await getClaimableAmountFromLiquidStakeTask().catch((e) => {
      disregard(e)
      return BigInt(0)
    })

    if (isSafeModeAccount(selectedAccount)) throw fatal('Safe mode is enabled.')

    const tx = await broadcastIbcTransaction(selectedAccount, stakeAutopilotRaw)

    assertIsDeliverTxSuccess(tx)

    const status = await traceIbcStatus({ account: selectedAccount, tx })

    if (status === 'return-later') {
      return {
        tx: tx,
        status: 'return-later',
        claimableAmount
      }
    }

    // We'll handle this after balance is updated because gas fee is still consumed
    // @TODO: It's possible that we may have to wait for funds to be fully refunded
    // @TODO: Handle error - we probably need a flow for this
    if (status === 'timeout') {
      return {
        tx: tx,
        status: 'timeout',
        claimableAmount
      }
    }

    return {
      tx: tx,
      status: 'complete',
      claimableAmount
    }
  }

  const handleSettled = async () => {
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })
  }

  const handleSuccess = async ({ tx, claimableAmount }: BroadcastSendTokenMutationReturnType) => {
    setStakeAutopilotTransaction(tx)

    handleClaimableAmountFromLiquidStakeTask(claimableAmount)

    await client.invalidateQueries({
      queryKey: queryKeys.transactionHistoryIbcStatusByHash({ hash: tx.transactionHash })
    })

    // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
    // But we'll return anyway instead of crashing just to be safe (given the unknowns).
    // https://github.com/Stride-Labs/interface/issues/262
    if (!strideAccount || !selectedAccount) {
      return
    }

    try {
      await updateAccountBalances()
    } catch (e) {
      disregard(e)
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
    }
  }

  return useMutation({
    mutationFn: handleMutation,
    onSettled: handleSettled,
    onSuccess: handleSuccess
  })
}

export { useBroadcastStakeAutopilotMutation }
