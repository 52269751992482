import React, { useMemo } from 'react'

import { StepperModal, StepperProvider, useStepper } from '@/components'
import { useUnstakeIbcxModal } from '.'
import { useStakeIbcx } from '../StakeIbcxProvider'
import { UnstakeIbcxModalStepOne } from './UnstakeIbcxModalStepOne'
import { UnstakeIbcxModalStepTwo } from './UnstakeIbcxModalStepTwo'
import { useSelectedCoin } from '@/contexts/wallet'

interface UnstakeModalProps {
  onSuccess: () => void
}

const UnstakeIbcxModal: React.FC<UnstakeModalProps> = ({ onSuccess }) => {
  const { unstakeModal, setUnstakeModal } = useStakeIbcx()

  const {
    isBroadcastingRedeemStake,
    signRedeemStakeError,
    broadcastRedeemStakeError,
    isBroadcastRedeemIbcxSuccessful,
    resetUnstakeModalState
  } = useUnstakeIbcxModal()

  const denom = useSelectedCoin()

  const stakedDenom = useMemo(() => `st${denom}`, [denom])

  const title = 'Unstaking'

  const formattedAmount = `${unstakeModal.amount} ${stakedDenom}`

  const steps = useMemo(() => {
    return ['Approve transfer in wallet', isBroadcastRedeemIbcxSuccessful ? `Unstake Success` : `Unstake tokens`]
  }, [denom, isBroadcastRedeemIbcxSuccessful])

  const handleClose = () => {
    setUnstakeModal({ isOpen: false, amount: 0 })
    resetUnstakeModalState()
  }

  const stepper = useStepper({
    steps,
    warning: false,
    error: Boolean(signRedeemStakeError || broadcastRedeemStakeError),
    isOpen: unstakeModal.isOpen,
    isLoading: isBroadcastingRedeemStake,
    isPostError: false,
    isComplete: false,
    onClose: handleClose,
    onComplete: onSuccess
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title={title} amount={formattedAmount}>
        <UnstakeIbcxModalStepOne />
        <UnstakeIbcxModalStepTwo />
      </StepperModal>
    </StepperProvider>
  )
}

export { UnstakeIbcxModal }
