import React, { useContext, useState } from 'react'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal } from '@/utils'
import { IBCTransferStatus } from '@/wallet-utils'
import {
  useSignWithdrawTokenizedSharesMutation,
  useBroadcastWithdrawTokenizedSharesMutation,
  useTraceIbcTransfer,
  useBroadcastRedeemTokenForSharesMutation,
  useSignRedeemTokenForSharesMutation,
  BroadcastWithdrawStTokenMutationReturnType,
  BroadcastRedeemTokenForsharesMutationReturnType,
  MutationParameters
} from './mutations'

interface WithdrawStTokenContextType {
  resetWithdrawStTokenState: () => void

  signWithdrawTokenizedShares: () => Promise<TxRaw>
  isSigningWithdrawTokenizedShares: boolean
  signWithdrawTokenizedSharesError: unknown

  broadcastWithdrawTokenizedShares: () => Promise<BroadcastWithdrawStTokenMutationReturnType>
  isBroadcastingWithdrawTokenizedShares: boolean
  isBroadcastWithdrawTokenizedSharesSuccess: boolean
  broadcastWithdrawTokenizedSharesError: unknown

  ibcStatus: { status: IBCTransferStatus } | undefined
  traceIbcStatus: () => Promise<{ status: IBCTransferStatus }>
  isIbcStatusLoading: boolean
  ibcStatusError: unknown

  signRedeemTokenForShares: () => Promise<TxRaw>
  isSigningRedeemTokenForShares: boolean
  signRedeemTokenForSharesError: unknown

  broadcastRedeemTokenForShares: () => Promise<BroadcastRedeemTokenForsharesMutationReturnType>
  isBroadcastingRedeemTokenForShares: boolean
  isBroadcastRedeemTokenForSharesSuccess: boolean
  broadcastRedeemTokenForSharesError: unknown
}

const WithdrawStTokenContext = React.createContext<WithdrawStTokenContextType | null>(null)

const WithdrawLsmModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [withdrawStTokenRaw, setWithdrawStTokenRaw] = useState<TxRaw | null>(null)

  const [withdrawStTokenTransaction, setWithdrawStTokenTransaction] = useState<DeliverTxResponse | null>(null)

  const [redeemTokensRaw, setRedeemTokensRaw] = useState<TxRaw | null>(null)

  const parameters: MutationParameters = {
    withdrawStTokenRaw,
    setWithdrawStTokenRaw,
    withdrawStTokenTransaction,
    setWithdrawStTokenTransaction,
    redeemTokensRaw,
    setRedeemTokensRaw
  }

  const {
    mutateAsync: signWithdrawTokenizedShares,
    isPending: isSigningWithdrawTokenizedShares,
    error: signWithdrawTokenizedSharesError,
    reset: resetSignWithdrawTokenizedShares
  } = useSignWithdrawTokenizedSharesMutation(parameters)

  const {
    mutateAsync: broadcastWithdrawTokenizedShares,
    isPending: isBroadcastingWithdrawTokenizedShares,
    isSuccess: isBroadcastWithdrawTokenizedSharesSuccess,
    error: broadcastWithdrawTokenizedSharesError,
    reset: resetBroadcastWithdrawTokenizedShares
  } = useBroadcastWithdrawTokenizedSharesMutation(parameters)

  const {
    data: ibcStatus,
    mutateAsync: traceIbcStatus,
    isPending: isIbcStatusLoading,
    error: ibcStatusError,
    reset: resetIbcStatus
  } = useTraceIbcTransfer(parameters)

  const {
    mutateAsync: signRedeemTokenForShares,
    isPending: isSigningRedeemTokenForShares,
    error: signRedeemTokenForSharesError,
    reset: resetSignRedeemTokenForShares
  } = useSignRedeemTokenForSharesMutation(parameters)

  const {
    mutateAsync: broadcastRedeemTokenForShares,
    isPending: isBroadcastingRedeemTokenForShares,
    isSuccess: isBroadcastRedeemTokenForSharesSuccess,
    error: broadcastRedeemTokenForSharesError,
    reset: resetBroadcastRedeemTokenForShares
  } = useBroadcastRedeemTokenForSharesMutation(parameters)

  const resetWithdrawStTokenState = () => {
    setWithdrawStTokenRaw(null)
    setWithdrawStTokenTransaction(null)
    resetSignWithdrawTokenizedShares()
    resetBroadcastWithdrawTokenizedShares()
    resetIbcStatus()
    resetSignRedeemTokenForShares()
    resetBroadcastRedeemTokenForShares()
  }

  return (
    <WithdrawStTokenContext.Provider
      value={{
        resetWithdrawStTokenState,

        signWithdrawTokenizedShares,
        isSigningWithdrawTokenizedShares,
        signWithdrawTokenizedSharesError,

        broadcastWithdrawTokenizedShares,
        isBroadcastingWithdrawTokenizedShares,
        isBroadcastWithdrawTokenizedSharesSuccess,
        broadcastWithdrawTokenizedSharesError,

        ibcStatus,
        traceIbcStatus,
        isIbcStatusLoading,
        ibcStatusError,

        signRedeemTokenForShares,
        isSigningRedeemTokenForShares,
        signRedeemTokenForSharesError,

        broadcastRedeemTokenForShares,
        isBroadcastingRedeemTokenForShares,
        isBroadcastRedeemTokenForSharesSuccess,
        broadcastRedeemTokenForSharesError
      }}>
      {children}
    </WithdrawStTokenContext.Provider>
  )
}

const useWithdrawLsmModal = () => {
  const context = useContext(WithdrawStTokenContext)

  if (context == null) {
    throw fatal('You forgot to mount WithdrawLsmModalProvider.')
  }

  return context
}

export { WithdrawLsmModalProvider, useWithdrawLsmModal }
