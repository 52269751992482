import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { StepperProvider, StepperModal, useStepper } from '@/components'
import { useStakeIbcx } from '../StakeIbcxProvider'
import { useStakeIbcxModal } from './StakeIbcxModalProvider'
import { StakeIbcxStepOne } from './StakeIbcxStepOne'
import { StakeIbcxStepTwo } from './StakeIbcxStepTwo'
import { useStakeIbcxOsmosisPoolTotalApr } from './queries'
import { useSelectedCoin } from '@/contexts/wallet'

interface StakeIbcxModalProps {
  onSuccess: () => void
}

const StakeIbcxModal: React.FC<StakeIbcxModalProps> = ({ onSuccess }) => {
  // Prefetch stIBCX's pool aPR
  useStakeIbcxOsmosisPoolTotalApr()

  const { isBroadcastingStakeIbcx, signStakeIbcxError, broadcastStakeIbcxError, resetStakeIbcxModalState } =
    useStakeIbcxModal()

  const denom = useSelectedCoin()

  const { stakeModal, setStakeModal } = useStakeIbcx()

  const formattedAmount = `${new BigNumber(stakeModal?.amount ?? 0).decimalPlaces(3).toString()} ${denom}`

  const steps = useMemo(() => {
    return ['Approve staking in wallet', `Stake ${denom}, get st${denom}`]
  }, [denom])

  const handleClose = () => {
    setStakeModal({ isOpen: false, amount: 0 })
    resetStakeIbcxModalState()
  }

  const stepper = useStepper({
    steps,
    warning: false,
    error: Boolean(signStakeIbcxError || broadcastStakeIbcxError),
    isOpen: stakeModal.isOpen,
    isLoading: isBroadcastingStakeIbcx,
    isPostError: false,
    isComplete: false,
    onClose: handleClose,
    onComplete: onSuccess
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title="Liquid Staking" amount={formattedAmount}>
        <StakeIbcxStepOne />
        <StakeIbcxStepTwo />
      </StepperModal>
    </StepperProvider>
  )
}

export { StakeIbcxModal }
