import { useMutation } from '@tanstack/react-query'
import { CHAIN_CONFIG } from '@/config'
import { generateIbcTimeoutTimestamp, calculateFee, isSafeModeAccount } from '@/wallet-utils'
import { assert } from '@/utils'
import { MutationParameters } from './types'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { useStake } from '../../StakeProvider'
import { useSelectedWallet, useStrideWallet } from '@/contexts/wallet'
import { flushSync } from 'react-dom'

const useSignWithdrawTokenizedSharesMutation = ({ setWithdrawStTokenRaw }: MutationParameters) => {
  const strideAccount = useStrideWallet()
  const selectedAccount = useSelectedWallet()

  const { withdrawLsm } = useStake()

  const handleMutation = async (): Promise<TxRaw> => {
    assert(strideAccount, 'You are unable to revert tokenized shares without connecting your Stride wallet.')
    assert(!isSafeModeAccount(strideAccount), 'Cannot revert tokenized shares while safe mode is enabled')
    assert(selectedAccount, 'You are unable to revert tokenized shares without connecting your selected wallet.')

    const sourceChannel = CHAIN_CONFIG[selectedAccount.currency.coinDenom].withdrawChannel

    const msgTransfer = {
      typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
      value: {
        sourcePort: 'transfer',
        sourceChannel: sourceChannel,
        token: {
          amount: withdrawLsm.amount,
          denom: withdrawLsm.txHistoryIbcDenom
        },
        sender: strideAccount.address,
        receiver: selectedAccount.address,
        memo: '',
        timeoutTimestamp: generateIbcTimeoutTimestamp()
      }
    }

    const gas = await strideAccount.client.simulate(strideAccount.address, [msgTransfer], '')
    const fee = calculateFee(strideAccount, gas)

    return await strideAccount.client.sign(strideAccount.address, [msgTransfer], fee, '')
  }

  const handleSuccess = (raw: TxRaw) => {
    flushSync(() => {
      setWithdrawStTokenRaw(raw)
    })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  });
}

export { useSignWithdrawTokenizedSharesMutation }
