import type { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx'
import { MetaDataHandlerParameters } from './types'

// There is a known issue where sometimes a "Withdraw St Token" transaction is categorized
// as an "IBC DEX" transaction. Technically it is! This happens to chains whose DEX is under
// the same chain. For instance, Evmos Forge also belongs to Evmos. I'm not sure if there's
// any way we can distinguish the two.
const handleIbcDex = ({
  message,
  strideAccount,
  selectedAccount,
  dexAccount
}: MetaDataHandlerParameters<MsgTransfer>): boolean => {
  if (!dexAccount) {
    return false
  }

  if (message.token == null) {
    return false
  }

  if (!message.token.denom.startsWith('st')) {
    return false
  }

  const isTransferredForOsmosisWithdrawStTokenToDex =
    message.sender === strideAccount.address &&
    message.receiver === dexAccount.address &&
    message.token.denom === `st${selectedAccount.currency.coinMinimalDenom}`

  return isTransferredForOsmosisWithdrawStTokenToDex
}

export { handleIbcDex }
