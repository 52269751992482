import { InlineLoader, StepperModalContent, useStepperContext } from '@/components'
import { CHAIN_SUPPORTS_ADD_TO_DEX, DEX_POOL_MINIMUM_APY, getDexInfoFromDenom } from '@/config'
import { notify } from '@/contexts/notifications'
import { useDexWallet, useSelectedCoin } from '@/contexts/wallet'
import { fatal } from '@/utils'
import { StandardTransactionError, formatDenom } from '@/wallet-utils'
import { Anchor, Box, Button, Space } from '@mantine/core'
import { useSetAtom } from 'jotai'
import Link from 'next/link'
import React, { useEffect, useMemo } from 'react'
import { useStake } from '../StakeProvider'
import { stakeModeAtom, withdrawStTokenToDexModalIsOpenAtom, withdrawStTokenToDexTransactionAtom } from '../atoms'
import { useDexPoolApyQuery, useHostZoneQuery } from '../queries'
import { getLsmEstimatedStTokenValue } from '../utils'
import { useStakeLsmModal } from './StakeLsmModalProvider'
import { useLiquidStakeQueryCallbackQuery } from './queries'
import { StakeLsmSlashed } from './shared'

const StakeLsmModalStepThree: React.FC = () => {
  const { lsm } = useStake()

  const {
    signLiquidStake,
    signLiquidStakeError,
    broadcastLiquidStakeData,
    broadcastLiquidStake,
    isBroadcastingLiquidStake,
    broadcastLiquidStakeError
  } = useStakeLsmModal()

  const { handleClose, forceClose, close, complete } = useStepperContext()

  const dexAccount = useDexWallet()

  const denom = useSelectedCoin()

  const setWithdrawStTokenToDexModalIsOpen = useSetAtom(withdrawStTokenToDexModalIsOpenAtom)

  const setWithdrawStTokenToDexTransaction = useSetAtom(withdrawStTokenToDexTransactionAtom)

  const setStakeMode = useSetAtom(stakeModeAtom)

  const { data: hostZone } = useHostZoneQuery()

  const { data: withdrawStTokenToDexApy, error: withdrawStTokenToDexApyError } = useDexPoolApyQuery()

  const {
    data: queryCallback,
    isLoading: isQueryCallbackLoading,
    error: queryCallbackError,
    refetch: refetchQueryCallback
  } = useLiquidStakeQueryCallbackQuery()

  const estimatedStTokenValue = useMemo(() => {
    return getLsmEstimatedStTokenValue({
      amount: lsm.amount,
      hostZone,
      validatorAddress: lsm.validatorAddress
    })
  }, [lsm, hostZone])

  const formattedAmount = `${formatDenom(lsm.amount, 3)} ${denom}`

  const formattedStakedAmount = `${formatDenom(estimatedStTokenValue, 3)} st${denom}`

  const handleBroadcast = async () => {
    const { status } = await broadcastLiquidStake()

    if (status === 'success') {
      complete()
    }
  }

  const handleStep = async () => {
    await signLiquidStake()
    await handleBroadcast()
  }

  useEffect(() => {
    // We want to initiate the whole step either when the user came from step 2
    // or when the user retries a query callback that ended up being a failure.
    // We'll explicitly check that the modal is open; otherwise we end up restarting
    // the step when the user closes the modal.
    if (lsm.isOpen && broadcastLiquidStakeData == null) {
      handleStep()
    }
  }, [lsm.isOpen, broadcastLiquidStakeData])

  useEffect(() => {
    // When the query callback ends up succeeding, we'll mark the flow as complete.
    if (queryCallback?.status === 'success') {
      complete()
    }
  }, [queryCallback])

  const handleCloseCallback = () => {
    // It means it was closed after the broadcast
    if (!isBroadcastingLiquidStake) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the stake completes.")
  }

  handleClose(handleCloseCallback)

  const handleWithdrawStTokenToDex = () => {
    // If the liquid stake transaction ends up pending, it does not contain the event attributes
    // needed by "Add to DEX" to parse the actual stToken amount received.
    const transaction =
      broadcastLiquidStakeData?.status === 'pending'
        ? queryCallback?.transaction
        : broadcastLiquidStakeData?.transaction

    if (transaction == null) {
      throw fatal('Unable to proceed to osmosis liquidity pools while stake transaction is null.')
    }

    setStakeMode('classic')
    setWithdrawStTokenToDexTransaction(transaction)
    close()
    setWithdrawStTokenToDexModalIsOpen(true)
  }

  const handleRetryRequestFailure = () => {
    refetchQueryCallback()
  }

  const stepperModalContentTitle = lsm.txHistoryIbcDenom
    ? `Great! Approve in your wallet to start staking your ${denom}`
    : `Transfer complete! Approve in your wallet again to start staking your ${denom}`

  const isQueryCallbackPending =
    broadcastLiquidStakeData?.status === 'pending' && (isQueryCallbackLoading || queryCallback?.status === 'pending')

  if (broadcastLiquidStakeData?.status === 'failed' || queryCallback?.status === 'failed') {
    return <StakeLsmSlashed />
  }

  if (queryCallbackError) {
    return (
      <StepperModalContent
        title="An error occurred"
        description={`Your ${denom} tokens have been liquid staked on Stride, but was unable to confirm status due to rpc failure. Please check again.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetryRequestFailure}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isQueryCallbackPending) {
    return (
      <StepperModalContent
        title="Running an additional safety check"
        description={`For your safety, we’re running an additional safety check of the selected validator. This will take 30 seconds on average. You can close this popup now, your st${denom} should appear in your wallet shortly. `}
        actions={
          <Button color="dark" variant="outline" onClick={forceClose}>
            Close
          </Button>
        }
      />
    )
  }

  if (
    broadcastLiquidStakeError instanceof StandardTransactionError &&
    broadcastLiquidStakeError.description === 'INSUFFICIENT_FUNDS'
  ) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            Insufficient funds for gas. Get STRD, ATOM or TIA on{' '}
            <Anchor href="https://app.osmosis.zone/?from=ATOM&to=OSMO" target="_blank">
              Osmosis
            </Anchor>
            .
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
        error={broadcastLiquidStakeError}
      />
    )
  }

  if (broadcastLiquidStakeError instanceof StandardTransactionError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={broadcastLiquidStakeError.message}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
        error={broadcastLiquidStakeError}
      />
    )
  }

  if (broadcastLiquidStakeError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            This transfer could not be completed. Your tokens are on Stride, but have not been staked. Please try again.
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  // It's important that this is placed behind a success condition to avoid the step from
  // flashing this message on the first render given that the mutation only runs after the mount.
  // In addition, we'll check for both mutation data and query data. Query data signifies that we
  // came back from Step 5 and the transaction was _eventually_ successful.
  if (broadcastLiquidStakeData?.status === 'success' || queryCallback?.status === 'success') {
    const dexInfo = getDexInfoFromDenom(denom)

    // If [there are errors] or [apy has been loaded by is below the minimum], we'll hide the APR.
    const isAprDataInsufficient =
      Boolean(withdrawStTokenToDexApyError) ||
      (withdrawStTokenToDexApy && withdrawStTokenToDexApy.apy <= DEX_POOL_MINIMUM_APY)

    return (
      <StepperModalContent
        title="Success!"
        description={
          <>
            <Box>
              You liquid staked {formattedAmount} on Stride and {formattedStakedAmount} has been added to your wallet.
            </Box>

            {CHAIN_SUPPORTS_ADD_TO_DEX[denom] && dexInfo && dexAccount ? (
              <>
                <Space h="xs" />

                {isAprDataInsufficient ? (
                  <Box>Do you want to add it to the liquidity pool on {dexInfo.name} to earn an extra APR?</Box>
                ) : (
                  <Box>
                    Do you want to add it to the liquidity pool on {dexInfo.name} to earn an{' '}
                    <strong>
                      extra{' '}
                      {withdrawStTokenToDexApy == null ? (
                        <InlineLoader />
                      ) : (
                        formatDenom(withdrawStTokenToDexApy.apy * 100, 2)
                      )}
                      % APR
                    </strong>
                    ?
                  </Box>
                )}
              </>
            ) : null}
          </>
        }
        actions={
          CHAIN_SUPPORTS_ADD_TO_DEX[denom] && dexInfo && dexAccount ? (
            <>
              <Button onClick={handleWithdrawStTokenToDex}>Add to DEX</Button>

              <Link href="/earn" passHref legacyBehavior>
                <Button component="a" variant="outline" color="dark">
                  See other options
                </Button>
              </Link>
            </>
          ) : (
            <Button onClick={close} variant="outline" color="dark">
              Exit
            </Button>
          )
        }
      />
    )
  }

  if (isBroadcastingLiquidStake) {
    return (
      <StepperModalContent
        title={`Staking your ${denom}...`}
        description="Just a few seconds, unless the network is congested"
      />
    )
  }

  if (signLiquidStakeError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            {' '}
            This transfer could not be completed. Your tokens are on Stride, but have not been staked. Please try again.
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleStep}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title={stepperModalContentTitle}
      description={`This will initiate staking and the transfer of ${denom} to your wallet`}
    />
  )
}

export { StakeLsmModalStepThree }
