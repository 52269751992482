import { OSMO_CHAIN_INFO } from '@/config'
import { queryKeys } from '@/query-keys'
import { WeightedPoolRaw, StablePoolRaw } from '@osmosis-labs/pools'
import axios from 'axios'
import { stringify } from 'qs'
import { useQuery } from '@tanstack/react-query'

export type PoolRaw = WeightedPoolRaw | StablePoolRaw

/**
 * Osmosis Pool query
 */
const osmosisInstance = axios.create({
  baseURL: OSMO_CHAIN_INFO.rest,
  paramsSerializer(params) {
    return stringify(params, { arrayFormat: 'comma', allowDots: true })
  }
})

type OsmosisPaginationParams =
  | {
      key?: string
      offset?: number
      limit?: number
      count_total?: boolean
    }
  | undefined

interface PaginationResponse {
  next_key: string | null
  total: string
}

interface PoolsResponse {
  pools: PoolRaw[]
  pagination: PaginationResponse
}

const getPools = async ({ pagination }: { pagination?: OsmosisPaginationParams }): Promise<PoolsResponse> => {
  return (
    await osmosisInstance.get<PoolsResponse>('/osmosis/gamm/v1beta1/pools', {
      params: { pagination }
    })
  ).data
}

export const useOsmosisPoolsQuery = () => {
  const handleRequest = () => getPools({ pagination: { limit: 2000 } })

  return useQuery({
    queryKey: queryKeys.osmosisPoolsQuery,
    queryFn: handleRequest,
    refetchInterval: 5_000
  })
}
