import React, { useMemo } from 'react'
import { Space } from '@mantine/core'
import { TransactionCard, TransactionCardStatus } from '@/components'
import { CHAIN_INFO_LIST } from '@/config'
import { IBCMetaData, formatMicroDenom } from '@/wallet-utils'
import { useIbcStatusQuery } from './queries'
import { ViewMintscanButton } from './shared'

interface IBCMetaDataCardProps {
  meta: IBCMetaData
  onDismiss: (id: string) => void
}

// We don't need to support the other states
type SimpleTransactionCardStatus = Exclude<TransactionCardStatus, 'checking-expanded' | 'warning'>

const IBCMetaDataCard: React.FC<IBCMetaDataCardProps> = ({ meta, onDismiss }) => {
  const { data, error } = useIbcStatusQuery({ transaction: meta })

  const cardStatus: SimpleTransactionCardStatus = useMemo(() => {
    if (error) return 'checking-failed'
    if (data == null) return 'checking'
    if (data.status === 'return-later') return 'pending'
    if (data.status === 'timeout') return 'error'
    return data.status
  }, [data, error])

  const chainInfo = CHAIN_INFO_LIST[meta.values.denom]

  const formattedAmount = `${formatMicroDenom(meta.values.amount, meta.values.minimalDenom, 3)} ${meta.values.denom}`

  const titles: Record<SimpleTransactionCardStatus, () => string> = {
    checking: () => `Transfer of ${formattedAmount} ${meta.type === 'withdraw' ? 'from' : 'to'} Stride`,
    'checking-failed': () => `Transfer of ${formattedAmount} ${meta.type === 'withdraw' ? 'from' : 'to'} Stride`,
    pending: () => `Transferring ${formattedAmount} ${meta.type === 'withdraw' ? 'from' : 'to'} Stride`,
    error: () => `Error transferring ${formattedAmount}`,
    complete: () =>
      meta.type === 'withdraw' ? `${formattedAmount} transferred from Stride` : `Ready to stake ${formattedAmount}`
  }

  const description: Record<SimpleTransactionCardStatus, () => string> = {
    checking: () => 'Checking the status of your IBC transfer.',
    'checking-failed': () => 'We were unable to check the status of this IBC transaction. Please refresh the page.',
    pending: () =>
      'This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the transfer will continue.',
    complete: () =>
      meta.type === 'withdraw'
        ? `${formattedAmount} has been successfully transferred from Stride to your wallet on ${chainInfo.chainName}. The tokens are visible at your ${chainInfo.chainName} address.`
        : `${formattedAmount} has been successfully transferred to Stride and is now ready to be staked.`,
    error: () =>
      meta.type === 'withdraw'
        ? `This transfer could not be completed. Your tokens have not been moved to ${chainInfo.chainName}. You can try again if you haven’t already.`
        : 'This transfer could not be completed. Your tokens have not been moved to Stride. You can try again if you haven’t already.'
  }

  const mintscanDenom = meta.type === 'withdraw' ? 'STRD' : meta.values.denom

  return (
    <TransactionCard
      status={cardStatus}
      title={titles[cardStatus]()}
      onDismiss={() => onDismiss(meta.values.hash)}
      description={description[cardStatus]()}>
      {(cardStatus === 'complete' || cardStatus === 'error') && (
        <>
          <Space h="md" />

          <ViewMintscanButton denom={mintscanDenom} hash={meta.values.hash} />
        </>
      )}
    </TransactionCard>
  )
}

export { IBCMetaDataCard }
