import React, { ReactNode, createContext, useContext, useState } from 'react'

import { assert } from '@/utils'

interface StakeFeeTokensModalContextType {
  isFeeTokensModalOpen: boolean
  setIsFeeTokensModalOpen: (value: boolean) => void
}

const Context = createContext<StakeFeeTokensModalContextType | null>(null)

const StakeFeeTokensModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isFeeTokensModalOpen, setIsFeeTokensModalOpen] = useState(false)

  return <Context.Provider value={{ isFeeTokensModalOpen, setIsFeeTokensModalOpen }}>{children}</Context.Provider>
}

const useStakeFeeTokensModal = () => {
  const context = useContext(Context)
  assert(context, 'You forgot to mount StakeFeeTokensModalProvider')
  return context
}

export { StakeFeeTokensModalProvider, useStakeFeeTokensModal }
