import BigNumber from 'bignumber.js'
import { useFormContext } from '@/components'
import { Box, Divider, Group, Space, Text, TextInput, UnstyledButton } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import { useState } from 'react'

import { UnstakeIbcxButton } from './UnstakeIbcxButton'
import { UnstakeFormType } from './useUnstakeIbcxForm'

import { IBCX_CURRENCY, STAKED_IBCX_CURRENCY } from '@/config/ibcx'
import { useStakeIbcx } from '../StakeIbcxProvider'
import { StakeIbcxEstimate } from '../StakeIbcxEstimate'
import { useChainWallet } from '@/contexts/wallet'

const UnstakeIbcxForm: React.FC = () => {
  const { data: osmosisAccount } = useChainWallet('OSMO')

  const denom = IBCX_CURRENCY.coinDenom

  const stIbcxBalance = Number(
    osmosisAccount?.coins.find((coin) => coin.denom === STAKED_IBCX_CURRENCY.coinMinimalDenom)?.amount ?? 0
  )

  const [isFocused, setIsFocused] = useState(false)

  const { setUnstakeModal } = useStakeIbcx()

  const { values, setFieldValue, getInputProps, onSubmit, validate } = useFormContext<UnstakeFormType>()

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleClickMax = () => {
    const max = Math.max(stIbcxBalance, 0)
    setFieldValue('amount', String(max))
  }

  const handleSubmit = () => {
    setUnstakeModal({
      isOpen: true,
      amount: Number(values.amount)
    })
  }

  useDidUpdate(() => {
    if (!isFocused) return
    // We only want to validate if the user changed something,
    // but not if reset by a successful unstake
    validate()
  }, [values, isFocused])

  const stakedDenom = `st${denom}`

  return (
    <>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Text align="center" sx={(t) => ({ color: t.colors.gray[9] })}>
          Unstake your {stakedDenom} tokens in exchange for {denom}.
        </Text>

        <Space h="lg" />

        <Box>
          <Text inline sx={(t) => ({ color: t.colors.gray[9] })}>
            Amount to unstake:
          </Text>

          <Space h="xs" />

          <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
            {stakedDenom}
          </Text>
        </Box>

        <Space h="sm" />

        <TextInput
          required
          placeholder="0"
          size="xl"
          radius="md"
          sx={(t) => ({
            input: {
              fontWeight: 'bold',
              textAlign: 'right',
              color: t.colors.gray[9],
              '&::placeholder': { color: t.colors.gray[9] },
              '&:focus:not(.mantine-TextInput-invalid)': {
                border: `1px solid ${t.colors.gray[6]}`
              }
            }
          })}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...getInputProps('amount')}
        />

        {Boolean(osmosisAccount) && (
          <>
            <Space h="xs" />

            <Group position="apart" align="center">
              <Text sx={(t) => ({ color: t.colors.gray[7] })}>
                Available in wallet: {new BigNumber(stIbcxBalance).decimalPlaces(5).toString()} {stakedDenom}
              </Text>

              <UnstyledButton onClick={handleClickMax}>
                <Text sx={(t) => ({ color: t.colors.pink[7] })}>Add max</Text>
              </UnstyledButton>
            </Group>
          </>
        )}

        <Space h="lg" />

        <Divider />

        <Space h="lg" />

        <Group position="apart" align="center">
          <Box>
            <Text inline sx={(t) => ({ color: t.colors.gray[9] })}>
              What you'll get:
            </Text>

            <Space h="xs" />

            <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
              {denom}
            </Text>
          </Box>

          <Text
            weight={700}
            size="xl"
            sx={(t) => ({ paddingRight: t.other.stakeForm.amountPaddingRight, color: t.colors.gray[9] })}
            inline>
            <StakeIbcxEstimate amount={Number(values.amount)} mode="unstake" />
          </Text>
        </Group>

        <Space h="lg" />

        <UnstakeIbcxButton />
      </form>
    </>
  )
}

export { UnstakeIbcxForm }
