import React, { useContext, useState } from 'react'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { fatal } from '@/utils'
import { IBCTransferStatus, IbcReturnType } from '@/wallet-utils'
import {
  useSignStakeAutopilotMutation,
  useBroadcastStakeAutopilotMutation,
  useTraceStakeAutopilotMutation,
  MutationParameters,
  BroadcastSendTokenMutationReturnType
} from './mutations'

interface ContextType {
  resetStakeAutopilotModalState: () => void

  // @TODO: Consider removing this and refactoring the other components
  // to access this value from `broadcastLiquidStakeData` instead.
  // For reference, we have a version of this for `StakeLsm`.
  stakeAutopilotTransaction: DeliverTxResponse | null

  signStakeAutopilot: () => Promise<IbcReturnType>
  isSigningStakeAutopilot: boolean
  signStakeAutopilotError: unknown

  broadcastStakeAutopilot: () => Promise<BroadcastSendTokenMutationReturnType>
  isBroadcastingStakeAutopilot: boolean
  broadcastStakeAutopilotError: unknown

  stakeAutopilotStatus: { status: IBCTransferStatus } | undefined
  traceStakeAutopilotStatus: () => Promise<{ status: IBCTransferStatus }>
  isStakeAutopilotStatusLoading: boolean
  stakeAutopilotStatusError: unknown
}

const Context = React.createContext<ContextType | null>(null)

const StakeAutopilotModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [stakeAutopilotRaw, setStakeAutopilotRaw] = useState<IbcReturnType | null>(null)

  const [stakeAutopilotTransaction, setStakeAutopilotTransaction] = useState<DeliverTxResponse | null>(null)

  const parameters: MutationParameters = {
    stakeAutopilotRaw,
    setStakeAutopilotRaw,
    stakeAutopilotTransaction,
    setStakeAutopilotTransaction
  }

  const {
    mutateAsync: signStakeAutopilot,
    isPending: isSigningStakeAutopilot,
    error: signStakeAutopilotError,
    reset: resetSignStakeAutopilot
  } = useSignStakeAutopilotMutation(parameters)

  const {
    mutateAsync: broadcastStakeAutopilot,
    isPending: isBroadcastingStakeAutopilot,
    error: broadcastStakeAutopilotError,
    reset: resetBroadcastStakeAutopilot
  } = useBroadcastStakeAutopilotMutation(parameters)

  const {
    data: stakeAutopilotStatus,
    mutateAsync: traceStakeAutopilotStatus,
    isPending: isStakeAutopilotStatusLoading,
    error: stakeAutopilotStatusError,
    reset: resetStakeAutopilotStatus
  } = useTraceStakeAutopilotMutation(parameters)

  const resetStakeAutopilotModalState = () => {
    setStakeAutopilotRaw(null)
    setStakeAutopilotTransaction(null)
    resetSignStakeAutopilot()
    resetBroadcastStakeAutopilot()
    resetStakeAutopilotStatus()
  }

  return (
    <Context.Provider
      value={{
        resetStakeAutopilotModalState,

        stakeAutopilotTransaction,

        signStakeAutopilot,
        isSigningStakeAutopilot,
        signStakeAutopilotError,

        broadcastStakeAutopilot,
        isBroadcastingStakeAutopilot,
        broadcastStakeAutopilotError,

        stakeAutopilotStatus,
        traceStakeAutopilotStatus,
        isStakeAutopilotStatusLoading,
        stakeAutopilotStatusError
      }}>
      {children}
    </Context.Provider>
  )
}

const useStakeAutopilotModal = () => {
  const context = useContext(Context)

  if (context == null) {
    throw fatal('You forgot to mount StakeAutopilotModalProvider.')
  }

  return context
}

export { StakeAutopilotModalProvider, useStakeAutopilotModal }
