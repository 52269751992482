import axios from 'axios'
import { useAtomValue } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { assert } from '@/utils'
import { STRIDE_CHAIN_INFO } from '@/config'
import { queryKeys } from '@/query-keys'
import { getSendPacketAttributesFromRawLogsOrEvents, TxResponse } from '@/wallet-utils'
import { withdrawStTokenToDexTransactionAtom, stakeModeAtom } from '../../atoms'
import { useSelectedWallet } from '@/contexts/wallet'
import { normalizeEventAttribute } from '../../utils'
import { DeliverTxResponse } from '@cosmjs/stargate'

interface TransactionListResponse {
  tx_responses: TxResponse[]
}

// Our first approach was to calculate the staked token amount
// client-side. Problem is there's been a lot of errors
// transferring a value precisionally higher user's real stToken amount.
// To fix this, we are going a bit out of way to actually get
// the amount of st token gained from the liquid stake flow.
// In addition, we'll round down to the 5th decimal place in
// the signing mutation so we don't hit a precision error (if that's even
// possible).
const useLiquidStakeTransactionAmountQuery = () => {
  const transaction = useAtomValue(withdrawStTokenToDexTransactionAtom)

  const selectedAccount = useSelectedWallet()

  const stakeMode = useAtomValue(stakeModeAtom)

  // Get the attributes of transactions.events[n] where event.type === 'coin_received' (this can be multiple)
  // Then check find the attribute where attribute.value ends wih stATOM
  const getStakeClassicAmount = async (): Promise<string> => {
    assert(selectedAccount, 'Unable to query stake transaction while disconnected.')

    assert(transaction, 'Missing autopilot liquid stake transaction.')

    // 1. { events: [{ attributes: [] }, { attributes: [] }, { attributes: [] }, { attributes: [] }] }
    // 2. { events: [{ type: 'coin_received, attributes: [] }, { type: 'coin_received, attributes: [] }] }
    const attributes = transaction.events
      .filter((event) => event.type === 'coin_received')
      .flatMap((event) => event.attributes)
      .map(normalizeEventAttribute)

    const stakedMinimalDenom = `st${selectedAccount.currency.coinMinimalDenom}`

    const attribute = attributes.find((attribute) => {
      return attribute.key === 'amount' && attribute.value.endsWith(stakedMinimalDenom)
    })

    assert(attribute, 'Missing amount attribute from `coin_received` event for classic liquid staking.')

    return attribute.value.replace(stakedMinimalDenom, '')
  }

  // Unlike classic liquid staking (which is technically not an ibc transfer), we cannot get the
  // actual stToken amount from the transaction itself. Instead, we have to query the recv_packet
  // transaction.
  //
  // Get transaction where recv_packet.packet_sequence === packet_sequence from ibc transaction
  // Afterwards, parse logs[n].events[n].attributes[n] where attribute.value ends wih stuatom
  //
  // There is a problem with the Injective only specifically. This fetch function fails because
  // the event and attrtibute we are looking for does not exist for the Autopilot transaction
  // from Injective. To workaround this for now (since we've spent more than time here), Injective
  // specifically uses classic for now (via CHAIN_SUPPORTS_AUTOPILOT_LS).
  const getStakeAutopilotAmount = async (): Promise<string> => {
    assert(selectedAccount, 'Unable to query stake transaction while disconnected.')

    assert(transaction, 'Missing autopilot liquid stake transaction.')

    // We expect transaction to be a DeliverTxResponse in this case.
    // `getTxRawLogSendPacketAttributes` and all its sub functions rely on this.
    // Plus, we're guaranteed to get a DeliverTxResponse type for Autopilot.
    assert(isDeliverTxResponse(transaction), 'Missing autopilot liquid stake transaction.')

    const packets = getSendPacketAttributesFromRawLogsOrEvents(transaction, selectedAccount.currency.coinDenom)

    const instance = axios.create({
      baseURL: STRIDE_CHAIN_INFO.rest
    })

    const query = new URLSearchParams()
    query.append('events', `recv_packet.packet_sequence='${packets.packet_sequence}'`)
    query.append('events', `recv_packet.packet_dst_channel='${packets.packet_dst_channel}'`)
    query.append('pagination.limit', '1')
    const response = await instance.get<TransactionListResponse>(`cosmos/tx/v1beta1/txs?${query}`)

    // [{ events: [a, b] }, { events: [c, d, e] }] -> [a, b, c, d, e] -> c.attributes
    const attributes = response.data.tx_responses[0].logs
      .flatMap((log) => log.events)
      .filter((event) => event.type === 'coin_received')
      .flatMap((event) => event.attributes)
      .map(normalizeEventAttribute)

    const stakedMinimalDenom = `st${selectedAccount.currency.coinMinimalDenom}`

    const attribute = attributes.find((attribute) => {
      return attribute.key === 'amount' && attribute.value.endsWith(stakedMinimalDenom)
    })

    assert(attribute, 'Missing amount attribute from `coin_received` event for autopilot liquid staking.')

    return attribute.value.replace(stakedMinimalDenom, '')
  }

  const handleRequest = async () => {
    assert(stakeMode, 'Unable to query stake transaction while stake mode is not defined.')

    // @IMPORTANT: This is a micro-denom unlike the stuff filled by useConnectWallet
    return { amount: await (stakeMode === 'classic' ? getStakeClassicAmount() : getStakeAutopilotAmount()) }
  }

  const hash = transaction ? (isDeliverTxResponse(transaction) ? transaction.transactionHash : transaction.txhash) : ''

  return useQuery({
    queryKey: queryKeys.withdrawStTokenToDexStakeTransactionByHash({ hash }),
    queryFn: handleRequest,
    enabled: Boolean(hash)
  })
}

// DeliverTxResponse has transactionHash; TxResponse has txhash
const isDeliverTxResponse = (value: any): value is DeliverTxResponse => {
  return 'transactionHash' in value
}

export { useLiquidStakeTransactionAmountQuery }
