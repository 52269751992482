import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { Tooltip } from '@mantine/core'
import { Icon, TextLoader } from '@/components'

import { useIbcxTokensMarketPriceQuery } from '../queries'
import { useSelectedCoin } from '@/contexts/wallet'

const StakeIbcxStatProtocolRedemptionRate: React.FC = () => {
  const denom = useSelectedCoin()

  const {
    data: marketPrice,
    isLoading: isMarketPriceLoading,
    error: marketPriceError
  } = useIbcxTokensMarketPriceQuery()

  const stakedTokenValue = useMemo(() => {
    if (marketPrice == null) return '0'
    return new BigNumber(marketPrice.strideStTokenValue).decimalPlaces(3).toString()
  }, [marketPrice])

  if (marketPriceError) {
    return (
      <Tooltip label="Unable to load redemption rate at this time." withArrow>
        <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
      </Tooltip>
    )
  }

  return (
    <TextLoader loading={isMarketPriceLoading} sx={(t) => ({ color: t.colors.gray[7] })}>
      1 st{denom} = {stakedTokenValue} {denom}
    </TextLoader>
  )
}

export { StakeIbcxStatProtocolRedemptionRate }
