import axios from 'axios'
import { useAtomValue } from 'jotai'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CHAIN_INFO_LIST } from '@/config'
import { useLatestValue } from '@/hooks'
import { disregard, fatal, poll } from '@/utils'
import { queryKeys } from '@/query-keys'
import { notify } from '@/contexts/notifications'
import { assertIsDeliverTxSuccess, convertDenomToMicroDenom, signAndBroadcast, TX_MSG } from '@/wallet-utils'
import { claimMetaDataAtom, isUnstakingModalOpenAtom } from '../../atoms'
import { useRefreshBalances, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

export interface UseClaimTokensMutationParameters {
  epoch: string
}

// @TODO: Let's make the sign and broadcast call separate
// so that we're able to display the correct text if
// the user already approved the transaction
const useClaimTokensMutation = () => {
  const strideAccount = useStrideWallet()
  const selectedAccount = useSelectedWallet()
  const updateAccountBalances = useRefreshBalances()

  const client = useQueryClient()

  const claimMetaData = useAtomValue(claimMetaDataAtom)

  const isUnstakingModalOpen = useAtomValue(isUnstakingModalOpenAtom)

  // At least on react-query v3, once a mutation is called, it will take a snapshot
  // of the event handlers like onSuccess, which makes state inside onSuccess stale.
  const isUnstakingModelOpenRef = useLatestValue(isUnstakingModalOpen)

  // This makes sure when the user exits the flow, it will stop existing in the transaction history.
  const verifyRedemptionRecordIsClaimed = async () => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('Unable to verify removal record redemption while disconnected.')
    }

    if (!claimMetaData) {
      throw fatal('Unable to verify removal record redemption without an epoch number.')
    }

    const selectedChainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

    const strideChainInfo = CHAIN_INFO_LIST[strideAccount.currency.coinDenom]

    const instance = axios.create({ baseURL: strideChainInfo.rest })

    const redemptionRecordId = [
      selectedChainInfo.chainId,
      claimMetaData.values.epochNumber,
      strideAccount.address
    ].join('.')

    const pollFn = () => {
      return instance.get(`/Stride-Labs/stride/records/user_redemption_record/${redemptionRecordId}`, {
        validateStatus(status) {
          // When the redemption record doesn't exist, the endpoint doesn't implement a normal 404 error code. It acts
          // as if the "method" we're requesting doesn't exist. In this case, we want to allow this request to pass to
          // reset the poll.
          if (status === 501) return true
          // Default status validation. If it fails due to server or network errors,
          // then we just want to get it over with and let the poll exit
          return status >= 200 && status < 300
        }
      })
    }

    try {
      // We're giving the chain a 5 minute window to properly claim the record. If this fails, it's fine.
      await poll(pollFn, (res) => res.status === 404, { ms: 3000, max: 100 })
    } catch (e) {
      notify.error(
        'Oops, something did not work correctly. If you do not receive your claimed tokens after refreshing the page, please contact support.'
      )

      disregard(e)
    }
  }

  const handleMutation = async (): Promise<DeliverTxResponse> => {
    if (!strideAccount || !selectedAccount || !strideAccount.client) {
      throw fatal('Trying to claim while disconnected')
    }

    if (!claimMetaData) {
      throw fatal('Unable to verify removal record redemption without an epoch number.')
    }

    const chainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

    const msgClaimUndelegatedTokens = {
      typeUrl: TX_MSG.MsgClaimUndelegatedTokens,
      value: {
        creator: strideAccount.address,
        hostZoneId: chainInfo.chainId,
        epoch: claimMetaData.values.epochNumber,
        sender: strideAccount.address
      }
    }

    const fee = {
      amount: [{ amount: '0.25', denom: strideAccount.currency.coinMinimalDenom }],
      gas: String(convertDenomToMicroDenom(1))
    }

    const tx = await signAndBroadcast(strideAccount.client, strideAccount.address, [msgClaimUndelegatedTokens], fee, '')

    assertIsDeliverTxSuccess(tx)

    return tx
  }

  const handleError = () => {
    // When this mutation succeeds while they're still on the modal, we don't want to show the success message.
    // We're doing this for claiming because there's no exact way to know that the claim actually succeeded.
    // In other transactions, the addition of the transaction to the transaction history is good feedback.
    // But this doesn't happen for claiming.
    if (isUnstakingModelOpenRef.current) {
      return
    }

    // We want to show the error message when the user minimizes a failing claim transaction.
    notify.error('Claim resulted to an error. Please try again.')
  }

  const handleSuccess = async () => {
    await verifyRedemptionRecordIsClaimed()

    // Confirm redemption record stops existing before refetching unbondings
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })

    try {
      await updateAccountBalances()
    } catch (e) {
      notify.error('Attempt to refresh your balance failed. Please refresh the page.')
      disregard(e)
    }

    // When this mutation succeeds while they're still on the modal, we don't want to show the success message.
    // We're doing this for claiming because there's no exact way to know that the claim actually succeeded.
    // In other transactions, the addition of the transaction to the transaction history is good feedback.
    // But this doesn't happen for claiming.
    if (isUnstakingModelOpenRef.current) {
      return
    }

    // We want to show the error message only when the user minimizes a succeeding claim transaction.
    notify.success('Claim completed successfully.')
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess,
    onError: handleError
  })
}

export { useClaimTokensMutation }
