import { useSelectedCoin } from '@/contexts/wallet'
import { useHostZoneQuery as useOriginalHostZoneQuery } from '@/queries'

// Originally, useHostZoneQuery was only used within the staking page. With the introduction
// of the stTIA Airdrop (and the decision to do "manual" calculations; refer to useTvlQuery)
// we had to use `useHostZoneQuery` on another page which does not use `useSelectedCoin`
const useHostZoneQuery = () => {
  const denom = useSelectedCoin()
  return useOriginalHostZoneQuery(denom)
}

export { useHostZoneQuery }
