import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { fatal } from '@/utils'
import { queryKeys } from '@/query-keys'
import { assertIsDeliverTxSuccess } from '@/wallet-utils'
import { MutationParameters } from './types'
import { useChainWallet } from '@/contexts/wallet'

const useBroadcastRedeemStIbcxMutation = ({ redeemStakeRaw }: MutationParameters) => {
  const { data: osmosisAccount } = useChainWallet('OSMO')

  const client = useQueryClient()

  const handleMutation = async (): Promise<DeliverTxResponse> => {
    if (!osmosisAccount || !osmosisAccount.cosmwasm) {
      throw fatal('Unable to redeem staked tokens without connecting your wallet.')
    }

    if (!redeemStakeRaw) {
      throw fatal('Unable to broadcast `RedeemStake` transaction without a signed document. Please try again.')
    }

    const bytes = TxRaw.encode(redeemStakeRaw).finish()

    const tx = await osmosisAccount.cosmwasm.broadcastTx(bytes)

    assertIsDeliverTxSuccess(tx)

    return tx
  }

  const handleSettled = async () => {
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })
  }

  const handleSuccess = async () => {
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryUnbondingsBase })

    // We'll update this for precautionary measures
    await client.invalidateQueries({ queryKey: queryKeys.unstakeFormEpochTrackerBase })

    // We'll invalidate redemption records as epoch tracker may not necessarily change
    await client.invalidateQueries({ queryKey: queryKeys.unstakeFormRedemptionRecordBase })

    if (!osmosisAccount) {
      // We're unlikely to be here, but provided a bug with with react-query
      // giving us stale values on mutation handlers, we'll just return for safety.
      // https://github.com/Stride-Labs/interface/issues/262
      return
    }

    // @TODO: We've ceased supporting and exposing IBCX for more than a year now, and
    // this seems a bit cumbersome to update. There's probably no more hope for this project,
    // and it's best to just comment out problems as we go.
    // try {
    //   await client.refetchQueries('chainAccount')
    // } catch (e) {
    //   notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
    //   disregard(e)
    // }
  }

  return useMutation({
    mutationFn: handleMutation,
    onSettled: handleSettled,
    onSuccess: handleSuccess
  })
}

export { useBroadcastRedeemStIbcxMutation }
