import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { useAtomValue } from 'jotai'
import { useMutation } from '@tanstack/react-query'
import { CHAIN_INFO_LIST } from '@/config'
import { fatal } from '@/utils'
import { convertDenomToMicroDenom, isSafeModeAccount, calculateFee } from '@/wallet-utils'
import { unstakeAmountAtom } from '../../atoms'
import { MutationParameters } from './types'
import { useSelectedWallet, useStrideWallet } from '@/contexts/wallet'
import { stride } from 'stridejs'

const useSignRedeemStakeMutation = ({ setRedeemStakeRaw }: MutationParameters) => {
  const selectedAccount = useSelectedWallet()

  const strideAccount = useStrideWallet()

  const amount = useAtomValue(unstakeAmountAtom)

  const handleMutation = async (): Promise<TxRaw> => {
    if (!strideAccount || !selectedAccount || !strideAccount.client) {
      throw fatal('Unable to redeem staked tokens without connecting your wallet.')
    }

    if (isSafeModeAccount(strideAccount) || isSafeModeAccount(selectedAccount)) {
      throw fatal('Safe mode is enabled.')
    }

    const amountInMicroDenom = convertDenomToMicroDenom(amount, selectedAccount.currency.coinMinimalDenom)

    const getMsgRedeemStake = () => {
      if (selectedAccount.currency.coinDenom === 'TIA') {
        return stride.staketia.MessageComposer.withTypeUrl.redeemStake({
          redeemer: strideAccount.address,
          stTokenAmount: amountInMicroDenom.toString()
        })
      }

      if (selectedAccount.currency.coinDenom === 'DYM') {
        return stride.stakedym.MessageComposer.withTypeUrl.redeemStake({
          redeemer: strideAccount.address,
          stTokenAmount: amountInMicroDenom.toString()
        })
      }

      return stride.stakeibc.MessageComposer.withTypeUrl.redeemStake({
        creator: strideAccount.address,
        amount: amountInMicroDenom.toString(),
        hostZone: CHAIN_INFO_LIST[selectedAccount.currency.coinDenom].chainId,
        receiver: selectedAccount.address
      })
    }

    const msgRedeemStake = getMsgRedeemStake()

    const gas = await strideAccount.client.simulate(strideAccount.address, [msgRedeemStake], '')
    const fee = calculateFee(selectedAccount, gas)

    return await strideAccount.client.sign(strideAccount.address, [msgRedeemStake], fee, '')
  }

  const handleSuccess = (raw: TxRaw) => {
    setRedeemStakeRaw(raw)
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

export { useSignRedeemStakeMutation }
