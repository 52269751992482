import React from 'react'
import { Space } from '@mantine/core'
import { TransactionCard } from '@/components'
import { LiquidStakeStatus, LiquidStakeMetaData, convertMicroDenomToDenom, formatDenom } from '@/wallet-utils'
import { useHostZoneQuery } from '../queries'
import { ViewMintscanButton } from './shared'

interface LiquidStakeMetaDataCardProps {
  meta: LiquidStakeMetaData
  onDismiss: (id: string) => void
}

const LiquidStakeMetaDataCard: React.FC<LiquidStakeMetaDataCardProps> = ({ meta, onDismiss }) => {
  const { data: hostZone } = useHostZoneQuery()

  // @TODO: Consider adding an ErrorBeacon to let the user know that market prices aren't loading.
  const redemptionRate = Number(hostZone?.redemption_rate ?? 1)

  const amountInDenom = convertMicroDenomToDenom(meta.values.amount, meta.values.minimalDenom)

  const formattedAmount = `${formatDenom(amountInDenom, 3)} ${meta.values.denom}`

  const stakedAmount = Number(amountInDenom) / redemptionRate

  const formattedStakedAmount = `${formatDenom(stakedAmount, 5)} st${meta.values.denom}`

  const titles: Record<LiquidStakeStatus, string> = {
    pending: `Staking ${formattedAmount}...`,
    error: `Error · Staking ${formattedAmount}`,
    complete: `${formattedAmount} staked!`
  }

  const description: Record<LiquidStakeStatus, string> = {
    pending:
      'This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the transfer will continue. Once the transfer is complete, you will need to return to initiate the staking process.',
    error:
      'This transfer could not be completed. Your tokens have not been moved to Stride. You can try again if you haven’t already.',
    complete: `You staked ${formattedAmount} on Stride and ${formattedStakedAmount} has been added to your wallet.`
  }

  return (
    <TransactionCard
      status={meta.values.status}
      title={titles[meta.values.status]}
      onDismiss={() => onDismiss(meta.values.hash)}
      description={description[meta.values.status]}>
      <>
        <Space h="md" />

        <ViewMintscanButton denom="STRD" hash={meta.values.hash} />
      </>
    </TransactionCard>
  )
}

export { LiquidStakeMetaDataCard }
