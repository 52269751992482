import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { assertIsDeliverTxSuccess, broadcastTx } from '@/wallet-utils'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { queryKeys } from '@/query-keys'
import { fatal, disregard } from '@/utils'
import { notify } from '@/contexts/notifications'
import { MutationParameters } from './types'
import { useRefreshBalances, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

export interface BroadcastRedeemTokenForsharesMutationReturnType {
  transaction: DeliverTxResponse
}

const useBroadcastRedeemTokenForSharesMutation = ({ redeemTokensRaw }: MutationParameters) => {
  const client = useQueryClient()

  const strideAcccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const refreshBalances = useRefreshBalances()

  const handleMutation = async (): Promise<BroadcastRedeemTokenForsharesMutationReturnType> => {
    if (!selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!redeemTokensRaw) {
      throw fatal('Unable to broadcast empty signed transaction.')
    }

    if (!selectedAccount.client) {
      throw fatal('Stargate client is not available.')
    }

    const bytes = TxRaw.encode(redeemTokensRaw).finish()

    const transaction = await broadcastTx(selectedAccount.client, bytes)

    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })

    assertIsDeliverTxSuccess(transaction)

    return { transaction }
  }

  const handleSuccess = async () => {
    if (!selectedAccount || !strideAcccount) {
      // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
      // But we'll return anyway instead of crashing just to be safe (given the unknowns).
      // https://github.com/Stride-Labs/interface/issues/262
      return
    }

    try {
      // Sometimes we have outdated information and the invalidation in `useBroadcastWithdrawTokenizedSharesMutation`
      // doesn't work. So we'll refresh the balances for Stride here as well (despite being unreasonable)
      await refreshBalances()
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }

    // Sometimes we have outdated information and the invalidation in `useBroadcastWithdrawTokenizedSharesMutation`
    // doesn't work. So we'll invalidate the query here as well.
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryLsmIbcBalancesBase })

    // Make sure that the validators list is updated after the account balances have been updated.
    await client.invalidateQueries({ queryKey: queryKeys.lsmValidatorsBase })
  }

  const handleError = async () => {
    // Sometimes we have outdated information and the invalidation in `useBroadcastWithdrawTokenizedSharesMutation`
    // doesn't work. So we'll invalidate the query here as well.
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryLsmIbcBalancesBase })

    // Make sure that the validators list is updated after the account balances have been updated.
    await client.invalidateQueries({ queryKey: queryKeys.lsmValidatorsBase })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess,
    onError: handleError
  })
}

export { useBroadcastRedeemTokenForSharesMutation }
