import { MsgLsmLiquidStake } from '@/wallet-utils'
import { MetaDataHandlerParameters } from './types'
import { CHAIN_INFO_LIST, CHAIN_SUPPORTS_LSM } from '@/config'

const handleLsmLiquidStake = ({
  response,
  message,
  strideAccount,
  selectedAccount
}: MetaDataHandlerParameters<MsgLsmLiquidStake>): boolean => {
  if (!CHAIN_SUPPORTS_LSM[selectedAccount.currency.coinDenom]) {
    return false
  }

  // @TODO: We had a recent issue with TIA/DYM Liquid Staking showing up on other chains.
  // LSM may be the same because we're not checking for the source denom. So, check when
  // we have the chance. I'm not sure if any other code takes care of it, but we defintely
  // should here if possible. IF not, let's leave a comment here.
  const liquidStakeEvent = response.events.find((event) => {
    return event.type === 'lsm_liquid_stake'
  })

  // Transactions can sometimes fail before the lsm_liquid_stake event is formed.
  if (liquidStakeEvent == null) {
    return false
  }

  const baseDenom = liquidStakeEvent.attributes.find((attribute) => {
    return attribute.key === 'lsm_token_base_denom'
  })

  if (baseDenom == null) {
    return false
  }

  const chainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

  return (
    // Double-check that the IBC denom does indeed exist
    (Boolean(message.lsm_token_ibc_denom) &&
    // Double-check that the LSM transaction is from the connected wallet
    message.creator === strideAccount.address && // Tokenized shares (base denom) look like cosmosvaloper1lkujuk2004p3w42tgvuvqnsdmsq8u6jqkwf9wj/37816
    // obviously based on the validator address which will help us check if it an LSM transaction from the
    // selected chain
    baseDenom.value.startsWith(chainInfo.bech32Config.bech32PrefixValAddr))
  );
}

export { handleLsmLiquidStake }
