import { TextLoader, useFormContext } from '@/components'
import { Anchor, Alert, Box, Divider, Group, Space, Text, TextInput, UnstyledButton, Badge } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import BigNumber from 'bignumber.js'
import { useMemo, useState } from 'react'
import {
  config,
  CHAIN_CONFIG,
  INJ_CHAIN_INFO,
  STRIDE_CHAIN_INFO,
  CHAIN_INFO_LIST,
  ISLM_CHAIN_INFO,
  CHAIN_SUPPORTS_REFERRAL
} from '@/config'
import { StakeEstimate } from '../StakeEstimate'
import { StakeFormType } from './useStakeForm'
import { useSelectedBalancesV2, useSelectedCoin, useStrideBalancesV2, useStrideWallet } from '@/contexts/wallet'
import { SelectedStakedCoinDenom, convertMicroDenomToDenom, formatMicroDenom } from '@/wallet-utils'
import { useReferral } from '@/page-components/Referral'
import { env } from '@/utils'

interface Props {
  amountInputRef: React.RefObject<HTMLInputElement>
}

const StakeDefaultForm: React.FC<Props> = ({ amountInputRef }) => {
  const selectedCoinDenom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[selectedCoinDenom]

  const strideAccount = useStrideWallet()

  const { data: strideBalances, isLoading: isStrideBalancesLoading } = useStrideBalancesV2()

  const chainBalanceOnStride = BigInt(strideBalances?.strideSelectedAccountBalance ?? 0)

  const { data: selectedBalances, isLoading: isSelectedBalancesLoading } = useSelectedBalancesV2()

  const chainBalance = BigInt(selectedBalances?.selectedAccountBalance ?? 0)

  const stakedDenom: SelectedStakedCoinDenom = `st${selectedCoinDenom}`

  const [isFocused, setIsFocused] = useState(false)

  const { values, setFieldValue, getInputProps, validate } = useFormContext<StakeFormType>()

  const { referral } = useReferral()

  const totalBalance = chainBalance + chainBalanceOnStride

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const recommendedMaxAmount = useMemo(() => {
    const totalBalanceInDenom = convertMicroDenomToDenom(totalBalance, chainInfo.stakeCurrency.coinMinimalDenom)

    const max = new BigNumber(totalBalanceInDenom)
      .minus(CHAIN_CONFIG[selectedCoinDenom].minimumGas)
      .decimalPlaces(5, BigNumber.ROUND_DOWN)
      .toNumber()

    return Math.max(max, 0)
  }, [totalBalance, selectedCoinDenom])

  const handleClickMax = () => {
    setFieldValue('defaultAmount', recommendedMaxAmount.toString())
  }

  const handleClickHalf = () => {
    const totalBalanceInDenom = convertMicroDenomToDenom(totalBalance, chainInfo.stakeCurrency.coinMinimalDenom)

    const halfValue = new BigNumber(totalBalanceInDenom)
      .dividedBy(2)
      .minus(CHAIN_CONFIG[selectedCoinDenom].minimumGas)
      .decimalPlaces(5, BigNumber.ROUND_DOWN)
      .toNumber()

    const half = Math.max(halfValue, 0)

    setFieldValue('defaultAmount', half.toString())
  }

  useDidUpdate(() => {
    if (!isFocused) return
    // We only want to validate if the user changed something,
    // but not if reset by a successful stake
    // We're strictly not validating when focus changes as it
    // causes the form to validate when we autofocus on mount
    // or when stake modal closes and reset the form
    validate()
  }, [values.defaultAmount])

  const isTransferringAboveRecommendedBalance = useMemo(() => {
    const totalBalanceInDenom = convertMicroDenomToDenom(totalBalance, chainInfo.stakeCurrency.coinMinimalDenom)

    // If user is staking more than their total balance, we'll let the validation take care of it.
    if (Number(values.defaultAmount) > Number(totalBalanceInDenom)) {
      return false
    }

    return Number(values.defaultAmount) > recommendedMaxAmount
  }, [values, totalBalance, recommendedMaxAmount])

  const isUsingInjective = selectedCoinDenom === INJ_CHAIN_INFO.stakeCurrency.coinDenom

  const isUsingIslm = selectedCoinDenom === ISLM_CHAIN_INFO.stakeCurrency.coinDenom

  return (
    <>
      <Box>
        <Text inline sx={(t) => ({ color: t.colors.gray[9] })}>
          Amount to stake:
        </Text>

        <Space h="xs" />

        <Group spacing="xs">
          <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
            {selectedCoinDenom}
          </Text>

          {env('STRIDE_ENV') === 'development' && referral && CHAIN_SUPPORTS_REFERRAL[selectedCoinDenom] && (
            <Badge color="gray" variant="filled" size="xs">
              {referral}
            </Badge>
          )}
        </Group>
      </Box>

      <Space h="sm" />

      <TextInput
        ref={amountInputRef}
        required
        placeholder="0"
        size="xl"
        sx={(t) => ({
          input: {
            fontWeight: 'bold',
            textAlign: 'right',
            color: t.colors.gray[9],
            '&::placeholder': { color: t.colors.gray[9] },
            '&:focus:not(.mantine-TextInput-invalid)': {
              border: `1px solid ${t.colors.gray[6]}`
            }
          }
        })}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...getInputProps('defaultAmount')}
      />

      {/* This is intentional. All conditions use the same UI, but we only want one to appear at a time. Recommended balance warning takes precedence if it exists. */}

      {isTransferringAboveRecommendedBalance && (
        <>
          <Space h="xs" />

          <Alert color="yellow">
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>
              It looks like you're transferring close to your full balance. We recommend you leave at least 0.1{' '}
              {selectedCoinDenom} in your wallet to cover the gas fees associated with liquid staking. The transaction
              may fail if you run out of gas.
            </Text>
          </Alert>
        </>
      )}

      {!isTransferringAboveRecommendedBalance && isUsingInjective && (
        <>
          <Space h="xs" />

          <Alert color="yellow">
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>
              Ledger support coming soon, for now, please use the{' '}
              <Anchor href={config.links.injectiveBridge} target="_blank">
                Injective bridge
              </Anchor>{' '}
              to transfer your {INJ_CHAIN_INFO.stakeCurrency.coinDenom} to {STRIDE_CHAIN_INFO.chainName} using Ledger.
            </Text>
          </Alert>
        </>
      )}

      {!isTransferringAboveRecommendedBalance && isUsingIslm && (
        <>
          <Space h="xs" />

          <Alert color="yellow">
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>Ledger support is coming soon.</Text>
          </Alert>
        </>
      )}

      {Boolean(strideAccount) && (
        <>
          <Space h="xs" />

          <Group position="apart" align="center">
            <Group spacing={0}>
              <Text sx={(t) => ({ color: t.colors.gray[7] })}>Available in wallet:</Text>
              &nbsp;
              <TextLoader
                loading={isStrideBalancesLoading || isSelectedBalancesLoading}
                sx={(t) => ({ color: t.colors.gray[7] })}>
                <>
                  {formatMicroDenom(totalBalance, chainInfo.stakeCurrency.coinMinimalDenom, 5)} {selectedCoinDenom}
                </>
              </TextLoader>
            </Group>

            <Group spacing="sm">
              <UnstyledButton onClick={handleClickHalf}>
                <Text sx={(t) => ({ color: t.colors.pink[7] })}>Add half</Text>
              </UnstyledButton>

              <UnstyledButton onClick={handleClickMax}>
                <Text sx={(t) => ({ color: t.colors.pink[7] })}>Add max</Text>
              </UnstyledButton>
            </Group>
          </Group>
        </>
      )}

      <Space h="lg" />

      <Divider />

      <Space h="lg" />

      <Group position="apart" align="center">
        <Box>
          <Text inline sx={(t) => ({ color: t.colors.gray[9] })}>
            What you'll get:
          </Text>

          <Space h="xs" />

          <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
            {stakedDenom}
          </Text>
        </Box>

        <StakeEstimate />
      </Group>
    </>
  )
}

export { StakeDefaultForm }
