import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { fatal, disregard } from '@/utils'
import { queryKeys } from '@/query-keys'
import { notify } from '@/contexts/notifications'
import { assertIsDeliverTxSuccess, broadcastTx } from '@/wallet-utils'
import { MutationParameters } from './types'
import { useRefreshStrideBalances, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

const useBroadcastRedeemStakeMutation = ({ redeemStakeRaw }: MutationParameters) => {
  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const refreshStrideBalances = useRefreshStrideBalances()

  const client = useQueryClient()

  const handleMutation = async (): Promise<DeliverTxResponse> => {
    if (!strideAccount || !selectedAccount || !strideAccount.client) {
      throw fatal('Unable to redeem staked tokens without connecting your wallet.')
    }

    if (!redeemStakeRaw) {
      throw fatal('Unable to broadcast `RedeemStake` transaction without a signed document. Please try again.')
    }

    const bytes = TxRaw.encode(redeemStakeRaw).finish()

    const tx = await broadcastTx(strideAccount.client, bytes)

    assertIsDeliverTxSuccess(tx)

    return tx
  }

  const handleSettled = async () => {
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })
  }

  const handleSuccess = async () => {
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryUnbondingsBase })

    // We'll update this for precautionary measures
    await client.invalidateQueries({ queryKey: queryKeys.unstakeFormEpochTrackerBase })

    // We'll invalidate redemption records as epoch tracker may not necessarily change
    await client.invalidateQueries({ queryKey: queryKeys.unstakeFormRedemptionRecordBase })

    try {
      await refreshStrideBalances()
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }
  }

  return useMutation({
    mutationFn: handleMutation,
    onSettled: handleSettled,
    onSuccess: handleSuccess
  })
}

export { useBroadcastRedeemStakeMutation }
