import React, { useMemo } from 'react'
import { useAtom } from 'jotai'
import { StepperProvider, StepperModal, useStepper } from '@/components'
import { StakeClassicModalStepOne } from './StakeClassicModalStepOne'
import { StakeClassicModalStepTwo } from './StakeClassicModalStepTwo'
import { StakeClassicModalStepThree } from './StakeClassicModalStepThree'
import { StakeClassicModalStepFour } from './StakeClassicModalStepFour'
import { useDexPoolApyQuery } from '../queries'
import { stakeAmountAtom, stakeClassicModalIsOpenAtom } from '../atoms'
import { useStakeClassicModal } from './StakeClassicModalProvider'
import { useSelectedCoin } from '@/contexts/wallet'
import { formatDenom } from '@/wallet-utils'

interface StakeModalProps {
  onSuccess: () => void
}

// Autopilot is now our de-facto standards for most transactions. However,
// if the user happens to have some tokens on Stride or if a chain does not
// yet support Autopilot, the classic staking flow can take care of it.
const StakeClassicModal: React.FC<StakeModalProps> = ({ onSuccess }) => {
  useDexPoolApyQuery()

  const denom = useSelectedCoin()

  const [amount, setAmount] = useAtom(stakeAmountAtom)

  const [isOpen, setIsOpen] = useAtom(stakeClassicModalIsOpenAtom)

  const {
    isBroadcastingIbcTransfer,
    isBroadcastingLiquidStake,
    isIbcStatusLoading,
    signIbcTransferError,
    broadcastIbcTransferError,
    signLiquidStakeError,
    broadcastLiquidStakeError,
    ibcStatusError,
    resetStakeClassicModalState
  } = useStakeClassicModal()

  const formattedAmount = `${formatDenom(amount, 5)} ${denom}`

  const steps = useMemo(() => {
    return [
      'Approve transfer in wallet',
      `Transfer ${denom} to Stride`,
      'Approve staking in wallet',
      `Stake ${denom}, get st${denom}`
    ]
  }, [denom])

  const handleClose = () => {
    setAmount(0)
    setIsOpen(false)
    resetStakeClassicModalState()
  }

  const stepper = useStepper({
    steps,
    warning: isIbcStatusLoading,
    error: Boolean(
      signIbcTransferError ||
        broadcastIbcTransferError ||
        signLiquidStakeError ||
        broadcastLiquidStakeError ||
        ibcStatusError
    ),
    isOpen,
    isLoading: isBroadcastingIbcTransfer || isBroadcastingLiquidStake || isIbcStatusLoading,
    isPostError: false,
    isComplete: false,
    onClose: handleClose,
    onComplete: onSuccess
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title="Liquid Staking" amount={formattedAmount}>
        <StakeClassicModalStepOne />
        <StakeClassicModalStepTwo />
        <StakeClassicModalStepThree />
        <StakeClassicModalStepFour />
      </StepperModal>
    </StepperProvider>
  )
}

export { StakeClassicModal }
