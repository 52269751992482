import { useMutation } from '@tanstack/react-query'
import { fatal } from '@/utils'

import { convertDenomToMicroDenom } from '@/wallet-utils'

import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { MutationParameters } from './types'

import { IBCX_CONTRACTS, IBCX_CURRENCY } from '@/config/ibcx'
import { ExecuteMsg } from '@many-things/ibcx-contracts-sdk/types/contracts/Periphery.types'
import BigNumber from 'bignumber.js'
import { useStakeIbcx } from '../../StakeIbcxProvider'
import { useOsmosisPoolsQuery } from '../../queries/useOsmosisPoolsQuery'
import { simulateIbcxLiquidStaking } from '../../queries/simulate'
import { toUtf8 } from '@cosmjs/encoding'
import { useChainWallet } from '@/contexts/wallet'

const useSignStakeIbcxMutation = ({ setStakeIbcxRaw }: MutationParameters) => {
  const { stakeModal } = useStakeIbcx()

  const { data: osmosisPools } = useOsmosisPoolsQuery()

  const { data: osmosisAccount } = useChainWallet('OSMO')

  const handleMutation = async (): Promise<TxRaw> => {
    if (!osmosisAccount || !osmosisAccount.cosmwasm) {
      throw fatal('You must be connected to a wallet and approved Osmosis chain permission for to stake IBCX.')
    }

    const amountInMicroDenom = convertDenomToMicroDenom(stakeModal.amount, IBCX_CURRENCY.coinMinimalDenom)

    if (!osmosisPools) {
      throw fatal('Missing osmosis pools data')
    }

    const { burnSwapInfo, burnExactAmountOutResult, mintSwapInfo, mintExactAmountInResult } =
      await simulateIbcxLiquidStaking({
        inputAmount: BigNumber(stakeModal.amount).toString(),
        pools: osmosisPools.pools
      })

    const msgIbcxBurnPayload: ExecuteMsg = {
      burn_exact_amount_out: {
        core_addr: IBCX_CONTRACTS.ibcx,
        swap_info: burnSwapInfo,
        output_asset: burnExactAmountOutResult.swap_result_amount
      }
    }

    const msgIbcxBurn = {
      typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
      value: {
        sender: osmosisAccount.address,
        contract: IBCX_CONTRACTS.peripheryV2,
        msg: toUtf8(JSON.stringify(msgIbcxBurnPayload)),
        funds: [
          {
            denom: IBCX_CURRENCY.coinMinimalDenom,
            amount: amountInMicroDenom.toString()
          }
        ]
      }
    }

    const msgStIbcxMintPayload: ExecuteMsg = {
      mint_exact_amount_in: {
        core_addr: IBCX_CONTRACTS.stIbcx,
        input_asset: 'uosmo',
        min_output_amount: BigNumber(mintExactAmountInResult.mint_amount).decimalPlaces(0).toString(),
        swap_info: mintSwapInfo
      }
    }

    const msgStIbcxMint = {
      typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
      value: {
        sender: osmosisAccount.address,
        contract: IBCX_CONTRACTS.peripheryV2,
        msg: toUtf8(JSON.stringify(msgStIbcxMintPayload)),
        funds: [burnExactAmountOutResult.swap_result_amount]
      }
    }

    const simulateGas = await osmosisAccount.cosmwasm
      .simulate(osmosisAccount.address, [msgIbcxBurn, msgStIbcxMint], '')
      .catch((e: Error) => {
        throw new SimulateGasInsufficientAmountError(e.message)
      })

    const fee = {
      amount: [{ amount: '625', denom: osmosisAccount.currency.coinMinimalDenom }],
      gas: BigNumber(simulateGas).multipliedBy(1.25).toString()
    }

    return await osmosisAccount.cosmwasm.sign(osmosisAccount.address, [msgIbcxBurn, msgStIbcxMint], fee, '')
  }

  const handleSuccess = async (raw: TxRaw) => {
    // This is used so we can pass the transaction TxRaw to the broadcast mutation behind the hood.
    // This keeps keeps implementation details away from the step handlers.
    setStakeIbcxRaw(raw)
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  });
}
class SimulateGasInsufficientAmountError extends Error {}

export { useSignStakeIbcxMutation, SimulateGasInsufficientAmountError }
