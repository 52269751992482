import { notify } from '@/contexts/notifications'
import { StepperModalContent, useStepperContext } from '@/components'
import { useLatestValue, useMount } from '@/hooks'
import { Button } from '@mantine/core'
import { useAtomValue } from 'jotai'
import React from 'react'
import { useStakeClassicModal } from './StakeClassicModalProvider'
import { stakeClassicModalIsOpenAtom } from '../atoms'
import { useSelectedCoin } from '@/contexts/wallet'
import { StandardTransactionError } from '@/wallet-utils'

const StakeClassicModalStepTwo: React.FC = () => {
  const {
    broadcastIbcTransfer,
    isBroadcastingIbcTransfer,
    broadcastIbcTransferError,
    traceIbcStatus,
    isIbcStatusLoading
  } = useStakeClassicModal()

  const { nextStep, forceClose, handleClose } = useStepperContext()

  const denom = useSelectedCoin()

  const isOpen = useAtomValue(stakeClassicModalIsOpenAtom)

  const isOpenRef = useLatestValue(isOpen)

  const handleStep = async () => {
    const { status } = await broadcastIbcTransfer()

    if (!isOpenRef.current) {
      // This function doesn't get cancelled when the modal gets closed.
      // If we get here when pool is already closed, it's likely that the stuff that
      // `traceIbcStatus` (transactionRef) needs has already been reset by the
      // modal close handler.
      return
    }

    if (status === 'return-later') {
      // If we're here, it means that our ibc transfer took too long and we're tracking it
      // the second time without the 30-second timeout. This way, the user can continue the
      // flow if it they haven't closed.
      //
      // We can check for the return-later status by checking using `isIbcStatusLoading`
      // to check if the ibc transfer hasn't completed and ibc tracking is still on-going.
      //
      // @TODO: Handle timeout state if this doesn't succeed. The original implementation
      // from the ibc/staking modal doesn't have this yet neither. Add display states for
      // `status === 'timeout'` for both broadcast and tracing and `ibcStatusError`
      await traceIbcStatus()
    }

    nextStep()
  }

  const handleCloseCallback = () => {
    // If it was closed after the broadcast, we don't need to show the toast.
    if (!isBroadcastingIbcTransfer && !isIbcStatusLoading) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the transfer completes.")
  }

  useMount(() => {
    handleStep()
  })

  handleClose(handleCloseCallback)

  const handleRetryBroadcastIbcTransfer = async () => {
    handleStep()
  }

  if (isIbcStatusLoading) {
    return (
      <StepperModalContent
        title="This transaction is taking longer than usual"
        description={`If you don’t want to wait here, you can close this dialog. Your transaction will continue and you’ll be able to see status on the Stride app page.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
      />
    )
  }

  if (broadcastIbcTransferError instanceof StandardTransactionError) {
    // Generally, INSUFFICIENT_FUNDS (code 5) and OUT_OF_GAS (code 11) can mean the same thing.
    //
    // https://github.com/Stride-Labs/interface/issues/529
    // @TODO: When we try fixing how retry broadcast works, it's possible that when their ATOMs
    // are used as gas, they'll have less, and so we might want to cover for that.

    // @TODO Review - we had to ship so we ended up separating the two anyway (i.e., not the same).
    // It makes sense, but we should review because we had a bit of back and forth last time. However,
    // hard to do given time.
    // const isOutOfGas =
    //   broadcastIbcTransferError.description === 'INSUFFICIENT_FUNDS' ||
    //   broadcastIbcTransferError.description === 'OUT_OF_GAS'

    // const description = isOutOfGas
    //   ? `You are trying to liquid stake close to your full token balance, please decrease the liquid staked amount to leave some tokens for gas fees.`
    //   : broadcastIbcTransferError.message

    const description =
      broadcastIbcTransferError.description === 'INSUFFICIENT_FUNDS'
        ? `Insufficient funds for gas.`
        : broadcastIbcTransferError.description === 'OUT_OF_GAS'
        ? `Transaction exceeded gas limit.`
        : broadcastIbcTransferError.message

    return (
      <StepperModalContent
        title="Transaction error"
        description={description}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetryBroadcastIbcTransfer}>
              Try again
            </Button>
          </>
        }
        error={broadcastIbcTransferError}
      />
    )
  }

  if (broadcastIbcTransferError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`This transfer could not be completed. Your tokens have not been moved.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
            <Button color="dark" variant="outline" onClick={handleRetryBroadcastIbcTransfer}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title={`Transferring your ${denom} to the Stride app...`}
      description="This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the transfer will continue. Once the transfer is complete, you will need to return to initiate the staking process."
    />
  )
}

export { StakeClassicModalStepTwo }
