import axios, { AxiosError } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { fatal } from '@/utils'
import { CHAIN_CONFIG, CHAIN_INFO_LIST, CHAIN_SUPPORTS_LSM, STRIDE_CHAIN_INFO } from '@/config'
import { queryKeys } from '@/query-keys'
import { z } from 'zod'
import { useSelectedWallet, useStrideBalancesV2, useStrideWallet } from '@/contexts/wallet'
import { useLatestValue } from '@/hooks'

export interface LsmIbcBalance {
  amount: string
  ibcDenom: string
  tokenizedDenom: string
}

export interface LsmIbcBalancesQueryPayload {
  lsmIbcBalances: LsmIbcBalance[]
}

// This was originally part of StakeTransactionHistory / useTransactionHistory, but ultimately
// decided to make it separate. This is to guarantee that LSM continue flow will always work.
// At this time, edge proxy is not able to handle the tx endpoint for osme addresses, and ends up
// responding with an error code 413 (more on this in Transaction History). In addition, this
// makes lsm ibc transactions to be placed on top of the list for visibility.
//
// There is a known bug where LsmIbc transactions are often not removed despite being invalidated
// when the revert flow succeeds. This is likely because there are stale values, and I have no idea
// how to fix it.
const useLsmIbcBalancesQuery = () => {
  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  // @TODO: Handle errors properly - for now StakeWallet uses this query extensively and has implemented some errors states
  const { data: strideBalances } = useStrideBalancesV2()

  // For some reason, we have a stale value of strideBalances inside the query.
  // This is despite us awaiting for the update balance function (from the LSM flow)
  // to properly finish, and only then do we invalidate this query. This is a workaround
  // for now.
  //
  // When we upgrade react-query in the near future, we might want to remove if this
  // is still a valid case.
  //
  // A probable good fix here would either be using flushSync (to let state propagate/render)
  // on the update balance function. Or, include the balances as part of the query key.
  // The latter might be tricky as it likely may remove all of the data (since technically
  // it's already a different query key)
  const strideBalancesRef = useLatestValue(strideBalances)

  // Unlike most of the transaction history, LsmIbc does not depend on the IBC messages we usually query through
  // the /txs endpoint. LsmIbc uses the stride account's balances and does some querying to an entirely different
  // endpoint to check if the ibc denom is actually an lsm balance. In a sense, it also does not use `useIbcStatusQuery`
  // and is not dismissible - we want users to have complete visibility over their LSM transactions, and push them to either
  // liquid stake them or revert them back as natively staked balance.
  const handleQueryLsmIbcBalances = async (): Promise<LsmIbcBalancesQueryPayload> => {
    if (!selectedAccount) {
      throw fatal('Unable to query lsm ibc balances while disconnected.')
    }

    if (!strideBalancesRef.current) {
      throw fatal('Unable to query lsm ibc balances while balances are empty.')
    }

    if (!CHAIN_SUPPORTS_LSM[selectedAccount.currency.coinDenom]) {
      return { lsmIbcBalances: [] }
    }

    const lsmIbcBalances = await Promise.all(
      strideBalancesRef.current.strideBalances.map(async (coin) => {
        if (!coin.denom.startsWith('ibc')) {
          return null
        }

        const ibcDenomHash = coin.denom.replace('ibc/', '')

        try {
          const { path, base_denom } = (await getIbcDenomTrace(ibcDenomHash)).denom_trace

          const chainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

          const chainConfig = CHAIN_CONFIG[selectedAccount.currency.coinDenom]

          // Guarantee that the ibc denom hash is for the selected chain
          if (
            path !== `transfer/${chainConfig.withdrawChannel}` ||
            !base_denom.startsWith(chainInfo.bech32Config.bech32PrefixValAddr)
          ) {
            return null
          }

          // We dont necessarily need the return value of ibc denom hash - if it fails,
          // it's likely that it's not an ibc denom hash for an lsm balance.
          return {
            amount: coin.amount,
            ibcDenom: coin.denom,
            tokenizedDenom: base_denom
          }
        } catch (e: unknown) {
          if (e instanceof AxiosError && e.response?.status === 501) {
            return null
          }

          // If it's not a "Not Found" error, it's likely a legitimiate error.
          throw e
        }
      })
    )

    return {
      lsmIbcBalances: lsmIbcBalances.filter((balance): balance is NonNullable<LsmIbcBalance> => {
        return balance != null
      })
    }
  }

  // Despite selecting INJ, selectedAccount is still an ATOM account. This is a workaround for now.
  // To go in detail, selectedAccount is outdated for some time; Either there's a race condition on our
  // wallet connection code, or our wallet connection code is too slow, or even both.
  // @TODO: We might want to investigate this if we haven't when we start enabing LSM for more chains.
  const addresses = {
    strideAccount: strideAccount?.address ?? '',
    selectedAccount: selectedAccount?.address ?? ''
  }

  return useQuery({
    queryKey: queryKeys.transactionHistoryLsmIbcBalancesByAddress({ addresses }),
    queryFn: handleQueryLsmIbcBalances,
    enabled: Boolean(strideAccount && selectedAccount && strideBalances),
    staleTime: 60_000
  })
}

const ibcTransferDenomTraceSchema = z.object({
  denom_trace: z.object({
    path: z.string(),
    base_denom: z.string()
  })
})

const getIbcDenomTrace = async (ibcDenomHash: string) => {
  const instance = axios.create({
    baseURL: STRIDE_CHAIN_INFO.rest
  })

  const response = await instance.get(`ibc/apps/transfer/v1/denom_traces/${ibcDenomHash}`)

  return ibcTransferDenomTraceSchema.parse(response.data)
}

export { useLsmIbcBalancesQuery }
