import BigNumber from 'bignumber.js'
import isBase64 from 'is-base64'
import { z } from 'zod'
import { Buffer } from 'buffer'
import { Attribute, Event } from '@cosmjs/stargate'
import { fatal } from '@/utils'
import { DEX_NUDGE_THRESHOLD } from '@/config'
import { MarketPriceQueryPayload, LsmValidatorsQueryPayload, RateLimitPayload } from './queries'
import { ChainInfo } from '@keplr-wallet/types'
import { HostZone, TiaHostZone, isTiaHostZone } from '@/queries'

interface GetDexProfitRateParameters {
  amount: number
  marketPrice: MarketPriceQueryPayload
}

// How much a user may profit per token in dollars if they stake their tokens on DEX instead of Stride
// Profit = dexTotalSwapValueInUsd - strideTotalSwapValueInUsd; where:
// dexTotalSwapValueInUsd = (amount / dexStTokenValue) * (dexStTokenValueInUsd - fees); and
// strideTotalSwapValueInUsd = (amount / strideStTokenValue) * strideStTokenValueInUsd
// This is a utility function expected to be used with `useMarketPriceQuery`.
const getDexProfitValueInUsd = ({ amount, marketPrice }: GetDexProfitRateParameters): number => {
  const dex = new BigNumber(amount)
    .dividedBy(marketPrice.dexStTokenValue)
    .multipliedBy(marketPrice.dexStTokenValueInUsd)

  const stride = new BigNumber(amount)
    .dividedBy(marketPrice.strideStTokenValue)
    .multipliedBy(marketPrice.strideStTokenValueInUsd)

  return dex.minus(stride).toNumber()
}

interface IsMarketPriceWithinThresholdParameters {
  amount: number
  marketPrice: MarketPriceQueryPayload | undefined
}

// If the difference between dex price and stride price at least between these two values much,
// we'll trigger the nudge pool modal when the user clicks the "Liquid Stake" button.
//
// The minimum threshold is for the pool nudge gives significant bonuses.
// The maximum threshold is set to avoid slippage because not most dexes support them at the moment.
//
// To test this manually, it's best to find a chain with a positive profit on their dex, and then
// hard-set the amount to a value like 5000 where the [total of statom in usd] won't exceed the max threshold.
// The automated tests may help.
//
// To test the minimum threshold: this is simplest - most chains would return negative profit.
// To test both: (either find a chain with positive profit / hard-code dexProfitValueInUsd to 6) & hard-code amount to ~5_000.
// To test the maximum threshold: (either find a chain with positive profit / hard-code dexProfitValueInUsd to 6) & hard-code amount to 10_000.
//
// If you want to test the stuff inside pool nudge, simply set this to true
const isMarketPriceWithinThreshold = ({ amount, marketPrice }: IsMarketPriceWithinThresholdParameters) => {
  if (!marketPrice?.dexAvailability) {
    // If market price hasn't loaded yet (or chain has no configured dex) when this function is called, that's fine!
    return false
  }

  const dexProfitValueInUsd = getDexProfitValueInUsd({ amount, marketPrice })

  const strideTotalStTokenValueInUsd = new BigNumber(amount)
    .dividedBy(marketPrice.strideStTokenValue)
    .multipliedBy(marketPrice.strideStTokenValueInUsd)
    .toNumber()

  return dexProfitValueInUsd > DEX_NUDGE_THRESHOLD.min && strideTotalStTokenValueInUsd < DEX_NUDGE_THRESHOLD.max
}

interface GetTotalNativelyStakedBalanceParameters {
  lsmValidators: LsmValidatorsQueryPayload | undefined
}

// Calculates the total amount of natively staked tokens from each validator for LSM
// This is a utility function that is expect to be used with `useLsmValidatorsQuery`.
const getTotalNativelyStakedBalance = ({ lsmValidators }: GetTotalNativelyStakedBalanceParameters): bigint => {
  if (!lsmValidators) {
    return BigInt(0)
  }

  return lsmValidators.validators.reduce((total, validator) => {
    return total + BigInt(validator.amount)
  }, BigInt(0))
}

// This captures `/1` in a string like `cosmosvaloper1uk4ze0x4nvh4fk0xm4jdud58eqn4yxhrdt795p/1`,
// effectively allowing us to remove it extract just the validator address.
const TOKENIZED_SHARE_DENOM_SUFFIX = /\/(\d+)$/i

interface IsLsmTokenizedShareDenomParameters {
  // The tokenized denom to check (produced by MsgTokenizeShares)
  denom: string
  // The selected chain's chainInfo
  chainInfo: ChainInfo
}

// We're looking for denoms in the pattern of `{validator}/{recordId}` which looks
// something like `cosmosvaloper1uk4ze0x4nvh4fk0xm4jdud58eqn4yxhrdt795p/1`.
// In this case, we're looking for `cosmosvaloper` and the ending `/1`.
const isLsmTokenizedShareDenom = ({ denom, chainInfo }: IsLsmTokenizedShareDenomParameters): boolean => {
  return denom.startsWith(chainInfo.bech32Config.bech32PrefixValAddr) && TOKENIZED_SHARE_DENOM_SUFFIX.test(denom)
}

// A tokenized share has a denom format of `cosmosvaloper1uk4ze0x4nvh4fk0xm4jdud58eqn4yxhrdt795p/1`
// This function simply grabs the `cosmosvaloper1uk4ze0x4nvh4fk0xm4jdud58eqn4yxhrdt795p` part.
const getValidatorAddressFromLsmTokenizedShare = (denom: string): string => {
  return denom.replace(TOKENIZED_SHARE_DENOM_SUFFIX, '')
}
interface GetEstimatedStTokenParameters {
  amount: number
  // Data from `useHostZoneQuery`
  hostZone: HostZone | TiaHostZone | undefined
  // Data from `lsm` from `useStake`
  validatorAddress: string
}

// (amount * validatorExchangeRate) / redemptionRate
// Calculates the estimated amount of stToken a user would receive if they were to redeem their LSM tokens
// This is expected to be used in tandem with `useHostZone` and `lsm` from `useStake`
// @TODO: Consider returning microdenoms to adhere to standards
const getLsmEstimatedStTokenValue = ({ amount, hostZone, validatorAddress }: GetEstimatedStTokenParameters): number => {
  if (!hostZone) {
    return 0
  }

  if (isTiaHostZone(hostZone)) {
    throw fatal('Unable to get estimated st token value for lsm due to invalid hostZone type: staketia HostZone')
  }

  const hostZoneValidator = hostZone.validators.find((validator) => {
    return validator.address === validatorAddress
  })

  if (!hostZoneValidator) {
    return 0
  }

  // @TODO: Remove use of BigNumber to run computations
  return new BigNumber(amount)
    .multipliedBy(hostZoneValidator.shares_to_tokens_rate)
    .dividedBy(hostZone.redemption_rate)
    .toNumber()
}

// Convenience method for `normalizeEventAttribute`, nothing more.
const fromBase64 = (value: string) => {
  return Buffer.from(value, 'base64').toString()
}

// Normalizes an event attribute to be human-readble if it's encoded.
// Chains and environments (dockernet, mainnet) are inconsistent whether the attributes will
// be encoded or not. We have to check both cases.
// This is a low-level functionality of `convertAttributesToObject` (preferred),
// so use this only if `convertAttributesToObject` doesn't work for your case.
const normalizeEventAttribute = (attribute: Attribute) => {
  const key = isBase64(attribute.key) ? fromBase64(attribute.key) : attribute.key

  // We intentionally want to check for key because it seems unlikely to have values that are
  // intentionally base64 encoded or something like that. As an example, the lsm_liquid_stake
  // event has an attribute called `lsm_liquid_stake_tx_id` and its values look like this:
  // `e9960b595468d29264f24408d71bd72cef955ebdf9e93a31ef00effce73039da`. Now, when we try to
  // decode the value, we get broken values like {�zѾ}玼wov�����<w�[w��y�y���׽ݭ�y�4y��{����Z.
  // Apparently, the library we're using `isBase64(...)` isn't smart enough to know this.
  // If you'd like to know more, feel free to test `useLsmLiquidStakeQueryCallbackQuery` by
  // running the lsm flow that ends up with a `pending` status.
  const value = isBase64(attribute.key) ? fromBase64(attribute.value) : attribute.value

  return { key, value }
}

// Converts the attributes of an event into a simpler key-value object.
// [{ key: 'amount', value: '1000uatom' }] -> { amount: '1000uatom' }
//
// This is intended to be used on cases where you expect the attribute keys to be unique.
// This simply cannot work if your case includes extracting attributes from multiple events
// and then trying to check for key and values of each.
//
// This function can take care of both base64-encoded and normal attributes, which can be
// inconsistent across chains and different environments (dockernet vs mainnet)
const convertAttributesToObject = (attributes: Event['attributes']): Record<string, string> => {
  return attributes.reduce((object, attribute) => {
    const { key, value } = normalizeEventAttribute(attribute)
    return { ...object, [key]: value }
  }, {})
}

const lsmStatusSchema = z.enum(['success', 'pending', 'failed'])

const lsmLiquidStakeAttributesSchema = z.object({
  transaction_status: lsmStatusSchema,
  lsm_liquid_stake_tx_id: z.string(),
  sttoken_amount: z.string(),
  lsm_token_base_denom: z.string(),
  native_ibc_denom: z.string()
})

// Extracts type-safe attributes of the `lsm_liquid_stake` event from the transactions.
// This would fail if you try to read the events of a LSM Liquid Stake transaction that resulted
// to code -32603. I got this error when I attempted to run the flow with a validator not in our
// active set for the past 6 months (not included in the hostZone.validators list)
const getLsmLiquidStakeAttributes = (events: readonly Event[]): z.infer<typeof lsmLiquidStakeAttributesSchema> => {
  const liquidStakeEvent = events.find((event) => {
    return event.type === 'lsm_liquid_stake'
  })

  if (liquidStakeEvent == null) {
    throw fatal('Missing `lsm_liquid_stake` event from LSMLiquidStake transaction.')
  }

  const attributes = convertAttributesToObject(liquidStakeEvent.attributes)

  return lsmLiquidStakeAttributesSchema.parse(attributes)
}

interface GetRateLimitStatusParameters {
  amount: string
  rateLimit: RateLimitPayload | undefined
}

interface GetRateLimitMetaDataPayload {
  status: 'loose' | 'tight' | 'closed'
  difference: bigint
}

// Gets the status and amount to be sent based on the input amount and remaining capacity.
// @TODO: We might not need the "difference" at all and instead just use the bigger value.
// If the user exceeds capacity, then just use the capacity?
const getRateLimitMetaData = ({ amount, rateLimit }: GetRateLimitStatusParameters): GetRateLimitMetaDataPayload => {
  // Either query is still loading or an error occurred; eitherway, it should be handled.
  if (rateLimit == null) {
    return { status: 'loose', difference: BigInt(0) }
  }

  if (BigInt(rateLimit.remaining) <= 0) {
    return { status: 'closed', difference: BigInt(0) }
  }

  // Positive difference = user exceeds limit
  return BigInt(amount) > BigInt(rateLimit.remaining)
    ? { status: 'tight', difference: BigInt(rateLimit.remaining) }
    : { status: 'loose', difference: BigInt(0) }
}

export {
  // Classic & Autopilot
  getDexProfitValueInUsd,
  isMarketPriceWithinThreshold,
  // LSM
  getTotalNativelyStakedBalance,
  isLsmTokenizedShareDenom,
  getValidatorAddressFromLsmTokenizedShare,
  lsmStatusSchema,
  normalizeEventAttribute,
  convertAttributesToObject,
  getLsmEstimatedStTokenValue,
  getLsmLiquidStakeAttributes,
  // Rate Limiting
  getRateLimitMetaData
}
