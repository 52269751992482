import { CHAIN_INFO_LIST, DEX_CONFIG, DEX_INFO_LIST, STRIDE_CHAIN_INFO } from '@/config'
import { queryKeys } from '@/query-keys'
import { fatal } from '@/utils'
import axios from 'axios'
import BigNumber from 'bignumber.js'
import { useQuery } from '@tanstack/react-query'
import { HostZoneResponse } from '../../../queries/types'
import { useMemo } from 'react'
import { useSelectedCoin } from '@/contexts/wallet'
import { redis } from '@/redis'

export interface CoinGeckoPricesResponse {
  [key: string]: { usd: number }
}

// While `useHostZone` is plenty to get the conversion of 1 ATOM to stATOM,
// this query provides high-level conversion rates so components can mostly
// just use the values by a simple multiplication or division. Used mostly for
// the Pool Nudge.
//
// Rule of thumb: you need market price in some way? Use this query. Otherwise,
// prefer `useHostZone` for more consistent availability.
//
// [Tip]: The simplest way to understand the values here is to look
// at it in the perspective of a swap UI (like https://app.osmosis.zone/pool/803).
//
// [Example]: 1 ATOM = {dexTokenValue} stATOM
// [Example]: 1 stATOM = {dexStTokenValue} ATOM ($ {dexStTokenValueInUsd})
//
// @TODO: In the future, let's consider renaming to `dexRedemptionRate` and `strideRedemptionRate`
// These seem like more universal terms used in the community.
//
// @TODO: Consider removing the dollar values and rely on `useTokenValue` instead.
export interface MarketPriceQueryPayload {
  // Convenient attribute to know if we should run the calculations
  // This is true if the chain has a dex's config, not if the dex's api is not down.
  // You can conveniently do marketPrice?.dexAvailability to check if the query
  // has completed loading or if a dex config is present.
  dexAvailability: boolean
  // (DEX) Swap value of 1 ATOM to stATOM (0.86)
  // 1 ATOM = {dexTokenValue} stATOM
  dexTokenValue: number
  // (DEX) Swap value of 1 stATOM to ATOM (1.15; redemption rate)
  // 1 stATOM = {dexStTokenValue} ATOM ($ {dexStTokenValueInUsd})
  dexStTokenValue: number
  // (DEX) Swap value of 1 stATOM to ATOM in USD (deducted by fees)
  // We have no case where we need a value that's not deducted by fees.
  dexStTokenValueInUsd: number
  // (Stride) Swap value of 1 stATOM to ATOM (1.15)
  // 1 ATOM = {strideTokenValue} stATOM
  strideTokenValue: number
  // (Stride) Swap value of 1 ATOM to stATOM (0.86)
  // 1 stATOM = {strideStTokenValue} ATOM ($ {strideStTokenValueInUsd})
  strideStTokenValue: number
  // (Stride) Swap value of 1 stATOM to ATOM in USD
  strideStTokenValueInUsd: number
}

const useMarketPriceQuery = () => {
  const selectedCoinDenom = useSelectedCoin()

  const dexConfig = useMemo(() => DEX_CONFIG[selectedCoinDenom], [selectedCoinDenom])

  const handleRequest = async (): Promise<MarketPriceQueryPayload> => {
    // @TODO: This query DOES not support IBCX yet. We'll setup a dummy for now.
    if (selectedCoinDenom === 'IBCX') {
      return {
        dexAvailability: false,
        dexTokenValue: 0,
        dexStTokenValue: 0,
        dexStTokenValueInUsd: 0,
        strideTokenValue: 1,
        strideStTokenValue: 1,
        strideStTokenValueInUsd: 1
      }
    }

    // @TODO: Consider using useTokenValueInUsdQuery instead moving forward
    const tokenValueInUsd = await redis.get<number>(`sheet_COINGECKO_PRICE_DOLLAR_${selectedCoinDenom}`)

    if (tokenValueInUsd == null) {
      throw fatal(`Missing token value in usd for ${selectedCoinDenom}`)
    }

    const getStrideMarketPrice = async () => {
      const instance = axios.create({
        baseURL: STRIDE_CHAIN_INFO.rest
      })

      const chainInfo = CHAIN_INFO_LIST[selectedCoinDenom]

      const hostZoneResponse = await instance.get<HostZoneResponse>(
        selectedCoinDenom === 'TIA'
          ? `Stride-Labs/stride/staketia/host_zone`
          : selectedCoinDenom === 'DYM'
          ? `Stride-Labs/stride/stakedym/host_zone`
          : `Stride-Labs/stride/stakeibc/host_zone/${chainInfo.chainId}`
      )

      const stTokenValue = Number(hostZoneResponse.data.host_zone.redemption_rate)

      return {
        tokenValue: new BigNumber(1).dividedBy(stTokenValue).toNumber(),
        stTokenValue,
        stTokenValueInUsd: new BigNumber(stTokenValue).multipliedBy(tokenValueInUsd).toNumber()
      }
    }

    const getDexMarketPrice = async () => {
      if (dexConfig == null) {
        return { tokenValue: 0, stTokenValue: 0, stTokenValueInUsd: 0 }
      }

      const dexInfo = DEX_INFO_LIST[dexConfig.type]

      const { tokenValue, stTokenValue, stTokenValueInUsd } = await dexInfo.adapter({
        selectedCoinDenom,
        priceInUsd: tokenValueInUsd
      })

      const feeMultiplier = new BigNumber(1).minus(dexConfig.fees)

      // The adapters themselves return the pure swap value of 1 stATOM to ATOM, so we'll take care of fee deduction here.
      const stTokenValueInUsdMinusFees = stTokenValueInUsd
        ? new BigNumber(stTokenValueInUsd).multipliedBy(feeMultiplier).toNumber()
        : 0

      return { tokenValue, stTokenValue, stTokenValueInUsd: stTokenValueInUsdMinusFees }
    }

    const [dexValues, strideValues] = await Promise.all([getDexMarketPrice(), getStrideMarketPrice()])

    const {
      tokenValue: dexTokenValue,
      stTokenValue: dexStTokenValue,
      stTokenValueInUsd: dexStTokenValueInUsd
    } = dexValues

    const {
      tokenValue: strideTokenValue,
      stTokenValue: strideStTokenValue,
      stTokenValueInUsd: strideStTokenValueInUsd
    } = strideValues

    return {
      dexAvailability: Boolean(dexConfig),
      dexTokenValue,
      dexStTokenValue,
      dexStTokenValueInUsd,
      strideTokenValue,
      strideStTokenValue,
      strideStTokenValueInUsd
    }
  }

  // @TODO: Consider disabling if the chain does not have a dex configuration. Honestly, I've forgotten
  // most context including why we did not do this. It's possible the requirements were different initially.
  return useQuery({
    queryKey: queryKeys.stakingStatsOsmosisMarketPriceByDenom({ denom: selectedCoinDenom, dexConfig }),
    queryFn: handleRequest
  })
}

export { useMarketPriceQuery }
