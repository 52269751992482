import React from 'react'
import { Box, Button } from '@mantine/core'
import { StepperModalContent } from '@/components'
import { useSimpleCountdown } from '@/hooks'
import { useStakeLsmModal } from '../StakeLsmModalProvider'

const StakeLsmSlashed: React.FC = () => {
  const { countdown } = useSimpleCountdown(30)

  const { resetForLsmLiquidStake } = useStakeLsmModal()

  const handleRetry = () => {
    resetForLsmLiquidStake()
  }

  return (
    <StepperModalContent
      title="Stride is processing a slash"
      description={
        <>
          <Box>
            Looks like your validator was slashed recently. Your tokens are safe, just retry after Stride finished
            processing the slash.
          </Box>

          {countdown > 0 && (
            <Box>
              Try again in: <strong>{countdown} seconds</strong>
            </Box>
          )}
        </>
      }
      actions={
        <Button disabled={countdown > 0} onClick={handleRetry}>
          Retry
        </Button>
      }
    />
  )
}

export { StakeLsmSlashed }
