import { DEX_CONFIG, OSMO_CHAIN_INFO } from '@/config'
import { IBCX_CURRENCY, STAKED_IBCX_CURRENCY } from '@/config/ibcx'
import { queryKeys } from '@/query-keys'

import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

import BigNumber from 'bignumber.js'
import { fatal } from '@/utils'
import { CoinGeckoPricesResponse } from '../../Stake/queries'
import { useIbcxTokensSwapRoutesQuery } from './useIbcxTokensSwapRoutesQuery'

export interface OsmosisV2PoolPricesResponse {
  spot_price: string
}

// We removed Pool Nudge support because IBCX has two pools IBCX/OSMO and stIBCX/OSMO.
// Logically, it makes sense for Pool Nudge to link to the IBCX/OSMO pool. However,
// Pool Nudge makes a comaprison against the stIBCX rates on the DEX side. We had two
// options: display links to both pools or disable Pool Nudge entirely. Both IBCX team
// and the Stride team decided to disable it entirely. Even so, the queries still
// have the data needed for pool nudge - just in case! While we've removed traces of
// Pool Nudge implementation for IBCX, we're leaving alone parts of this query for Pool Nudge.
export const useIbcxTokensMarketPriceQuery = () => {
  const { data: swapRoutes, isLoading: isSwapRoutesLoading, error: swapRoutesError } = useIbcxTokensSwapRoutesQuery()

  const handleRequest = async () => {
    const coinGeckoId = OSMO_CHAIN_INFO.stakeCurrency.coinGeckoId

    if (coinGeckoId === undefined) {
      throw fatal(`Missing coin gecko id for ${OSMO_CHAIN_INFO.stakeCurrency.coinDenom}`)
    }

    const coinGeckoResponse = await axios.get<CoinGeckoPricesResponse>(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coinGeckoId}&vs_currencies=usd`
    )

    const osmosisMarketPrice = coinGeckoResponse.data[coinGeckoId].usd

    const getIbcxTokensPrice = () => {
      if (swapRoutes === undefined) {
        throw fatal('Unable to get stIbcx apy')
      }

      const { stIbcxRoutes, ibcxRoutes } = swapRoutes

      const totalStIbcxPortfolioOsmoAmount = stIbcxRoutes.reduce(
        (acc, { calculatedOsmoOutput }) => acc.plus(BigNumber(calculatedOsmoOutput)),
        BigNumber(0)
      )

      const totalIbcxPortfolioOsmoAmount = ibcxRoutes.reduce(
        (acc, { calculatedOsmoOutput }) => acc.plus(BigNumber(calculatedOsmoOutput)),
        BigNumber(0)
      )

      const stIbcxPrice = BigNumber(osmosisMarketPrice)
        .multipliedBy(
          totalStIbcxPortfolioOsmoAmount.dividedBy(new BigNumber(`1e+${STAKED_IBCX_CURRENCY.coinDecimals}`))
        )
        .toNumber()

      const ibcxPrice = BigNumber(osmosisMarketPrice)
        .multipliedBy(totalIbcxPortfolioOsmoAmount.dividedBy(new BigNumber(`1e+${IBCX_CURRENCY.coinDecimals}`)))
        .toNumber()

      return { stIbcxPrice, ibcxPrice }
    }

    const ibcxDexConfig = DEX_CONFIG['IBCX']
    const stIbcxDexConfig = DEX_CONFIG['STIBCX']

    const getDexMarketPrice = async (priceInUsd: number) => {
      if (osmosisMarketPrice === undefined || ibcxDexConfig == null || stIbcxDexConfig == null) {
        return { tokenValue: 0, stTokenValue: 0, stTokenValueInUsd: 0 }
      }

      const ibcxPoolPriceParams = {
        base_asset_denom: IBCX_CURRENCY.coinMinimalDenom,
        quote_asset_denom: 'uosmo'
      }

      const ibcxOsmosisPoolPricesResponse = await axios.get<OsmosisV2PoolPricesResponse>(
        `https://osmosis-api.polkachu.com/osmosis/gamm/v2/pools/${ibcxDexConfig.poolId}/prices`,
        {
          params: ibcxPoolPriceParams
        }
      )

      const stIbcxPoolPriceParams = {
        base_asset_denom: STAKED_IBCX_CURRENCY.coinMinimalDenom,
        quote_asset_denom: 'uosmo'
      }

      const stIbcxOsmosisPoolPricesResponse = await axios.get<OsmosisV2PoolPricesResponse>(
        `https://osmosis-api.polkachu.com/osmosis/gamm/v2/pools/${stIbcxDexConfig.poolId}/prices`,
        {
          params: stIbcxPoolPriceParams
        }
      )

      const ibcxTokenSpotPrice = Number(ibcxOsmosisPoolPricesResponse.data.spot_price)
      const stIbcxTokenSpotPrice = Number(stIbcxOsmosisPoolPricesResponse.data.spot_price)

      const feeMultiplier = new BigNumber(1).minus(ibcxDexConfig.fees).minus(stIbcxDexConfig.fees)

      const tokenValue = new BigNumber(ibcxTokenSpotPrice).dividedBy(stIbcxTokenSpotPrice).toNumber()
      const stTokenValue = new BigNumber(stIbcxTokenSpotPrice).dividedBy(ibcxTokenSpotPrice).toNumber()

      return {
        tokenValue,
        stTokenValue,
        stTokenValueInUsd: new BigNumber(stTokenValue).multipliedBy(priceInUsd).multipliedBy(feeMultiplier).toNumber()
      }
    }

    const { stIbcxPrice, ibcxPrice } = getIbcxTokensPrice()

    const ibcxValues = {
      strideTokenValue: new BigNumber(ibcxPrice).dividedBy(stIbcxPrice).toNumber(),
      strideStTokenValue: new BigNumber(stIbcxPrice).dividedBy(ibcxPrice).toNumber(),
      strideStTokenValueInUsd: stIbcxPrice
    }

    const {
      tokenValue: dexTokenValue,
      stTokenValue: dexStTokenValue,
      stTokenValueInUsd: dexStTokenValueInUsd
    } = await getDexMarketPrice(ibcxPrice)

    return {
      dexAvailability: Boolean(ibcxDexConfig && stIbcxDexConfig),
      dexTokenValue,
      dexStTokenValue,
      dexStTokenValueInUsd,
      ...ibcxValues
    }
  }

  const { isLoading, error, ...rest } = useQuery({
    queryKey: queryKeys.stakingStatsIbcxMarketPrice,
    queryFn: handleRequest,
    enabled: swapRoutes !== undefined
  })

  return {
    ...rest,
    error: error || swapRoutesError,
    isLoading: isLoading || isSwapRoutesLoading
  }
}
