import React from 'react'
import { Box, Group, Space, Text } from '@mantine/core'

import { assert } from '@/utils'
import { ErrorBeacon, InlineLoader, useFormContext } from '@/components'
import { formatCurrency, formatDenom } from '@/wallet-utils'
import { useTokenValueInUsdQuery } from '@/queries'
import { useSelectedCoin } from '@/contexts/wallet'
import { useHostZoneQuery } from '../queries'
import { UnstakeFormType } from './useUnstakeForm'

const UnstakeEstimate: React.FC = () => {
  const denom = useSelectedCoin()

  const { values } = useFormContext<UnstakeFormType>()

  const { data: hostZone, isLoading: isHostZoneLoading, error: hostZoneError } = useHostZoneQuery()

  const { data: tokenValueInUsd, error: tokenValueInUsdError } = useTokenValueInUsdQuery(denom)

  if (hostZoneError) {
    return (
      <UnstakeEstimateWrapper>
        <ErrorBeacon label="Unable to fetch redemption rate at this time. Please try again." />
      </UnstakeEstimateWrapper>
    )
  }

  if (isHostZoneLoading) {
    return (
      <UnstakeEstimateWrapper>
        <InlineLoader />
      </UnstakeEstimateWrapper>
    )
  }

  assert(hostZone, 'Unable to get estimate without host zone')

  const estimate = values.amount ? Number(values.amount) * Number(hostZone.redemption_rate) : 0

  return (
    <UnstakeEstimateWrapper>
      <Text weight={700} size="xl" align="right" sx={(t) => ({ color: t.colors.gray[7] })} inline>
        {format(estimate)}
      </Text>

      <Space h={4} />

      <Group spacing="xs" position="right">
        <Text weight={500} align="right" size="sm" inline sx={(t) => ({ color: t.colors.gray[7] })}>
          <span>≈</span>
        </Text>

        {tokenValueInUsdError ? (
          <ErrorBeacon label={`Unable to get the dollar value of st${denom}. Please try again.`} />
        ) : tokenValueInUsd == null ? (
          <InlineLoader />
        ) : (
          <Text weight={500} align="right" size="sm" inline sx={(t) => ({ color: t.colors.gray[7] })}>
            {formatCurrency(estimate * tokenValueInUsd)}
          </Text>
        )}
      </Group>
    </UnstakeEstimateWrapper>
  )
}

const format = (estimate: number) => {
  if (estimate >= 1) return formatDenom(estimate, 2)
  if (estimate >= 0.1) return formatDenom(estimate, 3)
  if (estimate >= 0.01) return formatDenom(estimate, 4)
  return formatDenom(estimate, 5)
}

const UnstakeEstimateWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Box sx={(t) => ({ paddingRight: t.other.stakeForm.amountPaddingRight })}>{children}</Box>
}

export { UnstakeEstimate }
