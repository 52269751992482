import React, { useMemo } from 'react'
import { Box, Button, Collapse, Group, Space, Tooltip } from '@mantine/core'
import { DataList, DataListItem, TransactionCard } from '@/components'
import {
  LsmLiquidStakeMetaData,
  convertMicroDenomToDenom,
  formatMicroDenom,
  isLsmLiquidStakeMetaData
} from '@/wallet-utils'
import { useStake } from '../StakeProvider'
import { LsmValidatorName } from '../shared'
import { useEligibleAirdropsQuery } from '@/queries'
import { useLsmIbcBalancesQuery, useTransactionHistoryQuery, LsmIbcBalance } from './queries'
import { getValidatorAddressFromLsmTokenizedShare } from '../utils'
import { useSelectedCoin } from '@/contexts/wallet'
import { CHAIN_INFO_LIST, CHAIN_SUPPORTS_LSM } from '@/config'

// This was originally part of StakeTransactionHistory / useTransactionHistory, but ultimately
// decided to make it separate. This is to guarantee that LSM continue flow will always work.
// At this time, edge proxy is not able to handle the tx endpoint for osme addresses, and ends up
// responding with an error code 413 (more on this in Transaction History). In addition, this
// makes lsm ibc transactions to be placed on top of the list for visibility.
const LsmIbcTransferMetaDataCard: React.FC = () => {
  const { data: transactionHistory } = useTransactionHistoryQuery()

  const { data: lsmIbcBalances } = useLsmIbcBalancesQuery()

  const denom = useSelectedCoin()

  // When a MsgLSMLiquidStake transaction fails, we give users the option to retry said transaction.
  // Furthermore, LsmIbc transactions are based on the user's balance and also offer a "continue" option.
  // This means, by default both transactions would show up and can become a source of confusion. To fix this,
  // we want to hide ibc transactions that has a MsgLSMLiquidStake transaction that *has not succeeded*.
  // To simplify, [ibc denom existing + transaction status that's not 'success' = failure]
  //
  // This is a simple map to enable a quick look up and filter out transactions that pass the condition above.
  // i.e., isIbcDenomHidden[meta.values.hash]
  //
  // We made a look-up map to avoid nested array operations as we usually do with the rest of Stride Frontend.
  //
  // @TODO: Let's ask the team if the user's tokenized ibc denom is immediately taken away while the lsm liquid stake
  // is "pending". If no, we may also need to make sure that `useLsmLiquidStakeQueryCallbackQuery` refreshes
  // the balance when it succeeds. Downside: our wc and update balance implementation is ancient; this means
  // we'll make multiple subsequent requests if there are multiple MsgLSMLiquidStake transctions at a time.
  // In my opinion, the wallet connection rework (with cosmos-kit) will fix this, but I am not sure yet.
  //
  // @TODO: Revisit the above in the future, we have too much to handle at this time.
  //
  // { 'AS34FG1AS': true }
  const isIbcDenomHidden: Record<string, boolean> = useMemo(() => {
    if (transactionHistory == null) {
      return {}
    }

    return Object.fromEntries(
      transactionHistory.transactions.filter(isLsmLiquidStakeMetaData).map((meta) => {
        return [meta.values.denom, meta.values.status !== 'success']
      })
    )
  }, [transactionHistory])

  // Despite selecting INJ, useLsmIbcBalances still uses tokens from ATOM. This is a workaround for now.
  // To go in detail, selectedAccount is outdated for some time; Either there's a race condition on our
  // wallet connection code, or our wallet connection code is too slow, or even both.
  // @TODO: We might want to investigate this if we haven't when we start enabing LSM for more chains.
  if (!CHAIN_SUPPORTS_LSM[denom]) {
    return null
  }

  return (
    <>
      {lsmIbcBalances?.lsmIbcBalances
        .filter((ibcBalance) => {
          // Filter out ibc balances with non-successful (which means the ibc balances are still there) transactions
          return !isIbcDenomHidden[ibcBalance.ibcDenom]
        })
        .map((ibcBalance) => {
          return <LsmIbcTransferMetaDataCardItem ibcBalance={ibcBalance} key={ibcBalance.ibcDenom} />
        })}
    </>
  )
}

interface LsmIbcTransferMetaDataCardProps {
  ibcBalance: LsmIbcBalance
}

const LsmIbcTransferMetaDataCardItem: React.FC<LsmIbcTransferMetaDataCardProps> = ({ ibcBalance }) => {
  const denom = useSelectedCoin()

  const { resumeLsmIbc, withdrawLsmIbc } = useStake()

  const { isLoading: isEligibleAirdropsLoading, error: eligibleAirdropsError } = useEligibleAirdropsQuery()

  const { data: transactionHistory } = useTransactionHistoryQuery()

  const lsmLiquidStakeMeta = useMemo(() => {
    if (transactionHistory == null) {
      return undefined
    }

    return transactionHistory.transactions.find((lsmMeta): lsmMeta is LsmLiquidStakeMetaData => {
      return isLsmLiquidStakeMetaData(lsmMeta) && lsmMeta.values.ibcDenom === ibcBalance.ibcDenom
    })
  }, [transactionHistory, ibcBalance])

  const validatorAddress = useMemo(() => {
    return getValidatorAddressFromLsmTokenizedShare(ibcBalance.tokenizedDenom)
  }, [ibcBalance])

  const chainInfo = CHAIN_INFO_LIST[denom]

  const formattedTokenValue = `${formatMicroDenom(
    ibcBalance.amount,
    chainInfo.stakeCurrency.coinMinimalDenom,
    5
  )} ${denom}`

  const handleContinue = () => {
    resumeLsmIbc({
      amount: Number(convertMicroDenomToDenom(ibcBalance.amount, chainInfo.stakeCurrency.coinMinimalDenom)),
      validatorAddress,
      ibcDenom: ibcBalance.ibcDenom
    })
  }

  const handleRevert = () => {
    withdrawLsmIbc({
      amount: ibcBalance.amount,
      ibcDenom: ibcBalance.ibcDenom,
      tokenizedDenom: ibcBalance.tokenizedDenom
    })
  }

  // If an lsm liquid stake transaction exists in anyway, we want to hide this card to avoid confusion.
  const hasRecentAttemptsToLiquidStake = Boolean(lsmLiquidStakeMeta)

  // LSMLiquidStake depends on eligibleAirdropsError to work properly. If it's loading or unavailable
  // we want to make sure we're blocking an informed user from proceeding.
  const tooltipMessage = eligibleAirdropsError
    ? 'We have trouble calculating your gas amount. Please refresh the page.'
    : ''

  return (
    <Collapse in={!hasRecentAttemptsToLiquidStake}>
      <TransactionCard
        status="warning"
        title="You have an unfinished LSM liquid stake"
        description="Looks like your last LSM liquid stake was interrupted.">
        <>
          <Space h="md" />

          <DataList>
            <DataListItem label="Amount" value={formattedTokenValue} bold={false} />
            <DataListItem label="Validator" value={<LsmValidatorName address={validatorAddress} />} bold={false} />
          </DataList>

          <Space h="md" />

          <Group position="apart">
            <Box />
            <Group spacing="xs">
              <Button onClick={handleRevert} variant="outline" color="dark">
                Revert
              </Button>

              <Tooltip label={tooltipMessage} disabled={!tooltipMessage} withArrow withinPortal>
                <Button onClick={handleContinue} loading={isEligibleAirdropsLoading} disabled={Boolean(tooltipMessage)}>
                  Continue liquid staking
                </Button>
              </Tooltip>
            </Group>
          </Group>
        </>
      </TransactionCard>
    </Collapse>
  )
}

export { LsmIbcTransferMetaDataCard }
