import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { assertIsDeliverTxSuccess, broadcastTx } from '@/wallet-utils'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { queryKeys } from '@/query-keys'
import { fatal, disregard } from '@/utils'
import { notify } from '@/contexts/notifications'
import { traceIbcStatus, IBCTransferStatus, isSafeModeAccount } from '@/wallet-utils'
import { MutationParameters } from './types'
import { useRefreshBalances, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'
import { flushSync } from 'react-dom'

export interface BroadcastWithdrawStTokenMutationReturnType {
  status: IBCTransferStatus
  transaction: DeliverTxResponse
}

const useBroadcastWithdrawTokenizedSharesMutation = ({
  withdrawStTokenRaw,
  setWithdrawStTokenTransaction
}: MutationParameters) => {
  const client = useQueryClient()

  const strideAccount = useStrideWallet()
  const selectedAccount = useSelectedWallet()
  const updateAccountBalances = useRefreshBalances()

  const handleMutation = async (): Promise<BroadcastWithdrawStTokenMutationReturnType> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!withdrawStTokenRaw) {
      throw fatal('Unable to broadcast empty signed transaction.')
    }

    const bytes = TxRaw.encode(withdrawStTokenRaw).finish()

    if (!strideAccount.client) {
      throw fatal('Stargate account could not be found.')
    }

    if (isSafeModeAccount(strideAccount)) {
      throw fatal('Safe mode is enabled.')
    }

    const transaction = await broadcastTx(strideAccount.client, bytes)

    assertIsDeliverTxSuccess(transaction)

    const status = await traceIbcStatus({ account: strideAccount, tx: transaction })

    // @TODO: Handle if ibc status === 'timeout'. We likely need a flow for this.
    return { status, transaction }
  }

  const handleSuccess = async ({ transaction }: BroadcastWithdrawStTokenMutationReturnType) => {
    flushSync(() => {
      setWithdrawStTokenTransaction(transaction)
    })

    if (!strideAccount || !selectedAccount) {
      // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
      // But we'll return anyway instead of crashing just to be safe (given the unknowns).
      // https://github.com/Stride-Labs/interface/issues/262
      return
    }

    try {
      await updateAccountBalances()
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }

    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryLsmIbcBalancesBase })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

export { useBroadcastWithdrawTokenizedSharesMutation }
