import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { queryKeys } from '@/query-keys'
import { fatal, disregard } from '@/utils'
import { notify } from '@/contexts/notifications'
import {
  assertIsDeliverTxSuccess,
  traceIbcStatus,
  IBCTransferStatus,
  isSafeModeAccount,
  broadcastTx
} from '@/wallet-utils'
import { MutationParameters } from './types'
import { useRefreshStrideBalances, useStrideWallet } from '@/contexts/wallet'
import { flushSync } from 'react-dom'

export interface BroadcastStakeToWithdrawStTokenToDexMutationReturnType {
  status: IBCTransferStatus
  transaction: DeliverTxResponse
}

const useBroadcastWithdrawStTokenToDexMutation = ({
  withdrawStTokenToDexRaw,
  setWithdrawStTokenToDexTransaction
}: MutationParameters) => {
  const client = useQueryClient()

  const strideAccount = useStrideWallet()

  const refreshStrideBalances = useRefreshStrideBalances()

  const handleMutation = async (): Promise<BroadcastStakeToWithdrawStTokenToDexMutationReturnType> => {
    if (!strideAccount || !strideAccount.client) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!withdrawStTokenToDexRaw) {
      throw fatal('Unable to broadcast empty signed transaction.')
    }

    if (isSafeModeAccount(strideAccount)) throw fatal('Safe mode is enabled.')

    const bytes = TxRaw.encode(withdrawStTokenToDexRaw).finish()

    // We'll store hash twice; variable comes in useful
    // so that we still hold reference to the transaction object
    // when transactionRef gets reset (e.g., user closes the modal which
    // in turn sets the ref to null)
    const transaction = await broadcastTx(strideAccount.client, bytes)

    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })

    assertIsDeliverTxSuccess(transaction)

    const status = await traceIbcStatus({ account: strideAccount, tx: transaction })

    try {
      await refreshStrideBalances()
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }

    // @TODO: Handle if ibc status === 'timeout'. We likely need a flow for this.
    return { status, transaction }
  }

  const handleSuccess = async ({ transaction }: { status: IBCTransferStatus; transaction: DeliverTxResponse }) => {
    flushSync(() => {
      setWithdrawStTokenToDexTransaction(transaction)
    })

    await client.invalidateQueries({
      queryKey: queryKeys.transactionHistoryIbcStatusByHash({ hash: transaction.transactionHash })
    })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

export { useBroadcastWithdrawStTokenToDexMutation }
