import React from 'react'
import { useAtomValue } from 'jotai'
import { Anchor, Box, Button, Space, Text } from '@mantine/core'

import { useMount } from '@/hooks'
import { StepperModalContent, TextLoader, useStepperContext } from '@/components'
import { claimMetaDataAtom } from '@/page-components/Stake/atoms'
import { useSelectedCoin } from '@/contexts/wallet'
import { CHAIN_SUPPORTS_ADD_TO_DEX, DEX_CONFIG, DEX_INFO_LIST } from '@/config'
import { useStakeStatsQuery } from '@/queries'
import { AnalyticsTracker } from '@/page-components/shared'
import { useUnstakeModal } from './UnstakeModalProvider'
import { STAKING_STEP_FOUR, UNSTAKE_TRANSACTION_BEGIN_LEARN_MORE_URL } from './constants'

const UnstakeModalStepOne: React.FC = () => {
  const { start, nextStep, jumpStep, close, forceClose } = useStepperContext()

  const { signRedeemStake, isSigningRedeemStake, signRedeemStakeError } = useUnstakeModal()

  const denom = useSelectedCoin()

  const stakedDenom = `st${denom}`

  const claimMetaData = useAtomValue(claimMetaDataAtom)

  const { data: stakeStats, isLoading: isStakeStatsLoading } = useStakeStatsQuery()

  const getUnboundingPeriod = () => {
    if (stakeStats == null) return '21-24 days'
    const { start, end, unit } = stakeStats[denom].unbondingPeriod
    return `${start}-${end} ${unit}`
  }

  const handleStart = async () => {
    start()

    await signRedeemStake()

    nextStep()
  }

  const handleJump = () => {
    start()
    jumpStep(STAKING_STEP_FOUR)
  }

  useMount(() => {
    if (claimMetaData) handleJump()
  })

  const handleRetrySignStakeTokens = () => {
    handleStart()
  }

  if (signRedeemStakeError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="Your tokens couldn’t be unstaked at this time. Try again in a few minutes."
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" onClick={handleRetrySignStakeTokens}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningRedeemStake) {
    return (
      <StepperModalContent
        title="Confirm the transaction in your wallet to continue"
        description={`This will start the transfer of your ${stakedDenom} tokens out of your wallet and start the unstaking process`}
      />
    )
  }

  const dexConfig = DEX_CONFIG[denom]

  const dexInfo = dexConfig && DEX_INFO_LIST[dexConfig.type]

  // @TODO: Maybe display a different message if we "Add to DEX" is not a viable flow
  // (e.g., does not support ADD to DEX, does not have a dex info, or a dex account)
  return (
    <>
      <StepperModalContent
        title="In a rush? Unstaking will take about 3 weeks to complete."
        description={
          <>
            <Box>
              If you'd like access to your {denom} sooner, you can trade {stakedDenom} directly on a DEX like Osmosis.{' '}
              <Anchor href={UNSTAKE_TRANSACTION_BEGIN_LEARN_MORE_URL} target="_blank">
                Learn more
              </Anchor>
            </Box>

            <Space h="xs" />

            <TextLoader loading={isStakeStatsLoading}>
              <Box>
                Once you start the unbonding process,{' '}
                <Text weight={600} sx={{ display: 'inline ' }}>
                  you won't be able to cancel it
                </Text>{' '}
                and your tokens will stay locked for {getUnboundingPeriod()}.
              </Box>
            </TextLoader>
          </>
        }
        actions={
          <>
            {CHAIN_SUPPORTS_ADD_TO_DEX[denom] && dexConfig && dexInfo ? (
              <Button
                component="a"
                target="_blank"
                href={dexInfo.poolUrl(dexConfig.poolId)}
                variant="outline"
                color="dark">
                Go to {dexInfo.name}
              </Button>
            ) : (
              <Button variant="outline" color="dark" onClick={close}>
                Cancel
              </Button>
            )}

            <Button onClick={handleStart}>Unstake</Button>
          </>
        }
      />

      <AnalyticsTracker eventName="redeem_start" />
    </>
  )
}

export { UnstakeModalStepOne }
