import { MsgLiquidStakeSDKType as TiaMsgLiquidStake } from 'stridejs/types/codegen/stride/staketia/tx'
import { MetaDataHandlerParameters } from './types'
import { TIA_CHAIN_INFO } from '@/config'

const handleTiaLiquidStake = ({
  response,
  message,
  selectedAccount,
  strideAccount
}: MetaDataHandlerParameters<TiaMsgLiquidStake>): boolean => {
  // We'll exclude liquid stake tx that was committed but failed and
  // ones that did not come from the selected chain. We'll also make sure
  // to only display this transaction if DYM is the selected chain
  return (
    response.code === 0 &&
    message.staker === strideAccount.address &&
    selectedAccount.currency.coinDenom === TIA_CHAIN_INFO.stakeCurrency.coinDenom
  )
}

export { handleTiaLiquidStake }
