import { useQuery, useQueryClient } from '@tanstack/react-query'
import { z } from 'zod'
import { disregard, fatal, throttle } from '@/utils'
import { TxQueryResponse, TxResponse } from '@/wallet-utils'
import axios from 'axios'
import { useEffect, useMemo } from 'react'
import { STRIDE_CHAIN_INFO } from '@/config'
import { getLsmLiquidStakeAttributes, lsmStatusSchema } from '../../utils'
import { queryKeys } from '@/query-keys'
import { useStake } from '../../StakeProvider'
import { useStakeLsmModal } from '../StakeLsmModalProvider'
import { notify } from '@/contexts/notifications'
import { useRefreshBalances, useStrideWallet } from '@/contexts/wallet'

interface LiquidStakeQueryCallbackQueryPayload {
  transaction: TxResponse | null
  status: z.infer<typeof lsmStatusSchema>
}

// Fetch LSMLiquidStake's query callback which will contain updated status about the transaction
// This works by getting the `lsm_liquid_stake_tx_id` attribute from `LSMLiquidStake`'s transaction events
// and polling its events until the transaction is no longer pending.
const useLiquidStakeQueryCallbackQuery = () => {
  const strideAccount = useStrideWallet()
  const updateAccountBalances = useRefreshBalances()

  const client = useQueryClient()

  const { broadcastLiquidStakeData } = useStakeLsmModal()

  const { lsm } = useStake()

  const queryCallbackId = useMemo(() => {
    return broadcastLiquidStakeData?.status === 'pending'
      ? getLsmLiquidStakeAttributes(broadcastLiquidStakeData.transaction.events).lsm_liquid_stake_tx_id
      : ''
  }, [broadcastLiquidStakeData])

  const handleRequest = async (): Promise<LiquidStakeQueryCallbackQueryPayload> => {
    if (!strideAccount) {
      throw fatal('Unable to stake without connecting your wallet.')
    }

    if (!broadcastLiquidStakeData) {
      throw fatal('Missing data from `useBroadcastLiquidStakeMutation`.')
    }

    const txUrlParameters = new URLSearchParams()
    txUrlParameters.append('events', `lsm_liquid_stake.lsm_liquid_stake_tx_id='${queryCallbackId}'`)

    const instance = axios.create({
      baseURL: STRIDE_CHAIN_INFO.rest
    })

    // Throttle so we guarantee that the safety check text shows for at least 3 seconds.
    // In the future, let's make it so this only happens the first refetch.
    const response = await throttle(
      () => instance.get<TxQueryResponse>(`cosmos/tx/v1beta1/txs?${txUrlParameters}`),
      3000
    )

    // We *expect* the query above to return two transactions: the original transaction and the query callback
    const queryCallback = response.data.tx_responses.find((response) => {
      return response.txhash !== broadcastLiquidStakeData.transaction.transactionHash
    })

    if (queryCallback == null) {
      // This may happen if the query callback hasn't been made, due to relayer going down or being overloaded.
      // To "simulate" this on Dockernet, liquid stake twice to the same validator. Afterwards, pause the gaia relayer,
      // then liquid stake again. Just log inside the if condition to confirm you're getting here. For reference, our
      // guides / Sam should know more about this.
      return { transaction: null, status: 'pending' }
    }

    const attributes = getLsmLiquidStakeAttributes(queryCallback.events)

    return { transaction: queryCallback, status: attributes.transaction_status }
  }

  const query = useQuery({
    queryKey: queryKeys.lsmLiquidStakeQueryCallbackByHash({ hash: queryCallbackId }),
    queryFn: handleRequest,
    enabled: Boolean(strideAccount && lsm.isOpen && queryCallbackId),
    refetchInterval: (query) => {
      return query.state.data?.status === 'pending' ? 30_000 : false
    }
  })

  useEffect(() => {
    async function invalidateQueriesOnSettle() {
      if (query.data == null) {
        return
      }

      if (query.data.status === 'pending') {
        return
      }

      await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryLsmIbcBalancesBase })

      await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })

      await client.invalidateQueries({
        queryKey: queryKeys.transactionHistoryLsmLiquidStakeQueryCallbackByHash({ hash: queryCallbackId })
      })

      if (!strideAccount) {
        // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
        // But we'll return anyway instead of crashing just to be safe (given the unknowns).
        // https://github.com/Stride-Labs/interface/issues/262
        return
      }

      try {
        await updateAccountBalances()
      } catch (e) {
        disregard(e)
        notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      }
    }

    invalidateQueriesOnSettle()
  }, [query.data])

  return query
}

export { useLiquidStakeQueryCallbackQuery }
