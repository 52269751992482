import React from 'react'
import { Button } from '@mantine/core'
import { useAtomValue } from 'jotai'

import { StepperModalContent, useStepperContext } from '@/components'
import { useLatestValue, useMount } from '@/hooks'
import { notify } from '@/contexts/notifications'
import { stakeAutopilotModalIsOpenAtom } from '../atoms'
import { useStakeAutopilotModal } from './StakeAutopilotModalProvider'
import { useSelectedCoin } from '@/contexts/wallet'
import { StandardTransactionError } from '@/wallet-utils'

const StakeAutopilotModalStepTwo: React.FC = () => {
  const {
    broadcastStakeAutopilot,
    isBroadcastingStakeAutopilot,
    broadcastStakeAutopilotError,
    traceStakeAutopilotStatus,
    isStakeAutopilotStatusLoading
  } = useStakeAutopilotModal()

  const { complete, forceClose, handleClose } = useStepperContext()

  const denom = useSelectedCoin()

  const isOpen = useAtomValue(stakeAutopilotModalIsOpenAtom)

  const isOpenRef = useLatestValue(isOpen)

  const handleStep = async () => {
    const { status } = await broadcastStakeAutopilot()

    if (!isOpenRef.current) {
      // This function doesn't get cancelled when the modal gets closed.
      // If we get here when pool is already closed, it's likely that the stuff that
      // `traceIbcStatus` (transactionRef) needs has already been reset by the
      // modal close handler.
      return
    }

    if (status === 'return-later') {
      // If we're here, it means that our ibc transfer took too long and we're tracking it
      // the second time without the 30-second timeout. This way, the user can continue the
      // flow if it they haven't closed.
      //
      // We can check for the return-later status by checking using `isIbcStatusLoading`
      // to check if the ibc transfer hasn't completed and ibc tracking is still on-going.
      //
      // @TODO: Handle timeout state if this doesn't succeed. The original implementation
      // from the ibc/staking modal doesn't have this yet neither. Add display states for
      // `status === 'timeout'` for both broadcast and tracing and `ibcStatusError`
      await traceStakeAutopilotStatus()
    }

    complete()
  }

  const handleCloseCallback = () => {
    // If it was closed after the broadcast, we don't need to show the toast.
    if (!isBroadcastingStakeAutopilot && !isStakeAutopilotStatusLoading) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the transfer completes.")
  }

  useMount(() => {
    handleStep()
  })

  handleClose(handleCloseCallback)

  if (isStakeAutopilotStatusLoading) {
    return (
      <StepperModalContent
        title="This transaction is taking longer than usual"
        description={`If you don’t want to wait here, you can close this dialog. Your transaction will continue and you’ll be able to see status on the Stride app page.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
      />
    )
  }

  if (broadcastStakeAutopilotError instanceof StandardTransactionError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={broadcastStakeAutopilotError.description}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleStep}>
              Try again
            </Button>
          </>
        }
        error={broadcastStakeAutopilotError}
      />
    )
  }

  if (broadcastStakeAutopilotError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`This transfer could not be completed. Your tokens have not been moved.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleStep}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title={`Staking your ${denom}...`}
      description="This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the liquid stake will continue."
    />
  )
}

export { StakeAutopilotModalStepTwo }
