import React, { useMemo } from 'react'
import { Box, Space } from '@mantine/core'
import { STRIDE_CHAIN_INFO } from '@/config'
import { DataList, DataListItem, TransactionCard } from '@/components'
import { TiaRedemptionMetaData, formatMicroDenom, formatUnbondingRange } from '@/wallet-utils'

interface TiaRedemptionDataMetaCardProps {
  meta: TiaRedemptionMetaData
}

// Until Celestia suppports ICA, we'll use the `staketia` module which will auto-process
// all unbondings unlike `stakeibc` which needs to be manually claimed.
const TiaRedemptionMetaDataCard: React.FC<TiaRedemptionDataMetaCardProps> = ({ meta }) => {
  const formattedAmount = `${formatMicroDenom(meta.values.amount, meta.values.minimalDenom, 3)} ${meta.values.denom}`

  const estimatedAt = useMemo(() => {
    return formatUnbondingRange(meta.values.estimatedAt)
  }, [meta])

  return (
    <TransactionCard
      status="pending"
      title={`Unstaking ${formattedAmount}`}
      description={
        <span>
          You'll soon receive {formattedAmount} on {STRIDE_CHAIN_INFO.chainName} at{' '}
          <Box sx={{ display: 'inline', wordBreak: 'break-all' }}>{meta.values.receiver}.</Box>
        </span>
      }>
      <>
        <Space h="md" />

        <DataList>
          <DataListItem label="Estimated Complete Date" value={estimatedAt} />
        </DataList>
      </>
    </TransactionCard>
  )
}

export { TiaRedemptionMetaDataCard }
