import { IBCX_CURRENCY, IBCX_CONTRACTS, STAKED_IBCX_CURRENCY } from '@/config/ibcx'
import { fatal } from '@/utils'
import BigNumber from 'bignumber.js'
import { getIbcxPeripheryQueryClient } from './query-clients'
import { getIbcxSwapInfo, GetSwapInfoArgs } from './osmosis-pools-utils'

export const simulateIbcxLiquidStaking = async ({ inputAmount, pools }: Omit<GetSwapInfoArgs, 'isRedeem'>) => {
  const { burnSwapInfo, mintSwapInfo } = getIbcxSwapInfo({ inputAmount, pools })

  const peripheryQueryClient = await getIbcxPeripheryQueryClient()

  if (burnSwapInfo === undefined || mintSwapInfo === undefined || peripheryQueryClient === undefined) {
    throw fatal('Unable to simulate ibcx liquid staking')
  }

  const amount = new BigNumber(inputAmount).multipliedBy(`1e+${IBCX_CURRENCY.coinDecimals}`).toString()

  const burnExactAmountInResult = await peripheryQueryClient.simulateBurnExactAmountIn({
    coreAddr: IBCX_CONTRACTS.ibcx,
    inputAmount: amount,
    // Mostly, OSMO/x pair pools have the highest TVL, so we use OSMO as output asset.
    outputAsset: 'uosmo',
    swapInfo: burnSwapInfo
  })

  const burnExactAmountOutResult = await peripheryQueryClient.simulateBurnExactAmountOut({
    coreAddr: IBCX_CONTRACTS.ibcx,
    outputAsset: burnExactAmountInResult.swap_result_amount,
    swapInfo: burnSwapInfo
  })

  const mintExactAmountInResult = await peripheryQueryClient.simulateMintExactAmountIn({
    coreAddr: IBCX_CONTRACTS.stIbcx,
    inputAsset: burnExactAmountOutResult.swap_result_amount,
    swapInfo: mintSwapInfo
  })

  return {
    burnSwapInfo,
    mintSwapInfo,
    burnExactAmountOutResult,
    mintExactAmountInResult
  }
}

export const simulateStIbcxRedeem = async ({ inputAmount, pools }: Omit<GetSwapInfoArgs, 'isRedeem'>) => {
  const { burnSwapInfo, mintSwapInfo } = getIbcxSwapInfo({ inputAmount, pools, isRedeem: true })

  const peripheryQueryClient = await getIbcxPeripheryQueryClient()

  if (burnSwapInfo === undefined || mintSwapInfo === undefined || peripheryQueryClient === undefined)
    throw fatal('Unable to simulate stIbcx redeem')

  const multiplierValue = new BigNumber(`1e+${STAKED_IBCX_CURRENCY.coinDecimals}`)
  const amount = new BigNumber(inputAmount).multipliedBy(multiplierValue).toString()

  const burnExactAmountInResult = await peripheryQueryClient.simulateBurnExactAmountIn({
    coreAddr: IBCX_CONTRACTS.stIbcx,
    inputAmount: amount,
    // Mostly, OSMO/x pair pools have the highest TVL, so we use OSMO as output asset.
    outputAsset: 'uosmo',
    swapInfo: burnSwapInfo
  })

  const burnExactAmountOutResult = await peripheryQueryClient.simulateBurnExactAmountOut({
    coreAddr: IBCX_CONTRACTS.stIbcx,
    outputAsset: burnExactAmountInResult.swap_result_amount,
    swapInfo: burnSwapInfo
  })

  const mintExactAmountInResult = await peripheryQueryClient.simulateMintExactAmountIn({
    coreAddr: IBCX_CONTRACTS.ibcx,
    inputAsset: burnExactAmountOutResult.swap_result_amount,
    swapInfo: mintSwapInfo
  })

  return {
    burnSwapInfo,
    mintSwapInfo,
    burnExactAmountOutResult,
    mintExactAmountInResult
  }
}
