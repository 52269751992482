import React from 'react'
import { useAtomValue } from 'jotai'
import { Anchor, Box, Button, Space } from '@mantine/core'

import { StepperModalContent, useStepperContext } from '@/components'
import { CHAIN_INFO_LIST } from '@/config'
import { useSelectedCoin } from '@/contexts/wallet'
import { StandardSimulationError, formatMicroDenom } from '@/wallet-utils'
import { AnalyticsTracker } from '@/page-components/shared'
import { RATE_LIMIT_DOCS_URL } from '../constants'
import { useRateLimitQuery } from '../queries'
import { getRateLimitMetaData } from '../utils'
import { RateLimitTimeUntilReset } from '../shared'
import { withdrawStTokenAmountAtom } from '../atoms'
import { useWithdrawStToken } from './WithdrawStTokenContext'

const WithdrawStTokenStepOne: React.FC = () => {
  const { signWithdrawStToken, isSigningWithdrawStToken, signWithdrawStTokenError, resetWithdrawStTokenState } =
    useWithdrawStToken()

  const amount = useAtomValue(withdrawStTokenAmountAtom)

  const { data: rateLimit } = useRateLimitQuery()

  const rateLimitMetaData = getRateLimitMetaData({ amount, rateLimit })

  const { start, nextStep, forceClose } = useStepperContext()

  const denom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const handleSign = async (value: string) => {
    start()
    await signWithdrawStToken(value)
    nextStep()
  }

  const handleStart = async () => {
    handleSign(amount)
  }

  const handleWithdrawDifference = () => {
    handleSign(rateLimitMetaData.difference.toString())
  }

  const handleRetry = () => {
    if (rateLimitMetaData.status === 'closed') {
      resetWithdrawStTokenState()
    } else if (rateLimitMetaData.status === 'tight') {
      handleWithdrawDifference()
    } else {
      handleStart()
    }
  }

  if (
    signWithdrawStTokenError instanceof StandardSimulationError &&
    signWithdrawStTokenError.description === 'RATE_LIMITING'
  ) {
    return (
      <StepperModalContent
        title="Transaction failed due to rate limiting."
        description={
          <>
            <Box>
              You can try again now or come back once the rate limits have been reset. You can learn more about this{' '}
              <Anchor href={RATE_LIMIT_DOCS_URL} target="_blank">
                here
              </Anchor>
              .
            </Box>

            <Space h="xs" />

            <Box>
              <RateLimitTimeUntilReset />
            </Box>

            <Space h="xs" />
          </>
        }
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Close
            </Button>

            <Button onClick={handleRetry}>Try again</Button>
          </>
        }
      />
    )
  }

  if (signWithdrawStTokenError instanceof StandardSimulationError) {
    return (
      <StepperModalContent
        // User must fund the entire transaction with IBC and has no tokens on Stride
        title={`Transaction error`}
        description={signWithdrawStTokenError.message}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetry}>
              Try again
            </Button>
          </>
        }
        error={signWithdrawStTokenError}
      />
    )
  }

  if (signWithdrawStTokenError) {
    return (
      <StepperModalContent
        // User must fund the entire transaction with IBC and has no tokens on Stride
        title={`Transaction error`}
        description="This transfer could not be completed. Your tokens have not been moved."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleRetry}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningWithdrawStToken) {
    return (
      <StepperModalContent
        // User must fund the entire transaction with IBC and has no tokens on Stride
        title={`Approve the transaction in your wallet to continue`}
        description={`This will start the transfer of your st${denom} tokens to your ${chainInfo.chainName} wallet.`}
      />
    )
  }

  if (rateLimitMetaData.status === 'closed') {
    return (
      <StepperModalContent
        title="Rate limits have been reached"
        description={
          <>
            <Box>
              You’re unable to withdraw any stATOM at this point in time, as the rate limits have been reached. You can
              learn more about this{' '}
              <Anchor href={RATE_LIMIT_DOCS_URL} target="_blank">
                here
              </Anchor>
              .
            </Box>

            <Space h="xs" />

            <Box>
              <RateLimitTimeUntilReset />
            </Box>
          </>
        }
        actions={<Button onClick={forceClose}>Close</Button>}
      />
    )
  }

  if (rateLimitMetaData.status === 'tight') {
    return (
      <StepperModalContent
        title="Your withdrawal amount exceeds the rate limits"
        description={
          <>
            <Box>
              You can learn more about this{' '}
              <Anchor href={RATE_LIMIT_DOCS_URL} target="_blank">
                here
              </Anchor>
              . Do you want to withdraw as much as you can now anyways?
            </Box>

            <Space h="xs" />

            <Box>
              Available:{' '}
              <strong>
                {formatMicroDenom(rateLimitMetaData.difference, chainInfo.stakeCurrency.coinMinimalDenom, 5)} st{denom}
              </strong>
            </Box>

            <Space h="xs" />

            <Box>
              <RateLimitTimeUntilReset />
            </Box>
          </>
        }
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Cancel
            </Button>

            <Button onClick={handleWithdrawDifference}>Withdraw Available</Button>
          </>
        }
      />
    )
  }

  return (
    <>
      <StepperModalContent
        // User must fund the entire transaction with IBC and has no tokens on Stride
        title={`You’re about to withdraw ${formatMicroDenom(
          amount,
          chainInfo.stakeCurrency.coinMinimalDenom,
          3
        )} st${denom} from Stride`}
        description={`This will move tokens from your balance on the Stride app to your ${chainInfo.chainName} wallet.`}
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Cancel
            </Button>

            <Button onClick={handleStart}>Withdraw</Button>
          </>
        }
      />

      <AnalyticsTracker eventName="withdraw_st_token_start" />
    </>
  )
}

export { WithdrawStTokenStepOne }
