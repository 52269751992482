import { TX_MSG, calculateFee, isSafeModeAccount } from '@/wallet-utils'
import { assert } from '@/utils'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { useMutation } from '@tanstack/react-query'
import { useStake } from '../../StakeProvider'
import { MutationParameters } from './types'
import { useSelectedWallet } from '@/contexts/wallet'
import { flushSync } from 'react-dom'

const useSignRedeemTokenForSharesMutation = ({ setRedeemTokensRaw }: MutationParameters) => {
  const selectedAccount = useSelectedWallet()

  const { withdrawLsm } = useStake()

  const handleMutation = async (): Promise<TxRaw> => {
    assert(selectedAccount, 'Unable to redeem tokens for shares without connecting your selected wallet.')
    assert(!isSafeModeAccount(selectedAccount), 'Unable to redeem tokens for shares while safe mode is enabled.')

    // The user has the option to reverts from either a TokenizeShares or LSM IBC transaction
    const tokenizedDenomToRedeem = withdrawLsm.txHistoryIbcTokenizedDenom || withdrawLsm.txHistoryTokenizedDenom

    const msgRedeemTokensForShares = {
      typeUrl: TX_MSG.MsgRedeemTokensForShares,
      value: {
        amount: {
          amount: withdrawLsm.amount,
          denom: tokenizedDenomToRedeem
        },
        delegatorAddress: selectedAccount.address
      }
    }

    const gas = await selectedAccount.client.simulate(selectedAccount.address, [msgRedeemTokensForShares], '')
    const fee = calculateFee(selectedAccount, gas)

    return await selectedAccount.client.sign(selectedAccount.address, [msgRedeemTokensForShares], fee, '')
  }

  const handleSuccess = (raw: TxRaw) => {
    flushSync(() => {
      setRedeemTokensRaw(raw)
    })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  });
}

export { useSignRedeemTokenForSharesMutation }
