import React from 'react'
import { Box, Group, Image, Stack, Text, UnstyledButton } from '@mantine/core'
import { useSelectedCoin } from '@/contexts/wallet'
import {
  ChainIcon,
  TiaAirdropSwapBannerModal,
  TiaAirdropSwapBannerProvider,
  useTiaAirdropSwapBanner
} from '@/page-components/shared'
import { TIA_CHAIN_INFO } from '@/config'
import { useMediumScreen } from '@/hooks'

const StakeTiaSwapBannerContent: React.FC = () => {
  const denom = useSelectedCoin()

  const { open } = useTiaAirdropSwapBanner()

  const { isMediumScreen } = useMediumScreen()

  if (denom !== 'TIA') {
    return null
  }

  return (
    <UnstyledButton onClick={open}>
      <Box
        sx={(t) => ({
          position: 'relative',
          background: '#5B002C',
          overflow: 'hidden',
          borderRadius: t.radius.md
        })}>
        <Box sx={{ position: 'absolute', right: 24, top: -32 }}>
          <Box sx={{ width: 190, flexShrink: 0 }}>
            <Image src="/banners/tia-bg.svg" />
          </Box>
        </Box>

        <Stack justify="space-between" p="md" sx={{ height: isMediumScreen ? 143 : 'auto' }}>
          <Box>
            <Text color="white" size="md" weight={600}>
              Swap 
              <TiaAirdropSwapBannerAsset denom="ETH" label="ETH" /> or{' '}
              <TiaAirdropSwapBannerAsset denom="axlUSDC" label="USDC" /> for st{TIA_CHAIN_INFO.stakeCurrency.coinDenom}
            </Text>

            <Text sx={(t) => ({ color: t.colors.gray[5] })}>Just a few clicks. Right here within the app.</Text>
          </Box>

          <Text sx={{ color: '#FF3D8E' }} inline component="span" weight={600}>
            Swap now &ndash;&gt;
          </Text>
        </Stack>
      </Box>
    </UnstyledButton>
  )
}

interface TiaAirdropSwapBannerAssetProps {
  denom: string
  label: string
}

const TiaAirdropSwapBannerAsset: React.FC<TiaAirdropSwapBannerAssetProps> = ({ denom, label }) => {
  return (
    <Box sx={{ display: 'inline-block', transform: 'translateY(4px)' }}>
      <Group spacing={4}>
        <ChainIcon denom={denom} size={20} />
        <Text size="md" component="span">
          {label}
        </Text>
      </Group>
    </Box>
  )
}

const StakeTiaSwapBanner: React.FC = () => {
  return (
    <TiaAirdropSwapBannerProvider>
      <StakeTiaSwapBannerContent />
      <TiaAirdropSwapBannerModal />
    </TiaAirdropSwapBannerProvider>
  )
}

export { StakeTiaSwapBanner }
