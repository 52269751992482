import React from 'react'
import { Box, Button, Group, Space, Text, Title } from '@mantine/core'
import { useLsmValidatorsQuery } from '../queries'
import { useSelectedCoin, useWallet } from '@/contexts/wallet'

const LsmTutorialWalletConnectStep = () => {
  const { connect, isFetchingStrideAccount, isFetchingSelectedAccount } = useWallet()

  const { isLoading: isLsmValidatorsLoading } = useLsmValidatorsQuery()

  const denom = useSelectedCoin()

  return (
    <Box sx={{ maxWidth: 320 }}>
      <Title order={4} sx={(t) => ({ colors: t.colors.gray[9], lineHeight: 1 })}>
        Connect your wallet first
      </Title>

      <Space h="xs" />

      <Text sx={(t) => ({ color: t.colors.gray[7] })}>
        You need to connect your wallet to get started turning your natively staked {denom} into st{denom}.
      </Text>

      <Space h="md" />

      <Group spacing="xs">
        <Button
          onClick={() => connect()}
          loading={isFetchingStrideAccount || isFetchingSelectedAccount || isLsmValidatorsLoading}>
          Connect wallet
        </Button>
      </Group>
    </Box>
  )
}

export { LsmTutorialWalletConnectStep }
