import { SelectedCoinDenom } from '@/wallet-utils'
import { queryKeys } from '@/query-keys'
import { useQuery } from '@tanstack/react-query'
import { redis } from '@/redis'
import { fatal } from '@/utils'
import { useSelectedWallet } from '@/contexts/wallet'

const mapping: Partial<Record<SelectedCoinDenom, string>> = {
  ATOM: 'GAIA'
}

interface LsmValidatorPersonaQueryParameters {
  address?: string
}

interface LsmValidatorPersona {
  name: string
  logo: string
}

// @TODO: Make it so query cache is never garbage collected
const useLsmValidatorPersonaQuery = ({ address }: LsmValidatorPersonaQueryParameters) => {
  const selectedAccount = useSelectedWallet()

  const queryFn = async (): Promise<LsmValidatorPersona> => {
    if (!selectedAccount) {
      throw fatal('Unable to query lsm validator persona while disconnected.')
    }

    const denomRedisKey = mapping[selectedAccount.currency.coinDenom] ?? selectedAccount.currency.coinDenom

    const [name, logo] = await Promise.all([
      // sheet_GAIA_LSM_ID_cosmos1c4k24jzduc365kywrsvf5ujz4ya6mwymy8vq4q
      redis.get<string>(`sheet_${denomRedisKey}_LSM_ID_${address}`),
      // sheet_GAIA_LSM_LOGO_cosmos1c4k24jzduc365kywrsvf5ujz4ya6mwymy8vq4q
      redis.get<string>(`sheet_${denomRedisKey}_LSM_LOGO_${address}`)
    ])

    return { name: name ?? '', logo: logo ?? '' }
  }

  return useQuery({
    queryKey: queryKeys.lsmValidatorPersonaByAddress({ address: address ?? '' }),
    queryFn,
    enabled: Boolean(selectedAccount && address)
  })
}

export { useLsmValidatorPersonaQuery }
