import React, { useMemo } from 'react'
import { Button } from '@mantine/core'
import { useFormContext } from '@/components'

import { UnstakeFormType } from './useUnstakeIbcxForm'
import { useChainWallet, useWallet } from '@/contexts/wallet'

const UnstakeIbcxButton: React.FC = () => {
  const { data: osmosisAccount } = useChainWallet('OSMO')

  const { errors } = useFormContext<UnstakeFormType>()

  const { connect, isFetchingStrideAccount: isConnectingWallet, isFetchingSelectedAccount } = useWallet()

  const isDisabled = useMemo(() => {
    if (osmosisAccount == null) return true

    return Boolean(errors.amount)
  }, [osmosisAccount, errors])

  if (!osmosisAccount) {
    return (
      <Button
        fullWidth
        size="lg"
        radius="md"
        onClick={() => connect()}
        loading={isConnectingWallet || isFetchingSelectedAccount}>
        Connect Wallet
      </Button>
    )
  }

  return (
    <Button type="submit" fullWidth size="lg" radius="md" disabled={isDisabled}>
      Unstake
    </Button>
  )
}

export { UnstakeIbcxButton }
