import React, { useMemo } from 'react'
import { Button, Group, Text, Space, Tooltip, Collapse } from '@mantine/core'
import { InlineLoader, TransactionCard, TransactionCardStatus, DataList, DataListItem } from '@/components'
import { CHAIN_INFO_LIST, STRIDE_CHAIN_INFO } from '@/config'
import {
  LsmLiquidStakeMetaData,
  LsmLiquidStakeStatus,
  convertMicroDenomToDenom,
  formatDenom,
  formatMicroDenom
} from '@/wallet-utils'
import { useSimpleCountdown } from '@/hooks'
import { useEligibleAirdropsQuery, isTiaHostZone } from '@/queries'
import { useHostZoneQuery } from '../queries'
import { LsmValidatorName } from '../shared'
import { useLsmLiquidStakeQueryCallbackQuery } from './queries'
import { useStake } from '../StakeProvider'
import { ViewMintscanButton } from './shared'

interface LsmLiquidStakeMetaDataCardProps {
  meta: LsmLiquidStakeMetaData
  // For context, from what I recall, we want to allow dismiss because users
  // can essentially use the transaction card for LsmIbc to retry.
  onDismiss: (id: string) => void
}

type LsmLiquidStakeTransactionCardStatus = LsmLiquidStakeStatus | 'checking-failed'

const LsmLiquidStakeMetaDataCard: React.FC<LsmLiquidStakeMetaDataCardProps> = ({ meta, onDismiss }) => {
  const {
    data: lsmLiquidStakeQueryCallback,
    error: lsmLiquidStakeQueryCallbackError,
    refetch: refetchLsmLiquidStakeQueryCallback
  } = useLsmLiquidStakeQueryCallbackQuery({ meta })

  const { data: hostZone, isLoading: isHostZoneLoading } = useHostZoneQuery()

  const sharesToTokensRate = useMemo(() => {
    if (hostZone == null) {
      return 1
    }

    // This initially happens because of how poorly Next.js handles route queries. What happens is we use
    // the default value (TIA) until Next gives us an actual value (ATOM). This means queries are already running for TIA,
    // and then refetching again for ATOM. To add, for some reason, the queries take a while to transition, probably due to
    // the different query keys and dependencies. This is a temporary fix to prevent the app from crashing. But to clarify,
    // this should be a very temporary scenario until the queries have updated/reset.
    if (isTiaHostZone(hostZone)) {
      return 1
    }

    const validator = hostZone?.validators.find((validator) => {
      return validator.address === meta.values.validatorAddress
    })

    if (!validator) {
      return 1
    }

    return Number(validator.shares_to_tokens_rate)
  }, [hostZone, meta])

  const transactionStatus: LsmLiquidStakeTransactionCardStatus = useMemo(() => {
    if (lsmLiquidStakeQueryCallbackError) return 'checking-failed'
    return meta.values.status === 'pending' ? lsmLiquidStakeQueryCallback?.status ?? 'pending' : meta.values.status
  }, [meta, lsmLiquidStakeQueryCallback, lsmLiquidStakeQueryCallbackError])

  const chainInfo = CHAIN_INFO_LIST[meta.values.denom]

  const amountInDenom = convertMicroDenomToDenom(meta.values.amount, chainInfo.stakeCurrency.coinMinimalDenom)

  const formattedTokenValue = `${formatDenom(Number(amountInDenom) * sharesToTokensRate, 3)} ${meta.values.denom}`

  const formattedStakedAmount = `${formatMicroDenom(
    meta.values.stTokenAmount,
    chainInfo.stakeCurrency.coinMinimalDenom,
    5
  )} st${meta.values.denom}`

  const titles: Record<LsmLiquidStakeTransactionCardStatus, string> = {
    'checking-failed': `Unable to check your validator's status`,
    pending: `Double checking your validator for safety`,
    failed: `Your validator got slashed`,
    success: `${formattedTokenValue} staked!`
  }

  const description: Record<LsmLiquidStakeTransactionCardStatus, React.ReactNode> = {
    'checking-failed': '',
    pending: '',
    failed: `Don't worry though, your funds are safe! Just press retry in a few moments to finish liquid staking.`,
    success: (
      <>
        You liquid staked {isHostZoneLoading ? <InlineLoader /> : formattedTokenValue} on {STRIDE_CHAIN_INFO.chainName}{' '}
        and {formattedStakedAmount} has been added to your wallet.
      </>
    )
  }

  const status: Record<LsmLiquidStakeTransactionCardStatus, TransactionCardStatus> = {
    'checking-failed': 'checking-failed',
    pending: 'checking-expanded',
    success: 'complete',
    failed: 'warning'
  }

  const content: Record<LsmLiquidStakeTransactionCardStatus, React.ReactElement> = {
    'checking-failed': (
      <>
        <Text sx={(t) => ({ color: t.colors.gray[7] })}>
          We were unable to check the status of this liquid stake transaction.
        </Text>

        <Space h="md" />

        <Group position="right">
          <Button onClick={() => refetchLsmLiquidStakeQueryCallback()}>Retry</Button>
        </Group>
      </>
    ),

    pending: (
      <>
        <Space h="md" />
        <DataList spacing="sm">
          <DataListItem label="Amount" value={formattedTokenValue} bold={false} />
          <DataListItem
            label="Validator"
            value={<LsmValidatorName address={meta.values.validatorAddress} />}
            bold={false}
          />
          <DataListItem label="Expected time" value="30 seconds" bold={false} />
        </DataList>
      </>
    ),

    failed: <LsmLiquidStakeFailedContent meta={meta} formattedAmount={formattedTokenValue} />,

    success: (
      <>
        <Space h="md" />

        <ViewMintscanButton denom="STRD" hash={meta.values.hash} />
      </>
    )
  }

  return (
    <TransactionCard
      status={status[transactionStatus]}
      title={titles[transactionStatus]}
      onDismiss={() => onDismiss(meta.values.hash)}
      description={description[transactionStatus]}>
      <>{content[transactionStatus]}</>
    </TransactionCard>
  )
}

interface FailedContentProps {
  meta: LsmLiquidStakeMetaData
  formattedAmount: string
}

const LsmLiquidStakeFailedContent: React.FC<FailedContentProps> = ({ meta, formattedAmount }) => {
  const { isLoading: isEligibleAirdropsLoading, error: eligibleAirdropsError } = useEligibleAirdropsQuery()

  const { countdown } = useSimpleCountdown(30)

  const isCountingDown = countdown > 0

  const { retryLsmLs } = useStake()

  const handleRetry = () => {
    const chainInfo = CHAIN_INFO_LIST[meta.values.denom]

    retryLsmLs({
      amount: Number(convertMicroDenomToDenom(meta.values.amount, chainInfo.stakeCurrency.coinMinimalDenom)),
      validatorAddress: meta.values.validatorAddress,
      ibcDenom: meta.values.ibcDenom,
      sourceHash: meta.values.hash
    })
  }

  // LSMLiquidStake depends on eligibleAirdropsError to work properly. If it's loading or unavailable
  // we want to make sure we're blocking an informed user from proceeding.
  const tooltipMessage = eligibleAirdropsError
    ? 'We have trouble calculating your gas amount. Please refresh the page.'
    : ''

  return (
    <>
      <Space h="md" />

      <DataList spacing="sm">
        <DataListItem label="Amount" value={formattedAmount} bold={false} />

        <DataListItem
          label="Validator"
          value={<LsmValidatorName address={meta.values.validatorAddress} />}
          bold={false}
        />

        <Collapse in={isCountingDown}>
          <DataListItem label="Try again" value={`${countdown} seconds`} />
        </Collapse>
      </DataList>

      <Space h="md" />

      <Group position="right">
        {isCountingDown ? (
          <Button disabled>Retry</Button>
        ) : (
          <Tooltip label={tooltipMessage} disabled={!tooltipMessage} withArrow withinPortal>
            <Button onClick={handleRetry} disabled={Boolean(tooltipMessage)} loading={isEligibleAirdropsLoading}>
              Retry
            </Button>
          </Tooltip>
        )}
      </Group>
    </>
  )
}

export { LsmLiquidStakeMetaDataCard }
