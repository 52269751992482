import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { Anchor, Box, Card, Space, Stack, Text, Title, Tooltip } from '@mantine/core'
import {
  CollapsibleStatsItem,
  CollapsibleStatsItemAnchor,
  CollapsibleStatsList,
  DataListItem,
  Icon,
  TextLoader
} from '@/components'
import { config, getDexInfoFromDenom, DEX_INFO_LIST, STRIDE_CHAIN_INFO, CHAIN_SUPPORTS_TVL_STAT } from '@/config'
import { useSmallScreen } from '@/hooks'
import { useStakeStatsQuery } from '@/queries'
import { useHostZoneQuery } from '../queries'
import { StakeStatMarketRate } from './StakeStatMarketRate'
import { StakeStatProtocolRedemptionRate } from './StakeStatProtocolRedemptionRate'
import { useSelectedCoin } from '@/contexts/wallet'
import { StakeStatTvl } from './StakeStatTvl'

const StakeStats: React.FC = () => {
  const denom = useSelectedCoin()

  // For display purposes, we'll default to Osmosis if the denom has no DEX information.
  const dexInfo = useMemo(() => getDexInfoFromDenom(denom) ?? DEX_INFO_LIST.osmosis, [denom])

  const { isSmallScreen } = useSmallScreen()

  const { data: hostZone, isLoading: isHostZoneLoading, error: hostZoneError } = useHostZoneQuery()

  const { data: stakeStats, isLoading: isStakeStatsLoading, error: stakeStatsError } = useStakeStatsQuery()

  const currentYield = useMemo(() => {
    if (stakeStats == null) return '0%'
    const { currentYield } = stakeStats[denom]
    return `${new BigNumber(currentYield).multipliedBy(100).decimalPlaces(2)}%`
  }, [denom, stakeStats])

  const strideYield = useMemo(() => {
    if (stakeStats == null) return '0%'
    const { strideYield } = stakeStats[denom]
    return `${new BigNumber(strideYield).multipliedBy(100).decimalPlaces(2)}%`
  }, [denom, stakeStats])

  const unbondingPeriod = useMemo(() => {
    if (stakeStats == null) return '21-24 days'
    const { start, end, unit } = stakeStats[denom].unbondingPeriod
    return `${start}-${end} ${unit}`
  }, [denom, stakeStats])

  const stakedTokenValue = useMemo(() => {
    if (hostZone == null) return '0'
    return new BigNumber(hostZone.redemption_rate).decimalPlaces(3).toString()
  }, [hostZone])

  return (
    <>
      <Card p="md">
        <Title order={5} sx={(t) => ({ color: t.colors.gray[9], fontWeight: 600 })}>
          About {denom} on Stride
        </Title>

        <Space h="xs" />

        <CollapsibleStatsList>
          <CollapsibleStatsItem
            icon="trophy"
            label="Rewards"
            value={
              Boolean(stakeStatsError) ? (
                <Tooltip label="Unable to load stride yield at this time." withArrow>
                  <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                </Tooltip>
              ) : (
                <TextLoader loading={isStakeStatsLoading}>{strideYield}</TextLoader>
              )
            }
            description={
              <>
                Rewards on Stride are similar to native staking rewards. However, you can use your st{denom} throughout
                DeFi.
              </>
            }
            data={[
              {
                label: 'Typical yield',
                value: Boolean(stakeStatsError) ? (
                  <Tooltip label="Unable to load current yield at this time." withArrow>
                    <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                  </Tooltip>
                ) : (
                  <TextLoader loading={isStakeStatsLoading}>{currentYield}</TextLoader>
                )
              },
              {
                label: 'Stride yield (after fee)',
                value: Boolean(stakeStatsError) ? (
                  <Tooltip label="Unable to load stride yield at this time." withArrow>
                    <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                  </Tooltip>
                ) : (
                  <TextLoader loading={isStakeStatsLoading}>{strideYield}</TextLoader>
                )
              }
            ]}
          />

          <CollapsibleStatsItem
            icon="fees"
            label="Fees"
            value={<Text>Low</Text>}
            description="Stride's 10% fee is only applied to rewards you earn. The tokens you stake (aka principal) and transactions are fee-free!"
          />

          <CollapsibleStatsItem
            icon="arrowHorizontal"
            label="Unbonding"
            value={
              Boolean(stakeStatsError) ? (
                <Tooltip label="Unable to load unbonding period at this time." withArrow>
                  <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                </Tooltip>
              ) : (
                <TextLoader loading={isStakeStatsLoading}>{unbondingPeriod}*</TextLoader>
              )
            }
            description={
              <>
                Unstaking on Stride requires an unbonding period before you can withdraw your tokens. If you don't want
                to wait, you can sell st{denom} directly on an exchange like{' '}
                <CollapsibleStatsItemAnchor href={dexInfo.url} target="_blank">
                  {dexInfo.name}
                </CollapsibleStatsItemAnchor>
                . In rare cases, you may need to wait slightly longer for unbondings to process, learn more in{' '}
                <CollapsibleStatsItemAnchor href={config.links.docs} target="_blank">
                  our docs
                </CollapsibleStatsItemAnchor>
                .
              </>
            }
          />

          <CollapsibleStatsItem
            icon="upwardArrow"
            label={`Value of 1 st${denom}`}
            value={
              Boolean(hostZoneError) ? (
                <Tooltip label="Unable to load redemption rate at this time." withArrow>
                  <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
                </Tooltip>
              ) : (
                <TextLoader loading={isHostZoneLoading}>
                  <Box sx={{ display: isSmallScreen ? 'inline' : 'none' }}>1 st{denom} = </Box>
                  {stakedTokenValue} {denom}
                </TextLoader>
              )
            }
            description={
              <>
                <Box>
                  The value of 1 st{denom} if redeemed through the Stride protocol redemption rate grows predictably as
                  staking rewards accrue.
                </Box>
                <Space h="md" />
                <Box>The market price of 1 st{denom} on exchanges fluctuates based on supply and demand.</Box>
              </>
            }>
            {!isSmallScreen && (
              <Stack>
                <Box>
                  <Text sx={(t) => ({ color: t.colors.gray[9] })}>Protocol redemption rate</Text>

                  <Box>
                    <StakeStatProtocolRedemptionRate />
                  </Box>
                </Box>

                <Box>
                  <Text sx={(t) => ({ color: t.colors.gray[9] })}>{dexInfo.name} market rate</Text>

                  <Box>
                    <StakeStatMarketRate />
                  </Box>
                </Box>
              </Stack>
            )}

            {isSmallScreen && (
              <>
                <DataListItem
                  label="Protocol redemption rate"
                  bold={false}
                  value={<StakeStatProtocolRedemptionRate />}
                />
                <DataListItem label={`${dexInfo.name} market rate`} bold={false} value={<StakeStatMarketRate />} />
              </>
            )}
          </CollapsibleStatsItem>

          {Boolean(CHAIN_SUPPORTS_TVL_STAT[denom]) && (
            <CollapsibleStatsItem
              icon="atom"
              label="Total Value Locked"
              value={<StakeStatTvl />}
              description={
                <>
                  <Box>
                    The total value of {denom} that have been deposited on {STRIDE_CHAIN_INFO.chainName} in exchange for
                    st{denom}.
                  </Box>

                  <Space h="md" />

                  <Box>
                    Check out our{' '}
                    <CollapsibleStatsItemAnchor href={config.links.stats} target="_blank">
                      Stats Page
                    </CollapsibleStatsItemAnchor>{' '}
                    for more metrics from the {STRIDE_CHAIN_INFO.chainName} ecosystem.
                  </Box>
                </>
              }
            />
          )}
        </CollapsibleStatsList>

        <Space h="xs" />

        <Card.Section
          p="md"
          pt="sm"
          sx={(t) => ({ background: t.colors.gray[0], paddingBottom: t.other.stakeStatsCard.paddingBottom })}>
          <Text sx={(t) => ({ color: t.colors.gray[7] })}>
            Want to learn more about rewards, fees, and unbonding on Stride?
          </Text>

          <Text sx={(t) => ({ color: t.colors.gray[7] })}>
            Check out the{' '}
            <Anchor href={config.links.docs} target="_blank" underline sx={{ color: 'currentColor' }}>
              Stride Docs
            </Anchor>
          </Text>
        </Card.Section>
      </Card>
    </>
  )
}

export { StakeStats }
