import { useMemo } from 'react'
import z from 'zod'
import { useForm, zodResolver } from '@mantine/form'
import { useSelectedBalancesV2, useSelectedCoin, useStrideBalancesV2 } from '@/contexts/wallet'
import { useStake } from '../StakeProvider'
import { useLsmValidatorsQuery } from '../queries'
import { CHAIN_CONFIG, CHAIN_INFO_LIST } from '@/config'
import { convertMicroDenomToDenom } from '@/wallet-utils'

export interface StakeFormType {
  defaultAmount: string
  lsmAmount: string
}

const useStakeForm = () => {
  const denom = useSelectedCoin()

  const { data: selectedBalances } = useSelectedBalancesV2()

  const chainBalance = BigInt(selectedBalances?.selectedAccountBalance ?? 0)

  const chainInfo = CHAIN_INFO_LIST[denom]

  const { data: strideBalances } = useStrideBalancesV2()

  const chainBalanceOnStride = BigInt(strideBalances?.strideSelectedAccountBalance ?? 0)

  const totalBalance = chainBalance + chainBalanceOnStride

  const { mode, lsm } = useStake()

  const { data: lsmValidators } = useLsmValidatorsQuery()

  const selectedValidator = useMemo(() => {
    return lsmValidators?.validators.find((validator) => {
      return validator.address === lsm.validatorAddress
    })
  }, [lsm, lsmValidators])

  const schema = useMemo(() => {
    if (mode === 'default') {
      const totalBalanceInDenom = Number(
        convertMicroDenomToDenom(totalBalance, chainInfo.stakeCurrency.coinMinimalDenom)
      )

      const minimum = CHAIN_CONFIG[denom].classicLsMinimum

      return z.object({
        // @TODO: Maybe we should add a validation for the decimal precision so
        // they don't manually enter something that would fail the ibc like so: 0.02310230985
        defaultAmount: z.preprocess(
          (v) => Number(z.string().parse(v)),
          z
            .number({
              required_error: 'Amount is required.',
              invalid_type_error: 'Amount must be a number.'
            })
            .min(minimum, `Amount must have a value more than ${minimum}.`)
            .max(totalBalanceInDenom, 'Amount exceeds your balance.')
        )
      })
    }

    const getMaxRules = () => {
      if (selectedValidator == null) {
        return { amount: 0, message: 'Amount exceeds your balance.' }
      }

      // @TODO: Add denom once more chains are supported
      const balanceOnSelectedValidatorInDenom = Number(convertMicroDenomToDenom(selectedValidator.amount))

      // @TODO: Add denom once more chains are supported
      const remainingBondSharesInDenom = Number(convertMicroDenomToDenom(selectedValidator.remainingBondShares))

      // We'll the lesser value and use that as the max amount a user can liquid stake.
      return remainingBondSharesInDenom > balanceOnSelectedValidatorInDenom
        ? { amount: balanceOnSelectedValidatorInDenom, message: `Amount exceeds your balance.` }
        : { amount: remainingBondSharesInDenom, message: `Amount exceeds the validator's capacity.` }
    }

    const max = getMaxRules()

    return z.object({
      // @TODO: Maybe we should add a validation for the decimal precision so
      // they don't manually enter something that would fail the ibc like so: 0.02310230985
      lsmAmount: z.preprocess(
        (v) => Number(z.string().parse(v)),
        z
          .number({
            required_error: 'Amount is required.',
            invalid_type_error: 'Amount must be a number.'
          })
          .min(0.001, 'Amount must have a value more than 0.001.')
          .max(max.amount, max.message)
      )
    })
  }, [denom, mode, totalBalance, selectedValidator])

  return useForm<StakeFormType>({
    schema: zodResolver(schema),
    initialValues: {
      defaultAmount: '',
      lsmAmount: ''
    }
  })
}

export { useStakeForm }
