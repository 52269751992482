import React, { useMemo } from 'react'
import { useSetAtom } from 'jotai'
import { Box, Button, Space } from '@mantine/core'
import { CHAIN_INFO_LIST } from '@/config'
import { DataList, DataListItem, TransactionCard } from '@/components'
import {
  convertMicroDenomToDenom,
  formatDenom,
  formatUnbondingRange,
  RedemptionMetaData,
  RedemptionStatus
} from '@/wallet-utils'
import { claimMetaDataAtom, isUnstakingModalOpenAtom } from '@/page-components/Stake/atoms'

interface RedemptionDataMetaCardProps {
  meta: RedemptionMetaData
}

// Regarding the claim flow, this component strongly assumes that `selectedAccount`
// is always an "Account" and not a "SafeModeAccount". If this assumption changes,
// make sure to properly handle this on the UI (try disabling the button with a tooltip).
const RedemptionMetaDataCard: React.FC<RedemptionDataMetaCardProps> = ({ meta }) => {
  const setClaimMetaData = useSetAtom(claimMetaDataAtom)

  const setIsUnstakingModalOpen = useSetAtom(isUnstakingModalOpenAtom)

  const handleClaim = () => {
    setClaimMetaData(meta)
    setIsUnstakingModalOpen(true)
  }

  const estimatedAt = useMemo(() => {
    return formatUnbondingRange(meta.values.claimableAt)
  }, [meta])

  const { chainName } = CHAIN_INFO_LIST[meta.values.denom]

  const amountInDenom = convertMicroDenomToDenom(meta.values.amount, meta.values.minimalDenom)

  const formattedAmount = `${formatDenom(amountInDenom, 3)} ${meta.values.denom}`

  const title = meta.values.isClaimable ? `${meta.values.denom} ready to claim` : `Unstaking ${formattedAmount}`

  const description: Record<RedemptionStatus, () => React.ReactNode> = {
    pending: () => (
      <span>
        You'll soon receive {formattedAmount} on {chainName} at{' '}
        <Box sx={{ display: 'inline', wordBreak: 'break-all' }}>{meta.values.receiver}.</Box>
      </span>
    ),

    complete: () => (
      <span>
        You received {formattedAmount} on {chainName} at{' '}
        <Box sx={{ display: 'inline', wordBreak: 'break-all' }}>{meta.values.receiver}.</Box>
      </span>
    )
  }

  return (
    <TransactionCard
      status={meta.values.isClaimable ? 'complete' : 'pending'}
      title={title}
      description={meta.values.isClaimable ? description['complete']() : description['pending']()}
      action={
        meta.values.isClaimable ? (
          <Button variant="outline" color="dark" onClick={handleClaim}>
            Claim tokens
          </Button>
        ) : null
      }>
      {!meta.values.isClaimable && (
        <>
          <Space h="md" />

          <DataList>
            <DataListItem label="Estimated Complete Date" value={estimatedAt} />
          </DataList>
        </>
      )}
    </TransactionCard>
  )
}

export { RedemptionMetaDataCard }
