import { ErrorBeacon, InlineLoader, useFormContext } from '@/components'
import { Box, Group, Space, Text } from '@mantine/core'
import React from 'react'
import { useHostZoneQuery } from './queries'
import { useSelectedCoin } from '@/contexts/wallet'
import { useTokenValueInUsdQuery } from '@/queries'
import { assert } from '@/utils'
import { StakeFormType } from './StakeForm'
import { formatCurrency, formatDenom } from '@/wallet-utils'

const StakeEstimate: React.FC = () => {
  const denom = useSelectedCoin()

  const { values } = useFormContext<StakeFormType>()

  const { data: stTokenValueInUsd, error: stTokenValueInUsdError } = useTokenValueInUsdQuery(`st${denom}`)

  const { data: hostZone, isLoading: isHostZoneLoading, error: hostZoneError } = useHostZoneQuery()

  if (hostZoneError) {
    return (
      <StakeEstimateWrapper>
        <ErrorBeacon label="Unable to fetch redemption rate at this time. Please try again." />
      </StakeEstimateWrapper>
    )
  }

  if (isHostZoneLoading) {
    return (
      <StakeEstimateWrapper>
        <InlineLoader />
      </StakeEstimateWrapper>
    )
  }

  assert(hostZone, 'Unable to get estimate without host zone')

  const estimate = values.defaultAmount ? Number(values.defaultAmount) / Number(hostZone.redemption_rate) : 0

  return (
    <StakeEstimateWrapper>
      <Text weight={700} size="xl" align="right" sx={(t) => ({ color: t.colors.gray[8] })} inline>
        {format(estimate)}
      </Text>

      <Space h={4} />

      <Group spacing="xs" position="right">
        <Text weight={500} align="right" size="sm" inline sx={(t) => ({ color: t.colors.gray[7] })}>
          <span>≈</span>
        </Text>

        {stTokenValueInUsdError ? (
          <ErrorBeacon label={`Unable to get the dollar value of st${denom}. Please try again.`} />
        ) : stTokenValueInUsd == null ? (
          <InlineLoader />
        ) : (
          <Text weight={500} align="right" size="sm" inline sx={(t) => ({ color: t.colors.gray[7] })}>
            {formatCurrency(estimate * stTokenValueInUsd)}
          </Text>
        )}
      </Group>
    </StakeEstimateWrapper>
  )
}

const format = (estimate: number) => {
  if (estimate >= 1) return formatDenom(estimate, 2)
  if (estimate >= 0.1) return formatDenom(estimate, 3)
  if (estimate >= 0.01) return formatDenom(estimate, 4)
  return formatDenom(estimate, 5)
}

const StakeEstimateWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Box sx={(t) => ({ paddingRight: t.other.stakeForm.amountPaddingRight })}>{children}</Box>
}

export { StakeEstimate }
