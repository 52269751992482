import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { notify } from '@/contexts/notifications'
import { fatal, disregard } from '@/utils'
import { queryKeys } from '@/query-keys'
import {
  assertIsDeliverTxSuccess,
  broadcastIbcTransaction,
  traceIbcStatus,
  IBCTransferStatus,
  isSafeModeAccount
} from '@/wallet-utils'
import { MutationParameters } from './types'
import { useRefreshBalances, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

export type BroadcastSendTokenMutationMode = 'deposit' | 'withdraw'

export interface BroadcastSendTokenMutationReturnType {
  tx: DeliverTxResponse
  status: IBCTransferStatus
}

const useBroadcastIbcTransferMutation = ({ ibcTransferRaw, setIbcTransferTransaction }: MutationParameters) => {
  const selectedAccount = useSelectedWallet()
  const strideAccount = useStrideWallet()
  const updateAccountBalances = useRefreshBalances()

  const client = useQueryClient()

  const handleMutation = async (): Promise<BroadcastSendTokenMutationReturnType> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!ibcTransferRaw) {
      throw fatal('Unable to broadcast transaction without a signed transaction raw.')
    }

    if (isSafeModeAccount(selectedAccount)) throw fatal('Safe mode is enabled.')

    const tx = await broadcastIbcTransaction(selectedAccount, ibcTransferRaw)

    assertIsDeliverTxSuccess(tx)

    const status = await traceIbcStatus({ account: selectedAccount, tx })

    if (status === 'return-later') {
      return {
        tx: tx,
        status: 'return-later'
      }
    }

    // We'll handle this after balance is updated because gas fee is still consumed
    // @TODO: It's possible that we may have to wait for funds to be fully refunded
    // @TODO: Handle error - we probably need a flow for this
    if (status === 'timeout') {
      return {
        tx: tx,
        status: 'timeout'
      }
    }

    return {
      tx: tx,
      status: 'complete'
    }
  }

  const handleSuccess = async ({ tx }: BroadcastSendTokenMutationReturnType) => {
    setIbcTransferTransaction(tx)

    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })

    await client.invalidateQueries({
      queryKey: queryKeys.transactionHistoryIbcStatusByHash({ hash: tx.transactionHash })
    })

    if (!strideAccount || !selectedAccount) {
      // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
      // But we'll return anyway instead of crashing just to be safe (given the unknowns).
      // https://github.com/Stride-Labs/interface/issues/262
      return
    }

    try {
      await updateAccountBalances()
    } catch (e) {
      notify.error(`We could not refresh your transaction history. Please refresh the page upon closing this modal.`)
      disregard(e)
    }
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  });
}

export { useBroadcastIbcTransferMutation }
