import React from 'react'
import { useAtom } from 'jotai'
import {
  isIBCMetaData,
  isIBCDexMetaData,
  isIBCWithdrawStTokenMetaData,
  isIBCLiquidStakeAutopilotMetaData,
  isRedemptionMetaData,
  isTiaRedemptionMetaData,
  isLiquidStakeMetaData,
  isTiaLiquidStakeMetaData,
  isLsmLiquidStakeMetaData,
  isDymRedemptionMetaData,
  isDymLiquidStakeMetaData
} from '@/wallet-utils'
import { fatal } from '@/utils'
import { dismissedTransactionsAtom } from '../atoms'
import { LsmTokenizeSharesMetaDataCard } from './LsmTokenizeSharesMetaDataCard'
import { LsmIbcTransferMetaDataCard } from './LsmIbcTransferMetaDataCard'
import { IBCMetaDataCard } from './IBCMetaDataCard'
import { RedemptionMetaDataCard } from './RedemptionMetaDataCard'
import { LiquidStakeMetaDataCard } from './LiquidStakeMetaDataCard'
import { IBCDexMetaDataCard } from './IBCDexMetaDataCard'
import { IBCWithdrawStTokenMetaDataCard } from './IBCWithdrawStTokenMetaDataCard'
import { IBCLiquidStakeAutopilotMetaDataCard } from './IBCLiquidStakeAutopilotMetaDataCard'
import { useTransactionHistoryQuery } from './queries'
import { useSelectedWallet, useStrideWallet } from '@/contexts/wallet'
import { LsmLiquidStakeMetaDataCard } from './LsmLiquidStakeMetaDataCard'
import { TiaRedemptionMetaDataCard } from './TiaRedemptionMetaDataCard'
import { TiaLiquidStakeMetaDataCard } from './TiaLiquidStakeMetaDataCard'
import { DymRedemptionMetaDataCard } from './DymRedemptionMetaDataCard'
import { DymLiquidStakeMetaDataCard } from './DymLiquidStakeMetaDataCard'

// This currently does not support IBCX because there is no public API for it.
const StakeTransactionHistory: React.FC = () => {
  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const [dismissedTxs, setDismissedTxs] = useAtom(dismissedTransactionsAtom)

  const { data: transactionHistory } = useTransactionHistoryQuery()

  const handleDismiss = (hash: string) => {
    setDismissedTxs((arr) => [...arr, hash])
  }

  if (!strideAccount || !selectedAccount) {
    return null
  }

  // @TODO: Consider simply mapping through (?) instead of running any filter at all

  return (
    <>
      <LsmTokenizeSharesMetaDataCard />

      <LsmIbcTransferMetaDataCard />

      {transactionHistory?.transactions
        .filter((meta) => {
          if (isIBCMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          if (isIBCLiquidStakeAutopilotMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          if (isIBCDexMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          if (isIBCWithdrawStTokenMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          if (isRedemptionMetaData(meta)) return true
          if (isTiaRedemptionMetaData(meta)) return true
          if (isDymRedemptionMetaData(meta)) return true
          if (isLiquidStakeMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          if (isTiaLiquidStakeMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          if (isDymLiquidStakeMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          if (isLsmLiquidStakeMetaData(meta)) return !dismissedTxs.includes(meta.values.hash)
          return false
        })
        .map((meta) => {
          if (isIBCDexMetaData(meta)) {
            return <IBCDexMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          if (isIBCWithdrawStTokenMetaData(meta)) {
            return <IBCWithdrawStTokenMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          if (isIBCMetaData(meta)) {
            return <IBCMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          if (isRedemptionMetaData(meta)) {
            return <RedemptionMetaDataCard meta={meta} key={meta.values.epochNumber} />
          }

          if (isTiaRedemptionMetaData(meta)) {
            return <TiaRedemptionMetaDataCard meta={meta} key={meta.values.id} />
          }

          if (isDymRedemptionMetaData(meta)) {
            return <DymRedemptionMetaDataCard meta={meta} key={meta.values.id} />
          }

          if (isLiquidStakeMetaData(meta)) {
            return <LiquidStakeMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          if (isTiaLiquidStakeMetaData(meta)) {
            return <TiaLiquidStakeMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          if (isDymLiquidStakeMetaData(meta)) {
            return <DymLiquidStakeMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          if (isIBCLiquidStakeAutopilotMetaData(meta)) {
            return <IBCLiquidStakeAutopilotMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          if (isLsmLiquidStakeMetaData(meta)) {
            return <LsmLiquidStakeMetaDataCard meta={meta} onDismiss={handleDismiss} key={meta.values.hash} />
          }

          // This means we somehow miss to filter out transactions we don't care about
          throw fatal('Cannot render an invalid transaction')
        })}
    </>
  )
}

export { StakeTransactionHistory }
