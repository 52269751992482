import { CHAIN_SUPPORTS_WITHDRAW_ST_TOKEN } from '@/config'
import type { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx'
import { MetaDataHandlerParameters } from './types'

const handleIbcWithdrawStToken = ({
  message,
  strideAccount,
  selectedAccount
}: MetaDataHandlerParameters<MsgTransfer>): boolean => {
  if (!CHAIN_SUPPORTS_WITHDRAW_ST_TOKEN[selectedAccount.currency.coinDenom]) {
    return false
  }

  if (message.token == null) {
    return false
  }

  if (!message.token.denom.startsWith('st')) {
    return false
  }

  const isStTokenTransferredFromStrideToChain =
    message.sender === strideAccount.address &&
    message.receiver === selectedAccount.address &&
    message.token.denom === `st${selectedAccount.currency.coinMinimalDenom}`

  return isStTokenTransferredFromStrideToChain
}

export { handleIbcWithdrawStToken }
