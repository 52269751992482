import React, { useMemo } from 'react'
import { Box, Button, Group, Space } from '@mantine/core'
import { DataList, DataListItem, TransactionCard } from '@/components'
import { CHAIN_INFO_LIST, CHAIN_SUPPORTS_LSM } from '@/config'
import { isLsmTokenizedShareDenom, getValidatorAddressFromLsmTokenizedShare } from '../utils'
import { useStake } from '../StakeProvider'
import { LsmValidatorName } from '../shared'
import { useSelectedBalancesV2, useSelectedCoin } from '@/contexts/wallet'
import { convertMicroDenomToDenom, formatMicroDenom } from '@/wallet-utils'

// We're not making any kind of queries at all so this is not part of `useTransactionHistoryQuery`.
// However, it may make sense to make `tokenizedShares` be part of it to centralize everything there.
// I don't feel strongly about this, and this works for now - so YYMV. Let's consider it in the
// future though, especially Stride v2.
const LsmTokenizeSharesMetaDataCard: React.FC = () => {
  const { data: selectedAccountBalances } = useSelectedBalancesV2()

  const denom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const { withdrawLsmTokenizeShare, resumeLsmTokenizeShare } = useStake()

  // @TODO: Before/after launch, let's consider adding a threshold just in case.1
  const tokenizedShares = useMemo(() => {
    if (selectedAccountBalances == null) {
      return []
    }

    return selectedAccountBalances.selectedAccountBalances.filter((balance) => {
      return isLsmTokenizedShareDenom({ denom: balance.denom, chainInfo })
    })
  }, [selectedAccountBalances, denom])

  // Despite selecting INJ, tokenizedShares still uses tokens from ATOM. This is a workaround for now.
  // To go in detail, selectedAccount is outdated for some time; Either there's a race condition on our
  // wallet connection code, or our wallet connection code is too slow, or even both.
  // @TODO: We might want to investigate this if we haven't when we start enabing LSM for more chains.
  if (!CHAIN_SUPPORTS_LSM[denom]) {
    return null
  }

  return (
    <>
      {tokenizedShares.map((balance) => {
        const validatorAddress = getValidatorAddressFromLsmTokenizedShare(balance.denom)

        const formattedTokenValue = `${formatMicroDenom(
          balance.amount,
          chainInfo.stakeCurrency.coinMinimalDenom,
          5
        )} ${denom}`

        const handleRevert = () => {
          withdrawLsmTokenizeShare({
            amount: balance.amount,
            tokenizedDenom: balance.denom
          })
        }

        const handleContinue = () => {
          resumeLsmTokenizeShare({
            amount: Number(convertMicroDenomToDenom(balance.amount, denom)),
            tokenizedDenom: balance.denom,
            validatorAddress
          })
        }

        return (
          <TransactionCard
            status="warning"
            title="You have an unfinished LSM liquid stake"
            description="Looks like your last LSM liquid stake was interrupted."
            key={balance.denom}>
            <>
              <Space h="md" />

              <DataList spacing="xs">
                <DataListItem label="Amount" value={formattedTokenValue} bold={false} />
                <DataListItem label="Validator" value={<LsmValidatorName address={validatorAddress} />} bold={false} />
              </DataList>

              <Space h="md" />

              <Group position="apart">
                <Box />
                <Group spacing="xs">
                  <Button onClick={handleRevert} variant="outline" color="dark">
                    Revert
                  </Button>

                  <Button onClick={handleContinue}>Continue liquid staking</Button>
                </Group>
              </Group>
            </>
          </TransactionCard>
        )
      })}
    </>
  )
}

export { LsmTokenizeSharesMetaDataCard }
