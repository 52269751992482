import React from 'react'
import { Stake, StakeProvider } from '@/page-components/Stake'
import { StakeIbcx, StakeIbcxProvider } from '@/page-components/StakeIbcx'
import { useWallet } from '@/contexts/wallet'
import { WithdrawModalProvider } from '@/page-components/Stake/WithdrawModal'
import { StakeFeeTokensModalProvider } from '@/page-components/Stake/StakeFeeTokensModal'

export default function Home() {
  const { selectedCoinDenom: denom } = useWallet()

  if (denom === 'IBCX') {
    return (
      <StakeIbcxProvider>
        <StakeIbcx />
      </StakeIbcxProvider>
    )
  }

  return (
    <StakeProvider>
      <StakeFeeTokensModalProvider>
        <WithdrawModalProvider>
          <Stake />
        </WithdrawModalProvider>
      </StakeFeeTokensModalProvider>
    </StakeProvider>
  )
}
