import { TiaRedemptionMetaData } from '@/wallet-utils'
import { TiaRedemptionMetaDataFactoryParameters } from './types'
import { fromUnixTimestamp } from '@/utils'

const createTiaRedemptionMetaData = ({
  payload: record,
  selectedAccount,
  strideAccount
}: TiaRedemptionMetaDataFactoryParameters) => {
  const { redemption_record, unbonding_completion_time_seconds } = record

  const meta: TiaRedemptionMetaData = {
    type: 'tia-unstaking',
    values: {
      id: redemption_record.unbonding_record_id,
      amount: redemption_record.native_amount,
      stAmount: redemption_record.st_token_amount,
      receiver: strideAccount.address,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // @NOTE We might need this in v2 given that the new "transaction history" likely displays all transactions
      // regardless of "selected" chain.
      denom: selectedAccount.currency.coinDenom,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // @NOTE We might need this in v2 given that the new "transaction history" likely displays all transactions
      // regardless of "selected" chain.
      minimalDenom: selectedAccount.currency.coinMinimalDenom,
      // Estimate date for when this unbonding will automatically be processed
      // Unlike all of our other transactions, we've moved this from beside "values" to inside "values"
      // It makes sense to go in this direction for all of transaction history; in my experience, each
      // transaction type is rather distinct and rarely share the same properties. And when they do,
      // it often has different intent.
      estimatedAt: fromUnixTimestamp(unbonding_completion_time_seconds)
    }
  }

  return meta
}

export { createTiaRedemptionMetaData }
