import z from 'zod'
import type { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx'
import { MetaDataHandlerParameters } from './types'

// Since autopilot change where `receiver` was deprecated in place of `memo`, it's important we prioritize this
// over handleIbc as autopilot transactions *now* have the same structure as normal IBC transactions. Meaning,
// transactions may be wrongly identified as an IBC transaction rather than an autopilot transaction.
const handleIbcLiquidStakeAutopilot = ({
  message,
  strideAccount,
  selectedAccount
}: MetaDataHandlerParameters<MsgTransfer>): boolean => {
  if (message.token == null) {
    return false
  }

  if (message.token.denom.startsWith('st')) {
    return false
  }

  const result = parseReceiverData(message)

  if (!result.success) {
    return false
  }

  return (
    message.sender === selectedAccount.address &&
    result.data.autopilot.receiver === strideAccount.address &&
    message.token.denom === selectedAccount.currency.coinMinimalDenom
  )
}

const schema = z.object({
  autopilot: z.object({
    stakeibc: z.object({
      stride_address: z.string(),
      action: z.literal('LiquidStake')
    }),
    receiver: z.string()
  })
})

// Autopilot initially used `receiver` field in the message, but later
// on switched to `memo` field. Furthermore, `receiver` is being deprecated
// for the new `memo` field. We'll support both eitherway.
const parseReceiverData = (message: MsgTransfer) => {
  const memo = schema.safeParse(parse(message.memo))

  if (memo.success) {
    return memo
  }

  return schema.safeParse(parse(message.receiver))
}

const parse = (json: string) => {
  try {
    return JSON.parse(json)
  } catch (e) {
    return ''
  }
}

export { handleIbcLiquidStakeAutopilot }
