import React, { useMemo } from 'react'
import { StepperProvider, StepperModal, UseStepperParamsOnClose, useStepper } from '@/components'
import { StakeLsmModalStepOne } from './StakeLsmModalStepOne'
import { StakeLsmModalStepTwo } from './StakeLsmModalStepTwo'
import { StakeLsmModalStepThree } from './StakeLsmModalStepThree'
import { useDexPoolApyQuery } from '../queries'
import { useStakeLsmModal } from './StakeLsmModalProvider'
import { useStake } from '../StakeProvider'
import { CHAIN_INFO_LIST, STRIDE_CHAIN_INFO } from '@/config'
import { useLiquidStakeQueryCallbackQuery } from './queries'
import { useSelectedCoin } from '@/contexts/wallet'
import { StandardTransactionError, LSM_ERROR_KEYS, formatDenom } from '@/wallet-utils'

interface StakeModalProps {
  onSuccess: () => void
}

const StakeLsmModal: React.FC<StakeModalProps> = ({ onSuccess }) => {
  useDexPoolApyQuery()

  const denom = useSelectedCoin()

  const { lsm, closeLsm, closeUnbegunLsm } = useStake()

  const {
    broadcastLiquidStakeData,
    isBroadcastingTokenizeShares,
    isBroadcastingIbcTransfer,
    isBroadcastingLiquidStake,
    isIbcStatusLoading,
    signTokenizeSharesError,
    broadcastTokenizeSharesError,
    signIbcTransferError,
    broadcastIbcTransferError,
    signLiquidStakeError,
    broadcastLiquidStakeError,
    ibcStatusError,
    resetStakeLsmModalState
  } = useStakeLsmModal()

  const {
    data: queryCallback,
    isFetching: isQueryCallbackFetching,
    error: queryCallbackError
  } = useLiquidStakeQueryCallbackQuery()

  // @TODO: Consider adding a decimal cap on the form itself so we don't have to format this.
  const formattedAmount = `${formatDenom(lsm.amount, 5)} ${denom}`

  // First we check if liquid stake ends up pending. If so, we'll check if either query callback is still fetching or ends up also pending.
  // Unlike `useTraceIbcTransfer`, `useLiquidStakeQueryCallbackQuery` uses react-query instead of using a polling function.
  // This means that `isLoading` is set to false for the subsequent requests, and we'll need to know if we're still polling
  // by relying on other state. This kind of feels like a simpler way to achieve almost the same thing. In the future,
  // let's consider refactoring `useTraceIbcTransfer` to use the same pattern if it makes sense. Making full use of react-query's
  // capabilities while being ever so slightly clever seems like the best way to go.
  const isLiquidStakeTransactionPending =
    broadcastLiquidStakeData?.status === 'pending' && (isQueryCallbackFetching || queryCallback?.status === 'pending')

  const steps = useMemo(() => {
    const chainInfo = CHAIN_INFO_LIST[denom]

    return [
      `Tokenize ${denom} to ${chainInfo.chainName}`,
      `Transfer ${denom} to ${STRIDE_CHAIN_INFO.chainName}`,
      `Stake ${denom}, get st${denom}`
    ]
  }, [denom])

  const handleClose = ({ isOnGoing, isComplete }: UseStepperParamsOnClose) => {
    const isPristine = !isOnGoing && !isComplete

    // Make sure this is updated whenever we register/handle a new error
    const isTokenizeErrorRelatedToBondOrCapacity =
      broadcastTokenizeSharesError instanceof StandardTransactionError &&
      LSM_ERROR_KEYS.includes(broadcastTokenizeSharesError.description)

    if (isPristine || isTokenizeErrorRelatedToBondOrCapacity) {
      // If the lsm flow has not been started or the error is related to bond shares,
      // we want to close the modal but keep the selected validator.
      closeUnbegunLsm()
    } else {
      closeLsm()
    }

    resetStakeLsmModalState()
  }

  const stepper = useStepper({
    steps,
    warning: isIbcStatusLoading || isLiquidStakeTransactionPending,
    error: Boolean(
      signTokenizeSharesError ||
        broadcastTokenizeSharesError ||
        signIbcTransferError ||
        broadcastIbcTransferError ||
        signLiquidStakeError ||
        broadcastLiquidStakeError ||
        ibcStatusError ||
        queryCallbackError
    ),
    isOpen: lsm.isOpen,
    isLoading:
      isBroadcastingTokenizeShares ||
      isBroadcastingIbcTransfer ||
      isIbcStatusLoading ||
      isBroadcastingLiquidStake ||
      isLiquidStakeTransactionPending,
    isPostError: broadcastLiquidStakeData?.status === 'pending' && queryCallback?.status === 'failed',
    isComplete: false,
    onClose: handleClose,
    onComplete: onSuccess
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title="Convert to Liquid Stake" amount={formattedAmount}>
        <StakeLsmModalStepOne />
        <StakeLsmModalStepTwo />
        <StakeLsmModalStepThree />
      </StepperModal>
    </StepperProvider>
  )
}

export { StakeLsmModal }
