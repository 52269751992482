import { format } from 'date-fns'
import type { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx'
import { fatal } from '@/utils'
import { getElaborateSendPacketAttributes, IBCLiquidStakeAutopilotMetaData } from '@/wallet-utils'
import { MetaDataFactoryParameters } from './types'

const createIbcLiquidStakeAutopilotMetaData = ({
  response,
  message,
  strideAccount,
  selectedAccount
}: MetaDataFactoryParameters<MsgTransfer>) => {
  if (!message.token) {
    // We're sure that token exists because we validate it from  the queryPendingTxs fn
    throw fatal(`Transaction ${response.txhash} is a MsgTransfer message but does not contain message.token.`)
  }

  const packets = getElaborateSendPacketAttributes(response.events)

  const meta: IBCLiquidStakeAutopilotMetaData = {
    type: 'ibc-liquid-stake-autopilot',
    values: {
      hash: response.txhash,
      sequence: packets?.packet_sequence ?? '',
      dstChannel: packets?.packet_dst_channel ?? '',
      amount: message.token.amount,
      sender: message.sender,
      // We can safely hard-code this given that the handler already validates this for us
      receiver: strideAccount.address,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // This assumes that all of the transactions are from Stride.
      // The fetch method takes care of filtering out ibc transfers that are likely have been made elsewhere.
      // (i.e. Sending STRD to / from Osmosis through the Keplr extensionn
      denom: selectedAccount.currency.coinDenom,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // This assumes that all of the transactions are from Stride.
      // The fetch method takes care of filtering out ibc transfers that are likely have been made elsewhere.
      // (i.e. Sending STRD to / from Osmosis through the Keplr extension)
      minimalDenom: selectedAccount.currency.coinMinimalDenom,
      // @TODO: Let's update IBCTranferStatus type 'timeout' to 'error'
      // Remove this property as we calculate this through a separate request
      status: 'complete'
    },
    // @TODO: Refactor this to be unformatted and let StakeTransactionList do its job
    startedAt: format(new Date(response.timestamp), 'MMMM d, yyyy h:mm O'),
    completedAt: null
  }

  return meta
}

export { createIbcLiquidStakeAutopilotMetaData }
