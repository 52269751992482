import React from 'react'
import { Button, Center, Group, Modal, Space, Stack, Text, Title, UnstyledButton, useMantineTheme } from '@mantine/core'
import Image from 'next/legacy/image'

import illustration from '@/images/insufficient-airdrop-fee-tokens-illustration.svg'
import { useMediumScreen } from '@/hooks'
import { Icon } from '@/components'
import { useSelectedCoin } from '@/contexts/wallet'
import { useStakeFeeTokensModal } from './StakeFeeTokensModalProvider'

// Make sure to only allow this modal to be opened if [the user will participate in a flow
// that will require STRD (like classic liquid staking)], and [the chain does not support
// auto-allocation feature (often because their tokens are not fee tokens on Stride)]
//
// @TODO: Once we start introducing this to more flows, we should consider making a hook
// that makes it simple to paste the condition like so: { isFeeTokenRequired } = useStakeFeeTokensViable()
//
// @TODO: Consider improving the `isFeeTokensNecessary` flag by actually checking if token is a fee token on Stride from the registry.
// Currently we only check if denom does not support auto-allocation (waiting for PR to be merged).
const StakeFeeTokensModal = () => {
  const denom = useSelectedCoin()

  const theme = useMantineTheme()

  const { isMediumScreen } = useMediumScreen()

  const { isFeeTokensModalOpen, setIsFeeTokensModalOpen } = useStakeFeeTokensModal()

  const handleClose = () => {
    setIsFeeTokensModalOpen(false)
  }

  return (
    <Modal
      opened={isFeeTokensModalOpen}
      onClose={handleClose}
      size={isMediumScreen ? theme.other.stepperModal.bodyWidth : '100%'}
      padding={0}
      radius={isMediumScreen ? 'lg' : 0}
      withCloseButton={false}>
      <Group spacing={0} align="stretch" noWrap={true}>
        <Group
          sx={(t) => ({
            backgroundColor: t.colors.gray[9],
            padding: 54,
            borderTopLeftRadius: isMediumScreen ? t.radius.lg : 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: isMediumScreen ? t.radius.lg : 0,
            borderBottomRightRadius: 0
          })}>
          <Image src={illustration} alt="Airdrop claim illustration" />
        </Group>

        <Center
          sx={(t) => ({
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: t.other.stepperModal.contentWidth,
            padding: isMediumScreen ? t.other.stepperModal.contentPaddingDesktop : t.spacing.md,
            paddingRight: isMediumScreen ? t.other.stepperModal.contentPaddingRightDesktop : t.spacing.md
          })}>
          <Space h="xl" />

          <Title order={4} sx={(t) => ({ color: t.colors.gray[9] })}>
            Insufficient gas tokens
          </Title>

          <Space h="sm" />

          <Stack spacing="xs">
            <Text size="sm" sx={(t) => ({ color: t.colors.gray[8] })}>
              To liquid stake your {denom} tokens on Stride, you'll need some STRD tokens for gas. Get STRD on Osmosis.
            </Text>
          </Stack>

          <Space h="md" />

          <Group spacing="xs">
            <Button variant="outline" color="dark" fullWidth={!isMediumScreen} onClick={handleClose}>
              Close
            </Button>

            <Button component="a" href="https://app.osmosis.zone" target="_blank" fullWidth={!isMediumScreen}>
              Go to Osmosis
            </Button>
          </Group>

          <Space h="xl" />
        </Center>
      </Group>

      <UnstyledButton
        sx={{ position: 'absolute', top: theme.spacing.xl, right: theme.spacing.xl }}
        onClick={handleClose}>
        <Icon name="cross" sx={(t) => ({ color: t.colors.gray[8] })} />
      </UnstyledButton>
    </Modal>
  )
}

export { StakeFeeTokensModal }
