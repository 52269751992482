import React from 'react'
import { useFormContext } from '@/components'
import { Button, Tooltip } from '@mantine/core'
import { useHostZoneQuery } from '../queries'
import { UnstakeFormType } from './useUnstakeForm'
import { useSelectedCoin, useSelectedWallet, useStrideWallet, useWallet } from '@/contexts/wallet'
import { CHAIN_INFO_LIST, CHAIN_SUPPORTS_UNSTAKING } from '@/config'

const UnstakeButton: React.FC = () => {
  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const { connect, isFetchingStrideAccount: isConnectingWallet, isFetchingSelectedAccount } = useWallet()

  const { errors } = useFormContext<UnstakeFormType>()

  const { data: hostZone, error: hostZoneError } = useHostZoneQuery()

  const denom = useSelectedCoin()

  if (!strideAccount || !selectedAccount) {
    return (
      <Button
        type="submit"
        fullWidth
        size="lg"
        radius="md"
        onClick={() => connect()}
        loading={isConnectingWallet || isFetchingSelectedAccount}>
        Connect Wallet
      </Button>
    )
  }

  const selectedChainName = CHAIN_INFO_LIST[denom].chainName

  const tooltipMessage =
    !CHAIN_SUPPORTS_UNSTAKING[denom] && denom === 'DYDX'
      ? `Redemption of st${denom} is disabled for the launch period (72 hours)`
      : !CHAIN_SUPPORTS_UNSTAKING[denom] && denom === 'TIA'
      ? `Redemption of st${denom} is disabled for the launch period (24 hours)`
      : !CHAIN_SUPPORTS_UNSTAKING[denom] && denom === 'DYM'
      ? `Redemption of st${denom} is disabled for the launch period (72 hours)`
      : !CHAIN_SUPPORTS_UNSTAKING[denom] && denom === 'SAGA'
      ? `Redemption of st${denom} is disabled for the launch period (24 hours)`
      : !CHAIN_SUPPORTS_UNSTAKING[denom] && denom === 'ISLM'
      ? `Redemption of st${denom} is disabled for the launch period (72 hours)`
      : !CHAIN_SUPPORTS_UNSTAKING[denom] && denom === 'BAND'
      ? `Redemption of st${denom} is disabled for the launch period (72 hours)`
      : !CHAIN_SUPPORTS_UNSTAKING[denom]
      ? `Redemption of st${denom} tokens is disabled for the time being.`
      : hostZone?.halted
      ? `Unstaking from ${selectedChainName} has been disabled temporarily due to rate limiting.`
      : hostZoneError
      ? 'Unable to load host zone information. Please refresh the page or try again in a few minutes.'
      : ''

  const isDisabled = Boolean(tooltipMessage || errors.amount)

  return (
    <Tooltip label={tooltipMessage} disabled={!tooltipMessage} sx={{ width: '100%' }} withinPortal>
      <Button type="submit" fullWidth size="lg" radius="md" disabled={isDisabled}>
        Unstake
      </Button>
    </Tooltip>
  )
}

export { UnstakeButton }
