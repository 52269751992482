import { StepperModalContent } from '@/components'
import { Anchor, Box, Button, Space } from '@mantine/core'
import { RateLimitTimeUntilReset } from '../RateLimitTimeUntilReset'
import { RATE_LIMIT_DOCS_URL } from '../../constants'
import { useSelectedCoin } from '@/contexts/wallet'

interface RateLimitClosedLiquidStakeModalWarningProps {
  onContinue: () => void
}

// @TIP: The simplest way to test this componeent and all the other parts of rate limiting is to
// find `getRateLimitMetaData` and change it up to enable the rate limit screens to be shown.
const RateLimitClosedLiquidStakeModalWarning: React.FC<RateLimitClosedLiquidStakeModalWarningProps> = ({
  onContinue
}) => {
  const denom = useSelectedCoin()

  return (
    <StepperModalContent
      title="Rate limits have been reached"
      description={
        <>
          <Box>You will be unable to withdraw any st{denom} until the rate limit resets.</Box>

          <Space h="xs" />

          <Box>
            <RateLimitTimeUntilReset />
          </Box>
          <Space h="xs" />

          <Box>
            You’re unable to withdraw any st{denom} at this point in time, as the rate limits have been reached. You can
            learn more about this{' '}
            <Anchor href={RATE_LIMIT_DOCS_URL} target="_blank">
              here
            </Anchor>
            .
          </Box>
        </>
      }
      actions={<Button onClick={onContinue}>Continue</Button>}
    />
  )
}

export { RateLimitClosedLiquidStakeModalWarning }
