import { useMutation } from '@tanstack/react-query'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { assert } from '@/utils'
import { CHAIN_CONFIG } from '@/config'
import { calculateFeeWithHelper, deductFeeFromTxAmountWhileEmptyStrd } from '@/wallet-utils'
import { generateIbcTimeoutTimestamp } from '@/wallet-utils/ibc'
import { MutationParameters } from './types'
import { useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

export interface SignIbcTransferMutationParameters {
  amount: number
}

const useSignWithdrawMutation = ({ setWithdrawRaw }: MutationParameters) => {
  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const handleMutation = async (amount: string): Promise<TxRaw> => {
    assert(strideAccount, 'You are unable to send token without connecting your Stride wallet.')
    assert(strideAccount.client, 'Stride stargate client does not exist')
    assert(selectedAccount, 'You are unable to send token without connecting your selected wallet.')

    const createMsgTransfer = (amount: string) => {
      return {
        typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
        value: {
          sourcePort: 'transfer',
          sourceChannel: CHAIN_CONFIG[selectedAccount.currency.coinDenom].withdrawChannel,
          token: {
            amount,
            denom: CHAIN_CONFIG[selectedAccount.currency.coinDenom].ibcDenom
          },
          sender: strideAccount.address,
          receiver: selectedAccount.address,
          memo: '',
          timeoutTimestamp: generateIbcTimeoutTimestamp()
        }
      }
    }

    const msgTransferForSimulation = createMsgTransfer(amount)
    const gas = await strideAccount.client.simulate(strideAccount.address, [msgTransferForSimulation], '')
    const { fee, deductible } = calculateFeeWithHelper(selectedAccount.currency.coinMinimalDenom, gas)

    // Allot a small portion of the user's tokens for the transaction gas fee
    //
    // Testing Guide (Preparation):
    // 1. Setup a fresh account, and send a small amount of tokens to it (e.g., 1 SAGA).
    // 2. IBC transfer the tokens to the wallet's Stride address.
    // 3. Proceed with "Withdraw" flow.
    // 4. Inspect the transaction amount and you'll find that it should be less than the full amount.
    //
    // @TODO: Only deduct if the user does not have enough tokens for gas
    const amountWithSafetyDeduction = deductFeeFromTxAmountWhileEmptyStrd(
      strideAccount.coins,
      BigInt(amount),
      deductible
    )

    const msgTransfer = createMsgTransfer(amountWithSafetyDeduction.toString())

    return await strideAccount.client.sign(strideAccount.address, [msgTransfer], fee, '')
  }

  const handleSuccess = (raw: TxRaw) => {
    setWithdrawRaw(raw)
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  });
}

export { useSignWithdrawMutation }
