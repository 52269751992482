import React, { useMemo } from 'react'
import { Switch, Space, Text, Tooltip, useMantineTheme, Group, Box } from '@mantine/core'
import { ErrorBeacon, InlineLoader } from '@/components'
import { useStake } from '../StakeProvider'
import { useHostZoneQuery, useLsmValidatorsQuery } from '../queries'
import { getTotalNativelyStakedBalance } from '../utils'
import { CHAIN_INFO_LIST, CHAIN_SUPPORTS_LSM } from '@/config'
import { useSelectedCoin, useStrideWallet } from '@/contexts/wallet'
import { formatMicroDenom } from '@/wallet-utils'

const StakeToggle: React.FC = () => {
  const theme = useMantineTheme()

  const denom = useSelectedCoin()

  const strideAccount = useStrideWallet()

  const { mode, setMode } = useStake()

  const { data: lsmValidators, error: lsmValidatorsError } = useLsmValidatorsQuery()

  const { data: hostZone, error: hostZoneError } = useHostZoneQuery()

  const totalNativelyStakedBalance = useMemo(() => {
    return getTotalNativelyStakedBalance({ lsmValidators })
  }, [lsmValidators])

  const handleChange = () => {
    setMode(mode === 'default' ? 'lsm' : 'default')
  }

  const isEnabled = !lsmValidatorsError && !hostZoneError && totalNativelyStakedBalance > 0

  // `isLsmValidatorsLoading` isn't set to `true` while its dependencies are loading (stride and selected account)
  // This makes sure we're able to cover this + hide the loader while wallet is disconnected.
  const isQueryLoading = (!lsmValidators && !lsmValidatorsError) || (!hostZone && !hostZoneError)
  const isLoading = strideAccount && isQueryLoading

  const errorMessage = lsmValidatorsError
    ? `Unable to fetch your validator's balance. Please refresh the page.`
    : hostZoneError
    ? `Unable to fetch your selected chain's host zone information. Please refresh the page.`
    : ''

  // Based on the conditionals after the stake label ("Pull from natively staked balance")
  const isSeparatorActive = isLoading || Boolean(errorMessage) || isEnabled

  // Tooltip is intentionally hidden if there's an error message; we only show one or the other.
  const tooltipMessage = errorMessage
    ? ''
    : !strideAccount
    ? 'You need to be connected to a wallet to use this feature.'
    : !isLoading && !isEnabled
    ? 'You don’t have any natively staked ATOM in this wallet.'
    : ''

  if (!CHAIN_SUPPORTS_LSM[denom]) {
    return null
  }

  const chainInfo = CHAIN_INFO_LIST[denom]

  return (
    <>
      <Tooltip label={tooltipMessage} disabled={!tooltipMessage}>
        <Switch
          checked={mode === 'lsm'}
          onChange={handleChange}
          color={theme.colors.pink[6]}
          disabled={!isEnabled}
          sx={{ input: { cursor: 'pointer' } }}
          label={
            <Group spacing={4}>
              <Text sx={(t) => ({ color: !isEnabled ? t.colors.gray[5] : t.colors.gray[8] })}>
                Pull from natively staked balance{isSeparatorActive ? ':' : ''}
              </Text>

              {isLoading ? (
                <InlineLoader />
              ) : errorMessage ? (
                <Box pl={4} sx={{ lineHeight: 1 }}>
                  <ErrorBeacon label={errorMessage} />
                </Box>
              ) : isEnabled ? (
                <Text weight={600} sx={(t) => ({ color: t.colors.gray[8] })}>
                  {formatMicroDenom(totalNativelyStakedBalance, chainInfo.stakeCurrency.coinMinimalDenom, 3)} {denom}
                </Text>
              ) : null}
            </Group>
          }
        />
      </Tooltip>

      <Space h="md" />
    </>
  )
}

export { StakeToggle }
