import { notify } from '@/contexts/notifications'
import { StepperModalContent, useStepperContext } from '@/components'
import { useLatestValue, useMount } from '@/hooks'
import { Button } from '@mantine/core'
import React from 'react'
import { useStakeLsmModal } from './StakeLsmModalProvider'
import { useStake } from '../StakeProvider'
import { useSelectedCoin } from '@/contexts/wallet'
import { StandardTransactionError } from '@/wallet-utils'

const StakeLsmModalStepTwo: React.FC = () => {
  const {
    signIbcTransfer,
    signIbcTransferError,
    broadcastIbcTransfer,
    isBroadcastingIbcTransfer,
    broadcastIbcTransferError,
    traceIbcStatus,
    isIbcStatusLoading
  } = useStakeLsmModal()

  const { nextStep, forceClose, handleClose } = useStepperContext()

  const { lsm } = useStake()

  const isOpenRef = useLatestValue(lsm.isOpen)

  const denom = useSelectedCoin()

  const handleBroadcast = async () => {
    if (!isOpenRef.current) {
      // This function doesn't get cancelled when the modal gets closed.
      // If we get here when pool is already closed, it's likely that the stuff that
      // `traceIbcStatus` (transactionRef) needs has already been reset by the
      // modal close handler.
      return
    }

    const { status } = await broadcastIbcTransfer()

    if (!isOpenRef.current) {
      // This function doesn't get cancelled when the modal gets closed.
      // If we get here when pool is already closed, it's likely that the stuff that
      // `traceIbcStatus` (transactionRef) needs has already been reset by the
      // modal close handler.
      return
    }

    if (status === 'return-later') {
      // If we're here, it means that our ibc transfer took too long and we're tracking it
      // the second time without the 30-second timeout. This way, the user can continue the
      // flow if it they haven't closed.
      //
      // We can check for the return-later status by checking using `isIbcStatusLoading`
      // to check if the ibc transfer hasn't completed and ibc tracking is still on-going.
      //
      // @TODO: Handle timeout state if this doesn't succeed. The original implementation
      // from the ibc/staking modal doesn't have this yet neither. Add display states for
      // `status === 'timeout'` for both broadcast and tracing and `ibcStatusError`
      await traceIbcStatus()
    }

    nextStep()
  }

  const handleStep = async () => {
    await signIbcTransfer()
    await handleBroadcast()
  }

  const handleCloseCallback = () => {
    // If it was closed after the broadcast, we don't need to show the toast.
    if (!isBroadcastingIbcTransfer && !isIbcStatusLoading) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the transfer completes.")
  }

  useMount(() => {
    handleStep()
  })

  handleClose(handleCloseCallback)

  if (isIbcStatusLoading) {
    return (
      <StepperModalContent
        title="This transaction is taking longer than usual"
        description={`If you don’t want to wait here, you can close this dialog. Your transaction will continue and you’ll be able to see status on the Stride app page.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
      />
    )
  }

  if (broadcastIbcTransferError instanceof StandardTransactionError) {
    // @TODO: Figure out if the tokenized shares are being used if user don't have enough
    // ATOMs or lower than the share's amount at this point. If not, then our messaging
    // makes sense. There's no need to "lower" the stake amount message like we do for other
    // flows.
    //
    // If the user runs into `INSUFFICIENT_FUNDS` (code 5), it either means that they don't have
    // any STRD to use as gas or have more ATOMs than Stride, and so Keplr tries to use the same
    // ATOM that was sent to Stride.
    //
    // @TODO: Can we check if ATOMs are still used in case there are more ATOMs on Stride
    //
    // It makes sense to get insufficient funds because if you have 5 ATOMs on Stride,
    // and some of is being used as gas (as Keplr does as backup), then you won't have enough
    // tokens to stake the full amount
    //
    // https://github.com/Stride-Labs/interface/issues/529
    // @TODO: When we try fixing how retry broadcast works, it's possible that when their ATOMs
    // are used as gas, they'll have less, and so we might want to cover for that.
    const isOutOfGas =
      broadcastIbcTransferError.description === 'INSUFFICIENT_FUNDS' ||
      broadcastIbcTransferError.description === 'OUT_OF_GAS'

    // @TODO: Update out of gas message to be more friendly and relevant
    const description = isOutOfGas
      ? `Transaction failed due to out of gas. Please try again.`
      : broadcastIbcTransferError.message

    return (
      <StepperModalContent
        title="Transaction error"
        description={description}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
        error={broadcastIbcTransferError}
      />
    )
  }

  if (broadcastIbcTransferError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`This transfer could not be completed. Your tokens have not been moved.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isBroadcastingIbcTransfer) {
    return (
      <StepperModalContent
        title={`Sending your staked ${denom} to the Stride app...`}
        description="This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the transfer will continue. Once the transfer is complete, you will need to return to initiate the staking process."
      />
    )
  }

  if (signIbcTransferError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`This transfer could not be completed. Your tokens have not been moved.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleStep}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title="Approve the transaction in your wallet to continue"
      description={`This will start the transfer of your tokenized ${denom} to Stride to start the liquid staking process.`}
    />
  )
}

export { StakeLsmModalStepTwo }
