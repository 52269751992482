import { CHAIN_CONFIG } from '@/config'
import { SelectedCoinDenom } from '@/wallet-utils'
import { Button } from '@mantine/core'

interface ViewMintscanButtonProps {
  denom: SelectedCoinDenom
  hash: string
}

const ViewMintscanButton: React.FC<ViewMintscanButtonProps> = ({ denom, hash }) => {
  const { mintscanId } = CHAIN_CONFIG[denom]

  return (
    <Button
      component="a"
      target="_blank"
      href={`https://mintscan.io/${mintscanId}/txs/${hash}`}
      variant="outline"
      color="dark">
      View details
    </Button>
  )
}

export { ViewMintscanButton }
