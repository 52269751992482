import { TX_MSG, calculateFee, convertDenomToMicroDenom, isSafeModeAccount, simulate } from '@/wallet-utils'
import { assert } from '@/utils'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { useMutation } from '@tanstack/react-query'
import { useStake } from '../../StakeProvider'
import { useLsmValidatorsQuery } from '../../queries'
import { MutationParameters } from './types'
import { useSelectedWallet } from '@/contexts/wallet'
import { flushSync } from 'react-dom'

const useSignTokenizeSharesMutation = ({ setTokenizeSharesRaw }: MutationParameters) => {
  const selectedAccount = useSelectedWallet()

  const { data: lsmValidators } = useLsmValidatorsQuery()

  const { lsm } = useStake()

  const handleMutation = async (): Promise<TxRaw> => {
    const selectedValidator = lsmValidators?.validators.find((validator) => {
      return validator.address === lsm.validatorAddress
    })

    assert(selectedAccount, 'You are unable to send token without connecting your selected wallet.')

    const amountInMicroDenom = convertDenomToMicroDenom(lsm.amount, selectedAccount.currency.coinMinimalDenom)

    assert(selectedValidator, 'You are unable to send token without a selected validator.')

    const messages = [
      {
        typeUrl: TX_MSG.MsgTokenizeShares,
        value: {
          delegatorAddress: selectedAccount.address,
          validatorAddress: selectedValidator.address,
          amount: {
            denom: selectedAccount.currency.coinMinimalDenom,
            amount: amountInMicroDenom.toString()
          },
          tokenizedShareOwner: selectedAccount.address
        }
      }
    ]

    assert(!isSafeModeAccount(selectedAccount), 'Safe mode is enabled')

    const gas = await simulate(selectedAccount.client, selectedAccount.address, messages, '')

    const fee = calculateFee(selectedAccount, gas)

    return await selectedAccount.client.sign(selectedAccount.address, messages, fee, '')
  }

  const handleSuccess = (raw: TxRaw) => {
    // This is the only way to allow React v18 to update the state first before
    // the next mutation (which requires this state to have a value) is executed.
    flushSync(() => {
      setTokenizeSharesRaw(raw)
    })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  });
}

export { useSignTokenizeSharesMutation }
