import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { CHAIN_INFO_LIST, CHAIN_IS_COSMOS_SDK_v50 } from '@/config'
import { fatal } from '@/utils'
import { queryKeys } from '@/query-keys'
import {
  IBCMetaData,
  IBCTransferStatus,
  TxQueryResponse,
  IBCDexMetaData,
  IBCWithdrawStTokenMetaData,
  IBCLiquidStakeAutopilotMetaData
} from '@/wallet-utils'
import { useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

interface IbcStatusQueryParameters {
  transaction: IBCMetaData | IBCDexMetaData | IBCWithdrawStTokenMetaData | IBCLiquidStakeAutopilotMetaData
}

interface IbcStatusQueryData {
  status: IBCTransferStatus
}

const useIbcStatusQuery = ({ transaction }: IbcStatusQueryParameters) => {
  const strideAccount = useStrideWallet()
  const selectedAccount = useSelectedWallet()

  const handleRequest = async (): Promise<IbcStatusQueryData> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal(`Unable to load ibc status of transaction (${transaction.values.hash}) while disconnected.`)
    }

    if (!transaction.values.sequence || !transaction.values.dstChannel) {
      // @TODO: This should be an error, let's fix IBC transfer first
      return { status: 'timeout' }
    }

    const getSenderDenom = () => {
      if (transaction.values.sender === strideAccount.address) {
        return strideAccount.currency.coinDenom
      }

      if (transaction.values.sender === selectedAccount.address) {
        return selectedAccount.currency.coinDenom
      }

      // IBC is tracked from the source account which is either the selected chain's address
      // or the stride address. We generally don't support transactions from other chains.
      // On the unlikely event that the user gets here, it's likely `useTransactionHistoryQuery`
      // is not able to filter out the data properly or we have transactions coming from
      // anything else that's not the stride account or the selected account.
      throw fatal(`IBC Transaction ${transaction.values.hash} has an invalid receiver.`)
    }

    const denom = getSenderDenom()

    const instance = axios.create({
      baseURL: CHAIN_INFO_LIST[denom].rest
    })

    // Generate url parameters to query acknowledge_packet or timeout_packet
    const createEventQueryParameters = (prefix: string): URLSearchParams => {
      const parameters = new URLSearchParams()
      // Cosmos v50 SDK deprecates the events parameters in favor of query
      const eventKey = CHAIN_IS_COSMOS_SDK_v50[denom] ? 'query' : 'events'
      parameters.append(eventKey, `${prefix}_packet.packet_sequence='${transaction.values.sequence}'`)
      parameters.append(eventKey, `${prefix}_packet.packet_dst_channel='${transaction.values.dstChannel}'`)
      parameters.append('pagination.limit', '1')
      return parameters
    }

    const eventQueryParameters = {
      acknowledge: createEventQueryParameters('acknowledge'),
      timeout: createEventQueryParameters('timeout')
    }

    const [acknowledgePacketResponse, timeoutPacketResponse] = await Promise.all([
      instance.get<TxQueryResponse>(`cosmos/tx/v1beta1/txs?${eventQueryParameters.acknowledge}`),
      instance.get<TxQueryResponse>(`cosmos/tx/v1beta1/txs?${eventQueryParameters.timeout}`)
    ])

    if (acknowledgePacketResponse.data.tx_responses.length) {
      return { status: 'complete' }
    }

    if (timeoutPacketResponse.data.tx_responses.length) {
      return { status: 'timeout' }
    }

    return { status: 'pending' }
  }

  return useQuery({
    queryKey: queryKeys.transactionHistoryIbcStatusByHash({ hash: transaction.values.hash }),
    queryFn: handleRequest,
    // We want to force poll the data every 30 seconds if we found the transaction to be pending.
    // staleTime doesn't necessarily force this query to refetch.
    refetchInterval: (query) => {
      return query.state.data?.status === 'pending' ? 30 * 1000 : false
    }
  })
}

export { useIbcStatusQuery }
