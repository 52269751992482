import { OSMO_CHAIN_INFO } from '@/config'
import { IBCX_CONTRACTS } from '@/config/ibcx'
import { CosmWasmClient } from '@cosmjs/cosmwasm-stargate'
import ibcxSdk from '@many-things/ibcx-contracts-sdk'

export const getIbcxPeripheryQueryClient = async () => {
  const client = await CosmWasmClient.connect(OSMO_CHAIN_INFO.rpc)
  return new ibcxSdk.Periphery.PeripheryQueryClient(client, IBCX_CONTRACTS.peripheryV2)
}

export const getIbcxCoreQueryClient = async () => {
  const client = await CosmWasmClient.connect(OSMO_CHAIN_INFO.rpc)
  return new ibcxSdk.Core.CoreQueryClient(client, IBCX_CONTRACTS.ibcx)
}

export const getStIbcxCoreQueryClient = async () => {
  const client = await CosmWasmClient.connect(OSMO_CHAIN_INFO.rpc)
  return new ibcxSdk.Core.CoreQueryClient(client, IBCX_CONTRACTS.stIbcx)
}
