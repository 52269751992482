import { useMemo } from 'react'

import { useForm, zodResolver } from '@mantine/form'
import z from 'zod'
import { STAKED_IBCX_CURRENCY } from '@/config'
import { useChainWallet } from '@/contexts/wallet'

export interface UnstakeFormType {
  amount: string
}

const useUnstakeIbcxForm = () => {
  const { data: osmosisAccount } = useChainWallet('OSMO')

  const stIbcxBalance = Number(
    osmosisAccount?.coins.find((coin) => coin.denom === STAKED_IBCX_CURRENCY.coinMinimalDenom)?.amount ?? 0
  )

  const schema = useMemo(() => {
    return z.object({
      amount: z.preprocess(
        (v) => Number(z.string().parse(v)),
        z
          .number({
            required_error: 'Amount is required.',
            invalid_type_error: 'Amount must be a number.'
          })
          .min(0.001, `Amount must have a value more than 0.001.`)
          .max(stIbcxBalance, 'Amount exceeds your balance.')
      )
    })
  }, [stIbcxBalance])

  return useForm<UnstakeFormType>({
    schema: zodResolver(schema),
    initialValues: { amount: '' }
  })
}

export { useUnstakeIbcxForm }
