import React, { useMemo } from 'react'
import { Space } from '@mantine/core'
import { TransactionCard, TransactionCardStatus } from '@/components'
import { IBCLiquidStakeAutopilotMetaData, convertMicroDenomToDenom, formatDenom } from '@/wallet-utils'
import { useHostZoneQuery } from '../queries'
import { useIbcStatusQuery } from './queries'
import { ViewMintscanButton } from './shared'

interface IBCLiquidStakeAutopilotMetaDataCardProps {
  meta: IBCLiquidStakeAutopilotMetaData
  onDismiss: (id: string) => void
}

// We don't need to support the other states
type SimpleTransactionCardStatus = Exclude<TransactionCardStatus, 'checking-expanded' | 'warning'>

const IBCLiquidStakeAutopilotMetaDataCard: React.FC<IBCLiquidStakeAutopilotMetaDataCardProps> = ({
  meta,
  onDismiss
}) => {
  const { data: hostZone } = useHostZoneQuery()

  const { data, error } = useIbcStatusQuery({ transaction: meta })

  // @TODO: Consider adding an ErrorBeacon to let the user know that market prices aren't loading.
  const redemptionRate = Number(hostZone?.redemption_rate ?? 1)

  const amountInDenom = convertMicroDenomToDenom(meta.values.amount, meta.values.minimalDenom)

  const formattedAmount = `${formatDenom(amountInDenom, 3)} ${meta.values.denom}`

  const stakedAmount = Number(amountInDenom) / redemptionRate

  const formattedStakedAmount = `${formatDenom(stakedAmount, 5)} st${meta.values.denom}`

  const cardStatus: SimpleTransactionCardStatus = useMemo(() => {
    if (error) return 'checking-failed'
    if (data == null) return 'checking'
    if (data.status === 'return-later') return 'pending'
    if (data.status === 'timeout') return 'error'
    return data.status
  }, [data, error])

  const titles: Record<SimpleTransactionCardStatus, () => string> = {
    checking: () => `Staking ${formattedAmount}...`,
    'checking-failed': () => `Staking ${formattedAmount}`,
    pending: () => `Staking ${formattedAmount}...`,
    error: () => `Error · Staking ${formattedAmount}`,
    complete: () => `${formattedAmount} staked!`
  }

  const description: Record<SimpleTransactionCardStatus, () => string> = {
    checking: () => 'Checking the status of your IBC transfer.',
    'checking-failed': () => 'We were unable to check the status of this IBC transaction. Please refresh the page.',
    pending: () =>
      'This could take 30 seconds or longer if the network is congested. If you exit Stride, this status may not be visible when you return, but the transfer will continue.',
    complete: () =>
      `You staked ${formattedAmount} on Stride and ${formattedStakedAmount} has been added to your wallet.`,
    error: () =>
      'This transfer could not be completed. Your tokens have not been moved to Stride. You can try again if you haven’t already.'
  }

  return (
    <TransactionCard
      status={cardStatus}
      title={titles[cardStatus]()}
      onDismiss={() => onDismiss(meta.values.hash)}
      description={description[cardStatus]()}>
      {(cardStatus === 'complete' || cardStatus === 'error') && (
        <>
          <Space h="md" />

          <ViewMintscanButton denom={meta.values.denom} hash={meta.values.hash} />
        </>
      )}
    </TransactionCard>
  )
}

export { IBCLiquidStakeAutopilotMetaDataCard }
