import React, { useMemo } from 'react'
import { Box, Group, Modal, UnstyledButton, useMantineTheme, Button, Image, Center, Title } from '@mantine/core'
import Link from 'next/link'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { Space, Text } from '@mantine/core'
import {
  CHAIN_INFO_LIST,
  CHAIN_SUPPORTS_AUTOPILOT_LS,
  CHAIN_SUPPORTS_GAS_AUTO_ALLOCATION,
  config,
  DEX_CONFIG,
  DEX_INFO_LIST,
  STRD_FEE_TOKEN_MINIMUM
} from '@/config'
import { Icon } from '@/components'
import {
  isPoolNudgeModalOpenAtom,
  stakeAmountAtom,
  stakeClassicModalIsOpenAtom,
  stakeAutopilotModalIsOpenAtom
} from '@/page-components/Stake/atoms'
import poolNudgeImage from '@/images/pool-nudge.svg'
import { useMediumScreen, useSmallScreen } from '@/hooks'
import { useMarketPriceQuery } from '../queries'
import { getDexProfitValueInUsd } from '../utils'
import { useSelectedCoin, useStrideBalances } from '@/contexts/wallet'
import { convertDenomToMicroDenom, formatCurrency, formatDenom } from '@/wallet-utils'

const PoolNudgeModal: React.FC = () => {
  const theme = useMantineTheme()

  const amount = useAtomValue(stakeAmountAtom)

  // @TODO: Use StrideBalancesV2
  const { strideBalance, strideSelectedAccountBalance } = useStrideBalances()

  const denom = useSelectedCoin()

  const [isOpen, setIsOpen] = useAtom(isPoolNudgeModalOpenAtom)

  const setStakeClassicModalIsOpen = useSetAtom(stakeClassicModalIsOpenAtom)

  const setStakeAutopilotModalIsOpen = useSetAtom(stakeAutopilotModalIsOpenAtom)

  const { data: marketPrice } = useMarketPriceQuery()

  const { isMediumScreen } = useMediumScreen()

  const { isSmallScreen } = useSmallScreen()

  const dexProfitValueInUsd = useMemo(() => {
    if (!marketPrice?.dexAvailability) {
      return 0
    }

    return getDexProfitValueInUsd({
      amount,
      marketPrice
    })
  }, [marketPrice, amount])

  const formatTokenValue = (tokenValue: number) => {
    return formatDenom(amount * tokenValue, 2)
  }

  const formatStValueInUsd = (stTokenValue: number, stTokenValueInUsd: number) => {
    return formatDenom((amount / stTokenValue) * stTokenValueInUsd, 2)
  }

  const chainInfo = CHAIN_INFO_LIST[denom]

  const handleContinue = () => {
    const isViableToApplyStrideBalance =
      BigInt(strideSelectedAccountBalance) >=
      BigInt(convertDenomToMicroDenom(config.staking.strideBalanceThreshold, chainInfo.stakeCurrency.coinMinimalDenom))

    const isUsingClassicLiquidStaking = isViableToApplyStrideBalance || !CHAIN_SUPPORTS_AUTOPILOT_LS[denom]

    const isFeeTokenNecessary =
      !CHAIN_SUPPORTS_GAS_AUTO_ALLOCATION[denom] && BigInt(strideBalance) < STRD_FEE_TOKEN_MINIMUM

    if (isFeeTokenNecessary && isUsingClassicLiquidStaking) {
      setStakeClassicModalIsOpen(true)
    } else if (isUsingClassicLiquidStaking) {
      setStakeClassicModalIsOpen(true)
    } else {
      setStakeAutopilotModalIsOpen(true)
    }

    setIsOpen(false)
  }

  if (marketPrice == null) {
    return null
  }

  const dexConfig = DEX_CONFIG[denom]

  if (dexConfig == null) {
    return null
  }

  const dexInfo = DEX_INFO_LIST[dexConfig.type]

  return (
    <Modal
      opened={isOpen}
      onClose={handleContinue}
      size={isMediumScreen ? theme.other.stepperModal.bodyWidth : 'xl'}
      withCloseButton={false}
      padding="xl">
      <Group direction={isMediumScreen ? 'row' : 'column'} noWrap={isMediumScreen} spacing="xl" pt="xs">
        <Center sx={{ flexShrink: 0, width: '264px', ...(!isMediumScreen && { height: '120px' }) }}>
          <Box
            sx={(t) => ({
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...(isMediumScreen
                ? {
                    backgroundColor: t.colors.gray[9],
                    top: 0,
                    bottom: 0,
                    left: 0,
                    width: '303px',
                    padding: '32px',
                    borderRadius: '6px 0 0 6px'
                  }
                : {
                    backgroundColor: t.colors.gray[9],
                    borderRadius: '6px 6px 0 0',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '40px',
                    padding: '80px 80px'
                  })
            })}>
            <Image
              src={poolNudgeImage.src}
              alt="Pool Nudge Image"
              sx={{
                ...(!isMediumScreen && {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '120px',
                  height: 'auto'
                })
              }}
            />
          </Box>
        </Center>

        <Box>
          <Title order={4} sx={(t) => ({ color: t.colors.gray[9], lineHeight: 1 })}>
            Better rate detected!
          </Title>

          <Space h="sm" />

          <Text size="sm" sx={(t) => ({ color: t.colors.gray[7] })} inline>
            {amount} {denom} will get you:
          </Text>

          <Space h="sm" />

          <Text size="sm" sx={(t) => ({ color: t.colors.gray[7] })} inline>
            Stride: {formatTokenValue(marketPrice.strideTokenValue)} st
            {denom} ≈{' '}
            <Text weight={700} size="sm" component="span" inline>
              ${formatStValueInUsd(marketPrice.strideStTokenValue, marketPrice.strideStTokenValueInUsd)}
            </Text>
          </Text>

          <Space h="xs" />

          <Text size="sm" sx={(t) => ({ color: t.colors.gray[7] })} inline>
            {dexInfo.name}: {formatTokenValue(marketPrice.dexTokenValue)} st
            {denom} ≈{' '}
            <Text weight={700} size="sm" component="span" inline>
              ${formatStValueInUsd(marketPrice.dexStTokenValue, marketPrice.dexStTokenValueInUsd)}
            </Text>
          </Text>

          <Space h="sm" />

          <Text size="sm" sx={(t) => ({ maxWidth: 280, color: t.colors.gray[7] })}>
            You can get an extra{' '}
            <Text weight={700} sx={(t) => ({ color: t.colors.pink[6] })} component="span">
              ${formatCurrency(dexProfitValueInUsd)}
            </Text>{' '}
            worth of st{denom} if you swap on {dexInfo.name} now instead of liquid staking.
          </Text>

          <Space h="md" />

          <Group spacing="xs">
            <Button variant="outline" onClick={handleContinue} color="dark">
              {isSmallScreen ? 'Continue here' : 'Continue'}
            </Button>

            <Link href={dexInfo.poolUrl(dexConfig.poolId)} passHref legacyBehavior>
              <Button>Go to {dexInfo.name}</Button>
            </Link>
          </Group>
        </Box>
      </Group>

      <UnstyledButton
        sx={{ position: 'absolute', top: theme.spacing.xl, right: theme.spacing.xl }}
        onClick={handleContinue}>
        <Icon name="cross" sx={(t) => ({ color: t.colors.gray[8] })} />
      </UnstyledButton>
    </Modal>
  )
}

export { PoolNudgeModal }
