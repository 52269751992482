import React from 'react'
import { Box, Space, Group, Text } from '@mantine/core'
import { useStakeStatsQuery } from '@/queries'
import { CoinSelector } from '@/page-components/shared'
import { TextHeading, TextLoader } from '@/components'
import { useMediumScreen } from '@/hooks'
import BigNumber from 'bignumber.js'
import { FEATURE_FLAGS } from '@/feature-flags'
import { useSelectedCoin } from '@/contexts/wallet'

interface StakeCoinSelectorProps {
  onChange?: () => void
}

const StakeCoinSelector: React.FC<StakeCoinSelectorProps> = ({ onChange }) => {
  const selectedCoinDenom = useSelectedCoin()

  const { data: stakeStats, isLoading: isStakeStatsLoading } = useStakeStatsQuery()

  const strideYield = stakeStats ? percentage(stakeStats[selectedCoinDenom].strideYield) : '0%'

  const { isMediumScreen } = useMediumScreen()

  return (
    <Group position="apart" align="center" pb="md">
      {FEATURE_FLAGS.sidebar && isMediumScreen && <TextHeading>{selectedCoinDenom}</TextHeading>}
      {(!FEATURE_FLAGS.sidebar || !isMediumScreen) && <CoinSelector onChange={onChange} />}

      <Box hidden={!isMediumScreen}>
        <Text
          weight={600}
          inline
          sx={(t) => ({
            color: t.colors.gray[9],
            fontFamily: t.headings.fontFamily
          })}>
          APY
        </Text>
        <Space h="xs" />
        <TextLoader
          loading={isStakeStatsLoading}
          sx={(t) => ({
            color: t.colors.gray[9],
            fontSize: 26
          })}
          weight={700}
          inline>
          {strideYield}
        </TextLoader>
      </Box>
    </Group>
  )
}

const percentage = (decimal: number) => {
  return `${new BigNumber(decimal).multipliedBy(100).decimalPlaces(2).toString()}%`
}

export { StakeCoinSelector }
