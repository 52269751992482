import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { Tooltip } from '@mantine/core'
import { Icon, TextLoader } from '@/components'
import { useHostZoneQuery } from '../queries'
import { useSelectedCoin } from '@/contexts/wallet'

const StakeStatProtocolRedemptionRate: React.FC = () => {
  const denom = useSelectedCoin()

  const { data: hostZone, isLoading: isHostZoneLoading, error: hostZoneError } = useHostZoneQuery()

  const stakedTokenValue = useMemo(() => {
    if (hostZone == null) return '0'
    return new BigNumber(hostZone.redemption_rate).decimalPlaces(3).toString()
  }, [hostZone])

  if (hostZoneError) {
    return (
      <Tooltip label="Unable to load redemption rate at this time." withArrow>
        <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
      </Tooltip>
    )
  }

  return (
    <TextLoader loading={isHostZoneLoading} sx={(t) => ({ color: t.colors.gray[7] })}>
      1 st{denom} = {stakedTokenValue} {denom}
    </TextLoader>
  )
}

export { StakeStatProtocolRedemptionRate }
