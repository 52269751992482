import React, { useContext, useState } from 'react'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal } from '@/utils'
import { useBroadcastRedeemStIbcxMutation, MutationParameters, useSignRedeemStIbcxMutation } from './mutations'

interface UnstakeIbcxModalContextType {
  resetUnstakeModalState: () => void

  signRedeemStake: () => Promise<TxRaw>
  isSigningRedeemStake: boolean
  signRedeemStakeError: unknown

  broadcastRedeemStake: () => Promise<DeliverTxResponse>
  isBroadcastingRedeemStake: boolean
  isBroadcastRedeemIbcxSuccessful: boolean
  broadcastRedeemStakeError: unknown
}

const UnstakeIbcxModalContext = React.createContext<UnstakeIbcxModalContextType | null>(null)

const UnstakeIbcxModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [redeemStakeRaw, setRedeemStakeRaw] = useState<TxRaw | null>(null)

  const parameters: MutationParameters = {
    redeemStakeRaw,
    setRedeemStakeRaw
  }

  const {
    mutateAsync: signRedeemStake,
    isPending: isSigningRedeemStake,
    error: signRedeemStakeError,
    reset: resetSignRedeemStake
  } = useSignRedeemStIbcxMutation(parameters)

  const {
    mutateAsync: broadcastRedeemStake,
    isPending: isBroadcastingRedeemStake,
    isSuccess: isBroadcastRedeemIbcxSuccessful,
    error: broadcastRedeemStakeError,
    reset: resetBroadcastRedeemStake
  } = useBroadcastRedeemStIbcxMutation(parameters)

  const resetUnstakeModalState = () => {
    setRedeemStakeRaw(null)
    resetSignRedeemStake()
    resetBroadcastRedeemStake()
  }

  return (
    <UnstakeIbcxModalContext.Provider
      value={{
        resetUnstakeModalState,

        signRedeemStake,
        isSigningRedeemStake,
        signRedeemStakeError,

        broadcastRedeemStake,
        isBroadcastingRedeemStake,
        isBroadcastRedeemIbcxSuccessful,
        broadcastRedeemStakeError
      }}>
      {children}
    </UnstakeIbcxModalContext.Provider>
  )
}

const useUnstakeIbcxModal = () => {
  const context = useContext(UnstakeIbcxModalContext)

  if (context == null) {
    throw fatal('You forgot to mount UnstakeIbcxModalProvider.')
  }

  return context
}

export { UnstakeIbcxModalProvider, useUnstakeIbcxModal }
