import type { MsgTransfer } from 'cosmjs-types/ibc/applications/transfer/v1/tx'
import { CHAIN_CONFIG } from '@/config'
import { MetaDataHandlerParameters } from './types'

const handleIbc = ({ message, strideAccount, selectedAccount }: MetaDataHandlerParameters<MsgTransfer>): boolean => {
  if (message.token == null) {
    return false
  }

  if (message.token.denom.startsWith('st')) {
    return false
  }

  const isDepositingToStride =
    message.sender === selectedAccount.address &&
    message.receiver === strideAccount.address &&
    message.token.denom === selectedAccount.currency.coinMinimalDenom

  const isWithdrawingFromStride =
    message.sender === strideAccount.address &&
    message.receiver === selectedAccount.address &&
    message.token.denom === CHAIN_CONFIG[selectedAccount.currency.coinDenom].ibcDenom

  return isDepositingToStride || isWithdrawingFromStride
}

export { handleIbc }
