import React, { useMemo } from 'react'
import { StepperProvider, StepperModal, useStepper } from '@/components'
import { useStake } from '../StakeProvider'
import { useWithdrawLsmModal } from './WithdrawLsmModalProvider'
import { WithdrawLsmModalStepOne } from './WithdrawLsmModalStepOne'
import { WithdrawLsmModalStepTwo } from './WithdrawLsmModalStepTwo'
import { useSelectedCoin } from '@/contexts/wallet'
import { CHAIN_INFO_LIST } from '@/config'
import { formatMicroDenom } from '@/wallet-utils'

const WithdrawLsmModal: React.FC = () => {
  const {
    resetWithdrawStTokenState,
    signWithdrawTokenizedSharesError,
    isBroadcastingWithdrawTokenizedShares,
    broadcastWithdrawTokenizedSharesError,
    ibcStatusError,
    isIbcStatusLoading,
    signRedeemTokenForSharesError,
    broadcastRedeemTokenForSharesError,
    isBroadcastingRedeemTokenForShares
  } = useWithdrawLsmModal()

  const { withdrawLsm, closeWithdrawLsm } = useStake()

  const denom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const formattedAmount = `${formatMicroDenom(
    withdrawLsm.amount,
    chainInfo.stakeCurrency.coinMinimalDenom,
    5
  )} ${denom}`

  const steps = useMemo(() => {
    return [`Transfer tokenized ${denom}`, `Restore the previous delegation`]
  }, [denom])

  const handleClose = () => {
    closeWithdrawLsm()
    resetWithdrawStTokenState()
  }

  const stepper = useStepper({
    steps,
    warning: isIbcStatusLoading,
    error: Boolean(
      signWithdrawTokenizedSharesError ||
        broadcastWithdrawTokenizedSharesError ||
        ibcStatusError ||
        signRedeemTokenForSharesError ||
        broadcastRedeemTokenForSharesError
    ),
    isOpen: withdrawLsm.isOpen && Boolean(withdrawLsm.txHistoryIbcDenom && withdrawLsm.txHistoryIbcTokenizedDenom),
    isLoading: isBroadcastingWithdrawTokenizedShares || isIbcStatusLoading || isBroadcastingRedeemTokenForShares,
    isPostError: false,
    isComplete: false,
    onClose: handleClose
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title="Revert Back" amount={formattedAmount}>
        <WithdrawLsmModalStepOne />
        <WithdrawLsmModalStepTwo />
      </StepperModal>
    </StepperProvider>
  )
}

export { WithdrawLsmModal }
