import React, { useMemo } from 'react'
import { StepperProvider, StepperModal, useStepper } from '@/components'
import { useStake } from '../StakeProvider'
import { useWithdrawLsmFromTokenizeModal } from './WithdrawLsmFromTokenizeModalProvider'
import { WithdrawLsmFromTokenizeModalStepOne } from './WithdrawLsmFromTokenizeModalStepOne'
import { WithdrawLsmFromTokenizeModalStepTwo } from './WithdrawLsmFromTokenizeModalStepTwo'
import { useSelectedCoin } from '@/contexts/wallet'
import { CHAIN_INFO_LIST } from '@/config'
import { formatMicroDenom } from '@/wallet-utils'

// This is based from `WithdrawLsm` modal, and is used when user reverts a tokenized denom
// (either one that hasn't been IBC transferred or IBC transferred from Stride-side). This
// is trimmed down and refined version of steps 3 and 4 from the original. While it does not
// "withdraw" anything anymore, we try to reuse the prefix to avoid confusion.
const WithdrawLsmFromTokenizeModal: React.FC = () => {
  const {
    resetWithdrawLsmFromTokenizeState,
    signRedeemTokenForSharesError,
    broadcastRedeemTokenForSharesError,
    isBroadcastingRedeemTokenForShares
  } = useWithdrawLsmFromTokenizeModal()

  const { withdrawLsm, closeWithdrawLsm } = useStake()

  const denom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const formattedAmount = `${formatMicroDenom(
    withdrawLsm.amount,
    chainInfo.stakeCurrency.coinMinimalDenom,
    5
  )} ${denom}`

  const steps = useMemo(() => {
    return ['Approve token redemption in wallet', `Restore the previous delegation`]
  }, [])

  const handleClose = () => {
    closeWithdrawLsm()
    resetWithdrawLsmFromTokenizeState()
  }

  const stepper = useStepper({
    steps,
    warning: false,
    error: Boolean(signRedeemTokenForSharesError || broadcastRedeemTokenForSharesError),
    isOpen: withdrawLsm.isOpen && Boolean(withdrawLsm.txHistoryTokenizedDenom),
    isLoading: isBroadcastingRedeemTokenForShares,
    isPostError: false,
    isComplete: false,
    onClose: handleClose
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title="Revert Back" amount={formattedAmount}>
        <WithdrawLsmFromTokenizeModalStepOne />
        <WithdrawLsmFromTokenizeModalStepTwo />
      </StepperModal>
    </StepperProvider>
  )
}

export { WithdrawLsmFromTokenizeModal }
