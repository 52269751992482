import React, { useContext, useState } from 'react'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal } from '@/utils'
import {
  useSignStakeIbcxMutation,
  BroadcastStakeIbcxMutationReturnType,
  useBroadcastStakeIbcxMutation
} from './mutations'

interface WithdrawModalContextType {
  resetStakeIbcxModalState: () => void

  signStakeIbcx: () => Promise<TxRaw>
  isSigningStakeIbcx: boolean
  signStakeIbcxError: unknown

  broadcastStakeIbcx: () => Promise<BroadcastStakeIbcxMutationReturnType>
  isBroadcastingStakeIbcx: boolean
  isBroadcastStakeIbcxSuccessful: boolean
  broadcastStakeIbcxError: unknown
}

const StakeIbcxModalContext = React.createContext<WithdrawModalContextType | null>(null)

const StakeIbcxModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [stakeIbcxRaw, setStakeIbcxRaw] = useState<TxRaw | null>(null)

  const parameters = {
    stakeIbcxRaw,
    setStakeIbcxRaw
  }

  const {
    mutateAsync: signStakeIbcx,
    isPending: isSigningStakeIbcx,
    error: signStakeIbcxError,
    reset: resetSignStakeIbcx
  } = useSignStakeIbcxMutation(parameters)

  const {
    mutateAsync: broadcastStakeIbcx,
    isPending: isBroadcastingStakeIbcx,
    isSuccess: isBroadcastStakeIbcxSuccessful,
    error: broadcastStakeIbcxError,
    reset: resetBroadcastStakeIbcx
  } = useBroadcastStakeIbcxMutation(parameters)

  const resetStakeIbcxModalState = () => {
    resetSignStakeIbcx()
    resetBroadcastStakeIbcx()
  }

  return (
    <StakeIbcxModalContext.Provider
      value={{
        resetStakeIbcxModalState,

        signStakeIbcx,
        isSigningStakeIbcx,
        signStakeIbcxError,

        broadcastStakeIbcx,
        isBroadcastingStakeIbcx,
        isBroadcastStakeIbcxSuccessful,
        broadcastStakeIbcxError
      }}>
      {children}
    </StakeIbcxModalContext.Provider>
  )
}

const useStakeIbcxModal = () => {
  const context = useContext(StakeIbcxModalContext)

  if (context == null) {
    throw fatal('You forgot to mount StakeIbcxModalProvider.')
  }

  return context
}

export { StakeIbcxModalProvider, useStakeIbcxModal }
