import React, { useContext, useState } from 'react'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal } from '@/utils'
import { IBCTransferStatus, IbcReturnType } from '@/wallet-utils'
import {
  useSignIbcTransferMutation,
  useBroadcastIbcTransferMutation,
  useTraceIbcTransferMutation,
  useSignLiquidStakeMutation,
  useBroadcastLiquidStakeMutation,
  MutationParameters,
  SignIbcTransferMutationParameters,
  BroadcastSendTokenMutationReturnType,
  BroadcastLiquidStakeMutationReturnType
} from './mutations'

interface ContextType {
  resetStakeClassicModalState: () => void

  // @TODO: Consider removing this and refactoring the other components
  // to access this value from `broadcastLiquidStakeData` instead.
  // For reference, we have a version of this for `StakeLsm`.
  liquidStakeTransaction: DeliverTxResponse | null

  isIbcTransferSkipped: boolean
  setIsIbcTransferSkipped: (value: boolean) => void

  signIbcTransfer: (parameters: SignIbcTransferMutationParameters) => Promise<IbcReturnType>
  isSigningIbcTransfer: boolean
  signIbcTransferError: unknown

  broadcastIbcTransfer: () => Promise<BroadcastSendTokenMutationReturnType>
  isBroadcastingIbcTransfer: boolean
  broadcastIbcTransferError: unknown

  ibcStatus: { status: IBCTransferStatus } | undefined
  traceIbcStatus: () => Promise<{ status: IBCTransferStatus }>
  isIbcStatusLoading: boolean
  ibcStatusError: unknown

  signLiquidStake: () => Promise<TxRaw>
  isSigningLiquidStake: boolean
  signLiquidStakeError: unknown

  broadcastLiquidStake: () => Promise<BroadcastLiquidStakeMutationReturnType>
  isBroadcastingLiquidStake: boolean
  isBroadcastLiquidStakeSuccessful: boolean
  broadcastLiquidStakeError: unknown
}

const Context = React.createContext<ContextType | null>(null)

const StakeClassicModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ibcTransferRaw, setIbcTransferRaw] = useState<IbcReturnType | null>(null)

  const [ibcTransferTransaction, setIbcTransferTransaction] = useState<DeliverTxResponse | null>(null)

  const [liquidStakeRaw, setLiquidStakeRaw] = useState<TxRaw | null>(null)

  const [liquidStakeTransaction, setLiquidStakeTransaction] = useState<DeliverTxResponse | null>(null)

  const [isIbcTransferSkipped, setIsIbcTransferSkipped] = useState(false)

  const parameters: MutationParameters = {
    ibcTransferRaw,
    setIbcTransferRaw,
    ibcTransferTransaction,
    setIbcTransferTransaction,
    liquidStakeRaw,
    setLiquidStakeRaw,
    liquidStakeTransaction,
    setLiquidStakeTransaction
  }

  const {
    mutateAsync: signIbcTransfer,
    isPending: isSigningIbcTransfer,
    error: signIbcTransferError,
    reset: resetSignIbcTransfer
  } = useSignIbcTransferMutation(parameters)

  const {
    mutateAsync: broadcastIbcTransfer,
    isPending: isBroadcastingIbcTransfer,
    error: broadcastIbcTransferError,
    reset: resetBroadcastIbcTransfer
  } = useBroadcastIbcTransferMutation(parameters)

  const {
    data: ibcStatus,
    mutateAsync: traceIbcStatus,
    isPending: isIbcStatusLoading,
    error: ibcStatusError,
    reset: resetIbcStatus
  } = useTraceIbcTransferMutation(parameters)

  const {
    mutateAsync: signLiquidStake,
    isPending: isSigningLiquidStake,
    error: signLiquidStakeError,
    reset: resetSignLiquidStake
  } = useSignLiquidStakeMutation(parameters)

  const {
    mutateAsync: broadcastLiquidStake,
    isPending: isBroadcastingLiquidStake,
    isSuccess: isBroadcastLiquidStakeSuccessful,
    error: broadcastLiquidStakeError,
    reset: resetBroadcastLiquidStake
  } = useBroadcastLiquidStakeMutation(parameters)

  const resetStakeClassicModalState = () => {
    setIbcTransferRaw(null)
    setIbcTransferTransaction(null)
    setLiquidStakeRaw(null)
    setLiquidStakeTransaction(null)
    setIsIbcTransferSkipped(false)
    resetSignIbcTransfer()
    resetBroadcastIbcTransfer()
    resetIbcStatus()
    resetSignLiquidStake()
    resetBroadcastLiquidStake()
  }

  return (
    <Context.Provider
      value={{
        resetStakeClassicModalState,

        liquidStakeTransaction,

        isIbcTransferSkipped,
        setIsIbcTransferSkipped,

        signIbcTransfer,
        isSigningIbcTransfer,
        signIbcTransferError,

        broadcastIbcTransfer,
        isBroadcastingIbcTransfer,
        broadcastIbcTransferError,

        ibcStatus,
        traceIbcStatus,
        isIbcStatusLoading,
        ibcStatusError,

        signLiquidStake,
        isSigningLiquidStake,
        signLiquidStakeError,

        broadcastLiquidStake,
        isBroadcastingLiquidStake,
        isBroadcastLiquidStakeSuccessful,
        broadcastLiquidStakeError
      }}>
      {children}
    </Context.Provider>
  )
}

const useStakeClassicModal = () => {
  const context = useContext(Context)

  if (context == null) {
    throw fatal('You forgot to mount StakeClassicModalProvider.')
  }

  return context
}

export { StakeClassicModalProvider, useStakeClassicModal }
