import { useMutation, useQueryClient } from '@tanstack/react-query'
import { fatal, disregard } from '@/utils'
import { notify } from '@/contexts/notifications'
import { traceIbcStatus, IBCTransferStatus, isSafeModeAccount } from '@/wallet-utils'
import { queryKeys } from '@/query-keys'
import { MutationParameters } from './types'
import { useRefreshBalances, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

// [sign/broadcast] mutations and [trace] mutation are a 2-part process.
// First, if [sign/broadcast] doesn't succeed within the UI time limit, [trace] mutation will continue
// the [tracing] process so user can continue when the ibc transfer completes if they haven't closed the modal yet.
// We can use the `isLoading` status provided by [trace] mutation to display the "Return Later" screen.
const useTraceIbcTransfer = ({ withdrawStTokenTransaction }: MutationParameters) => {
  const client = useQueryClient()

  const strideAccount = useStrideWallet()
  const selectedAccount = useSelectedWallet()
  const updateAccountBalances = useRefreshBalances()

  return useMutation({
    mutationFn: async (): Promise<{ status: IBCTransferStatus }> => {
      if (!strideAccount || !selectedAccount) {
        throw fatal('Unable to trace ibc status of stEVMOS without connecting your wallet.')
      }

      if (!withdrawStTokenTransaction) {
        // We expect this to be set by the [sign/broadcast] mutation.
        // If this is empty, it's likely that modal close handler reset the ref.
        // And that we forgot to check if the modal is still open when we ran this mutation.
        throw fatal('Unable to trace ibc status of stEVMOS while transaction is empty.')
      }

      if (isSafeModeAccount(strideAccount)) {
        throw fatal('Safe mode is enabled.')
      }

      // @TODO: Handle timeout state if this doesn't succeed. The original implementation
      // from the ibc/staking modal doesn't have this yet neither. Add display states for
      const status = await traceIbcStatus(
        { account: strideAccount, tx: withdrawStTokenTransaction },
        { timeout: false }
      )

      await client.invalidateQueries({
        queryKey: queryKeys.transactionHistoryLsmIbcBalancesBase
      })

      await client.invalidateQueries({
        queryKey: queryKeys.transactionHistoryIbcStatusByHash({ hash: withdrawStTokenTransaction.transactionHash })
      })

      try {
        await updateAccountBalances()
      } catch (e) {
        notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
        disregard(e)
      }

      return { status }
    }
  })
}

export { useTraceIbcTransfer }
