import React from 'react'
import { Space } from '@mantine/core'
import { TransactionCard } from '@/components'
import { TiaLiquidStakeMetaData, convertMicroDenomToDenom, formatDenom } from '@/wallet-utils'
import { useHostZoneQuery } from '../queries'
import { ViewMintscanButton } from './shared'

interface TiaLiquidStakeMetaDataCardProps {
  meta: TiaLiquidStakeMetaData
  onDismiss: (id: string) => void
}

const TiaLiquidStakeMetaDataCard: React.FC<TiaLiquidStakeMetaDataCardProps> = ({ meta, onDismiss }) => {
  const { data: hostZone } = useHostZoneQuery()

  // @TODO: Consider adding an ErrorBeacon to let the user know that market prices aren't loading.
  const redemptionRate = Number(hostZone?.redemption_rate ?? 1)

  const amountInDenom = convertMicroDenomToDenom(meta.values.amount, meta.values.minimalDenom)

  const formattedAmount = `${formatDenom(amountInDenom, 3)} ${meta.values.denom}`

  const stakedAmount = Number(amountInDenom) / redemptionRate

  const formattedStakedAmount = `${formatDenom(stakedAmount, 5)} st${meta.values.denom}`

  return (
    <TransactionCard
      status="complete"
      title={`${formattedAmount} staked!`}
      description={`You staked ${formattedAmount} on Stride and ${formattedStakedAmount} has been added to your wallet.`}
      onDismiss={() => onDismiss(meta.values.hash)}>
      <>
        <Space h="md" />

        <ViewMintscanButton denom="STRD" hash={meta.values.hash} />
      </>
    </TransactionCard>
  )
}

export { TiaLiquidStakeMetaDataCard }
