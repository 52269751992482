import { useMutation, useQueryClient } from '@tanstack/react-query'
import { assert } from '@/utils'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { assertIsDeliverTxSuccess, broadcastTx } from '@/wallet-utils'
import { MutationParameters } from './types'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { queryKeys } from '@/query-keys'
import { z } from 'zod'
import { convertAttributesToObject } from '../../utils'
import { useRefreshSelectedBalances, useSelectedWallet } from '@/contexts/wallet'
import { flushSync } from 'react-dom'

export interface BroadcastTokenizeSharesMutationReturnType {
  tokenizedDenom: string
  tokenizedValue: string
}

const useBroadcastTokenizeSharesMutation = ({
  tokenizeSharesRaw,
  setTokenizeSharesDenom,
  setTokenizeSharesTokenValue
}: MutationParameters) => {
  const selectedAccount = useSelectedWallet()

  const refreshSelectedBalances = useRefreshSelectedBalances()

  const client = useQueryClient()

  const handleMutation = async (): Promise<BroadcastTokenizeSharesMutationReturnType> => {
    assert(selectedAccount, 'Unable to tokenized shares without connecting your wallet.')
    assert(tokenizeSharesRaw, 'Unable to broadcast transaction without a signed transaction raw.')
    assert(selectedAccount.client, 'Stargate client is not available.')

    const bytes = TxRaw.encode(tokenizeSharesRaw).finish()

    try {
      var tx = await broadcastTx(selectedAccount.client, bytes)
    } catch (e) {
      throw e
    }

    assertIsDeliverTxSuccess(tx)

    const { validator, share_record_id } = getTokenizedSharesAttributes(tx)

    // e.g., cosmosvaloper1uk4ze0x4nvh4fk0xm4jdud58eqn4yxhrdt795p/
    const tokenizedDenom = `${validator}/${share_record_id}`

    // Unlike most other transactions, we'll do this here since we need to get the correct amount for the next transactions.
    // @TODO: We need to notify the user by probably making a dedicated modal screen/state that balance update failed
    // due to network errors or that for some reason account balances are stale (this happens sometimes in production)
    const { selectedAccountBalances } = await refreshSelectedBalances()

    const tokenizedDenomBalance = selectedAccountBalances.find((balance) => {
      return balance.denom === tokenizedDenom
    })

    assert(tokenizedDenomBalance, 'Missing tokenized denom balance. Fetched balance is probably stale.')

    return { tokenizedDenom, tokenizedValue: tokenizedDenomBalance.amount }
  }

  const handleSuccess = async ({ tokenizedDenom, tokenizedValue }: BroadcastTokenizeSharesMutationReturnType) => {
    // This is the only way to allow React v18 to update the state first before
    // the next mutation (which requires this state to have a value) is executed.
    flushSync(() => {
      setTokenizeSharesDenom(tokenizedDenom)
      setTokenizeSharesTokenValue(tokenizedValue)
    })

    // Make sure that the validators list is updated after the account balances have been updated.
    await client.invalidateQueries({ queryKey: queryKeys.lsmValidatorsBase })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

const tokenizeSharesAttributesSchema = z.object({
  delegator: z.string(),
  validator: z.string(),
  share_owner: z.string(),
  share_record_id: z.string(),
  amount: z.string()
})

// We can get the denom given by `MsgTokenizeShares` with the format `{validator}/{shareRecordId}`
// e.g., cosmosvaloper1uk4ze0x4nvh4fk0xm4jdud58eqn4yxhrdt795p/1
// Ultimately, this function gets both data by parsing the `tokenize_shares` event from the transaction.
// Scanning for the `validator` and `share_record_id` attributes.
const getTokenizedSharesAttributes = (tx: DeliverTxResponse) => {
  const tokenizeSharesEvent = tx.events.find((event) => {
    return event.type === 'tokenize_shares'
  })

  assert(tokenizeSharesEvent, 'Missing `tokenize_shares` event from `MsgTokenizeShares` transaction.')

  return tokenizeSharesAttributesSchema.parse(convertAttributesToObject(tokenizeSharesEvent.attributes))
}

export { useBroadcastTokenizeSharesMutation }
