import React, { useMemo } from 'react'
import { useAtom } from 'jotai'
import { UnstakeModalStepOne } from './UnstakeModalStepOne'
import { UnstakeModalStepTwo } from './UnstakeModalStepTwo'
import { UnstakeModalStepThree } from './UnstakeModalStepThree'
import { UnstakeModalStepFour } from './UnstakeModalStepFour'
import { ErrorBeacon, InlineLoader, StepperModal, StepperProvider, useStepper } from '@/components'
import { claimMetaDataAtom, unstakeAmountAtom, isUnstakingModalOpenAtom } from '@/page-components/Stake/atoms'
import { useUnstakeModal } from './UnstakeModalProvider'
import { useSelectedCoin } from '@/contexts/wallet'
import { useStakeStatsQuery, ApyUnbondingPeriod } from '@/queries'
import { Group } from '@mantine/core'
import { formatDenom, formatMicroDenom } from '@/wallet-utils'

interface UnstakeModalProps {
  onSuccess: () => void
}

const formatUnbondingPeriod = (period: ApyUnbondingPeriod) => {
  return `${period.start}-${period.end} ${period.unit}`
}

const UnstakeModal: React.FC<UnstakeModalProps> = ({ onSuccess }) => {
  const {
    isBroadcastingRedeemStake,
    signRedeemStakeError,
    broadcastRedeemStakeError,
    claimTokensError,
    resetUnstakeModalState
  } = useUnstakeModal()

  const { data: stakeStats, error: stakeStatsError } = useStakeStatsQuery()

  const denom = useSelectedCoin()

  const [isOpen, setIsOpen] = useAtom(isUnstakingModalOpenAtom)

  const [claimMetaData, setClaimMetaData] = useAtom(claimMetaDataAtom)

  const [amount, setAmount] = useAtom(unstakeAmountAtom)

  const stakedDenom = useMemo(() => `st${denom}`, [denom])

  const title = claimMetaData ? 'Claiming' : 'Unstaking'

  const formattedAmount = claimMetaData
    ? `${formatMicroDenom(claimMetaData.values.amount, claimMetaData.values.denom, 3)} ${claimMetaData.values.denom}`
    : `${formatDenom(amount, 3)} ${stakedDenom}`

  const unbondingStep = stakeStatsError ? (
    <Group spacing="xs">
      Unbonding <ErrorBeacon label="Unable to load unbonding period at this time." />
    </Group>
  ) : stakeStats == null ? (
    <Group spacing="xs">
      Unbonding <InlineLoader />
    </Group>
  ) : (
    <>Unbonding ({formatUnbondingPeriod(stakeStats[denom].unbondingPeriod)})</>
  )

  const steps = ['Approve transfer in wallet', `Unstake tokens`, unbondingStep, `Claim ${denom}`]

  const handleClose = () => {
    setIsOpen(false)
    setAmount(0)
    setClaimMetaData(null)
    resetUnstakeModalState()
  }

  const stepper = useStepper({
    steps,
    warning: false,
    error: Boolean(signRedeemStakeError || broadcastRedeemStakeError || claimTokensError),
    isOpen,
    isLoading: isBroadcastingRedeemStake,
    isPostError: false,
    isComplete: false,
    onClose: handleClose,
    onComplete: onSuccess
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title={title} amount={formattedAmount}>
        <UnstakeModalStepOne />
        <UnstakeModalStepTwo />
        <UnstakeModalStepThree />
        <UnstakeModalStepFour />
      </StepperModal>
    </StepperProvider>
  )
}

export { UnstakeModal }
