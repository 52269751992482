import { useMutation, useQueryClient } from '@tanstack/react-query'
import { fatal, disregard } from '@/utils'
import { notify } from '@/contexts/notifications'
import { traceIbcStatus, IBCTransferStatus, isSafeModeAccount } from '@/wallet-utils'
import { queryKeys } from '@/query-keys'
import { MutationParameters } from './types'
import { useRefreshBalances, useStrideWallet } from '@/contexts/wallet'

export interface TraceIbcStatusMutationReturnType {
  status: IBCTransferStatus
}

// [sign/broadcast] mutations and [trace] mutation are a 2-part process.
// First, if [sign/broadcast] doesn't succeed within the UI time limit, [trace] mutation will continue
// the [tracing] process so user can continue when the ibc transfer completes if they haven't closed the modal yet.
// We can use the `isLoading` status provided by [trace] mutation to display the "Return Later" screen.
const useTraceIbcTransferMutation = ({ withdrawTransaction }: MutationParameters) => {
  const client = useQueryClient()

  const strideAccount = useStrideWallet()

  const refreshBalances = useRefreshBalances()

  const handleMutation = async (): Promise<TraceIbcStatusMutationReturnType> => {
    if (!strideAccount) {
      throw fatal('Unable to trace ibc status of withdraw transaction without connecting your wallet.')
    }

    if (isSafeModeAccount(strideAccount)) {
      throw fatal('Unable to continue tracing withdraw ibc while not fully connected.')
    }

    if (withdrawTransaction == null) {
      throw fatal('Unable to trace ibc status of withdraw transaction while transaction is empty.')
    }

    const status = await traceIbcStatus({ account: strideAccount, tx: withdrawTransaction }, { timeout: false })

    await client.invalidateQueries({
      queryKey: queryKeys.transactionHistoryIbcStatusByHash({ hash: withdrawTransaction.transactionHash })
    })

    try {
      await refreshBalances()
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }

    return { status }
  }

  return useMutation({
    mutationFn: handleMutation
  })
}

export { useTraceIbcTransferMutation }
