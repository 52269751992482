import React from 'react'
import { Button } from '@mantine/core'
import { useMount } from '@/hooks'
import { StepperModalContent, useStepperContext } from '@/components'
import { notify } from '@/contexts/notifications'
import { useUnstakeIbcxModal } from '.'
import { OSMO_CHAIN_INFO } from '@/config'

const UnstakeIbcxModalStepTwo: React.FC = () => {
  const {
    broadcastRedeemStake,
    isBroadcastingRedeemStake,
    isBroadcastRedeemIbcxSuccessful,
    broadcastRedeemStakeError
  } = useUnstakeIbcxModal()

  const { complete, forceClose, close, handleClose } = useStepperContext()

  const handleStep = async () => {
    await broadcastRedeemStake()
    complete()
  }

  const handleCloseCallback = () => {
    // If it was closed after the broadcast completed, it doesn't matter
    if (!isBroadcastingRedeemStake) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the unstake completes.")
  }

  useMount(() => {
    handleStep()
  })

  handleClose(handleCloseCallback)

  const handleRetryBroadcastStakeTokens = () => {
    handleStep()
  }

  if (broadcastRedeemStakeError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="Your tokens couldn’t be unstaked at this time. Try again in a few minutes."
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" onClick={handleRetryBroadcastStakeTokens}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isBroadcastRedeemIbcxSuccessful) {
    return (
      <StepperModalContent
        title="Success!"
        description={`You're good to go since there is no unbonding period for stIBCX. Check the IBCX balance in your wallet on ${OSMO_CHAIN_INFO.chainName}.`}
        actions={<Button onClick={close}>Done</Button>}
      />
    )
  }

  return (
    <StepperModalContent
      title="Unstaking your tokens..."
      description="Just a few seconds, unless the network is congested."
    />
  )
}

export { UnstakeIbcxModalStepTwo }
