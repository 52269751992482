import React, { useMemo } from 'react'
import { useAtom, useSetAtom } from 'jotai'
import { Group, Tooltip } from '@mantine/core'
import { formatMicroDenom } from '@/wallet-utils'
import {
  stakeModeAtom,
  withdrawStTokenToDexModalIsOpenAtom,
  withdrawStTokenToDexTransactionAtom
} from '@/page-components/Stake/atoms'
import { StepperProvider, StepperModal, useStepper, Icon, InlineLoader } from '@/components'
import { useLiquidStakeTransactionAmountQuery } from './queries'
import { useWithdrawStTokenToDex } from './WithdrawStTokenToDexProvider'
import { WithdrawStTokenToDexStepOne } from './WithdrawStTokenToDexStepOne'
import { WithdrawStTokenToDexStepTwo } from './WithdrawStTokenToDexStepTwo'
import { assert } from '@/utils'
import { CHAIN_INFO_LIST, getDexInfoFromDenom } from '@/config'
import { useDexWallet, useSelectedCoin } from '@/contexts/wallet'

const WithdrawStTokenToDexModal: React.FC = () => {
  const {
    resetWithdrawStTokenToDexState,
    signStakeToWithdrawStTokenToDexError,
    isBroadcastingStakeToWithdrawStTokenToDex,
    broadcastStakeToWithdrawStTokenToDexError,
    ibcStatusError,
    isIbcStatusLoading
  } = useWithdrawStTokenToDex()

  const { data: stakeTransactionAmount, error: stakeTransactionAmountError } = useLiquidStakeTransactionAmountQuery()

  const [isOpen, setIsOpen] = useAtom(withdrawStTokenToDexModalIsOpenAtom)

  const setTransaction = useSetAtom(withdrawStTokenToDexTransactionAtom)

  const setStakeMode = useSetAtom(stakeModeAtom)

  const denom = useSelectedCoin()

  const dexAccount = useDexWallet()

  const steps = useMemo(() => {
    // We'll return an empty array instead of a null assertion because
    // this component is unconditionally mounted on page root component.
    if (dexAccount == null) {
      return []
    }

    const dexInfo = getDexInfoFromDenom(denom)

    assert(dexInfo, `Missing dex config for ${denom}`)

    const chainInfo = CHAIN_INFO_LIST[dexAccount.currency.coinDenom]

    return [`Transfer st${denom} to ${chainInfo.chainName}`, `LP on ${dexInfo.name}`]
  }, [denom, dexAccount])

  const handleClose = () => {
    setIsOpen(false)
    setTransaction(null)
    setStakeMode(null)
    resetWithdrawStTokenToDexState()
  }

  const stepper = useStepper({
    steps,
    warning: isIbcStatusLoading,
    error: Boolean(signStakeToWithdrawStTokenToDexError || broadcastStakeToWithdrawStTokenToDexError || ibcStatusError),
    isOpen,
    isLoading: isBroadcastingStakeToWithdrawStTokenToDex || isIbcStatusLoading,
    isPostError: false,
    isComplete: false,
    onClose: handleClose
  })

  const renderAmount = () => {
    if (stakeTransactionAmountError) {
      return (
        <Group spacing="xs">
          <Tooltip label="Unable to load staked amount at this time." withArrow position="right">
            <Icon name="warning" sx={(t) => ({ color: t.colors.yellow[6] })} />
          </Tooltip>
          st{denom}
        </Group>
      )
    }

    if (stakeTransactionAmount == null) {
      return (
        <Group spacing="xs">
          <InlineLoader />
          <span>st{denom}</span>
        </Group>
      )
    }

    const chainInfo = CHAIN_INFO_LIST[denom]

    return (
      <span>
        {formatMicroDenom(stakeTransactionAmount.amount, chainInfo.stakeCurrency.coinMinimalDenom, 5)} st{denom}
      </span>
    )
  }

  return (
    <StepperProvider {...stepper}>
      <StepperModal title="Add to DEX" amount={renderAmount()}>
        <WithdrawStTokenToDexStepOne />
        <WithdrawStTokenToDexStepTwo />
      </StepperModal>
    </StepperProvider>
  )
}

export { WithdrawStTokenToDexModal }
