import z from 'zod'
import axios from 'axios'
import BigNumber from 'bignumber.js'
import { convertMicroDenomToDenom, Account, isSafeModeAccount, convertDenomToMicroDenom } from '@/wallet-utils'
import { queryKeys } from '@/query-keys'
import { useQuery } from '@tanstack/react-query'
import { fatal } from '@/utils'
import { CHAIN_INFO_LIST, CHAIN_SUPPORTS_LSM } from '@/config'
import { useSelectedWallet } from '@/contexts/wallet'

export interface LsmValidator {
  // Validator address
  address: string
  // Amount that the user staked to this specific validator, including amount that is
  // in lock-in period (21 days) due to redelegation
  amount: string
  // When staked tokens are redelegated, the balance is immediately taken away
  // from the origin validator, and immediately added to the destination validator.
  // However, redelegation introduces a lock in period (21 days), which affects all
  // of the staked tokens on the destination validator.
  redelegating: boolean
  // Max capacity of the validator that can be tokenized at a time
  remainingBondShares: string
}

export interface LsmValidatorsQueryPayload {
  validators: Array<LsmValidator>
}

const useLsmValidatorsQuery = () => {
  const selectedAccount = useSelectedWallet()

  const queryFn = async (): Promise<LsmValidatorsQueryPayload> => {
    if (!selectedAccount) {
      throw fatal('Unable to query lsm validators while disconnected.')
    }

    const chainInfo = CHAIN_INFO_LIST[selectedAccount.currency.coinDenom]

    const instance = axios.create({
      baseURL: chainInfo.rest
    })

    if (isSafeModeAccount(selectedAccount)) {
      throw fatal('Safe mode is enabled.')
    }

    const [delegations, params, redelegations] = await Promise.all([
      // Contains the list of delegations of the address per validator
      getDelegationsPerValidator(selectedAccount),
      // Used to get the bond factor for the validators data; to get the capacity
      getStakingParams(selectedAccount),
      // When staked tokens are redelegated, the balance is immediately taken away
      // from the origin validator, and immediately added to the destination validator.
      // Given that redelegation introduces a lock in period (21 days), the tokens on
      // the destination validator are not immediately available for liquid staking.
      getRedelegationRecords(selectedAccount)
    ])

    const validatorMetaData = await Promise.all(
      delegations.map(async (response) => {
        // overall capacity = ValidatorBondFactor * ValidatorBondShares (e.g., 250 * 10 = 2500)
        // remaining capacity = overall capacity - ValidatorLiquidShares (e.g., 2500 - 1000 = 1500)
        const getValidatorRemainingBondShares = async () => {
          const validatorResponse = await instance.get(
            `cosmos/staking/v1beta1/validators/${response.delegation.validator_address}`
          )

          const { validator } = validatorResponseSchema.parse(validatorResponse.data)

          // validator_bond_shares is supposedly in micro denoms, but for some reason it has decimals in it
          // we'll prune any decimals for safety since parseUnits is not able to handle this out of the box
          // + it is infinitesmally small, so we'll just round it down
          const validatorBondSharesWithFixedDecimals = new BigNumber(validator.validator_bond_shares)
            .decimalPlaces(0, BigNumber.ROUND_DOWN)
            .toString()

          const validatorBondSharesInDenom = convertMicroDenomToDenom(
            validatorBondSharesWithFixedDecimals,
            selectedAccount.currency.coinDenom
          )

          const capacity = Number(params.validator_bond_factor) * Number(validatorBondSharesInDenom)

          // liquid_shares is supposedly in micro denoms, but for some reason it has decimals in it
          // we'll prune any decimals for safety since parseUnits is not able to handle this out of the box
          // + it is infinitesmally small, so we'll just round it down
          const validatorLiquidSharesWithFixedDecimals = new BigNumber(validator.liquid_shares)
            .decimalPlaces(0, BigNumber.ROUND_DOWN)
            .toString()

          const validatorLiquidSharesInDenom = convertMicroDenomToDenom(
            validatorLiquidSharesWithFixedDecimals,
            selectedAccount.currency.coinDenom
          )

          // Despite being a denom, we'll prune *excess* decimals (unlike the above where they are completely pruned)
          // because values sometimes end up like `600.1536380000001`. In this case, `convertDenomToMicroDenom` crashes
          // due to an overflow.
          const remainingCapacityWithFixedDecimals = new BigNumber(capacity - Number(validatorLiquidSharesInDenom))
            .decimalPlaces(6, BigNumber.ROUND_DOWN)
            .toString()

          return convertDenomToMicroDenom(
            remainingCapacityWithFixedDecimals,
            selectedAccount.currency.coinDenom
          ).toString()
        }

        const remainingBondShares = await getValidatorRemainingBondShares()

        return {
          address: response.delegation.validator_address,
          amount: response.balance.amount,
          redelegating: Boolean(
            redelegations.find((record) => {
              return record.redelegation.validator_dst_address === response.delegation.validator_address
            })
          ),
          remainingBondShares
        }
      })
    )

    return {
      // @FIX https://github.com/Stride-Labs/interface/issues/462
      // We need to filter out validators with 0 amount, as they are not valid for staking.
      // This is probably a bug on the SDK, but whatever.
      validators: validatorMetaData.filter((validator) => {
        return BigInt(validator.amount) > 0
      })
    }
  }

  // Previously, we had an issue with StakeWallet displaying the UI for "Natively staked balance" which
  // is hidden if `lsmValidators == null`. The condition was not working when switching from ATOM (lsm-enabled)
  // to OSMO (lsm-disabled), and causes "natively staked balance" for a while to be displayed when it shouldn't.
  //
  // From the user's perspective, they would see the "natively staked balance" UI for a while, and then it would
  // disappear. The problem was that this query apparently has the value of the previous query key for some reason.
  //
  // @TODO: While this specific case is fixed, we likely will have an issue once we have more LSM-enabled chains.
  return useQuery({
    queryKey: queryKeys.lsmValidatorsByAddress({ address: selectedAccount?.address ?? '' }),
    queryFn,
    enabled: Boolean(selectedAccount && CHAIN_SUPPORTS_LSM[selectedAccount.currency.coinDenom])
  })
}

const delegationSchema = z.object({
  delegation: z.object({
    delegator_address: z.string(),
    validator_address: z.string(),
    // "0.000512043537117295"
    shares: z.string()
  }),

  balance: z.object({
    denom: z.string(),
    amount: z.string()
  })
})

const delegationsResponseSchema = z.object({
  delegation_responses: z.array(delegationSchema),
  pagination: z.object({ next_key: z.string().nullable() })
})

const paramsResponseSchema = z.object({
  params: z.object({
    unbonding_time: z.string(),
    max_validators: z.number(),
    max_entries: z.number(),
    historical_entries: z.number(),
    bond_denom: z.string(),
    validator_bond_factor: z.string(),
    global_liquid_staking_cap: z.string(),
    validator_liquid_staking_cap: z.string()
  })
})

const validatorResponseSchema = z.object({
  validator: z.object({
    operator_address: z.string(),
    consensus_pubkey: z.object({
      '@type': z.string(),
      key: z.string()
    }),
    jailed: z.boolean(),
    status: z.string(),
    tokens: z.string(),
    delegator_shares: z.string(),
    description: z.object({
      moniker: z.string(),
      identity: z.string(),
      website: z.string(),
      security_contact: z.string(),
      details: z.string()
    }),
    unbonding_height: z.string(),
    unbonding_time: z.string(),
    commission: z.object({
      commission_rates: z.object({
        rate: z.string(),
        max_rate: z.string(),
        max_change_rate: z.string()
      }),
      update_time: z.string()
    }),
    min_self_delegation: z.string(),
    unbonding_on_hold_ref_count: z.string(),
    // unbonding_ids: [], // No idea how this looks like at the moment
    validator_bond_shares: z.string(),
    liquid_shares: z.string()
  })
})

// Contains the list of delegations of the address per validator
// Navigates through all the pages if needed
const getDelegationsPerValidator = async (account: Account) => {
  const instance = axios.create({
    baseURL: CHAIN_INFO_LIST[account.currency.coinDenom].rest
  })

  const delegations: z.infer<typeof delegationSchema>[] = []

  let pageKey: string | null | undefined = null

  while (true) {
    const params = new URLSearchParams()

    if (pageKey) {
      // For some reason, when we append a null value, we get empty results despite
      // the address actually having some. It seems like this is very specific to this
      // endpoint; transaction history seems to work just fine!
      params.append('pagination.key', `${pageKey}`)
    }

    const response = await instance.get(`cosmos/staking/v1beta1/delegations/${account.address}?${params}`)

    const { delegation_responses, pagination } = delegationsResponseSchema.parse(response.data)

    for (const delegation of delegation_responses) {
      delegations.push(delegation)
    }

    // There is no next page
    if (!(pageKey = pagination.next_key)) {
      return delegations
    }
  }
}

// Used to get the bond factor for the validators data; to get the capacity
const getStakingParams = async (account: Account) => {
  const instance = axios.create({
    baseURL: CHAIN_INFO_LIST[account.currency.coinDenom].rest
  })

  const response = await instance.get(`cosmos/staking/v1beta1/params`)

  return paramsResponseSchema.parse(response.data).params
}

const redelegationRecordEntrySchema = z.object({
  redelegation_entry: z.object({
    creation_height: z.number(),
    completion_time: z.string(),
    initial_balance: z.string(),
    shares_dst: z.string(),
    unbonding_id: z.number()
  }),
  balance: z.string()
})

const redelegationSchema = z.object({
  redelegation: z.object({
    delegator_address: z.string(),
    validator_src_address: z.string(),
    validator_dst_address: z.string()
  }),
  entries: z.array(redelegationRecordEntrySchema)
})

const redelegationResponseSchema = z.object({
  redelegation_responses: z.array(redelegationSchema)
})

const getRedelegationRecords = async (account: Account): Promise<z.infer<typeof redelegationSchema>[]> => {
  const instance = axios.create({
    baseURL: CHAIN_INFO_LIST[account.currency.coinDenom].rest
  })

  const response = await instance.get(`cosmos/staking/v1beta1/delegators/${account.address}/redelegations`)

  return redelegationResponseSchema.parse(response.data).redelegation_responses
}

export { useLsmValidatorsQuery }
