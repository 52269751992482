import React from 'react'
import { Badge, Box, Button, Group, Space, Text, Title } from '@mantine/core'
import { useSelectedCoin } from '@/contexts/wallet'

interface LsmTutorialGetStartedStepProps {
  onStart: () => void
  onClose: () => void
}

const LsmTutorialGetStartedStep: React.FC<LsmTutorialGetStartedStepProps> = ({ onStart, onClose }) => {
  const denom = useSelectedCoin()

  return (
    <Box sx={{ maxWidth: 320 }}>
      <Badge size="sm" variant="filled">
        New
      </Badge>

      <Space h="xs" />

      <Title order={4} sx={(t) => ({ colors: t.colors.gray[9] })}>
        Turn your natively staked {denom} into liquid staked {denom}
      </Title>

      <Space h="xs" />

      <Text sx={(t) => ({ color: t.colors.gray[7] })}>No unbonding period. Fast & secure.</Text>

      <Space h="md" />

      <Group spacing="xs">
        <Button variant="outline" color="dark" onClick={onClose}>
          Dismiss
        </Button>

        <Button onClick={onStart}>Get started</Button>
      </Group>
    </Box>
  )
}

export { LsmTutorialGetStartedStep }
