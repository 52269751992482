import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { RedemptionMetaData, TxResponse } from '@/wallet-utils'
import { DeliverTxResponse } from '@cosmjs/stargate'

const stakeAmountAtom = atom<number>(0)

const stakeClassicModalIsOpenAtom = atom<boolean>(false)

const stakeAutopilotModalIsOpenAtom = atom<boolean>(false)

// Used for the "Add to DEX" flow so we can determine how to query
// the liquid stake transaction, allowing us to get the correct amount
// that was actually stake .
const stakeModeAtom = atom<'classic' | 'autopilot' | null>(null)

const claimMetaDataAtom = atom<RedemptionMetaData | null>(null)

const isUnstakingModalOpenAtom = atom(false)

const isPoolNudgeModalOpenAtom = atom(false)

const unstakeAmountAtom = atom<number>(0)

const withdrawAmountAtom = atom<number>(0)

const withdrawModalIsOpenAtom = atom<boolean>(false)

const withdrawStTokenToDexModalIsOpenAtom = atom<boolean>(false)

const withdrawStTokenToDexTransactionAtom = atom<DeliverTxResponse | TxResponse | null>(null)

const withdrawStTokenModalIsOpenAtom = atom<boolean>(false)

const withdrawStTokenAmountAtom = atom<string>('0')

const dismissedTransactionsAtom = atomWithStorage<string[]>('stride.dismissed-tx', [])

export {
  stakeAmountAtom,
  stakeClassicModalIsOpenAtom,
  stakeAutopilotModalIsOpenAtom,
  stakeModeAtom,
  claimMetaDataAtom,
  unstakeAmountAtom,
  isUnstakingModalOpenAtom,
  isPoolNudgeModalOpenAtom,
  withdrawAmountAtom,
  withdrawModalIsOpenAtom,
  withdrawStTokenToDexModalIsOpenAtom,
  withdrawStTokenModalIsOpenAtom,
  withdrawStTokenAmountAtom,
  withdrawStTokenToDexTransactionAtom,
  dismissedTransactionsAtom
}
