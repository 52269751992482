import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal, disregard } from '@/utils'
import { queryKeys } from '@/query-keys'
import { notify } from '@/contexts/notifications'
import { assertIsDeliverTxSuccess, broadcastTx } from '@/wallet-utils'
import { MutationParameters } from './types'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { getLsmLiquidStakeAttributes, lsmStatusSchema } from '../../utils'
import { useStake } from '../../StakeProvider'
import { dismissedTransactionsAtom } from '../../atoms'
import { z } from 'zod'
import { useRefreshBalances, useStrideWallet } from '@/contexts/wallet'

export interface BroadcastLiquidStakeMutationReturnType {
  transaction: DeliverTxResponse
  // We'll get the status here so that we can parse it once and read everywhere in the modal and mutations.
  // e.g., StakeLsmModal, StakeLsmModalStepFour, and useLiquidStakeQueryCallbackQuery.
  status: z.infer<typeof lsmStatusSchema>
}

// Unlike classic and autopilot, LSMLiquidStake currently does not claim any airdrop.
// In the future, let's look into adding this when chain support is added.
const useBroadcastLiquidStakeMutation = ({ liquidStakeRaw }: MutationParameters) => {
  const strideAccount = useStrideWallet()
  const updateAccountBalances = useRefreshBalances()

  const client = useQueryClient()

  const { lsm } = useStake()

  const setDismissedTxs = useSetAtom(dismissedTransactionsAtom)

  const handleMutation = async (): Promise<BroadcastLiquidStakeMutationReturnType> => {
    if (!strideAccount) {
      throw fatal('Unable to stake without connecting your wallet.')
    }

    if (!liquidStakeRaw) {
      throw fatal('Unable to broadcast transaction without a signed transaction raw.')
    }

    const bytes = TxRaw.encode(liquidStakeRaw).finish()

    if (!strideAccount.client) {
      throw fatal('Stride client does not exist.')
    }

    const transaction = await broadcastTx(strideAccount.client, bytes)

    assertIsDeliverTxSuccess(transaction)

    const attributes = getLsmLiquidStakeAttributes(transaction.events)

    return { transaction, status: attributes.transaction_status }
  }

  const handleError = async () => {
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })
  }

  const handleSuccess = async () => {
    // If this was a retry from a previously failing tranasction, we want to dismiss the previous transaction.
    if (lsm.txHistoryLsSourceHash) {
      setDismissedTxs((dismissedTxs) => {
        return [...dismissedTxs, lsm.txHistoryLsSourceHash]
      })
    }

    if (!strideAccount) {
      // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
      // But we'll return anyway instead of crashing just to be safe (given the unknowns).
      // https://github.com/Stride-Labs/interface/issues/262
      return
    }

    try {
      await updateAccountBalances()
    } catch (e) {
      disregard(e)
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
    }

    // We only want to invalidate the lsm ibc balances and transaction history after the account
    // balances have been updated, given that implementation for TokenizeShares/IBC relies on stride's
    // account balances.
    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryLsmIbcBalancesBase })

    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })
  }

  return useMutation({
    mutationFn: handleMutation,
    onError: handleError,
    onSuccess: handleSuccess
  })
}

export { useBroadcastLiquidStakeMutation }
