import { useMutation, useQueryClient } from '@tanstack/react-query'
import { fatal, disregard } from '@/utils'
import { notify } from '@/contexts/notifications'
import { traceIbcStatus, IBCTransferStatus, isSafeModeAccount } from '@/wallet-utils'
import { queryKeys } from '@/query-keys'
import { MutationParameters } from './types'
import { useRefreshBalances, useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

// [sign/broadcast] mutations and [trace] mutation are a 2-part process.
// First, if [sign/broadcast] doesn't succeed within the UI time limit, [trace] mutation will continue
// the [tracing] process so user can continue when the ibc transfer completes if they haven't closed the modal yet.
// We can use the `isLoading` status provided by [trace] mutation to display the "Return Later" screen.
const useTraceIbcTransferMutation = ({ ibcTransferTransaction }: MutationParameters) => {
  const client = useQueryClient()

  const selectedAccount = useSelectedWallet()
  const strideAccount = useStrideWallet()
  const updateAccountBalances = useRefreshBalances()

  const handleRequest = async (): Promise<{ status: IBCTransferStatus }> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('Unable to trace ibc status without connecting your wallet.')
    }

    if (ibcTransferTransaction == null) {
      throw fatal('Unable to trace ibc status while transaction is empty.')
    }

    if (isSafeModeAccount(selectedAccount)) throw fatal('Safe mode is enabled.')

    // @TODO: Handle timeout state if this doesn't succeed. The original implementation
    // from the ibc/staking modal doesn't have this yet neither. Add display states for
    const status = await traceIbcStatus({ account: selectedAccount, tx: ibcTransferTransaction }, { timeout: false })

    return { status }
  }

  const handleSuccess = async () => {
    if (ibcTransferTransaction) {
      // We likely don't need this condition given that react-query v3 has a bug with handlers having stale values.
      // https://github.com/Stride-Labs/interface/issues/262
      await client.invalidateQueries({
        queryKey: queryKeys.transactionHistoryIbcStatusByHash({ hash: ibcTransferTransaction.transactionHash })
      })
    }

    if (!strideAccount || !selectedAccount) {
      // We're unlikely to be here given that react-query v3 has a bug with handlers having stale values.
      // But we'll return anyway instead of crashing just to be safe (given the unknowns).
      // https://github.com/Stride-Labs/interface/issues/262
      return
    }

    try {
      await updateAccountBalances()
    } catch (e) {
      notify.error(`An error occured while fetching your updated account balance. Please refresh the page.`)
      disregard(e)
    }
  }

  return useMutation({
    mutationFn: handleRequest,
    onSuccess: handleSuccess
  })
}

export { useTraceIbcTransferMutation }
