import React, { useContext, useState } from 'react'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { assert } from '@/utils'
import {
  useSignWithdrawMutation,
  useBroadcastWithdrawMutation,
  useTraceIbcTransferMutation,
  BroadcastSendTokenMutationReturnType,
  TraceIbcStatusMutationReturnType,
  MutationParameters
} from './mutations'

interface WithdrawModalContextType {
  resetWithdrawModalState: () => void

  withdrawAmount: string
  setWithdrawAmount: React.Dispatch<React.SetStateAction<string>>

  signWithdraw: (amount: string) => Promise<TxRaw>
  isSigningWithdraw: boolean
  signWithdrawError: unknown

  broadcastWithdraw: () => Promise<BroadcastSendTokenMutationReturnType>
  isBroadcastingWithdraw: boolean
  isBroadcastWithdrawSuccessful: boolean
  broadcastWithdrawError: unknown

  traceIbcStatus: () => Promise<TraceIbcStatusMutationReturnType>
  isTracingIbcStatus: boolean
  traceIbcStatusError: unknown
}

const WithdrawModalContext = React.createContext<WithdrawModalContextType | null>(null)

const WithdrawModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [withdrawRaw, setWithdrawRaw] = useState<TxRaw | null>(null)

  const [withdrawTransaction, setWithdrawTransaction] = useState<DeliverTxResponse | null>(null)

  const [withdrawAmount, setWithdrawAmount] = useState<string>('0')

  const parameters: MutationParameters = {
    withdrawRaw,
    setWithdrawRaw,
    withdrawTransaction,
    setWithdrawTransaction
  }

  const {
    mutateAsync: signWithdraw,
    isPending: isSigningWithdraw,
    error: signWithdrawError,
    reset: resetSignWithdraw
  } = useSignWithdrawMutation(parameters)

  const {
    mutateAsync: broadcastWithdraw,
    isPending: isBroadcastingWithdraw,
    isSuccess: isBroadcastWithdrawSuccessful,
    error: broadcastWithdrawError,
    reset: resetBroadcastWithdraw
  } = useBroadcastWithdrawMutation(parameters)

  const {
    mutateAsync: traceIbcStatus,
    isPending: isTracingIbcStatus,
    error: traceIbcStatusError,
    reset: resetTraceIbcStatus
  } = useTraceIbcTransferMutation(parameters)

  const resetWithdrawModalState = () => {
    setWithdrawRaw(null)
    setWithdrawTransaction(null)
    resetSignWithdraw()
    resetBroadcastWithdraw()
    resetTraceIbcStatus()
  }

  return (
    <WithdrawModalContext.Provider
      value={{
        resetWithdrawModalState,

        withdrawAmount,
        setWithdrawAmount,

        signWithdraw,
        isSigningWithdraw,
        signWithdrawError,

        broadcastWithdraw,
        isBroadcastingWithdraw,
        isBroadcastWithdrawSuccessful,
        broadcastWithdrawError,

        traceIbcStatus,
        isTracingIbcStatus,
        traceIbcStatusError
      }}>
      {children}
    </WithdrawModalContext.Provider>
  )
}

const useWithdrawModal = () => {
  const context = useContext(WithdrawModalContext)
  assert(context, 'You forgot to mount WithdrawModalProvider.')
  return context
}

export { WithdrawModalProvider, useWithdrawModal }
