import { StepperModalContent } from '@/components'
import { Anchor, Box, Button, Space } from '@mantine/core'
import { RateLimitTimeUntilReset } from '../RateLimitTimeUntilReset'
import { RATE_LIMIT_DOCS_URL } from '../../constants'
import { useSelectedCoin } from '@/contexts/wallet'
import { useHostZoneQuery, useRateLimitQuery } from '../../queries'
import { getRateLimitMetaData } from '../../utils'
import { CHAIN_INFO_LIST } from '@/config'
import { convertDenomToMicroDenom, convertMicroDenomToDenom, formatDenom } from '@/wallet-utils'

interface RateLimitTightLiquidStakeModalWarningProps {
  amount: number
  onContinue: () => void
}

// @TIP: The simplest way to test this componeent and all the other parts of rate limiting is to
// find `getRateLimitMetaData` and change it up to enable the rate limit screens to be shown.
const RateLimitTightLiquidStakeModalWarning: React.FC<RateLimitTightLiquidStakeModalWarningProps> = ({
  amount,
  onContinue
}) => {
  const denom = useSelectedCoin()

  const { data: hostZone } = useHostZoneQuery()

  const { data: rateLimit } = useRateLimitQuery()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const amountInMicroDenom = convertDenomToMicroDenom(amount, chainInfo.stakeCurrency.coinMinimalDenom)

  const rateLimitMetaData = getRateLimitMetaData({ amount: amountInMicroDenom.toString(), rateLimit })

  // All of our flows often require the host zone to be queried and available.
  // However, React v18 changes something that causes components that are not
  // mounted by their parents to become mounted. Anyway, in this case, we'll just
  // return null instead of asserting.
  if (hostZone == null) {
    return null
  }

  const rateLimitMetaDataDifferenceInDenom = convertMicroDenomToDenom(
    rateLimitMetaData.difference,
    chainInfo.stakeCurrency.coinMinimalDenom
  )

  const stTokenAmount = Number(rateLimitMetaDataDifferenceInDenom) / Number(hostZone.redemption_rate)

  return (
    <StepperModalContent
      title="Rate limits have been reached"
      description={
        <>
          <Box>Some of your st{denom} will be locked on Stride until the rate limit resets.</Box>

          <Space h="xs" />

          <Box>
            Able to withdraw:{' '}
            <strong>
              {formatDenom(stTokenAmount, 3)} st{denom}
            </strong>
          </Box>

          <Space h="xs" />

          <Box>
            <RateLimitTimeUntilReset />
          </Box>

          <Space h="xs" />

          <Box>
            Liquid staking itself remains unaffected. You can learn more about this{' '}
            <Anchor href={RATE_LIMIT_DOCS_URL} target="_blank">
              here
            </Anchor>
            .
          </Box>
        </>
      }
      actions={<Button onClick={onContinue}>Continue</Button>}
    />
  )
}

export { RateLimitTightLiquidStakeModalWarning }
