import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeliverTxResponse } from '@cosmjs/stargate'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { notify } from '@/contexts/notifications'
import { fatal, disregard } from '@/utils'
import { queryKeys } from '@/query-keys'
import { assertIsDeliverTxSuccess, traceIbcStatus, IBCTransferStatus, Account, broadcastTx } from '@/wallet-utils'
import { MutationParameters } from './types'
import { useRefreshBalances, useWallet } from '@/contexts/wallet'
import { flushSync } from 'react-dom'

export type BroadcastSendTokenMutationMode = 'deposit' | 'withdraw'

export interface BroadcastSendTokenMutationReturnType {
  transaction: DeliverTxResponse
  status: IBCTransferStatus
}

const useBroadcastWithdrawMutation = ({ withdrawRaw, setWithdrawTransaction }: MutationParameters) => {
  const { selectedAccount, strideAccount } = useWallet()

  const refreshBalances = useRefreshBalances()

  const client = useQueryClient()

  const handleMutation = async (): Promise<BroadcastSendTokenMutationReturnType> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    if (!withdrawRaw) {
      throw fatal('Unable to broadcast transaction without a signed transaction raw.')
    }

    const bytes = TxRaw.encode(withdrawRaw).finish()

    if (!strideAccount.client) {
      throw fatal('Stride stargate client does not exist')
    }

    const transaction = await broadcastTx(strideAccount.client, bytes)

    await client.invalidateQueries({ queryKey: queryKeys.transactionHistoryBase })

    assertIsDeliverTxSuccess(transaction)

    const status = await traceIbcStatus({ account: selectedAccount as Account, tx: transaction })

    await client.invalidateQueries({
      queryKey: queryKeys.transactionHistoryIbcStatusByHash({ hash: transaction.transactionHash })
    })

    if (status === 'return-later') {
      return {
        transaction: transaction,
        status: 'return-later'
      }
    }

    try {
      await refreshBalances()
    } catch (e) {
      notify.error(`We could not refresh your transaction history. Please refresh the page upon closing this modal.`)
      disregard(e)
    }

    // We'll handle this after balance is updated because gas fee is still consumed
    // @TODO: It's possible that we may have to wait for funds to be fully refunded
    // @TODO: Handle error - we probably need a flow for this
    if (status === 'timeout') {
      return {
        transaction: transaction,
        status: 'timeout'
      }
    }

    return {
      transaction: transaction,
      status: 'complete'
    }
  }

  const handleSuccess = ({ transaction }: BroadcastSendTokenMutationReturnType) => {
    // Allow updates to propagate so trace function has access to this when it's ran asynchronously
    flushSync(() => {
      setWithdrawTransaction(transaction)
    })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  })
}

export { useBroadcastWithdrawMutation }
