import { useQuery } from '@tanstack/react-query'
import { redis } from '@/redis'
import { queryKeys } from '@/query-keys'
import { fatal } from '@/utils'
import { CHAIN_SUPPORTS_ADD_TO_DEX } from '@/config'
import { useDexWallet, useSelectedWallet } from '@/contexts/wallet'

const useDexPoolApyQuery = () => {
  const selectedAccount = useSelectedWallet()
  const dexAccount = useDexWallet()

  const handleRequest = async (): Promise<{ apy: number }> => {
    if (!selectedAccount) {
      throw fatal('Unable to fetch dex apy while disconnected.')
    }

    const denom = selectedAccount.currency.coinDenom

    // sheet_POOL_TOTAL_APR_stINJ/INJ
    const value = await redis.get<number>(`sheet_POOL_TOTAL_APR_st${denom}/${denom}`)

    return { apy: value ?? 0 }
  }

  return useQuery({
    queryKey: queryKeys.stakingModalDexPoolApyByDenom({ denom: selectedAccount?.currency.coinDenom ?? '' }),
    queryFn: handleRequest,
    enabled: Boolean(dexAccount && selectedAccount && CHAIN_SUPPORTS_ADD_TO_DEX[selectedAccount.currency.coinDenom])
  })
}

export { useDexPoolApyQuery }
