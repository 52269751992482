import React, { useState } from 'react'

interface StakeIbcxContextType {
  stakeModal: {
    isOpen: boolean
    amount: number
  }

  setStakeModal: (value: StakeIbcxContextType['stakeModal']) => void

  unstakeModal: {
    isOpen: boolean
    amount: number
  }

  setUnstakeModal: (value: StakeIbcxContextType['stakeModal']) => void
}

const StakeIbcxContext = React.createContext<StakeIbcxContextType | null>(null)

const StakeIbcxProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [stakeModal, setStakeModal] = useState<StakeIbcxContextType['stakeModal']>({
    isOpen: false,
    amount: 0
  })

  const [unstakeModal, setUnstakeModal] = useState<StakeIbcxContextType['unstakeModal']>({
    isOpen: false,
    amount: 0
  })

  return (
    <StakeIbcxContext.Provider
      value={{
        stakeModal,
        setStakeModal,
        unstakeModal,
        setUnstakeModal
      }}>
      {children}
    </StakeIbcxContext.Provider>
  )
}

const useStakeIbcx = () => {
  const context = React.useContext(StakeIbcxContext)

  if (context == null) {
    throw new Error('useStakeIbcx must be used within a StakeIbcxProvider')
  }

  return context
}

export { StakeIbcxProvider, useStakeIbcx }
