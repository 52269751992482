import React, { useMemo } from 'react'
import { InlineLoader } from '@/components'
import { useLsmValidatorPersonaQuery } from '../../queries'
import { ellipsis } from '@/utils'

interface LsmValidatorNameProps {
  // We'll make this optional which means that validator's
  // address is still being fetched (like for redelegation)
  address?: string
}

// @TODO: Handle errors
const LsmValidatorName: React.FC<LsmValidatorNameProps> = ({ address }) => {
  const { data: lsmValidatorPersona, isLoading: isLsmValidatorPersonaLoading } = useLsmValidatorPersonaQuery({
    address
  })

  const formattedValidator = useMemo(() => {
    if (address == null) return ''
    return ellipsis(address, 30)
  }, [address])

  if (address == null || isLsmValidatorPersonaLoading) {
    return <InlineLoader />
  }

  return <>{lsmValidatorPersona?.name || formattedValidator}</>
}

export { LsmValidatorName }
