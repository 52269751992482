import { useMutation } from '@tanstack/react-query'
import { CHAIN_CONFIG } from '@/config'
import {
  generateIbcTimeoutTimestamp,
  isSafeModeAccount,
  simulate,
  calculateFeeWithHelper,
  deductFeeFromTxAmountWhileEmptyStrd
} from '@/wallet-utils'
import { assert } from '@/utils'
import { MutationParameters } from './types'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { useSelectedWallet, useStrideWallet } from '@/contexts/wallet'

export interface SignWithdrawStTokenMutationReturnType {
  raw: TxRaw

  // For this flow, rate limiting may affect the amount that was actually sent.
  // If the amount being withdrawn only exceeds the capacity of the chain, we send the
  // capacity of the chain. If the transaction worked, the simplest and most reliable way
  // to get the amount in the transaction is by simply storing it into a state.
  //
  // To elaborate, actual IBC is slow. On top of this, the actual IBC experience is even
  // way slower. Given that rate limiting query is refetched more oftenly, it's possible that by then
  // capacity is already zero.
  amount: string
}

const useSignWithdrawStTokenMutation = ({ setWithdrawStTokenRaw }: MutationParameters) => {
  const strideAccount = useStrideWallet()

  const selectedAccount = useSelectedWallet()

  const handleMutation = async (amount: string): Promise<SignWithdrawStTokenMutationReturnType> => {
    assert(strideAccount, 'Uanble to send st tokens out of Stride without connecting your Stride wallet.')
    assert(
      !isSafeModeAccount(strideAccount),
      'Unable to send st tokens out of Stride without the Stride Stargate client'
    )
    assert(selectedAccount, 'Unable to send st tokens out of Stride without connecting your selected wallet.')

    const stakedDenom = `st${selectedAccount.currency.coinMinimalDenom}`

    const sourceChannel = CHAIN_CONFIG[selectedAccount.currency.coinDenom].withdrawChannel

    const createMsgTransfer = (amount: string) => {
      return {
        typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
        value: {
          sourcePort: 'transfer',
          sourceChannel: sourceChannel,
          token: {
            amount,
            denom: stakedDenom
          },
          sender: strideAccount.address,
          receiver: selectedAccount.address,
          memo: '',
          timeoutTimestamp: generateIbcTimeoutTimestamp()
        }
      }
    }

    const msgTransferForSimulation = createMsgTransfer(amount)
    const gas = await simulate(strideAccount.client, strideAccount.address, [msgTransferForSimulation], '')
    const { fee, deductible } = calculateFeeWithHelper(selectedAccount.currency.coinMinimalDenom, gas)

    // Allot a small portion of the user's tokens for the transaction gas fee
    //
    // Testing Guide (Preparation):
    // 1. Setup a fresh account, and send a small amount of tokens to it (e.g., 1 SAGA).
    // 2. Liquid Stake
    // 3. Proceed with "Withdraw St Token" flow.
    // 4. Inspect the transaction amount and you'll find that it should be less than the full amount.
    //
    // @TODO: Only deduct if the user does not have enough tokens for gas
    const amountWithSafetyDeduction = deductFeeFromTxAmountWhileEmptyStrd(
      strideAccount.coins,
      BigInt(amount),
      deductible
    )

    const msgTransfer = createMsgTransfer(amountWithSafetyDeduction.toString())

    return {
      raw: await strideAccount.client.sign(strideAccount.address, [msgTransfer], fee, ''),
      amount
    }
  }

  const handleSuccess = ({ raw }: SignWithdrawStTokenMutationReturnType) => {
    setWithdrawStTokenRaw(raw)
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  });
}

export { useSignWithdrawStTokenMutation }
