import { Icon, TextLoader, useFormContext } from '@/components'
import { Alert, Box, Divider, Group, Space, Text, TextInput, UnstyledButton, ActionIcon, Badge } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import BigNumber from 'bignumber.js'
import { useMemo, useState } from 'react'
import { CHAIN_CONFIG, CHAIN_INFO_LIST, CHAIN_SUPPORTS_REFERRAL } from '@/config'
import { StakeLsmEstimate } from './StakeLsmEstimate'
import { StakeFormType } from './useStakeForm'
import { useLsmValidatorsQuery } from '../queries'
import { StakeLsmSelection } from './StakeLsmSelection'
import { useStake } from '../StakeProvider'
import { LsmValidatorAvatar } from '../shared/LsmValidatorAvatar/LsmValidatorAvatar'
import { LsmValidatorName } from '../shared'
import { useSelectedBalancesV2, useSelectedCoin, useStrideWallet, useWallet } from '@/contexts/wallet'
import { convertDenomToMicroDenom, convertMicroDenomToDenom, formatMicroDenom } from '@/wallet-utils'
import { flushSync } from 'react-dom'
import { useLatestValue } from '@/hooks'
import { assert, env } from '@/utils'
import { useReferral } from '@/page-components/Referral'

interface Props {
  amountInputRef: React.RefObject<HTMLInputElement>
}

const StakeLsmForm: React.FC<Props> = ({ amountInputRef }) => {
  const strideAccount = useStrideWallet()

  const denom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const { isFetchingSelectedAccount } = useWallet()

  const { data: selectedBalances } = useSelectedBalancesV2()

  const [isFocused, setIsFocused] = useState(false)

  const { lsm, setLsmValidatorAddress } = useStake()

  const { values, setFieldValue, getInputProps, validate } = useFormContext<StakeFormType>()

  // `validate` seems to validate against a set of cached values (on the same time when the fn is created) rather than the latest values.
  // So, we'll store the latest reference to the validate function and use that instead.
  const validateRef = useLatestValue(validate)

  const { data: lsmValidators } = useLsmValidatorsQuery()

  const { referral } = useReferral()

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const selectedValidator = useMemo(() => {
    return lsmValidators?.validators.find((validator) => {
      return validator.address === lsm.validatorAddress
    })
  }, [lsm, lsmValidators])

  const selectedValidatorStakedAmount = BigInt(selectedValidator?.amount ?? 0)

  const recommendedMaxAmount = useMemo(() => {
    const selectedValidatorStakedAmountInDenom = convertMicroDenomToDenom(
      selectedValidatorStakedAmount,
      chainInfo.stakeCurrency.coinMinimalDenom
    )

    const maxRoundedDown = new BigNumber(selectedValidatorStakedAmountInDenom).decimalPlaces(5).toNumber()
    return Math.max(maxRoundedDown, 0)
  }, [selectedValidatorStakedAmount])

  const handleClickMax = () => {
    // We'll need to flush the state to ensure that the validate function is updated first before we run it.
    // The other forms do not have this problem because the value of recommendedMaxAmount is the same max
    // value used in the validation.
    flushSync(() => {
      setFieldValue('lsmAmount', String(recommendedMaxAmount))
    })
    assert(validateRef.current, 'Stale reference to validate function')
    // If we only call validate() here, it will use the stale reference to the validate function
    validateRef.current()
  }

  const handleClickHalf = () => {
    const selectedValidatorStakedAmountInDenom = convertMicroDenomToDenom(
      selectedValidatorStakedAmount,
      chainInfo.stakeCurrency.coinMinimalDenom
    )

    const halfRoundedDown = new BigNumber(selectedValidatorStakedAmountInDenom).dividedBy(2).decimalPlaces(5)
    const half = BigNumber.max(halfRoundedDown, 0)
    setFieldValue('lsmAmount', half.toString())
  }

  useDidUpdate(() => {
    if (!isFocused) return
    // We only want to validate if the user changed something,
    // but not if reset by a successful stake
    // We're strictly not validating when focus changes as it
    // causes the form to validate when we autofocus on mount
    // or when stake modal closes and reset the form
    validate()
  }, [values.lsmAmount])

  // If the user is logged in with a wallet that does not have sufficient unstaked token (e.g., no ATOM),
  // we'll display a note that their transaction may fail.
  const isTransferringWithoutSufficientBalanceForGas = selectedBalances?.selectedAccountBalance
    ? BigInt(selectedBalances.selectedAccountBalance) < convertDenomToMicroDenom(CHAIN_CONFIG[denom].minimumGas, denom)
    : false

  if (!selectedValidator) {
    return <StakeLsmSelection />
  }

  const handleUnselectValidator = () => {
    setLsmValidatorAddress('')
    setFieldValue('lsmAmount', '')
  }

  return (
    <>
      <Box>
        <Text inline sx={(t) => ({ color: t.colors.gray[9] })}>
          Amount to stake:
        </Text>

        <Space h="xs" />

        <Group spacing="xs">
          <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
            {denom}
          </Text>

          {env('STRIDE_ENV') === 'development' && referral && CHAIN_SUPPORTS_REFERRAL[denom] && (
            <Badge color="gray" variant="filled" size="xs">
              {referral}
            </Badge>
          )}
        </Group>
      </Box>

      <Space h="lg" />

      <Group spacing="sm">
        <ActionIcon variant="filled" onClick={handleUnselectValidator}>
          <Box sx={{ transform: 'rotate(-90deg)' }}>
            <Icon name="caretDown" size={12} />
          </Box>
        </ActionIcon>

        <Group spacing="xs">
          <LsmValidatorAvatar address={selectedValidator.address} size="sm" />

          <Text inline size="lg" weight={600}>
            <LsmValidatorName address={selectedValidator.address} />
          </Text>
        </Group>
      </Group>

      <Space h="sm" />

      <TextInput
        ref={amountInputRef}
        required
        placeholder="0"
        size="xl"
        sx={(t) => ({
          input: {
            fontWeight: 'bold',
            textAlign: 'right',
            color: t.colors.gray[9],
            '&::placeholder': { color: t.colors.gray[9] },
            '&:focus:not(.mantine-TextInput-invalid)': {
              border: `1px solid ${t.colors.gray[6]}`
            }
          }
        })}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...getInputProps('lsmAmount')}
      />

      {isTransferringWithoutSufficientBalanceForGas && (
        <>
          <Space h="xs" />

          <Alert color="yellow">
            <Text sx={(t) => ({ color: t.colors.gray[7] })}>
              It looks like you don't have enough unstaked {denom}. We recommend to have at least{' '}
              {CHAIN_CONFIG[denom].minimumGas} unstaked {denom} in your wallet to cover the gas fees associated with
              liquid staking. The transaction may fail if you ran out of gas.
            </Text>
          </Alert>
        </>
      )}

      {Boolean(strideAccount) && (
        <>
          <Space h="xs" />

          <Group position="apart" align="center">
            <Group spacing={0}>
              <Text sx={(t) => ({ color: t.colors.gray[7] })}>Available in wallet:</Text>
              &nbsp;
              <TextLoader loading={isFetchingSelectedAccount} sx={(t) => ({ color: t.colors.gray[7] })}>
                <>
                  {formatMicroDenom(selectedValidatorStakedAmount, chainInfo.stakeCurrency.coinMinimalDenom, 5)} {denom}
                </>
              </TextLoader>
            </Group>

            <Group spacing="sm">
              <UnstyledButton onClick={handleClickHalf}>
                <Text sx={(t) => ({ color: t.colors.pink[7] })}>Add half</Text>
              </UnstyledButton>

              <UnstyledButton onClick={handleClickMax}>
                <Text sx={(t) => ({ color: t.colors.pink[7] })}>Add max</Text>
              </UnstyledButton>
            </Group>
          </Group>
        </>
      )}

      <Space h="lg" />

      <Divider />

      <Space h="lg" />

      <Group position="apart" align="center">
        <Box>
          <Text inline sx={(t) => ({ color: t.colors.gray[9] })}>
            What you'll get:
          </Text>

          <Space h="xs" />

          <Text weight={700} size="xl" inline sx={(t) => ({ color: t.colors.gray[9] })}>
            st{denom}
          </Text>
        </Box>

        <StakeLsmEstimate />
      </Group>
    </>
  )
}

export { StakeLsmForm }
