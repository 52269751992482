import { RedemptionMetaData } from '@/wallet-utils'
import { RedemptionMetaDataFactoryParameters } from './types'

const createRedemptionMetaData = ({
  unbonding,
  selectedAccount
}: RedemptionMetaDataFactoryParameters): RedemptionMetaData => {
  const meta: RedemptionMetaData = {
    type: 'unstaking',
    values: {
      amount: unbonding.amount,
      // @TODO: Doesn't seem necessary since we can just use `selectedAccount.address`
      receiver: unbonding.receiver,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // @NOTE We might need this in v2 given that the new "transaction history" likely displays all transactions
      // regardless of "selected" chain.
      denom: selectedAccount.currency.coinDenom,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // @NOTE We might need this in v2 given that the new "transaction history" likely displays all transactions
      // regardless of "selected" chain.
      minimalDenom: selectedAccount.currency.coinMinimalDenom,
      // Used in claim flow to verify that the unbonding was claimed and to
      // confirm that redemption record no longer exists. This makes sure that
      // when the user exits the flow, it will stop existing in the transaction
      // history.
      epochNumber: unbonding.unbonding_estimated_time,
      // Correct property to check if the unbonding is actually claimable
      isClaimable: unbonding.claim_is_pending,
      // Estimate date for when this unbonding may be claimable
      // Unlike all of our other transactions, we've moved this from beside "values" to inside "values"
      // It makes sense to go in this direction for all of transaction history; in my experience, each
      // transaction type is rather distinct and rarely share the same properties. And when they do,
      // it often has different intent.
      claimableAt: unbonding.unbonding_estimated_time
    }
  }

  return meta
}

export { createRedemptionMetaData }
