import React from 'react'
import { Box, Space, Group, Text } from '@mantine/core'

import { CoinSelector } from '@/page-components/shared'
import { TextHeading, TextLoader } from '@/components'
import { useMediumScreen } from '@/hooks'

import { FEATURE_FLAGS } from '@/feature-flags'
import { useStIbcxApyQuery } from './queries'
import BigNumber from 'bignumber.js'
import { useSelectedCoin } from '@/contexts/wallet'

const StakeIbcxCoinSelector: React.FC = () => {
  const selectedCoinDenom = useSelectedCoin()

  const { data: apy, isLoading: isStakeStatsLoading } = useStIbcxApyQuery()

  const apyRate = `${BigNumber(apy ?? 0)
    .decimalPlaces(2)
    .toString()}%`

  const { isMediumScreen } = useMediumScreen()

  return (
    <Group position="apart" align="center" pb="md">
      {FEATURE_FLAGS.sidebar && isMediumScreen && <TextHeading>{selectedCoinDenom}</TextHeading>}
      {(!FEATURE_FLAGS.sidebar || !isMediumScreen) && <CoinSelector />}

      <Box hidden={!isMediumScreen}>
        <Text
          weight={600}
          inline
          sx={(t) => ({
            color: t.colors.gray[9]
          })}>
          APY
        </Text>
        <Space h="xs" />
        <TextLoader
          loading={isStakeStatsLoading}
          sx={(t) => ({
            color: t.colors.gray[9],
            fontSize: 26
          })}
          weight={700}
          inline>
          {apyRate}
        </TextLoader>
      </Box>
    </Group>
  )
}

export { StakeIbcxCoinSelector }
