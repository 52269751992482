import React, { useMemo } from 'react'
import { useAtom } from 'jotai'
import { StepperProvider, StepperModal, useStepper } from '@/components'
import { useWithdrawModal } from './WithdrawModalProvider'
import { WithdrawStepOne } from './WithdrawStepOne'
import { WithdrawStepTwo } from './WithdrawStepTwo'
import { withdrawModalIsOpenAtom } from '../atoms'
import { useSelectedCoin } from '@/contexts/wallet'
import { formatMicroDenom } from '@/wallet-utils'
import { CHAIN_INFO_LIST } from '@/config'

const WithdrawModal: React.FC = () => {
  const {
    withdrawAmount: amount,
    setWithdrawAmount,
    isBroadcastingWithdraw,
    isTracingIbcStatus,
    signWithdrawError,
    broadcastWithdrawError,
    resetWithdrawModalState
  } = useWithdrawModal()

  const denom = useSelectedCoin()

  const [isOpen, setIsOpen] = useAtom(withdrawModalIsOpenAtom)

  const chainInfo = CHAIN_INFO_LIST[denom]

  const formattedAmount = `${formatMicroDenom(amount, chainInfo.stakeCurrency.coinMinimalDenom, 3)} ${denom}`

  const steps = useMemo(() => {
    return ['Approve transfer in wallet', `Transfer ${denom}`]
  }, [denom])

  const handleClose = () => {
    setWithdrawAmount('0')
    setIsOpen(false)
    resetWithdrawModalState()
  }

  const stepper = useStepper({
    steps,
    warning: isTracingIbcStatus,
    error: Boolean(signWithdrawError || broadcastWithdrawError),
    isOpen,
    isLoading: isBroadcastingWithdraw || isTracingIbcStatus,
    isPostError: false,
    isComplete: false,
    onClose: handleClose
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title="Withdraw" amount={formattedAmount}>
        <WithdrawStepOne />
        <WithdrawStepTwo />
      </StepperModal>
    </StepperProvider>
  )
}

export { WithdrawModal }
