import { DymLiquidStakeMetaData } from '@/wallet-utils'
import { MsgLiquidStakeSDKType as DymMsgLiquidStake } from 'stridejs/types/codegen/stride/stakedym/tx'
import { MetaDataFactoryParameters } from './types'

const createDymLiquidStakeMetaData = ({
  response,
  message,
  selectedAccount
}: MetaDataFactoryParameters<DymMsgLiquidStake>) => {
  const meta: DymLiquidStakeMetaData = {
    type: 'dym-liquid-stake',
    values: {
      hash: response.txhash,
      // Let's add a new property called stakedAmount so we're much more accurate
      // instead of trying to calculate it using the current redemption rate which
      // may be different than when the actual stake occurred.
      amount: message.native_amount,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // This assumes that all of the transactions are from Stride
      denom: selectedAccount.currency.coinDenom,
      // We are hard-coding this so we don't have to write type-guards.
      // @TODO In the future, maybe on v2, let's remove this as we don't seem to need this to be strictly typed.
      // This assumes that all of the transactions are from Stride
      minimalDenom: selectedAccount.currency.coinMinimalDenom
    }
  }

  return meta
}

export { createDymLiquidStakeMetaData }
