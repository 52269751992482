import { getIbcxPortfolioAssets, IBCX_CURRENCY } from '@/config/ibcx'
import { Int } from '@keplr-wallet/unit'
import { SwapInfosCompact, SwapInfoCompact } from '@many-things/ibcx-contracts-sdk/types/contracts/Periphery.types'
import { StablePool, StablePoolRaw, WeightedPool, WeightedPoolRaw, OptimizedRoutes } from '@osmosis-labs/pools'
import BigNumber from 'bignumber.js'
import { PoolRaw } from './useOsmosisPoolsQuery'

/**
 * Osmosis Pool Route Calculation
 */
const STABLE_POOL_TYPE = '/osmosis.gamm.poolmodels.stableswap.v1beta1.Pool'

export const getOsmosisOptimizedRoutes = (rawPools: PoolRaw[]) => {
  const pools = rawPools.map((pool) => {
    if (pool['@type'] === STABLE_POOL_TYPE) {
      return new StablePool(pool as StablePoolRaw)
    }

    return new WeightedPool(pool as WeightedPoolRaw)
  })

  const optimizedRoutes = new OptimizedRoutes(pools, [], '')

  return optimizedRoutes
}

interface GetMintRouteInfoParams {
  assetInDenom: string
  amount: string
  rawPools: PoolRaw[]
  isStIbcx?: boolean
}

const getMintRouteInfo = ({
  assetInDenom,
  amount,
  rawPools,
  isStIbcx = false
}: GetMintRouteInfoParams): SwapInfosCompact => {
  const optimizedRoutes = getOsmosisOptimizedRoutes(rawPools)

  const portfolio = Object.values(getIbcxPortfolioAssets({ isStIbcx }))

  const routedPath = portfolio.map((denom) => {
    if (denom === assetInDenom) {
      return undefined
    }

    const paths = optimizedRoutes.getOptimizedRoutesByTokenIn(
      {
        denom: assetInDenom,
        amount: new Int(amount)
      },
      denom,
      4
    )

    let largestPathIdx = 0
    paths.forEach((path, idx) => {
      if (paths[largestPathIdx].amount.lt(path.amount)) {
        largestPathIdx = idx
      }
    })

    const { tokenOutDenoms } = paths[largestPathIdx]
    const routeDenoms = [assetInDenom, ...tokenOutDenoms]

    const routes = paths[largestPathIdx].pools.map((pool, i) => `${pool.id},${routeDenoms[i]}`)

    const swapInfo = {
      key: `${assetInDenom},${denom}`,
      routes
    }
    return swapInfo
  })

  const mintRoutePathInfo = routedPath.filter((path) => path !== undefined) as SwapInfoCompact[]

  return mintRoutePathInfo
}

interface GetBurnRouteInfoParams {
  assetOutDenom: string
  amount: string
  rawPools: PoolRaw[]
  isStIbcx?: boolean
}
const getBurnRouteInfo = ({
  assetOutDenom,
  amount,
  rawPools,
  isStIbcx = false
}: GetBurnRouteInfoParams): SwapInfosCompact => {
  const optimizedRoutes = getOsmosisOptimizedRoutes(rawPools)

  const portfolio = Object.values(getIbcxPortfolioAssets({ isStIbcx }))

  const routedPath = portfolio.map((denom) => {
    if (denom === assetOutDenom) {
      return undefined
    }

    const paths = optimizedRoutes.getOptimizedRoutesByTokenIn(
      {
        denom: assetOutDenom,
        amount: new Int(amount)
      },
      denom,
      4
    )

    let largestPathIdx = 0
    paths.forEach((path, idx) => {
      if (paths[largestPathIdx].amount.lt(path.amount)) {
        largestPathIdx = idx
      }
    })

    const { tokenOutDenoms } = paths[largestPathIdx]

    const routeDenoms = [assetOutDenom, ...tokenOutDenoms]

    const routes = paths[largestPathIdx].pools.map((pool, i) => `${pool.id},${routeDenoms[i]}`).reverse()

    const swapInfo = {
      key: `${denom},${assetOutDenom}`,
      routes
    }

    return swapInfo
  })

  const burnRoutePathInfo = routedPath.filter((path) => path !== undefined) as SwapInfoCompact[]

  return burnRoutePathInfo
}

export interface GetSwapInfoArgs {
  pools: PoolRaw[]
  inputAmount: string
  isRedeem?: boolean
}

export const getIbcxSwapInfo = ({ pools, inputAmount, isRedeem }: GetSwapInfoArgs) => {
  const multiplierValue = new BigNumber(`1e+${IBCX_CURRENCY.coinDecimals}`)
  const amount = new BigNumber(inputAmount).multipliedBy(multiplierValue).toString()

  const mintSwapInfo = pools
    ? getMintRouteInfo({
        assetInDenom: 'uosmo',
        amount,
        rawPools: pools,
        isStIbcx: isRedeem ? false : true
      })
    : undefined

  const burnSwapInfo = pools
    ? getBurnRouteInfo({
        assetOutDenom: 'uosmo',
        amount,
        rawPools: pools,
        isStIbcx: isRedeem ? true : false
      })
    : undefined

  return { mintSwapInfo, burnSwapInfo }
}
