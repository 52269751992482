import React, { useContext, useState } from 'react'
import { TxRaw } from 'cosmjs-types/cosmos/tx/v1beta1/tx'
import { fatal } from '@/utils'
import {
  useBroadcastRedeemTokenForSharesMutation,
  useSignRedeemTokenForSharesMutation,
  BroadcastRedeemTokenForsharesMutationReturnType,
  MutationParameters
} from './mutations'

interface WithdrawLsmFromTokenizeContextType {
  resetWithdrawLsmFromTokenizeState: () => void

  signRedeemTokenForShares: () => Promise<TxRaw>
  isSigningRedeemTokenForShares: boolean
  signRedeemTokenForSharesError: unknown

  broadcastRedeemTokenForShares: () => Promise<BroadcastRedeemTokenForsharesMutationReturnType>
  isBroadcastingRedeemTokenForShares: boolean
  isBroadcastRedeemTokenForSharesSuccess: boolean
  broadcastRedeemTokenForSharesError: unknown
}

const WithdrawStTokenContext = React.createContext<WithdrawLsmFromTokenizeContextType | null>(null)

const WithdrawLsmFromTokenizeModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [redeemTokensRaw, setRedeemTokensRaw] = useState<TxRaw | null>(null)

  const parameters: MutationParameters = {
    redeemTokensRaw,
    setRedeemTokensRaw
  }

  const {
    mutateAsync: signRedeemTokenForShares,
    isPending: isSigningRedeemTokenForShares,
    error: signRedeemTokenForSharesError,
    reset: resetSignRedeemTokenForShares
  } = useSignRedeemTokenForSharesMutation(parameters)

  const {
    mutateAsync: broadcastRedeemTokenForShares,
    isPending: isBroadcastingRedeemTokenForShares,
    isSuccess: isBroadcastRedeemTokenForSharesSuccess,
    error: broadcastRedeemTokenForSharesError,
    reset: resetBroadcastRedeemTokenForShares
  } = useBroadcastRedeemTokenForSharesMutation(parameters)

  const resetWithdrawStTokenState = () => {
    resetSignRedeemTokenForShares()
    resetBroadcastRedeemTokenForShares()
  }

  return (
    <WithdrawStTokenContext.Provider
      value={{
        resetWithdrawLsmFromTokenizeState: resetWithdrawStTokenState,

        signRedeemTokenForShares,
        isSigningRedeemTokenForShares,
        signRedeemTokenForSharesError,

        broadcastRedeemTokenForShares,
        isBroadcastingRedeemTokenForShares,
        isBroadcastRedeemTokenForSharesSuccess,
        broadcastRedeemTokenForSharesError
      }}>
      {children}
    </WithdrawStTokenContext.Provider>
  )
}

const useWithdrawLsmFromTokenizeModal = () => {
  const context = useContext(WithdrawStTokenContext)

  if (context == null) {
    throw fatal('You forgot to mount WithdrawLsmFromTokenizeModalProvider.')
  }

  return context
}

export { WithdrawLsmFromTokenizeModalProvider, useWithdrawLsmFromTokenizeModal }
