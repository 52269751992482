import BigNumber from 'bignumber.js'
import { Account, SelectedCoinDenom, convertDenomToMicroDenom } from '@/wallet-utils'
import { CHAIN_CONFIG, CHAIN_INFO_LIST, STRIDE_CHAIN_INFO, TIA_LIQUID_STAKE_STRD_GAS_ESTIMATE } from '@/config'

interface IsSafetyDeductionRequiredParameters {
  amount: bigint
  denom: SelectedCoinDenom
  strideAccount: Account
}

// Classic Liquid Staking is a 2-step transaction - IBC then Liquid Staking.
// You'll notice we only did this for Liquid Staking itself. We found that a lot of users, even technical
// users get often get stuck here. We have made safety rail for this: "Add max" deducts 0.1 from
// the wallet's full balance, and we have a warning message when users try to stake all of their tokens.
//
// However, this safety rail only covers the IBC Step, not the Liquid Stake step. We found this the hard way.
// Once you've sent X amount of tokens to Stride and use the same amount to Liquid Stake, you'll realize
// it will fail due to code 5 (INSUFFICIENT_FUNDS). This was tricky to wrap my mind into, but it made sense.
// I mean, what are they going to use as gas?
//
// I had no idea this was a problem because I always had STRD in my wallet. Most users won't have STRD in their wallets,
// just the specific token they care about! To elaborate further, wallets will often cycle through all of the chain's
// supported assets. On Stride, If you have STRD, it will be used as gas. If you have SAGA,
//
// For testing, setup new Keplr wallet and try these out:
// Test A: Send TIA to [fresh account], then IBC to Stride. Liquid Stake via Stride Frontend
// Expectation: X amount was deducted from the amount being liquid staked.
// Test B: Send TIA and STRD to [fresh account]'s TIA and STRD addresses. Liquid Stake via Stride Frontend
// Expectation: Full amount was used to liquid stake, no deductions
// Test C: Send TIA to [fresh account]. Liquid Stake via Stride Frontend
// Expectation: X amount was deducted from the amount being liquid staked (identical results to A, but guarantees
// that the full flow works)
//
// For context, this feature was first introduced for TIA, and TIA currently does not and cannot support
// Autopilot since it's a multisig module, and thus only uses Classic Liquid Staking (sure to run this)
//
// We can go a step further here and probably allow this to be used for all of our transactions.
// First, we can try to check the simulation gas amount and deduct it to the value inside the
// message object itself (like what Keplr does). Second, we can try to determine what token is
// being used by probabl checking the chain-registry
//
// @TODO: We definitely should add this to "Withdraw" flow or any flows that are executed on the Stride side.
//
// @TODO: I accidentally increased the deduction amount and we ended up crashing Liquid Staking because we
// were basically Liquid Staking a negative value. We might want to keep value to 0 if it ends up being negative.
// This is a safety rail for the safety rail. But we need to explore this further. My hunch is we probably should
// update the minimum value. But we might want to check how Keplr handles this first.
const getLiquidStakeSafetyDeduction = ({
  amount,
  denom,
  strideAccount
}: IsSafetyDeductionRequiredParameters): bigint => {
  // We'll only deduct if the user is staking TIA/DYM/SAGA for safety
  // @TODO: We don't want to break anyone's experience, so we'll roll out this feature slowly, one chain at a time.
  if (denom !== 'TIA' && denom !== 'DYM' && denom !== 'SAGA') {
    return BigInt(0)
  }

  // STRD on Stride
  const strdBalance = strideAccount.coins.find((coin) => {
    return coin.denom === STRIDE_CHAIN_INFO.stakeCurrency.coinMinimalDenom
  })

  // If user has enough STRD for this transaction, then we can assume it is being used.
  if (strdBalance && new BigNumber(strdBalance.amount).gt(TIA_LIQUID_STAKE_STRD_GAS_ESTIMATE)) {
    return BigInt(0)
  }

  // @TODO: We probably should not be using a guesstimate (refer to the implementation for Withdraw), and instead rely
  // on the the value from simulate(...)
  const gasEstimateInMicroDenom = convertDenomToMicroDenom(
    String(CHAIN_CONFIG[denom].classicLsGasEstimate),
    CHAIN_INFO_LIST[denom].stakeCurrency.coinMinimalDenom
  )

  // TIA on Stride
  const nativeBalanceOnStride = strideAccount.coins.find((coin) => {
    return coin.denom === CHAIN_CONFIG[denom].ibcDenom
  })

  // If native balance does not exist, then it likely means that we have a cached value
  // of the user's balances (before the user started the IBC step). We can safely assume
  // that the full TIA balance on Stride is going to be used, and we'll have to deduct
  // a safe amount.
  if (nativeBalanceOnStride == null) {
    return gasEstimateInMicroDenom
  }

  const endingBalance = gasEstimateInMicroDenom - amount

  // Whenever the user's ending balance is less than the gas estimate, we'll have to deduct
  // the gas estimate from the total value.
  if (gasEstimateInMicroDenom > endingBalance) {
    return gasEstimateInMicroDenom
  }

  return BigInt(0)
}

export { getLiquidStakeSafetyDeduction }
