import React, { useMemo } from 'react'
import { useAtom } from 'jotai'
import { StepperProvider, StepperModal, useStepper } from '@/components'
import { StakeAutopilotModalStepOne } from './StakeAutopilotModalStepOne'
import { StakeAutopilotModalStepTwo } from './StakeAutopilotModalStepTwo'
import { StakeAutopilotModalStepThree } from './StakeAutopilotModalStepThree'
import { useDexPoolApyQuery } from '../queries'
import { stakeAmountAtom, stakeAutopilotModalIsOpenAtom } from '../atoms'
import { useStakeAutopilotModal } from './StakeAutopilotModalProvider'
import { useSelectedCoin } from '@/contexts/wallet'
import { formatDenom } from '@/wallet-utils'

interface StakeAutopilotModalProps {
  onSuccess: () => void
}

// Autopilot is now our de-facto standards for most transactions. However,
// if the user happens to have some tokens on Stride or if a chain does not
// yet support Autopilot, the classic staking flow can take care of it.
const StakeAutopilotModal: React.FC<StakeAutopilotModalProps> = ({ onSuccess }) => {
  // Preload APY data ahead
  useDexPoolApyQuery()

  const denom = useSelectedCoin()

  const [amount, setAmount] = useAtom(stakeAmountAtom)

  const [isOpen, setIsOpen] = useAtom(stakeAutopilotModalIsOpenAtom)

  const {
    isBroadcastingStakeAutopilot,
    isStakeAutopilotStatusLoading,
    signStakeAutopilotError,
    broadcastStakeAutopilotError,
    stakeAutopilotStatusError,
    resetStakeAutopilotModalState
  } = useStakeAutopilotModal()

  const formattedAmount = `${formatDenom(amount, 5)} ${denom}`

  const steps = useMemo(() => {
    return ['Approve staking in wallet', `Staking ${denom} on Stride`, `Stake ${denom}, get st${denom}`]
  }, [denom])

  const handleClose = () => {
    setAmount(0)
    setIsOpen(false)
    resetStakeAutopilotModalState()
  }

  const stepper = useStepper({
    steps,
    warning: isStakeAutopilotStatusLoading,
    error: Boolean(signStakeAutopilotError || broadcastStakeAutopilotError || stakeAutopilotStatusError),
    isOpen,
    isLoading: isBroadcastingStakeAutopilot || isStakeAutopilotStatusLoading,
    isPostError: false,
    isComplete: false,
    onClose: handleClose,
    onComplete: onSuccess
  })

  return (
    <StepperProvider {...stepper}>
      <StepperModal title="Liquid Staking" amount={formattedAmount}>
        <StakeAutopilotModalStepOne />
        <StakeAutopilotModalStepTwo />
        <StakeAutopilotModalStepThree />
      </StepperModal>
    </StepperProvider>
  )
}

export { StakeAutopilotModal }
