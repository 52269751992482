import { useMutation } from '@tanstack/react-query'
import { fatal } from '@/utils'
import { CHAIN_CONFIG } from '@/config'
import {
  convertDenomToMicroDenom,
  generateIbcTimeoutTimestamp,
  signIbcTransaction,
  IbcReturnType,
  TX_MSG,
  isSafeModeAccount,
  simulateIbcTransaction,
  calculateFee
} from '@/wallet-utils'
import { MutationParameters } from './types'
import { useStake } from '../../StakeProvider'
import { useSelectedWallet, useStrideWallet } from '@/contexts/wallet'
import { flushSync } from 'react-dom'

const useSignIbcTransferMutation = ({
  tokenizeSharesDenom,
  tokenizeSharesTokenValue,
  setIbcTransferRaw
}: MutationParameters) => {
  const strideAccount = useStrideWallet()
  const selectedAccount = useSelectedWallet()

  const { lsm } = useStake()

  const handleMutation = async (): Promise<IbcReturnType> => {
    if (!strideAccount || !selectedAccount) {
      throw fatal('You are unable to send token without connecting your wallet.')
    }

    // Use the prefilled data from the transaction history if available.
    // Otherwise, we'll default to the data set in `useBroadcastTokenizeShares`.
    const tokenizeSharesDenomToIbc = lsm.txHistoryTokenizedDenom || tokenizeSharesDenom

    if (!tokenizeSharesDenomToIbc) {
      throw fatal('Unable to ibc transfer without `tokenizeShareRecordId`.')
    }

    // `tokenizeSharesTokenValue` is the accurate token value that was tokenized set by `useBroadcastTokenizeShares`.
    // Otherwise, we'll use `lsm.amount` which means that the user continued lsm from the transaction history.
    const amountInMicroDenom = tokenizeSharesTokenValue
      ? tokenizeSharesTokenValue
      : convertDenomToMicroDenom(lsm.amount, selectedAccount.currency.coinMinimalDenom)

    const msgTransfer = {
      typeUrl: TX_MSG.MsgTransfer,
      value: {
        sourcePort: 'transfer',
        sourceChannel: CHAIN_CONFIG[selectedAccount.currency.coinDenom].depositChannel,
        token: {
          amount: amountInMicroDenom.toString(),
          denom: tokenizeSharesDenomToIbc
        },
        sender: selectedAccount.address,
        receiver: strideAccount.address,
        memo: '',
        timeoutTimestamp: generateIbcTimeoutTimestamp()
      }
    }

    if (isSafeModeAccount(selectedAccount)) {
      throw fatal('Safe mode is enabled.')
    }

    const gas = await simulateIbcTransaction(selectedAccount, selectedAccount.address, [msgTransfer], '')
    const fee = calculateFee(selectedAccount, gas)

    return await signIbcTransaction(selectedAccount, selectedAccount.address, [msgTransfer], fee, '')
  }

  const handleSuccess = (raw: IbcReturnType) => {
    // This is the only way to allow React v18 to update the state first before
    // the next mutation (which requires this state to have a value) is executed
    flushSync(() => {
      setIbcTransferRaw(raw)
    })
  }

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: handleSuccess
  });
}

export { useSignIbcTransferMutation }
