import React from 'react'
import { Button } from '@mantine/core'
import { useMount } from '@/hooks'
import { StepperModalContent, useStepperContext } from '@/components'
import { notify } from '@/contexts/notifications'
import { useUnstakeModal } from './UnstakeModalProvider'
import { StandardTransactionError } from '@/wallet-utils'

const UnstakeModalStepTwo: React.FC = () => {
  const { broadcastRedeemStake, isBroadcastingRedeemStake, broadcastRedeemStakeError } = useUnstakeModal()

  const { nextStep, forceClose, handleClose } = useStepperContext()

  const handleStep = async () => {
    await broadcastRedeemStake()
    nextStep()
  }

  const handleCloseCallback = () => {
    // If it was closed after the broadcast completed, it doesn't matter
    if (!isBroadcastingRedeemStake) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the unstake completes.")
  }

  useMount(() => {
    handleStep()
  })

  handleClose(handleCloseCallback)

  const handleRetryBroadcastStakeTokens = () => {
    handleStep()
  }

  if (broadcastRedeemStakeError instanceof StandardTransactionError) {
    // Generally, INSUFFICIENT_FUNDS (code 5) and OUT_OF_GAS (code 11) can mean the same thing.
    const isOutOfGas =
      broadcastRedeemStakeError.description === 'INSUFFICIENT_FUNDS' ||
      broadcastRedeemStakeError.description === 'OUT_OF_GAS'

    // @TODO: Update out of gas message to be more friendly and relevant
    const description = isOutOfGas
      ? `Transaction failed due to out of gas. Please try again.`
      : broadcastRedeemStakeError.message

    return (
      <StepperModalContent
        title="Transaction error"
        description={description}
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" onClick={handleRetryBroadcastStakeTokens}>
              Try again
            </Button>
          </>
        }
        error={broadcastRedeemStakeError}
      />
    )
  }

  if (broadcastRedeemStakeError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="Your tokens couldn’t be unstaked at this time. Try again in a few minutes."
        actions={
          <>
            <Button variant="outline" color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" onClick={handleRetryBroadcastStakeTokens}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  return (
    <StepperModalContent
      title="Unstaking your tokens..."
      description="Just a few seconds, unless the network is congested."
    />
  )
}

export { UnstakeModalStepTwo }
