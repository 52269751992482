import React, { useState } from 'react'
import { useMount, useLatestValue } from '@/hooks'
import { Anchor, Button } from '@mantine/core'
import { notify } from '@/contexts/notifications'
import { StepperModalContent, useStepperContext } from '@/components'
import { useStakeLsmModal } from './StakeLsmModalProvider'
import { useStake } from '../StakeProvider'
import {
  LsmValidatorName,
  RateLimitClosedLiquidStakeModalWarning,
  RateLimitTightLiquidStakeModalWarning
} from '../shared'
import { useHostZoneQuery, useRateLimitQuery } from '../queries'
import { CHAIN_INFO_LIST } from '@/config'
import { useSelectedCoin } from '@/contexts/wallet'
import { convertDenomToMicroDenom, StandardSimulationError, StandardTransactionError } from '@/wallet-utils'
import { getRateLimitMetaData } from '../utils'

const StakeLsmModalStepOne: React.FC = () => {
  const { lsm } = useStake()

  const { data: hostZone } = useHostZoneQuery()

  const { data: rateLimit } = useRateLimitQuery()

  const denom = useSelectedCoin()

  const chainInfo = CHAIN_INFO_LIST[denom]

  const amountInMicroDenom = convertDenomToMicroDenom(lsm.amount, chainInfo.stakeCurrency.coinMinimalDenom)

  const rateLimitMetaData = getRateLimitMetaData({ amount: amountInMicroDenom.toString(), rateLimit })

  const [isRateLimitingEnabled, setIsRateLimitingEnabled] = useState(true)

  const {
    signTokenizeShares,
    isSigningTokenizeShares,
    signTokenizeSharesError,
    broadcastTokenizeShares,
    isBroadcastingTokenizeShares,
    broadcastTokenizeSharesError
  } = useStakeLsmModal()

  const { start, nextStep, jumpStep, forceClose, handleClose } = useStepperContext()

  const isOpenRef = useLatestValue(lsm.isOpen)

  const handleBroadcast = async () => {
    if (!isOpenRef.current) {
      // This function doesn't get cancelled when the modal gets closed.
      // If we get here when pool is already closed, it's likely that the stuff that
      // `traceIbcStatus` (transactionRef) needs has already been reset by the
      // modal close handler.
      return
    }

    await broadcastTokenizeShares()

    nextStep()
  }

  const handleStart = async () => {
    start()
    await signTokenizeShares()
    await handleBroadcast()
  }

  const handleJumpToIbc = () => {
    start()
    jumpStep(1)
  }

  const handleJumpToLs = () => {
    start()
    jumpStep(2)
  }

  const handleDismissRateLimitingWarning = () => {
    setIsRateLimitingEnabled(false)
  }

  useMount(() => {
    if (lsm.txHistoryTokenizedDenom) {
      handleJumpToIbc()
    } else if (lsm.txHistoryLsIbcDenom || lsm.txHistoryIbcDenom) {
      handleJumpToLs()
    }
  })

  const handleCloseCallback = () => {
    // If it was closed after the broadcast, we don't need to show the toast.
    if (!isBroadcastingTokenizeShares) {
      return
    }

    notify.progress('Transaction minimized', "We'll let you know when the tokenization completes.")
  }

  handleClose(handleCloseCallback)

  if (hostZone == null) {
    return null
  }

  if (
    broadcastTokenizeSharesError instanceof StandardTransactionError &&
    broadcastTokenizeSharesError.description === 'LSM_INSUFFICIENT_VALIDATOR_BOND_SHARES'
  ) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`The validator does not have sufficient self bond to accept liquid stakes. Try to liquid stake a smaller amount or let them know you support liquid staking so they can increase their limit.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
        error={broadcastTokenizeSharesError}
      />
    )
  }

  if (
    broadcastTokenizeSharesError instanceof StandardTransactionError &&
    broadcastTokenizeSharesError.description === 'LSM_TOKENIZE_EXCEEDS_GLOBAL_CAPACITY'
  ) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`With your liquid stake, the global ${denom} liquid staking limit of 25% would be surpassed. Please try to liquid stake a smaller amount or try again later.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
        error={broadcastTokenizeSharesError}
      />
    )
  }

  if (
    broadcastTokenizeSharesError instanceof StandardTransactionError &&
    broadcastTokenizeSharesError.description === 'LSM_TOKENIZE_EXCEEDS_VALIDATOR_CAPACITY'
  ) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            This amount exceeds the liquid staking limit for{' '}
            <strong>
              <LsmValidatorName address={lsm.validatorAddress} />
            </strong>{' '}
            validator. Please try to liquid stake a smaller amount.
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
        error={broadcastTokenizeSharesError}
      />
    )
  }

  if (
    broadcastTokenizeSharesError instanceof StandardTransactionError &&
    broadcastTokenizeSharesError.description === 'LSM_TOKENIZE_SHARES_DISABLED_FOR_ACCOUNT'
  ) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={
          <>
            Your account has disabled share tokenization. If you'd like to liquid stake your staked ATOM, please enable{' '}
            <Anchor href="https://docs.cosmos.network/main/modules/staking#msgenabletokenizeshares" target="_blank">
              tokenize shares
            </Anchor>
            .
          </>
        }
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
        error={broadcastTokenizeSharesError}
      />
    )
  }

  if (
    broadcastTokenizeSharesError instanceof StandardTransactionError &&
    broadcastTokenizeSharesError.description === 'LSM_TOKENIZE_DISABLED_REDELEGATION_IN_PROGRESS'
  ) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="Liquid staking is disabled due to an on-going redelegation. Please try again when the redelegation completes."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
        error={broadcastTokenizeSharesError}
      />
    )
  }

  if (broadcastTokenizeSharesError instanceof StandardTransactionError) {
    // From my experience, we can get `INSUFFICIENT_FUNDS` (code 5) even if we simply ran out of gas.
    // Possible that Cosmos is *confused* because we use `uatom` as gas and the token shares are
    // technically uatom (at least if you inspect the transaction and what denom we're trying to delegate/tokenize).
    //
    // https://github.com/Stride-Labs/interface/issues/529
    // @TODO: When we try fixing how retry broadcast works, it's possible that when their ATOMs
    // are used as gas, they'll have less, and so we might want to cover for that.
    const isOutOfGas =
      broadcastTokenizeSharesError.description === 'INSUFFICIENT_FUNDS' ||
      broadcastTokenizeSharesError.description === 'OUT_OF_GAS'

    // @TODO: Update out of gas message to be more friendly and relevant
    const description = isOutOfGas
      ? `Transaction failed due to out of gas. Please try again.`
      : broadcastTokenizeSharesError.message

    return (
      <StepperModalContent
        title="Transaction error"
        description={description}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
        error={broadcastTokenizeSharesError}
      />
    )
  }

  if (broadcastTokenizeSharesError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`This transaction could not be completed. Your staked ${denom}s have not been tokenized.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleBroadcast}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isBroadcastingTokenizeShares) {
    return (
      <StepperModalContent
        title={`Tokenizing your staked ${denom} to Cosmos Hub...`}
        description="Just a few seconds, unless the network is congested."
      />
    )
  }

  if (
    signTokenizeSharesError instanceof StandardSimulationError &&
    signTokenizeSharesError.description === 'LSM_INSUFFICIENT_VALIDATOR_BOND_SHARES'
  ) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`The validator does not have sufficient self bond to accept liquid stakes. Try to liquid stake a smaller amount or let them know you support liquid staking so they can increase their limit.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
        error={signTokenizeSharesError}
      />
    )
  }

  if (
    signTokenizeSharesError instanceof StandardSimulationError &&
    signTokenizeSharesError.description === 'LSM_REDELEGATION_IN_PROGRESS'
  ) {
    return (
      <StepperModalContent
        title="Transaction error"
        description="Liquid staking is disabled due to an on-going redelegation. Please try again when the redelegation completes."
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>
          </>
        }
      />
    )
  }

  if (signTokenizeSharesError instanceof StandardSimulationError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={signTokenizeSharesError.message}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleStart}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (signTokenizeSharesError) {
    return (
      <StepperModalContent
        title="Transaction error"
        description={`This transaction could not be completed. Your staked ${denom}s have not been tokenized.`}
        actions={
          <>
            <Button color="dark" onClick={forceClose}>
              Exit
            </Button>

            <Button color="dark" variant="outline" onClick={handleStart}>
              Try again
            </Button>
          </>
        }
      />
    )
  }

  if (isSigningTokenizeShares) {
    return (
      <StepperModalContent
        title="Approve the transaction in your wallet to continue"
        description={`This will start the tokenization of your staked ${denom} tokens to Cosmos Hub.`}
      />
    )
  }

  if (isRateLimitingEnabled && rateLimitMetaData.status === 'tight') {
    return <RateLimitTightLiquidStakeModalWarning amount={lsm.amount} onContinue={handleDismissRateLimitingWarning} />
  }

  if (isRateLimitingEnabled && rateLimitMetaData.status === 'closed') {
    return <RateLimitClosedLiquidStakeModalWarning onContinue={handleDismissRateLimitingWarning} />
  }

  return (
    <StepperModalContent
      title={`Nice! You’re about to convert ${lsm.amount} staked ${denom} to liquid staked ${denom}`}
      description="This should take about a minute and will require 3 wallet approvals"
      actions={<Button onClick={handleStart}>Start liquid staking</Button>}
    />
  )
}

export { StakeLsmModalStepOne }
