import { format } from 'date-fns'
import { LsmLiquidStakeMetaData, MsgLsmLiquidStake } from '@/wallet-utils'
import { MetaDataFactoryParameters } from './types'
import { getValidatorAddressFromLsmTokenizedShare, getLsmLiquidStakeAttributes } from '@/page-components/Stake/utils'

const createLsmLiquidStakeMetaData = ({
  response,
  message,
  selectedAccount
}: MetaDataFactoryParameters<MsgLsmLiquidStake>) => {
  const attributes = getLsmLiquidStakeAttributes(response.events)

  const meta: LsmLiquidStakeMetaData = {
    type: 'lsm-liquid-stake',
    values: {
      hash: response.txhash,
      // Amount of tokenized shares (not token value); this means in the component, we likely need to
      // multiply this against the validator's shares_to_token_rate to display the correct token value.
      amount: message.amount,
      // @TODO: Check the value if the transaction failed due to a slash. I feel like this has to be missing
      // from the attributes? I don't know haha.
      stTokenAmount: attributes.sttoken_amount,
      queryCallbackId: attributes.transaction_status === 'pending' ? attributes.lsm_liquid_stake_tx_id : '',
      validatorAddress: getValidatorAddressFromLsmTokenizedShare(attributes.lsm_token_base_denom),
      status: attributes.transaction_status,
      ibcDenom: attributes.native_ibc_denom,
      denom: selectedAccount.currency.coinDenom,
      minimalDenom: selectedAccount.currency.coinMinimalDenom
    },
    // @TODO: Refactor this to be unformatted and let StakeTransactionList do its job
    startedAt: format(new Date(response.timestamp), 'MMMM d, yyyy h:mm O'),
    completedAt: null
  }

  return meta
}

export { createLsmLiquidStakeMetaData }
