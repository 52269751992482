import React, { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { Loader } from '@mantine/core'
import { ErrorBeacon } from '@/components'

import { useIbcxTokensMarketPriceQuery } from './queries'
import { useSelectedCoin } from '@/contexts/wallet'

interface StakeEstimateProps {
  amount: number
  // StakeIbcxEstimate expects its parent component to memoize this function
  // by either moving it out of the render function or wrapping it in `useCallback`
  format?: (total: number) => string | number
  loading?: boolean
  mode: 'stake' | 'unstake'
}

const defaultFormat = (n: number) => new BigNumber(n).decimalPlaces(2).toString()

const StakeIbcxEstimate: React.FC<StakeEstimateProps> = ({ amount, mode, loading, format = defaultFormat }) => {
  const denom = useSelectedCoin()

  const {
    data: marketPrice,
    isLoading: isMarketPriceLoading,
    error: marketPriceError
  } = useIbcxTokensMarketPriceQuery()

  const estimate = useMemo(() => {
    if (!amount || !marketPrice) {
      return format(0)
    }

    const value =
      mode === 'stake'
        ? new BigNumber(amount).dividedBy(marketPrice.strideStTokenValue)
        : new BigNumber(amount).multipliedBy(marketPrice.strideStTokenValue)

    return format(value.toNumber())
  }, [marketPrice, amount, format, mode, denom])

  if (marketPriceError) {
    return <ErrorBeacon label="Unable to fetch redemption rate at this time. Please try again." />
  }

  if (isMarketPriceLoading || loading) {
    return <Loader variant="dots" color="dark" size="xs" />
  }

  return <span>{estimate}</span>
}

export { StakeIbcxEstimate }
